import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import imagePlaceholder from "../../../images/placeholder.png";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { 
  Badge,
  UncontrolledDropdown, 
  DropdownItem, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, 
  Alert 
} from "reactstrap";
import moment from "moment";
import { Empty, Input, InputNumber, Select, Form } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { findUpper, getAxiosHeaders } from "../../../utils/Utils";
import { 
  AlertModal,
  SuccessModal,
  LoadingModal 
 } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';

export const TaxSettings = ({ history, match }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, taxes:[]});
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const [taxFields, setTaxFields] = useState([]);
  const [taxProfileID, setTaxProfileID] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    tax_profile_name: '',
    taxes: [],
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleAddFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/businesses/taxes/${businessID}`, {
      tax_profile_name: _formData.tax_profile_name,
      taxes: JSON.stringify(_formData.taxes)
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      
      setSuccessTitle(`Tax Profile Added`);
      setSuccessMessage(`The tax profile has been added successfully.`);
      toggleModal("successModal");

      getTaxProfiles(businessID);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'tax_profile_name',
              errors: errorResponse.errors.hasOwnProperty("tax_profile_name") ? [errorResponse.errors.tax_profile_name] : [],
            },
            {
              name: 'taxes',
              errors: errorResponse.errors.hasOwnProperty("taxes") ? [errorResponse.errors.taxes] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const handleEditFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/businesses/taxes/${taxProfileID}`, {
      business_id: businessID,
      tax_profile_name: _formData.tax_profile_name,
      taxes: JSON.stringify(_formData.taxes)
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();

      setSuccessTitle(`Tax Profile Updated`);
      setSuccessMessage(`The tax profile has been updated successfully.`);
      toggleModal("successModal");

      setRequesting(false);
      getTaxProfiles(businessID);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'tax_profile_name',
              errors: errorResponse.errors.hasOwnProperty("tax_profile_name") ? [errorResponse.errors.tax_profile_name] : [],
            },
            {
              name: 'taxes',
              errors: errorResponse.errors.hasOwnProperty("taxes") ? [errorResponse.errors.taxes] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const deleteTaxProfile = () => {
    toggleModal("deleteLoadingModal");

    axios.delete(baseURLs.API_URL + `/businesses/taxes/${taxProfileID}`, {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID
      }
    })
    .then((response) => {

      setSuccessTitle(`Tax Profile Deleted`);
      setSuccessMessage(`The tax profile has been deleted successfully.`);
      toggleModal("successModal");
      getTaxProfiles(businessID);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getTaxProfiles = (businessID) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/businesses/taxes`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, taxes:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, taxes:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleAddTax = () => {
    setTaxFields([...taxFields, { tax_name: '' }]); // Each time you add, you append a new empty object
  };

  const handleRemoveTax = (index) => {
    const newFields = [...taxFields];
    newFields.splice(index, 1);
    setTaxFields(newFields);
  };

  const handleEditTax = (data) => {
    setTaxProfileID(data.tax_id);

    setFormData({
      tax_profile_name: data.tax_profile_name,
      taxes: data.taxes,
    });

    setTaxFields([...data.taxes]);

    form.setFieldsValue({
      tax_profile_name: data.tax_profile_name, 
      taxes: data.taxes
    });

    toggleModal('editTaxModal');
  }

  const handleDeleteTax = (data) => {
    setTaxProfileID(data.tax_id);
    toggleModal('deleteAlertModal');
  }

  const toggleModalClose = () => {
    setFormData({...formData, tax_profile_name: "", taxes: []});
    form.setFieldsValue({
      tax_profile_name: "", 
      taxes: []
    });

    setTaxFields([]);
    toggleModal(null);
  }

  useEffect(() => {
    setLoading(true);  
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);  
    getTaxProfiles(businessID);
  }, [match.params.businessID]);


  return (
    <React.Fragment>
      <Head title="Tax Profile Settings"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Tax Profile Settings
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update your tax information.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              errorMessage &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              </Block>
            }
            <div className="card">
              <div className="fm-content">
                {
                  tableData.taxes.length === 0 ?
                  <div className="nk-block">
                    <div className="text-center m-5">
                      <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">No tax found</span>
                      </div>
                      <div className="price-plan-action">
                        <button className="btn btn-primary btn-sm" onClick={() => toggleModal('newTaxModal')}>Add a Tax Profile</button>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="nk-block">
                    <div className="card-title-group mb-3">
                      <h6 className="lead-text m-0">
                        {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Tax Profiles` : `Tax Profile`}`}
                      </h6>
                      <div className="card-tools">
                        <ul className="btn-toolbar">
                          <li>
                            <button className="btn btn-primary btn-sm" onClick={() => toggleModal('newTaxModal')}>Add a Tax Profile</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-bordered card-full card">
                      <ul className="nk-support">
                        {
                          tableData.taxes.map((data, index) => {                    
                            return (
                              <li className="nk-support-item" key={data.tax_id}>
                                <div className="user-avatar bg-gray-dim sm">
                                  <span>{findUpper(data.tax_profile_name)}</span>
                                </div>
                                <div className="nk-support-content">
                                  <div className="title">
                                    <span>{data.tax_profile_name}</span>
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <DropdownItem tag="a" className="pointer-cursor" onClick={() => handleEditTax(data)}>
                                              <Icon name="edit-alt" />
                                              <span>Edit</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleDeleteTax(data)}>
                                              <Icon name="trash" />
                                              <span>Delete</span>
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <ul className="d-flex g-2">
                                    {
                                      data.taxes.map((tax, tIndex) => {
                                        return (
                                          <li key={tIndex}>
                                            <Badge title={`${tax.type} tax`} pill color={tax.type === 'compound' ? `outline-primary` : `outline-secondary`} >{tax.tax_name} - {tax.percentage}%</Badge>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                  <p className="time mt-1">Created at: {moment(data.created_at).format("Do MMM YYYY")}</p>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                    {/* <PaginationWithOnclick currentPage={page} pageCount={totalPages} loadNextPage={loadNextPage} />           */}
                  </div>
                }
              </div>
            </div>

          </Block>
        </>
      }
      </Content>

      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {
        activeModal === "deleteAlertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
          headerText={`Delete Tax Profile?`} 
          descriptionText={`Are you sure you want to delete this tax profile? This action can not be reverted.`}
          leftButtonText={`Delete`}
          leftButtonOnClick={deleteTaxProfile} />
      }

      {
        activeModal === "alertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }

      { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the tax profile is being deleted`} /> }

      {
        activeModal === "newTaxModal" &&
        <Modal backdrop="static" isOpen={true} id="create_tax_modal">
          <ModalHeader
            toggle={() => toggleModalClose('create')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModalClose()}>
                <Icon name="cross" />
              </button>
            }
          >
            Create a Tax Profile
          </ModalHeader>
          <Form name="add-tax-profile"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleAddFormSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              
                <div className="form-group">
                  <Form.Item>
                    <label className="form-label">
                      Tax Profile Name
                    </label>
                    <Form.Item name={'tax_profile_name'}
                      noStyle
                      rules={[
                        {required: true, message: 'Tax profile name is required',}
                      ]}>
                      <Input size="large" placeholder="eg. Standard Taxation" />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="col-md-12 mt-5">
                  <hr className="m-3 mx-0" />
                  <h6 className="overline-title text-primary-alt my-4">Taxes</h6>
                </div>
                <div className="col-md-12">
                  <div className="card-bordered card-full card">
                    {
                      taxFields.length === 0 ?
                      <div className="nk-block">
                        <div className="text-center m-5">
                          <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                          <div className="price-plan-info">
                            <span className="title fw-normal">No tax added</span>
                          </div>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddTax()}>Add Tax</button>
                          </div>
                        </div>
                      </div>
                      :
                      <ul className="nk-support">
                        {
                          taxFields.map((tax, index) => (
                            <li className="nk-support-item" key={index}>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveTax(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">#{index + 1}. Tax Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['taxes', index, 'tax_name']}
                                      rules={[{ required: true, message: 'Please enter a tax name' }]}>
                                      <Input type="text" placeholder="eg. VAT" />
                                    </Form.Item>
                                  </div>                                        
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">Percentage <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['taxes', index, 'percentage']}
                                      rules={[{ required: true, message: 'Please enter a percentage' }]}
                                    >
                                      <InputNumber min={0} max={100} step={0.01} addonAfter="%" placeholder="eg. 12" />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">Tax Type 
                                        <span className="text-danger">*</span>
                                        <Button type="button" id="exp_alert_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                          <Icon name="info" />
                                        </Button>
                                        <UncontrolledPopover target="exp_alert_info_popover" placement="bottom" trigger="focus">
                                          <PopoverBody>
                                            Select 'Compound Tax' if this tax affects the total amount including previously applied taxes. Choose 'Direct Tax' if this tax is applied directly to the subtotal of the items in the sale.
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </label>
                                    </div>
                                    <Form.Item name={['taxes', index, 'type']}
                                      noStyle
                                      rules={[
                                        {required: true, message: `Tax type is required`}
                                      ]}>
                                      <Select 
                                        placeholder="eg. Compound"
                                        style={{ width: "100%" }} 
                                        options={[
                                          {
                                            key: 'compound',
                                            label: 'Compound Tax',
                                            value: 'compound',
                                          },
                                          {
                                            key: 'direct',
                                            label: 'Direct Tax',
                                            value: 'direct',
                                          },                              
                                        ]}
                                        showSearch />   
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        }
                      </ul>                            
                    }
                  </div>
                  
                </div>
                {
                  taxFields.length > 0 && 
                  <div className="col-md-12 text-center mt-4">
                    <Button className="m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddTax()} >
                      <span>Add Tax</span>
                    </Button>
                  </div>
                }
              
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModalClose()}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }

      {
        activeModal === "editTaxModal" &&
        <Modal backdrop="static" isOpen={true} id="edit_tax_modal">
          <ModalHeader
            toggle={() => toggleModalClose()}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModalClose()}>
                <Icon name="cross" />
              </button>
            }
          >
            Edit Tax Profile
          </ModalHeader>
          <Form name="edit-tax-profile"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleEditFormSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              
                <div className="form-group">
                  <Form.Item>
                    <label className="form-label">
                      Tax Profile Name
                    </label>
                    <Form.Item name={'tax_profile_name'}
                      noStyle
                      rules={[
                        {required: true, message: 'Tax profile name is required',}
                      ]}>
                      <Input size="large" placeholder="eg. Standard Taxation" />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="col-md-12 mt-5">
                  <hr className="m-3 mx-0" />
                  <h6 className="overline-title text-primary-alt my-4">Taxes</h6>
                </div>
                <div className="col-md-12">
                  <div className="card-bordered card-full card">
                    {
                      taxFields.length === 0 ?
                      <div className="nk-block">
                        <div className="text-center m-5">
                          <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                          <div className="price-plan-info">
                            <span className="title fw-normal">No tax added</span>
                          </div>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddTax()}>Add Tax</button>
                          </div>
                        </div>
                      </div>
                      :
                      <ul className="nk-support">
                        {
                          taxFields.map((tax, index) => (
                            <li className="nk-support-item" key={index}>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveTax(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">#{index + 1}. Tax Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['taxes', index, 'tax_name']}
                                      rules={[{ required: true, message: 'Please enter a tax name' }]}>
                                      <Input type="text" placeholder="eg. VAT" />
                                    </Form.Item>
                                  </div>                                        
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">Percentage <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['taxes', index, 'percentage']}
                                      rules={[{ required: true, message: 'Please enter a percentage' }]}
                                    >
                                      <InputNumber min={0} max={100} step={0.01} addonAfter="%" placeholder="eg. 12" />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">Tax Type 
                                        <span className="text-danger">*</span>
                                        <Button type="button" id="exp_alert_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                          <Icon name="info" />
                                        </Button>
                                        <UncontrolledPopover target="exp_alert_info_popover" placement="bottom" trigger="focus">
                                          <PopoverBody>
                                            Select 'Compound Tax' if this tax affects the total amount including previously applied taxes. Choose 'Direct Tax' if this tax is applied directly to the subtotal of the items in the sale.
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </label>
                                    </div>
                                    <Form.Item name={['taxes', index, 'type']}
                                      noStyle
                                      rules={[
                                        {required: true, message: `Tax type is required`}
                                      ]}>
                                      <Select 
                                        placeholder="eg. Compound"
                                        style={{ width: "100%" }} 
                                        options={[
                                          {
                                            key: 'compound',
                                            label: 'Compound Tax',
                                            value: 'compound',
                                          },
                                          {
                                            key: 'direct',
                                            label: 'Direct Tax',
                                            value: 'direct',
                                          },                              
                                        ]}
                                        showSearch />   
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        }
                      </ul>                            
                    }
                  </div>
                  
                </div>
                {
                  taxFields.length > 0 && 
                  <div className="col-md-12 text-center mt-4">
                    <Button className="m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddTax()} >
                      <span>Add Tax</span>
                    </Button>
                  </div>
                }
              
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModalClose()}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </React.Fragment>
  )
}