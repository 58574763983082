import React, { useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { RedirectAs404, getAuthStatus } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";
import TawkToWidget from "./utils/TawkToWidget";

import Layout from "./layout/Index";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPasswordSuccess from "./pages/auth/ForgotPasswordSuccess";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordSuccess from "./pages/auth/ResetPasswordSuccess";
import AccountActivation from "./pages/auth/AccountActivation";
import PendingAccountActivation from "./pages/auth/PendingAccountActivation";
import Checkout from "./pages/auth/Checkout";
import Error504Modern from "./pages/error/504-modern";
import ExpiredSession from "./pages/auth/ExpiredSession";


const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = getAuthStatus();
  return (
    <Route {...rest} render={(props) => (
      !isAuthenticated ? <Component {...props} />
    : <Redirect to={{ pathname: '/my-businesses', state: { from: props.location } }} />
    )} />
  );
}


const App = () => {

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          // Exclude Firebase messaging SW by checking the service worker script URL
          if (!registration.active.scriptURL.includes('firebase-messaging-sw')) {
            registration.unregister();
          }
        });
      }).catch(error => {
        console.error("Error unregistering service workers:", error);
      });
    }
  }, []);

  return (
    <>
      <TawkToWidget />
      <Switch>
        {/* Auth Pages */}
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/register`} component={Register} />
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPassword}></AuthRoute>
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/forgot-password-success`} component={ForgotPasswordSuccess}></AuthRoute>
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/reset-password`} component={ResetPassword}></AuthRoute>
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/reset-password/:token`} component={ResetPassword}></AuthRoute>
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/reset-password-success`} component={ResetPasswordSuccess}></AuthRoute>
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/account-verification/:token`} component={AccountActivation}></AuthRoute>
        <AuthRoute exact path={`${process.env.PUBLIC_URL}/pending-account-verification`} component={PendingAccountActivation}></AuthRoute>
        
        {/* checkout route */}
        <Route exact path={`${process.env.PUBLIC_URL}/cart/:cart_token`} component={Checkout} />
        
        <Route exact path={`${process.env.PUBLIC_URL}/expired-session`} component={ExpiredSession} />
        <Route exact path={`${process.env.PUBLIC_URL}/server-offline`} component={Error504Modern} />
        
        {/*Main Routes*/}
        <PrivateRoute exact path="" component={Layout}></PrivateRoute>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </>
  );
};
export default withRouter(App);
