import React, { useState, useEffect } from "react";
import Logo from "../../layout/logo/Logo";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button } from "../../components/Component";
import { deleteCookie } from "../../utils/Utils";

const ExpiredSession = () => {
  const [countdown, setCountdown] = useState(3); // 3-second countdown

  const handleLogout = () => {
    deleteCookie("access_token");
    localStorage.clear();
    window.location = `${process.env.PUBLIC_URL}/login`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // Redirect after countdown finishes
    if (countdown === 0) {
      handleLogout();
    } else {
      // Cleanup the timer
      return () => clearInterval(timer);
    }

    
  }, [countdown]);

  return (
    <React.Fragment>
      <Head title="Expired Session" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Logo />
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Expired Session</BlockTitle>
              <BlockDes>
                <p className="fs-18px">
                  Your session has expired, you will be redirected to login in: {countdown} {countdown > 1 ? `seconds`: `second`}
                </p>
                <Button color="primary" size="lg" onClick={handleLogout}>
                  Go to Login
                </Button>
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default ExpiredSession;
