import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { Icon, UserAvatar, Button, LinkItem, LinkList } from "../../../components/Component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  Col,
  Badge
} from "reactstrap";

const MessageNav = ({
  aside,
  setAside,
  currentTab,
  setCurrentTab,
  navData,
  setNavData,
  toggleModal,
}) => {

  const switchView = (view) => {
    toggleModal(null);
    setAside(false);
    setCurrentTab(view)
  }

  return (
    <div className={`nk-ibx-aside toggle-screen-lg ${aside ? "content-active" : ""}`}>
      <SimpleBar className="nk-ibx-nav">
        <React.Fragment>
          <ul className="nk-ibx-menu">
            <li className={currentTab === "customers" ? "active" : ""} key={'customers'}>
              <a
                className="nk-ibx-menu-item"
                href="#menu-item"
                onClick={(ev) => {
                  ev.preventDefault();
                  switchView('customers');
                }}>
                <Icon name="happy"></Icon>
                <span className="nk-ibx-menu-text">Message Customers</span>
              </a>
            </li>
          </ul>
          <div className="nk-ibx-nav-head pb-0">
            <h6 className="overline-title text-primary-alt">SMS / Email</h6>
          </div>
          <ul className="nk-ibx-menu">
            <li className={currentTab === "m_sent" ? "active" : ""} key={'m_sent'}>
              <a
                className="nk-ibx-menu-item"
                href="#menu-item"
                onClick={(ev) => {
                  ev.preventDefault();
                  switchView('m_sent');
                }}>
                <Icon name="mail"></Icon>
                <span className="nk-ibx-menu-text">Sent</span>
                {/* <Badge pill color="light">23</Badge> */}
              </a>
            </li>
            {/* <li className={currentTab === "m_draft" ? "active" : ""} key={'m_draft'}>
              <a
                className="nk-ibx-menu-item"
                href="#menu-item"
                onClick={(ev) => {
                  ev.preventDefault();
                  switchView('m_draft');
                }}>
                <Icon name="edit"></Icon>
                <span className="nk-ibx-menu-text">Draft</span>
              </a>
            </li> */}
          </ul>

          {/* <div className="nk-ibx-nav-head pb-0">
            <h6 className="overline-title text-primary-alt">WhatsApp</h6>
          </div>
          <ul className="nk-ibx-menu">
            <li className={currentTab === "w_inbox" ? "active" : ""} key={'w_inbox'}>
              <a
                className="nk-ibx-menu-item"
                href="#menu-item"
                onClick={(ev) => {
                  ev.preventDefault();
                  switchView('w_inbox');
                }}>
                <Icon name="chat"></Icon>
                <span className="nk-ibx-menu-text">Inbox</span>
                <Badge pill color="primary">2</Badge>
              </a>
            </li>
            <li className={currentTab === "w_sent" ? "active" : ""} key={'w_sent'}>
              <a
                className="nk-ibx-menu-item"
                href="#menu-item"
                onClick={(ev) => {
                  ev.preventDefault();
                  switchView('w_sent');
                }}>
                <Icon name="send"></Icon>
                <span className="nk-ibx-menu-text">Sent</span>
              </a>
            </li>
            <li className={currentTab === "w_template" ? "active" : ""} key={'w_template'}>
              <a
                className="nk-ibx-menu-item"
                href="#menu-item"
                onClick={(ev) => {
                  ev.preventDefault();
                  switchView('w_template');
                }}>
                <Icon name="card-view"></Icon>
                <span className="nk-ibx-menu-text">Templates</span>
              </a>
            </li>
          </ul> */}
        </React.Fragment>
      </SimpleBar>
    </div>
  );
};

export default MessageNav;
