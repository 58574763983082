import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import imagePlaceholder from "../../../images/placeholder.png";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { 
  Badge,
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, 
  Alert 
} from "reactstrap";
import moment from "moment";
import { Empty, Input, InputNumber, Select, Form, Upload } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { findUpper, getAxiosHeaders, getAxiosUploadHeaders } from "../../../utils/Utils";
import { 
  AlertModal,
  SuccessModal,
  LoadingModal 
 } from "../AlertModals";
 import GooglePlacesInput from "../GooglePlacesInput";
import classNames from "classnames";
import axios from 'axios';
import { PaginationWithOnclick } from "../tables/Pagination";

const { Dragger } = Upload;

export const DeliveryPickupTable = ({ history, match }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, locations:[]});
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [showImportErrors, setShowImportErrors] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [importInfo, setImportInfo] = useState({});
  const [importErrors, setImportErrors] = useState([]);
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [locationID, setLocationID] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    location_type: '',
    location_name: '',
  });
  const [formData, setFormData] = useState({
    location_type: '',
    location_name: '',
    location: "",
    lat: '',
    lng: '',
    cost: '',
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleAddFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/businesses/delivery-pickup-locations`, {
      business_id: businessID,
      location_type: _formData.location_type,
      location_name: _formData.location_name,
      landmark: _formData.location,
      lat: formData.lat,
      lng: formData.lng,
      cost: _formData.cost
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      
      setSuccessTitle(`Location Added`);
      setSuccessMessage(`The location has been added successfully.`);
      toggleModal("successModal");

      getLocations(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'location_type',
              errors: errorResponse.errors.hasOwnProperty("location_type") ? [errorResponse.errors.location_type] : [],
            },
            {
              name: 'location_name',
              errors: errorResponse.errors.hasOwnProperty("location_name") ? [errorResponse.errors.location_name] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("landmark") ? [errorResponse.errors.landmark] : [],
            },
            {
              name: 'cost',
              errors: errorResponse.errors.hasOwnProperty("cost") ? [errorResponse.errors.cost] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const handleEditFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/businesses/delivery-pickup-locations/${locationID}`, {
      business_id: businessID,
      location_type: _formData.location_type,
      location_name: _formData.location_name,
      landmark: _formData.location,
      lat: formData.lat,
      lng: formData.lng,
      cost: _formData.cost
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();

      setSuccessTitle(`Location Updated`);
      setSuccessMessage(`The location has been updated successfully.`);
      toggleModal("successModal");

      setRequesting(false);
      getLocations(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'location_type',
              errors: errorResponse.errors.hasOwnProperty("location_type") ? [errorResponse.errors.location_type] : [],
            },
            {
              name: 'location_name',
              errors: errorResponse.errors.hasOwnProperty("location_name") ? [errorResponse.errors.location_name] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("landmark") ? [errorResponse.errors.landmark] : [],
            },
            {
              name: 'cost',
              errors: errorResponse.errors.hasOwnProperty("cost") ? [errorResponse.errors.cost] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const uploadLocationCSV = () => {
    if(fileList.length === 0 || fileList[0].status === "error") {
      setErrorMessage(fileList.length === 0 ? `Upload a CSV file` : fileList[0].error);
      return;
    }

    setRequesting(true);
    setErrorMessage("");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('locations_csv', file);
    });

    formData.set("business_id", businessID);

    axios.post(baseURLs.API_URL + "/businesses/delivery-pickup-locations/import", formData, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;

      setSuccessMessage(`Total records processed: ${responseInfo.data.total_records}`);
      setImportInfo(responseInfo.data);
      setImportErrors(responseInfo.data.errors);
      toggleModal("successImportModal");
      setFileList([]);
      setRequesting(false);
      getLocations(filters);
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteLocationsByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + `/businesses/delivery-pickup-locations/by-ids`, {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        locations: JSON.stringify(_checkedBoxes)
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_location_ids;
      let failedIDs = responseInfo.data.failed_location_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getLocations(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getLocations(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `location` : `location`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `locations` : `location`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `location` : `location`}. The selected ${failedIDs.length > 1 ? `locations were` : `location was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteLocationsByFilter = () => {
    toggleModal("deleteLoadingModal");

    axios.delete(baseURLs.API_URL + `/businesses/delivery-pickup-locations/by-filters`, {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        location_name: filters.location_name,
        location_type: filters.location_type
      }
    })
    .then((response) => {
      getLocations(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getLocations = (_filters, _businessID = businessID) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/businesses/delivery-pickup-locations`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: _businessID,
        location_name: _filters.location_name,
        location_type: _filters.location_type,
        page: _filters.page,
      },
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, locations:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${_businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${_businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, locations:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const downloadLocations = (_filters) => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + `/businesses/delivery-pickup-locations/download`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID,
        location_name: _filters.location_name,
        type: _filters.location_type
      },
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No location found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleEditLocation = (data) => {
    setLocationID(data.location_id);

    setFormData({
      location_name: data.location_name,
      location_type: data.type,
      location: data.landmark,
      lat: data.lat,
      lng: data.lng,
      cost: data.cost,
    });

    form.setFieldsValue({
      location_name: data.location_name,
      location_type: data.type,
      location: data.landmark,
      cost: data.cost,
    });

    toggleModal('editLocationModal');
  }

  const toggleModalClose = () => {
    setFormData({
      ...formData, 
      location_name: "", 
      location_type: "", 
      location: "", 
      lat: "", 
      lng: "", 
      cost: ""
    });

    form.setFieldsValue({
      location_name: "", 
      location_type: "", 
      location: "", 
      cost: ""
    });

    toggleModal(null);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getLocations(params)
  }

  const resetFilter = () => {
    let params = {
      page: 1,
      location_type: '',
      location_name: '',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getLocations(params);
  }

  const filterLocations = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getLocations(filters);
  }

  const handleSingleDelete = (locationID) => {
    setCheckedBoxes([locationID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.locations.map(item => item.location_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  useEffect(() => {
    if(fileList.length === 0){
      setErrorMessage("");
    }
  }, [fileList])

  const uploadProps = {
    fileList,
    maxCount: 1,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: file => {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        file.status = 'error';
        file.error = 'You can only upload a CSV file.';
        setErrorMessage('You can only upload a CSV file.');
      }
      
      const isSizeValid = file.size / 1024 / 1024 < 2;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 2MB!';
        setErrorMessage('File must be smaller than 2MB!');
      }

      if(isCSV && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setErrorMessage("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      setFileList([file]);
      return false;
    },
  };

  useEffect(() => {
    setLoading(true);   
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    loadGoogleMapsApi();
    getLocations(filters, businessID);
  }, [match.params.businessID]);

  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }


  return (
    <React.Fragment>
      <Head title="Delivery & Pick Up Locations"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Delivery &amp; Pick Up Locations
              </BlockTitle>
              <BlockDes>
                <p>Manage your delivery and pick up locations.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              errorMessage &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              </Block>
            }

            <div className="card">
              <div className="fm-content">
                {
                  tableData.locations.length === 0 ?
                  <div className="nk-block">
                    <div className="text-center m-5">
                      <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">No location found</span>
                      </div>
                      <div className="price-plan-action">
                        <button className="btn btn-outline-primary btn-sm m-1" onClick={() => toggleModal('newLocationModal')}>Add a Location</button>
                        <button className="btn btn-outline-primary btn-sm m-1" onClick={() => toggleModal('importLocationModal')}>Import Locations</button>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="nk-block">
                    <div className="card-title-group mb-3">
                      <h6 className="lead-text m-0"></h6>
                      <div className="card-tools">
                        <ul className="nk-block-tools g-3">
                          <li>
                            <button className="btn btn-primary btn-sm" onClick={() => toggleModal('newLocationModal')}>
                              <Icon name="map-pin"></Icon>
                              <span>Add Location</span>
                            </button>
                          </li>
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal('importLocationModal')}>
                                      <Icon name="upload-cloud"></Icon>
                                      <span>Import Locations</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Card className="card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <h6 className="lead-text m-0">
                              {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Locations` : `Location`}`}
                            </h6>
                            <div className="card-tools me-n1">
                              <ul className="btn-toolbar">
                                <li>
                                  <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                                    { hasFilters && <div className="dot dot-primary"></div> }
                                    <Icon name="search"></Icon>
                                  </Button>
                                  {
                                    activeModal === "filterModal" &&
                                    <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                                      <ModalHeader
                                        toggle={() => toggleModal('filterModal')}
                                        close={
                                          <button className="close" onClick={() => toggleModal('filterModal')}>
                                            <Icon name="cross" />
                                          </button>
                                        }
                                      >
                                        Locations Filter
                                      </ModalHeader>
                                      <ModalBody>
                                        <form>
                                          <div className="form-group">
                                            <label className="form-label" htmlFor="location_name">
                                              Location Name
                                            </label>
                                            <div className="form-control-wrap">
                                              <input type="text" onChange={onInputChange} name="location_name" className="form-control form-control-lg" id="location_name" defaultValue={filters.location_name} />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label className="form-label" htmlFor="phone-no">
                                              Location Type
                                            </label>
                                            <div className="form-control-wrap">
                                              <Select size="large"                          
                                                placeholder="Select status"
                                                defaultValue={filters.location_type}
                                                style={{ width: "100%" }} 
                                                name="type"
                                                onChange={(value) => onSelectChange(value, "location_type")}
                                                filterOption={(input, option) =>
                                                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={[
                                                  {value: "all", label: "All"},
                                                  {value: "delivery", label: "Delivery Locations"},
                                                  {value: "pick_up", label: "Pick Up Locations"},
                                                ]} 
                                                showSearch /> 
                                            </div>
                                          </div>
                                        </form>
                                      </ModalBody>
                                      <ModalFooter className="bg-lighter justify-content-center">
                                        <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterLocations();} } size="lg">
                                          Apply Filter
                                        </Button>

                                        {
                                          hasFilters &&
                                          <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                            Reset Filter
                                          </Button>
                                        }
                                      </ModalFooter>
                                    </Modal>
                                  }
                                </li>
                                <li className="btn-toolbar-sep"></li>                  
                                <li>
                                  <Button className="btn-icon btn-trigger" onClick={() => {downloadLocations(filters)}} title="Download Locations">
                                    <Icon name="download-cloud"></Icon>
                                  </Button>
                                  { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Locations"} descriptionText={"Please wait while your delivery and pick up locations are being prepared for download."} /> }
                                  
                                  { 
                                    activeModal === "downloadModal" &&
                                    <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                                      headerText={"Expenses Ready"} descriptionText={"Expenses CSV is ready for download."} 
                                      leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                                    />
                                  }
                                  
                                  {
                                    activeModal === "alertModal" &&
                                    <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                                      headerText={"Download Failed"} descriptionText={errorMessage}                       
                                    />
                                  }
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <DataTableBody>
                            <DataTableHead className="bg-lighter fw-bold">
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll}
                                    onChange={selectAll}
                                    className="custom-control-input table-checkbox"
                                    id="select_all" />
                                  <label className="custom-control-label" htmlFor="select_all"/>
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <span className="sub-text">Location</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="sub-text">Type</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="sub-text">Cost</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('delete')}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete Selected Locations</span>
                                          {
                                            activeModal === "deleteAlertModal" &&
                                            <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                              headerText={checkedBoxes.length > 1 ? `Delete Locations` : `Delete Location`} 
                                              descriptionText={`${checkAll ? 
                                              `Are you sure you want to delete all ${tableData.meta.total_records} locations. This action can not be reverted.` 
                                              :
                                              `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected locations` : `this selected location`}. This action can not be reverted.`}`}
                                              leftButtonText={`Delete`}
                                              leftButtonOnClick={checkAll ? deleteLocationsByFilter : deleteLocationsByIDs} />
                                          }
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                    {
                                      activeModal === "noSelectionAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                        headerText={`Error`} 
                                        descriptionText={`No location has been selected. Select a location and try again.`}
                                      />
                                    }

                                    { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `locations are` : `location is`} being deleted`} /> }
                              
                                    { 
                                      activeModal === "successDeleteModal" &&
                                      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                        headerText={`Deleted`} descriptionText={`Location(s) deleted successfully.`} 
                                      />
                                    }
                                    
                                    {
                                      activeModal === "deleteErrorModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                        headerText={"Error"} descriptionText={errorMessage}                       
                                      />
                                    }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableHead>
                            {
                              tableData.locations.map((data, index) => {
                                return (
                                  <DataTableItem key={index}>
                                    <DataTableRow className="nk-tb-col-check">
                                      <div className="custom-control custom-control-sm custom-checkbox">
                                        <input
                                          type="checkbox"
                                          checked={checkAll || checkedBoxes.includes(data.location_id)}
                                          onChange={() => handleCheckboxChange(data.location_id)}
                                          className="custom-control-input table-checkbox"
                                          id={`item${data.location_id}`} 
                                        />
                                        <label className="custom-control-label" htmlFor={`item${data.location_id}`} />
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <div className="user-card">
                                        <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                        <div className="user-info">
                                          <div className={`tb-lead`}>
                                            {data.location_name}{" "}
                                          </div>
                                          <span className="d-sm-none ms-1 text-muted"><br/>
                                            {`Type: ${data.type === 'delivery' ? `Delivery` : `Pick Up`}  •  Cost: `} 
                                            <small className="text-muted">{businessInfo.currency} </small>{data.cost}
                                          </span>
                                        </div>
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                      <span>{data.type === 'delivery' ? `Delivery` : `Pick Up`}</span>
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                      <span className="tb-amount">
                                        <small>{businessInfo.currency} </small> {data.cost}
                                      </span>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-tools text-end">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <DropdownItem tag="a" className="pointer-cursor" onClick={() => handleEditLocation(data)}>
                                                <Icon name="edit-alt" />
                                                <span>Edit</span>
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.location_id)}>
                                                <Icon name="trash" />
                                                <span>Delete</span>
                                              </DropdownItem>
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </DataTableRow>
                                  </DataTableItem>
                                )
                              })
                            }
                          </DataTableBody> 
                          <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                        </div>
                      </div>
                    </Card>
                    
                    
                  </div>
                }
              </div>
            </div>
          </Block>
        </>
      }
      </Content>
      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {
        activeModal === "alertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }

      {
        activeModal === "newLocationModal" &&
        <Modal backdrop="static" isOpen={true} id="add_location_modal">
          <ModalHeader
            toggle={() => toggleModalClose('create')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModalClose()}>
                <Icon name="cross" />
              </button>
            }
          >
            Add Location
          </ModalHeader>
          <Form name="add-location"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleAddFormSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Location Type <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'location_type'}
                          noStyle
                          rules={[
                            {required: true, message: `Select delivery or pick up`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select delivery or pick up"
                            style={{ width: "100%" }} 
                            onChange={(value) => {getLocations({businessID, locationType: value})}}
                            options={[
                              {
                                key: 'delivery',
                                label: 'Delivery',
                                value: 'delivery',
                              },
                              {
                                key: 'pick_up',
                                label: 'Pick Up',
                                value: 'pick_up',
                              },                              
                            ]}
                            showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                          <label className="form-label">City / Town <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'location_name'}
                        noStyle
                        rules={[
                          {required: true, message: `City / Town is required`}
                        ]}>
                        <Input size="large" placeholder="eg. Adenta" />
                      </Form.Item>
                    </Form.Item>
                  </div>
                </div>
                {
                  form.getFieldValue('location_type') === 'pick_up' &&
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Delivery Location <span className="text-danger">*</span></label>
                      </div>
                      <GooglePlacesInput form={form} isRequired={false} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={null} />
                    </div>
                  </div> 
                }
                <div className="col-md-6">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Cost <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'cost'}
                        noStyle
                        rules={[
                          {required: true, message: `cost is required`}
                        ]}>
                        <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
              </div>          
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModalClose()}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }

      {
        activeModal === "importLocationModal" &&
        <Modal backdrop="static" isOpen={true} id="import_location_modal">
          <ModalHeader
            toggle={() => toggleModal('importLocationModal')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModal('importLocationModal')}>
                <Icon name="cross" />
              </button>
            }
          >
            Import Locations
          </ModalHeader>
          <ModalBody>
            <Block>
              <div className="card-bordered card mb-2">
                <div className="card-inner">
                  <div className="nk-help pointer-cursor" onClick={() => window.open(baseURLs.LOCATIONS_IMPORT_TEMPLATE_LINK, '_blank').focus()}>
                    <div className="nk-help-img m-0">
                      <lord-icon
                        src="https://cdn.lordicon.com/ckatldkn.json"
                        trigger="loop"
                        delay="1000"
                        colors="primary:#e4e4e4,secondary:#ffffff,tertiary:#3080e8"
                        style={{width:"120px",height:"120px"}}>
                      </lord-icon>
                    </div>
                    <div className="nk-help-text">
                      <h5> Download CSV Template</h5>
                      <p className="text-soft">
                        Import delivery and pick up locations using our simple CSV template. 
                        Just download, enter your locations, and import hassle-free!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Block>        

            <Block>
              {errorMessage && (
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorMessage}{" "}
                </Alert>
              )}

              <div className="row g-4">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label">Locations CSV <span className="text-danger">*</span></label>
                    </div>
                    <Dragger {...uploadProps}>
                      <div className="p-3">
                        <p className="ant-upload-drag-icon fs-1 text-muted">
                          <Icon name="upload-cloud"></Icon>
                        </p>
                        <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint text-muted">
                          Accepted file type for upload must be in CSV format, and the file size should not exceed 2MB.
                        </p>

                      </div>
                    </Dragger>
                  </div>
                </div>
              </div>
            </Block>
          </ModalBody>
          <ModalFooter className="bg-lighter justify-content-center">
            <Button disabled={requesting} onClick={uploadLocationCSV} color="primary" size="md" className="btn-mw m-1">
              {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
            </Button>
            <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModalClose()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }

      {
        activeModal === "editLocationModal" &&
        <Modal backdrop="static" isOpen={true} id="edit_location_modal">
          <ModalHeader
            toggle={() => toggleModalClose()}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModalClose()}>
                <Icon name="cross" />
              </button>
            }
          >
            Edit Location
          </ModalHeader>
          <Form name="edit-location"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleEditFormSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Location Type <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'location_type'}
                          noStyle
                          rules={[
                            {required: true, message: `Select delivery or pick up`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select delivery or pick up"
                            style={{ width: "100%" }} 
                            onChange={(value) => {getLocations({businessID, locationType: value})}}
                            options={[
                              {
                                key: 'delivery',
                                label: 'Delivery',
                                value: 'delivery',
                              },
                              {
                                key: 'pick_up',
                                label: 'Pick Up',
                                value: 'pick_up',
                              },                              
                            ]}
                            showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                          <label className="form-label">City / Town <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'location_name'}
                        noStyle
                        rules={[
                          {required: true, message: `City / Town is required`}
                        ]}>
                        <Input size="large" placeholder="eg. Adenta" />
                      </Form.Item>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Cost <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'cost'}
                        noStyle
                        rules={[
                          {required: true, message: `cost is required`}
                        ]}>
                        <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
              </div>       
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModalClose()}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }

      {/* success import modal */}
      { 
        activeModal === "successImportModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Import Complete`} descriptionText={successMessage} 
        >
          <div className="text-start mt-3">
            <Alert color="light" className="alert-icon">
              {" "}
              <Icon name="check-circle" />
              Added Locations: <b>{importInfo.added_locations}</b>
            </Alert>
            <Alert color="light" className="alert-icon">
              {" "}
              <Icon name="check-circle" />
              Updated Locations: <b>{importInfo.updated_locations}</b>
            </Alert>
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              Failed: <b>{importInfo.failed}.</b> <span className="alert-link pointer-cursor" onClick={() => setShowImportErrors(!showImportErrors)}>Click to {showImportErrors ? `hide` : `view`} {importInfo.failed > 1 ? `errors` : `error`}</span>
            </Alert>
          </div>
          {
            showImportErrors &&
            <div className="mt-4 text-start">
              {
                importErrors.map((error, index) => {
                  return (
                    <Alert color="danger" className="alert-icon" key={index}>
                      {" "}
                      <Icon name="alert-circle" /> 
                        <span className="d-block">Location: {`${error.location_name} - (${error.location_type})`}</span>
                        {
                          error.errors.map((msg, msgIndex) => {
                            return (
                              <span className="d-block" key={msgIndex}>{msgIndex + 1}. {msg}</span>
                            )
                          })
                        }
                      
                    </Alert>
                  )
                })
              }
            </div>
          }
        </SuccessModal>
      }
    </React.Fragment>
  )
}