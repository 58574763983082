import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import HeaderMockUI from "../../../images/headerMock.svg";
import LandscapeMockUI from "../../../images/landscapeMock.svg";
import PortraitMockUI from "../../../images/portraitMock.svg";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert } from "reactstrap";
import moment from "moment";
import { Upload, Input, Select, Checkbox, Form, Divider, Space } from 'antd';
import ImgCrop from 'antd-img-crop';
import { baseURLs } from '../../../utils/Constants';
import { getAxiosHeaders, getTextColorBaseOnTheme, recommendedColors } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const StorefrontAppearance = ({ history, match }) => {

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [form] = Form.useForm();
  const [isVerified, setIsVerified] = useState("not-verified");  
  const [noStorefrontFound, setNoStorefrontFound] = useState(false);
  const [formError, setFormError] = useState("");
  const [headerLogoFile, setHeaderLogoFile] = useState([]);
  const [landscapeBannerFile, setLandscapeBannerFile] = useState([]);
  const [portraitBannerFile, setPortraitBannerFile] = useState([]);
  const [formData, setFormData] = useState({
    header_logo: "",
    landscape_banner: "",
    portrait_banner: "",
    about_us: "",
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setFormError("");

    axios.put(baseURLs.API_URL + `/businesses/storefront-appearance/${businessID}`, {
      header_logo: formData.header_logo,
      landscape_banner: formData.landscape_banner,
      portrait_banner: formData.portrait_banner,
      about_us: _formData.about_us,
    }, getAxiosHeaders())
    .then((response) => {
      // let responseInfo = response.data;
      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'about_us',
              errors: errorResponse.errors.hasOwnProperty("about_us") ? [errorResponse.errors.about_us] : [],
            },
            {
              name: 'header_logo',
              errors: errorResponse.errors.hasOwnProperty("header_logo") ? [errorResponse.errors.header_logo] : [],
            },
            {
              name: 'landscape_banner',
              errors: errorResponse.errors.hasOwnProperty("landscape_banner") ? [errorResponse.errors.landscape_banner] : [],
            },
            {
              name: 'portrait_banner',
              errors: errorResponse.errors.hasOwnProperty("portrait_banner") ? [errorResponse.errors.portrait_banner] : [],
            }            
          ]);
          
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getStoreSettings = (businessID) => {
    axios.get(baseURLs.API_URL + `/businesses/storefront-settings`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then(async(response) => {
      if (response.status === 204) {
        setIsVerified(businessInfo.is_verified);
        setNoStorefrontFound(true);
      } else {      
        let responseInfo = response.data;
        let isVerified = responseInfo.data.is_verified;
        setIsVerified(isVerified);
        let storefrontSettings = responseInfo.data.storefront_info;

        let _formData = formData;
        _formData.about_us = storefrontSettings.about_us;
        if(storefrontSettings.header_logo) {
          let url = storefrontSettings.header_logo;
          _formData.header_logo = url;
          setHeaderLogoFile([{
            uid: '-1',
            name: url.substring(url.lastIndexOf('/') + 1),
            status: 'done',
            url: url,
            thumbUrl: url
          }])
        }

        if(storefrontSettings.landscape_banner) {
          let url = storefrontSettings.landscape_banner;
          _formData.landscape_banner = url;
          setLandscapeBannerFile([{
            uid: '-2',
            name: url.substring(url.lastIndexOf('/') + 1),
            status: 'done',
            url: url,
            thumbUrl: url
          }])
        }

        if(storefrontSettings.portrait_banner) {
          let url = storefrontSettings.portrait_banner;
          _formData.portrait_banner = url;
          setPortraitBannerFile([{
            uid: '-3',
            name: url.substring(url.lastIndexOf('/') + 1),
            status: 'done',
            url: url,
            thumbUrl: url
          }]);
        }
        setFormData(_formData);
      }

      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleAboutUsChange = (value) => {
    form.setFieldValue("about_us", value);
    setFormData({...formData, about_us: value});
  };

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getStoreSettings(businessID);
  }, []);

  const handleFileChange = (info, type) => {
    console.log({info})
    if(type === 'header_logo'){
      info.fileList.length > 0 ? setHeaderLogoFile([...info.fileList]) : setHeaderLogoFile(null);
    } else if(type === 'landscape_banner'){
      info.fileList.length > 0 ? setLandscapeBannerFile([...info.fileList]) : setLandscapeBannerFile(null);
    } else if(type === 'portrait_banner'){
      info.fileList.length > 0 ? setPortraitBannerFile([...info.fileList]) : setPortraitBannerFile(null);
    }


    if (info.file.status === 'done' && info.fileList.length > 0) {
      
      const imageUrl = info.file.response.image_url;
      setFormData({
        ...formData,
        [type]: imageUrl
      });
    } else if(info.fileList.length === 0) {
      setFormData({
        ...formData,
        [type]: null
      });
    }
  };

  const uploadProps = {
    listType: "picture",
    maxCount: 1,
    accept: "image/*",
    beforeUpload: (file) => {
      const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type);
      if(!isImage) {
        file.status = 'error';
        file.error = 'You can only upload a png, jpeg and jpg file.';
        setFormError('You can only upload a .png, .jpeg, .jpg file.');
      }
      
      const isSizeValid = file.size / 1024 / 1024 < 2;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 2MB!';
        setFormError('File must be smaller than 2MB!');
      }

      if(isImage && isSizeValid){
        file.status = undefined; // Reset status
        file.error   = undefined;
        setFormError("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
        return false;
      }
    }
  }


  return (
    <React.Fragment>
      <Head title="Storefront Appearance Settings"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Storefront Appearance Settings
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update your storefront appearance.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              formError &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {formError}{" "}
                  </Alert>
                </div>
              </Block>
            }

            <div className="card card-bordered">
              <div className="fm-content">
                {
                  isVerified !== 'verified' || noStorefrontFound ?
                  <Block>
                    {
                      noStorefrontFound ? 
                      <div className="text-center m-5">
                        <div className="price-plan-media">
                          <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                        </div>
                        <div className="price-plan-info">
                          <span className="title fw-normal">No Storefront found. Setup your Storefront then update the fulfillment settings.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/storefront-settings/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Setup Storefront</button>
                          </Link>                    
                        </div>
                      </div>
                      :
                      <div className="text-center m-5">
                        <div className="price-plan-media">
                          <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                        </div>
                        <div className="price-plan-info">
                          <span className="title fw-normal">Verify your business to start receiving orders online.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Verify Business</button>
                          </Link>                    
                        </div>
                      </div>
                    }
                    
                  </Block>
                  :
                  <Block>
                    <Form name="update-storefront-appearance"
                      form={form} className="is-alter"
                      initialValues={formData} 
                      onFinish={handleFormSubmit}
                      onFinishFailed={onFinishFailed}>

                      <div className="row g-2">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">Upload Header Logo <span className="text-muted">(optional)</span>
                                <Button type="button" id="header_logo_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                  <Icon name="info" />
                                </Button>
                                <UncontrolledPopover target="header_logo_info_popover" placement="bottom" trigger="focus">
                                  <PopoverBody>
                                    The landscape banner welcomes visitors! Use it for promotions or welcome messages. Make sure it looks great on large screens!. 
                                    For best results, please use an image with a 3:1 aspect ratio. The recommended dimensions is 300x100 pixels.
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </label>
                            </div>
                            <img className="mb-3" src={HeaderMockUI} alt="header_logo" />
                            <Form.Item name="header_logo"
                              valuePropName="fileList"
                              rules={[
                                { required: false }
                              ]}
                            >
                              <ImgCrop modalTitle="Crop Logo" 
                                cropperProps={
                                  {restrictPosition: false}
                                }
                                minZoom={0.5}  
                                aspect={3/1} fillColor="transparent">
                                <Upload 
                                  fileList={headerLogoFile}
                                  action= {`${baseURLs.API_URL}/businesses/storefront-appearance/upload/${businessID}/header_logo`}
                                  headers={getAxiosHeaders().headers}
                                  name="header_logo"
                                  {...uploadProps}
                                  onChange={(file) => handleFileChange(file, 'header_logo')}>
                                  <Button type="button" outline color="primary"><Icon name="upload-cloud me-1" /> Click to Upload</Button>
                                </Upload>
                              </ImgCrop>
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">Upload Landscape Banner 
                                <span className="text-muted">(optional)</span>
                                <Button type="button" id="landscape_banner_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                  <Icon name="info" />
                                </Button>
                                <UncontrolledPopover target="landscape_banner_info_popover" placement="bottom" trigger="focus">
                                  <PopoverBody>
                                    The landscape banner welcomes visitors! Use it for promotions or welcome messages. Make sure it looks great on large screens!. 
                                    For best results, please use an image with a 3:1 aspect ratio. The recommended dimensions is 1200x400 pixels.
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </label>
                            </div>
                            <img className="mb-3" src={LandscapeMockUI} alt="landscape_banner" />
                            <Form.Item name="landscape_banner"
                              valuePropName="fileList"
                              rules={[
                                { required: false },
                              ]}
                            >
                              <ImgCrop modalTitle="Crop Landscape Banner" 
                                cropperProps={
                                  {restrictPosition: false}
                                }
                                minZoom={0.5}
                                aspect={3/1}>
                                <Upload 
                                  fileList={landscapeBannerFile}
                                  action= {`${baseURLs.API_URL}/businesses/storefront-appearance/upload/${businessID}/landscape_banner`}
                                  headers={getAxiosHeaders().headers}
                                  name="landscape_banner"
                                  {...uploadProps}
                                  onChange={(file) => handleFileChange(file, 'landscape_banner')}>
                                  <Button type="button" outline color="primary"><Icon name="upload-cloud me-1" /> Click to Upload</Button>
                                </Upload>
                              </ImgCrop>
                            </Form.Item>
                          </div>
                        </div> 

                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">Upload Portrait Banner 
                              <span className="text-muted">(optional)</span>
                              <Button type="button" id="portrait_banner_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                <Icon name="info" />
                              </Button>
                              <UncontrolledPopover target="portrait_banner_info_popover" placement="bottom" trigger="focus">
                                <PopoverBody>
                                  The portrait banner welcomes mobile visitors! Use it for promotions or welcome messages. Make sure it looks great on phones!
                                  For best results, please use an image with a 1:1 aspect ratio. The recommended dimensions is 600x600 pixels.
                                </PopoverBody>
                              </UncontrolledPopover>
                            </label>
                            </div>
                            <img className="mb-3" src={PortraitMockUI} alt="portrait_banner" />
                            <Form.Item name="portrait_banner"
                              valuePropName="fileList"
                              rules={[
                                { required: false },
                              ]}
                            >
                              <ImgCrop modalTitle="Crop Portrait Banner" 
                                cropperProps={
                                  {restrictPosition: false}
                                }
                                minZoom={0.5}
                                aspect={1}>
                                <Upload 
                                  fileList={portraitBannerFile ?? null}
                                  action= {`${baseURLs.API_URL}/businesses/storefront-appearance/upload/${businessID}/portrait_banner`}
                                  headers={getAxiosHeaders().headers}
                                  name="portrait_banner"
                                  {...uploadProps}
                                  onChange={(file) => handleFileChange(file, 'portrait_banner')}>
                                  <Button type="button" outline color="primary"><Icon name="upload-cloud me-1" /> Click to Upload</Button>
                                </Upload>
                              </ImgCrop>
                            </Form.Item>
                          </div>
                        </div>                  

                        <div className="col-md-12">
                          <hr className="m-3 mx-0" />
                          <h6 className="overline-title text-primary-alt mb-4 mt-4">About Us</h6>
                        </div>

                        <div className="col-md-12">
                          <div className="form-label-group">
                              <label className="form-label">About Us 
                                <span className="text-danger">*</span>
                                <Button type="button" id="about_us_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                  <Icon name="info" />
                                </Button>
                                <UncontrolledPopover target="about_us_info_popover" placement="bottom" trigger="focus">
                                  <PopoverBody>
                                    Share information about your business, brand, or organization. Visitors will see this on your storefront's "About Us" page.
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </label>
                          </div>
                          <Form.Item
                            name="about_us"
                            rules={[{ required: true, message: 'Please enter about us content' }]}
                          >
                            <ReactQuill
                              value={formData.about_us}
                              onChange={handleAboutUsChange}
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [1, 2, 3, false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    ['link'], ['clean']
                                  ],
                                }
                              }}
                              formats={[
                                'header',
                                'bold', 'italic', 'underline', 'strike',
                                'list', 'bullet', 'link'
                              ]}
                              placeholder="Enter about us content..."
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="pt-5 text-center">
                        <Button disabled={requesting} type="submit" color="primary" size="lg" >
                          {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                        </Button>
                      </div>
                    </Form>
                  </Block>
                } 
              </div>
            </div>
          </Block>

        </>
      }
      </Content>

      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Updated Successfully`} descriptionText={`Storefront appearance settings have been updated successfully.`} 
        />
      }
    </React.Fragment>
  )
}