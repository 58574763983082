import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../../components/Component";
import { Card, Modal, ModalBody, Alert, Spinner } from "reactstrap";
import { Input, Select, Checkbox, Form, Upload } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber } from "../../../utils/Utils";
import { AlertModal, LoadingModal, SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';
const { Dragger } = Upload;

export const BusinessAPI = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [apiKey, setAPIKey] = useState("••••••••••••••••••••••••••••••••••");
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [apiStatus, setAPIStatus] = useState("");
  const [apiKeyOTP, setAPIKeyOTP] = useState("");
  const [apiKeyOTPEmail, setAPIKeyOTPEmail] = useState("");
  const [apiKeyAction, setAPIKeyAction] = useState("");
  const [apiKeyActionStatus, setAPIKeyActionStatus] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [copy, setCopy] = useState(false);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onCopyClick = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
  };

  const getActionMessages = (action) => {
    switch (action) {
      case 'view_api_key':
      return {
        success_title: "Done",
        success_message: "Your API Key is ready.",
        loading_message: "Please wait while your API Key is being retrieved",
      }

      case 'generate_api_key':
      return {
        success_title: "Done",
        success_message: "A new API Key has been generated.",
        loading_message: "Please wait while a new API Key is being generated",
      }

      case 'update_api_key_status':
      return {
        success_title: "Done",
        success_message: "Your API Key status has been updated.",
        loading_message: "Please wait while your API Key status is being updated",
      }
    
      default:
      return {
        success_title: "Done",
        success_message: "Your API Key is ready.",
        loading_message: "Please wait while your API Key is being retrieved",
      }
    }
  }

  const handleOTPSubmit = () => {
    if(requesting) {return;}

    if(!apiKeyOTP) {
      setErrorMessage("OTP is required");
      return;
    }

    setErrorMessage("");
    setRequesting(true);

    setLoadingMessage(`Please wait while the OTP is verified.`);
    toggleModal('loadingModal');
    
    axios.post(baseURLs.API_URL + `/businesses/api/otp/${businessID}`, {
      otp: apiKeyOTP,
      action: apiKeyAction,
      status: apiKeyActionStatus,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let _apiKeyInfo = responseInfo.data;

      if(apiKeyAction !== 'update_api_key_status'){
        setAPIKey(_apiKeyInfo.api_token);
        setShowAPIKey(true);

      } else {hideAPIKey();}

      setAPIStatus(_apiKeyInfo.status);
      
      let actionMessages = getActionMessages(apiKeyAction);
      setSuccessTitle(actionMessages.success_title);
      setSuccessMessage(actionMessages.success_message);
      toggleModal('successModal');

      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal(null)
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const getAPIKey = () => {
    if(requesting) {return;}

    setRequesting(true);
    setErrorMessage("");
    
    let actionMessages = getActionMessages(apiKeyAction);
    setLoadingMessage(actionMessages.loading_message);
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + `/businesses/api/${businessID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let _otpEmail = responseInfo.data.email;

      setAPIKeyOTPEmail(_otpEmail);
      toggleModal("OTPModal")
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const initAPIKeyAction = (action, status = '') => {    
    if(status){
      setAPIKeyActionStatus(status);
    }
    
    setAPIKeyAction(action);
    getAPIKey();
  }

  const hideAPIKey = () => {
    setAPIKey("••••••••••••••••••••••••••••••••••");
    setShowAPIKey(false);
  }

  useEffect(() => {
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);

  }, [match.params.businessID]);

  return (
    <React.Fragment>
      <Head title="Business API Key"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Business API Key
              </BlockTitle>
              <BlockDes>
                <p>Connect your Keepup Store Account with your website or other tools effortlessly.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              errorMessage &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              </Block>
            }
        
            <Card className="card-bordered">
              <div className="nk-refwg-invite card-inner">
                <div className="nk-refwg-head g-3">
                  <div className="nk-refwg-title">
                    <h5 className="title">API Key</h5>
                    <div className="title-sub">Manage your API key using the form below.</div>
                  </div>
                  <div className="nk-refwg-action">
                    {
                      apiStatus === 'enabled' &&
                      <Button disabled={requesting} onClick={() => { initAPIKeyAction('updated_api_key_status', 'disable') }} color="danger">Disable</Button>
                    }

                    {
                      apiStatus === 'disabled' &&
                      <Button disabled={requesting} onClick={() => { initAPIKeyAction('updated_api_key_status', 'enable') }} color="success">Enable</Button>
                    }
                  </div>
                </div>
                <div className="nk-refwg-url">
                  <div className={`form-control-wrap ${copy ? "clipboard-success" : ""}`}>
                    {
                      showAPIKey ?
                      <div className="form-clip">
                        <CopyToClipboard
                          text={apiKey}
                          className="clipboard-init me-2"
                          onCopy={onCopyClick}
                        >
                          <div>
                            <Icon name="copy" className="clipboard-icon"></Icon>
                            <span className="clipboard-text">{copy ? "Copied" : "Copy"}</span>
                          </div>
                        </CopyToClipboard>
                        <div className="pointer-cursor" onClick={hideAPIKey}>
                          <Icon name="eye-off"></Icon>
                          <span className="clipboard-text">Hide</span>
                        </div>
                      </div>
                      :
                      <div className="form-clip pointer-cursor disabled" onClick={() => { initAPIKeyAction('view_api_key') }}>
                        <Icon name="eye"></Icon>
                        <span className="clipboard-text">Show</span>
                      </div>
                    }
                    
                    <div className="form-icon">
                      <Icon name="terminal"></Icon>
                    </div>
                    <input
                      type="text"
                      className="form-control copy-text"
                      id="api_key"
                      defaultValue={apiKey}
                      value={apiKey}/>
                  </div>
                </div>
                <p className="my-2">
                  <a aria-disabled={requesting} href="#" onClick={(e) => {e.preventDefault(); toggleModal('alertModal')}} className="link link-primary">Generate new API Key.</a>
                </p>
              </div>
            </Card>
            
            <div className="gap-lg"></div>

            <a href={baseURLs.API_DOCUMENTATION_LINK} target="_blank">
              <div className="card-bordered card mb-2">
                <div className="card-inner">
                  <div className="nk-help">
                    <div className="nk-help-img m-0">
                      <lord-icon
                        src="https://cdn.lordicon.com/hhclilyr.json"
                        trigger="loop"
                        delay="2000"
                        colors="primary:#3098fc,secondary:#ffffff"
                        style={{width:"100px",height:"100px"}}>
                      </lord-icon>
                    </div>
                    <div className="nk-help-text">
                      <h5> API Documentation</h5>
                      <p className="text-soft">
                        Explore our comprehensive documentation to seamlessly integrate Keepup Store and your bookkeeping data with your website and other tools.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </Block>
        </>
      }
      </Content>

      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {/* error modal */}
      { 
        activeModal === "alertModal" &&
        <AlertModal 
          index="alert-key"
          showModal={true} 
          toggleModal={() => toggleModal('alertModal')}
          headerText={`Generate New API Key?`}
          descriptionText={`Are you sure you want to generate a new API Key ? This will make your current API Key invalid and this action can not be reverted.`}
          leftButtonText={`Generate`}
          leftButtonOnClick={() => {initAPIKeyAction('generate_api_key')}} />
      }

      {/* loading modal */}
      { 
        activeModal === "loadingModal" &&
        <LoadingModal showModal={true} headerText={`Please wait...`} descriptionText={loadingMessage} />
      }

      {/* OTP modal */}
      {
        activeModal === "OTPModal" &&
        <Modal backdrop="static" isOpen={true} id="otp-modal">
          <button className="close" onClick={() => toggleModal('OTPModal')}><em className="icon ni ni-cross"></em></button>
          <ModalBody className="modal-body-lg text-center">
            <div className="nk-modal">
              <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-more-h bg-light"></Icon>
              <h4 className="nk-modal-title fw-normal">OTP Verification</h4>
              <div className="nk-modal-text">
                <div className="caption-text mb-3">
                  {`An OTP email has been sent to: ${apiKeyOTPEmail}`}
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                      <label className="form-label">OTP <span className="text-danger">*</span></label>
                  </div>
                  <Input size="large" type="text" className="form-control form-control-lg" 
                    onChange={(e) => setAPIKeyOTP(e.target.value)} placeholder="eg. 000000" />
                  <p className="text-danger text-start mt-1">{errorMessage}</p>
                </div>
              </div>
              <div className="nk-modal-action mt-5">
                <Button disabled={requesting} color="dark" size="lg" className="btn-mw m-1" onClick={handleOTPSubmit}>
                  <span>Submit</span>
                </Button>

                <Button color="light" size="lg" className="btn-mw m-1" onClick={() => toggleModal('OTPModal')}>
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }
    </React.Fragment>
  )
}