import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { PayoutTransactionsTable } from "./components/tables/PayoutTransactions";

const PayoutDetails = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [batchNumber, setBatchNumber] = useState("...");
  
  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const batchNumber = match.params.batchNumber;
    if ( Number.isInteger(businessID) && batchNumber ) {
      localStorage.setItem('current_business_id', businessID);
      let businesses = JSON.parse(localStorage.getItem('my_businesses'));
      let currentBusiness = businesses?.find((business) => business.business_id == businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setBusinessInfo({...currentBusiness});
      setBusinessID(businessID);
      setBatchNumber(batchNumber);
      setLoading(false);
    } else {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Payout Details - Batch #" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      <BlockHead size="sm">
        <div className="nk-block-between">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              Batch #{batchNumber}
            </BlockTitle>
            
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <BackTo className="mb-3" history={history} link={`/payouts/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </div>
      </BlockHead>

      <PayoutTransactionsTable history={history} businessID={businessID} currency={businessInfo.currency} batchNumber={batchNumber} />
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default PayoutDetails;