import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  UserAvatar,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { Empty } from 'antd';
import { getAxiosHeaders, getDateAgo, getNotificationIcons, getRouteFromUrl } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { SuccessModal } from "./components/AlertModals";
import { markAllNotificationsRead, markNotificationRead } from "../layout/header/dropdown/notification/Notification";

const Notifications = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [notificationList, setNotificationList] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const getNotifications = (business_id) => {
    axios.get(baseURLs.API_URL + `/notifications/${business_id}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status !== 204) {        
        let responseInfo = response.data;
        let notifications = responseInfo.data.notifications;
        let localStorageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]'); 
        if(localStorageNotifications) {
          let businessIndex = localStorageNotifications?.findIndex((notification) => notification.business_id == business_id);
          if(businessIndex > -1){
            localStorageNotifications[businessIndex].notifications = notifications;
          } else {
            localStorageNotifications.push({
              business_id,
              notifications
            })
          }
  
          localStorage.setItem('notifications', JSON.stringify(localStorageNotifications))
        }

        setNotificationList([...notifications]);
      }
  
      window.dispatchEvent(new CustomEvent('notificationUpdate'));
      setLoading(false);
  
    }).catch((error) => {
      console.error(error)
      setLoading(false);
    });
  }

  const viewNotification = (notification) => {
    markNotificationRead(businessID, notification.notification_id);
    let _notificationsList = notificationList.filter(item => item.notification_id !== notification.notification_id);
    setNotificationList([..._notificationsList]);
    
    if(notification.link) {
      history.push(`${process.env.PUBLIC_URL}${getRouteFromUrl(notification.link)}`);
    }

  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getNotifications(businessID);
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Notifications" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Notifications
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/dashboard/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        <Block className="wide-sm m-auto">
          <div className="card card-bordered">            
            {
              notificationList.length === 0 ?
              <div className="text-center m-5">
                <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                <div className="price-plan-info">
                  <h6 className="title fw-normal">All caught up!</h6>
                </div>
              </div>
              :
              <>
                <div className="card-inner border-bottom">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title">Notifications</h6>
                    </div>
                    <div className="card-tools">
                      <a href="#" onClick={(e) => {e.preventDefault(); markAllNotificationsRead(businessID)}} className="link link-primary">Mark all as read</a>
                    </div>
                  </div>
                </div>
                <ul className="nk-support">
                {
                  notificationList.map((item) => {
                    let iconColorInfo = getNotificationIcons(item.type);
                    return (
                      <li key={item.notification_id} className="nk-support-item pointer-cursor" onClick={() => {viewNotification(item)}}>
                        <div className={`user-avatar ${iconColorInfo.bg_color} md fs-3`}>
                          <Icon name={iconColorInfo.icon} />
                        </div>
                        <div className="nk-support-content">
                          <div className="title"><span>{item.title}</span></div>
                          <p>{item.description}</p>
                          <span className="time text-muted">{getDateAgo(item.created_at)}</span>
                        </div>
                      </li>
                    );
                  })
                }
                </ul>
              </>
            }
          </div>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default Notifications;