export const countries = [
  {
    "country_code": "AF",
      "name":"Afghanistan",
      "currency":"AFN",
      "symbol":'؋'
  },
  {
    "country_code": "AL",
      "name":"Albania",
      "currency":"ALL",
      "symbol":'L'
  },
  {
    "country_code": "DZ",
      "name":"Algeria",
      "currency":"DZD",
      "symbol": 'دج',
  },
  {
    "country_code": "AS",
      "name":"American Samoa",
      "currency":"USD",
      "symbol": '$',
  },
  {
    "country_code": "AD",
      "name":"Andorra",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "AO",
      "name":"Angola",
      "currency":"AOA",
      "symbol": '',
  },
  {
    "country_code": "AI",
      "name":"Anguilla",
      "currency":"XCD",
      "symbol": '$',
  },
  {
    "country_code": "AG",
      "name":"Antigua and Barbuda",
      "currency":"XCD",
      "symbol": '$',
  },
  {
    "country_code": "AR",
      "name":"Argentina",
      "currency":"ARS",
      "symbol": '$',
  },
  {
    "country_code": "AM",
      "name":"Armenia",
      "currency":"AMD",
      "symbol": '֏',
  },
  {
    "country_code": "AW",
      "name":"Aruba",
      "currency":'AWG',
      "symbol": 'ƒ',
  },
  {
    "country_code": "AU",
      "name":"Australia",
      "currency":"AUD",
      "symbol": '$',
  },
  {
    "country_code": "AT",
      "name":"Austria",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "AZ",
      "name":"Azerbaijan",
      "currency":"AZN",
      "symbol": '₼',
  },
  {
    "country_code": "BS",
      "name":"Bahamas",
      "currency":"BSD",
      "symbol": '$',
  },
  {
    "country_code": "BH",
      "name":"Bahrain",
      "currency":"BHD",
      "symbol": '.د.ب',
  },
  {
    "country_code": "BD",
      "name":"Bangladesh",
      "currency":"BDT",
      "symbol": '৳',
  },
  {
    "country_code": "BB",
      "name":"Barbados",
      "currency":"BBD",
      "symbol": '$',
  },
  {
    "country_code": "BY",
      "name":"Belarus",
      "currency":'BYN',
      "symbol": 'Br',
  },
  {
    "country_code": "BE",
      "name":"Belgium",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "BZ",
      "name":"Belize",
      "currency":'BZD',
      "symbol": 'BZ$',
  },
  {
    "country_code": "BJ",
      "name":"Benin",
      "currency":"XOF",
      "symbol": 'CFA',
  },
  {
    "country_code": "BM",
      "name":"Bermuda",
      "currency":"BMD",
      "symbol": '$',
  },
  {
    "country_code": "BT",
      "name":"Bhutan",
      "currency":"BTN",
      "symbol": 'Nu.',
  },
  {
    "country_code": "BO",
      "name":"Bolivia, Plurinational State of",
      "currency":"BOB",
      "symbol": '$b',
  },
  {
    "country_code": "BA",
      "name":"Bosnia and Herzegovina",
      "currency":"BAM",
      "symbol": 'KM',
  },
  {
    "country_code": "BW",
      "name":"Botswana",
      "currency":"BWP",
      "symbol": 'P',
  },
  {
    "country_code": "BR",
      "name":"Brazil",
      "currency":"BRL",
      "symbol": 'R$',
  },
  {
    "country_code": "IO",
      "name":"British Indian Ocean Territory",
      "currency":"USD",
      "symbol": '$',
  },
  {
    "country_code": "BG",
      "name":"Bulgaria",
      "currency":"BGN",
      "symbol": 'лв',
  },
  {
    "country_code": "BF",
      "name":"Burkina Faso",
      "currency":"XOF",
      "symbol": 'CFA',
  },
  {
    "country_code": "BI",
      "name":"Burundi",
      "currency":'BIF',
      "symbol": 'FBu',
  },
  {
    "country_code": "KH",
      "name":"Cambodia",
      "currency":"KHR",
      "symbol": '៛',
  },
  {
    "country_code": "CM",
      "name":"Cameroon",
      "currency":"XAF",
      "symbol": 'FCFA',
  },
  {
    "country_code": "CA",
      "name":"Canada",
      "currency":"CAD",
      "symbol": '$',
  },
  {
    "country_code": "CV",
      "name":"Cape Verde",
      "currency":'CVE',
      "symbol": '$',
  },
  {
    "country_code": "KY",
      "name":"Cayman Islands",
      "currency":"KYD",
      "symbol": '$',
  },
  {
    "country_code": "CF",
      "name":"Central African Republic",
      "currency":"XAF",
      "symbol": 'FCFA',
  },
  {
    "country_code": "TD",
      "name":"Chad",
      "currency":'XAF',
      "symbol": 'FCFA',
  },
  {
    "country_code": "CL",
      "name":"Chile",
      "currency":"CLP",
      "symbol": '$',
  },
  {
    "country_code": "CN",
      "name":"China",
      "currency":"CNY",
      "symbol": '¥',
  },
  {
    "country_code": "CO",
      "name":"Colombia",
      "currency":"COP",
      "symbol": '$',
  },
  {
    "country_code": "KM",
      "name":"Comoros",
      "currency":"KMF",
      "symbol": 'CF',
  },
  {
    "country_code": "CG",
      "name":"Congo",
      "currency":"XAF",
      "symbol": 'FCFA',
  },
  {
    "country_code": "CD",
      "name":"Democratic Republic of the Congo",
      "currency":"CDF",
      "symbol": 'FC',
  },
  {
    "country_code": "CK",
      "name":"Cook Islands",
      "currency":"NZD",
      "symbol": '$',
  },
  {
    "country_code": "CR",
      "name":"Costa Rica",
      "currency":"CRC",
      "symbol": '₡',
  },
  {
    "country_code": "CI",
      "name":"Côte d'Ivoire",
      "currency":"XOF",
      "symbol": 'CFA',
  },
  {
    "country_code": "HR",
      "name":"Croatia",
      "currency":"HRK",
      "symbol": 'kn',
  },
  {
    "country_code": "CU",
      "name":"Cuba",
      "currency":"CUP",
      "symbol": '₱',
  },
  {
    "country_code": "CW",
      "name":"Curaçao",
      "currency":"ANG",
      "symbol": 'ƒ',
  },
  {
    "country_code": "CY",
      "name":"Cyprus",
      "currency":'EUR',
      "symbol": '€',
  },
  {
    "country_code": "CZ",
      "name":"Czech Republic",
      "currency":"CZK",
      "symbol": 'Kč',
  },
  {
    "country_code": "DK",
      "name":"Denmark",
      "currency":"DKK",
      "symbol": 'kr',
  },
  {
    "country_code": "DJ",
      "name":"Djibouti",
      "currency":"DJF",
      "symbol": 'Fdj',
  },
  {
    "country_code": "DM",
      "name":"Dominica",
      "currency":"XCD",
      "symbol": '$',
  },
  {
    "country_code": "DO",
      "name":"Dominican Republic",
      "currency":"DOP",
      "symbol": 'RD$',
  },
  {
    "country_code": "EC",
      "name":"Ecuador",
      "currency":"USD",
      "symbol": '$',
  },
  {
    "country_code": "EG",
      "name":"Egypt",
      "currency":"EGP",
      "symbol": '£',
  },
  {
    "country_code": "SV",
      "name":"El Salvador",
      "currency":"SVC",
      "symbol": '$',
  },
  {
    "country_code": "GQ",
      "name":"Equatorial Guinea",
      "currency":"XAF",
      "symbol": 'FCFA',
  },
  {
    "country_code": "ER",
      "name":"Eritrea",
      "currency":"ERN",
      "symbol": 'Nfk',
  },
  {
    "country_code": "EE",
      "name":"Estonia",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "ET",
      "name":"Ethiopia",
      "currency":"ETB",
      "symbol": 'Br',
  },
  {
    "country_code": "FK",
      "name":"Falkland Islands (Malvinas)",
      "currency":'FKP',
      "symbol": '£',
  },
  {
    "country_code": "FO",
      "name":"Faroe Islands",
      "currency":"DKK",
      "symbol": 'kr',
  },
  {
    "country_code": "FJ",
      "name":"Fiji",
      "currency":"FJD",
      "symbol": '$',
  },
  {
    "country_code": "FI",
      "name":"Finland",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "FR",
      "name":"France",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "PE",
      "name":"French Polynesia",
      "currency":'XPF',
      "symbol":  '₣',
  },
  {
    "country_code": "GA",
      "name":"Gabon",
      "currency":"XAF",
      "symbol": 'FCFA',
  },
  {
    "country_code": "GM",
      "name":"Gambia",
      "currency":"GMD",
      "symbol": 'D',
  },
  {
    "country_code": "GE",
      "name":"Georgia",
      "currency":"GEL",
      "symbol": '₾',
  },
  {
    "country_code": "DE",
      "name":"Germany",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "GH",
      "name":"Ghana",
      "currency":"GHS",
      "symbol": 'GH₵',
  },
  {
    "country_code": "GI",
      "name":"Gibraltar",
      "currency":"GIP",
      "symbol": '£',
  },
  {
    "country_code": "GR",
      "name":"Greece",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "GL",
      "name":"Greenland",
      "currency":"DKK",
      "symbol": 'kr',
  },
  {
    "country_code": "GD",
      "name":"Grenada",
      "currency":"XCD",
      "symbol": '$',
  },
  {
    "country_code": "GU",
      "name":"Guam",
      "currency":"USD",
      "symbol": '$',
  },
  {
    "country_code": "GT",
      "name":"Guatemala",
      "currency":"GTQ",
      "symbol": 'Q',
  },
  {
    "country_code": "GG",
      "name":"Guernsey",
      "currency":"GBP",
      "symbol": '£',
  },
  {
    "country_code": "GN",
      "name":"Guinea",
      "currency":"GNF",
      "symbol": 'FG',
  },
  {
    "country_code": "GW",
      "name":"Guinea Bissau",
      "currency":"XOF",
      "symbol": 'CFA',
  },
  {
    "country_code": "HT",
      "name":"Haiti",
      "currency":"HTG",
      "symbol": 'G',
  },
  {
    "country_code": "HN",
      "name":"Honduras",
      "currency":"HNL",
      "symbol": 'L',
  },
  {
    "country_code": "HK",
      "name":"Hong Kong",
      "currency":"HKD",
      "symbol": '$',
  },
  {
    "country_code": "HU",
      "name":"Hungary",
      "currency":'HUF',
      "symbol": 'Ft',
  },
  {
    "country_code": "IS",
      "name":"Iceland",
      "currency":'ISK',
      "symbol": 'kr',
  },
  {
    "country_code": "IN",
      "name":"India",
      "currency":"INR",
      "symbol": '₹',
  },
  {
    "country_code": "ID",
      "name":"Indonesia",
      "currency":"IDR",
      "symbol": 'Rp',
  },
  {
    "country_code": "IR",
      "name":"Iran, Islamic Republic of",
      "currency":'IRR',
      "symbol": '﷼',
  },
  {
    "country_code": "IQ",
      "name":"Iraq",
      "currency":"IQD",
      "symbol": 'ع.د',
  },
  {
    "country_code": "IE",
      "name":"Ireland",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "IM",
      "name":"Isle of Man",
      "currency":"GBP",
      "symbol": '£',
  },
  {
    "country_code": "IL",
      "name":"Israel",
      "currency":"ILS",
      "symbol": '₪',
  },
  {
    "country_code": "IT",
      "name":"Italy",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "JM",
      "name":"Jamaica",
      "currency":"JMD",
      "symbol": 'J$',
  },
  {
    "country_code": "JP",
      "name":"Japan",
      "currency":"JPY",
      "symbol": '¥',
  },
  {
    "country_code": "JE",
      "name":"Jersey",
      "currency":"GBP",
      "symbol": '£',
  },
  {
    "country_code": "JO",
      "name":"Jordan",
      "currency":"JOD",
      "symbol": 'JD',
  },
  {
    "country_code": "KZ",
      "name":"Kazakhstan",
      "currency":"KZT",
      "symbol": 'лв',
  },
  {
    "country_code": "KE",
      "name":"Kenya",
      "currency":"KES",
      "symbol": 'KSh',
  } ,
  {
    "country_code": "KI",
      "name":"Kiribati",
      "currency":"AUD",
      "symbol": '$',
  },
  {
    "country_code": "KP",
      "name":"North Korea",
      "currency":"KPW",
      "symbol": '₩',
  }  ,
  {
    "country_code": "KR",
      "name":"South Korea",
      "currency":"KRW",
      "symbol": '₩',
  }  ,
  {
    "country_code": "KW",
      "name":"Kuwait",
      "currency":"KWD",
      "symbol": 'KD',
  }  ,
  {
    "country_code": "KG",
      "name": "Kyrgyzstan",
      "currency":"KGS",
      "symbol": 'лв',
  } ,
  {
    "country_code": "LA",
      "name":"Lao People's Democratic Republic",
      "currency":"LAK",
      "symbol": '₭',
  } ,
  {
    "country_code": "LV",
      "name":"Latvia",
      "currency":"LVL",
      "symbol": 'Ls',
  }  ,
  {
    "country_code": "LB",
      "name": "Lebanon",
      "currency":"LBP",
      "symbol": '£',
  } ,
  {
    "country_code": "LS",
      "name":"Lesotho",
      "currency":"LSL",
      "symbol":  'M',
  }  ,
  {
    "country_code": "LR",
      "name": "Liberia",
      "currency":"LRD",
      "symbol": '$',
  } ,
  {
    "country_code": "LY",
      "name":"Libya",
      "currency":"LYD",
      "symbol": 'LD',
  }  ,
  {
    "country_code": "LI",
      "name":"Liechtenstein",
      "currency":'CHF',
      "symbol": 'CHF',
  }  ,
  {
    "country_code": "LT",
      "name":"Lithuania",
      "currency":"LTL",
      "symbol": 'Lt',
  }  ,
  {
    "country_code": "LU",
      "name":"Luxembourg",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "MO",
      "name":"Macao",
      "currency":"MOP",
      "symbol": 'MOP$',
  }  ,
  {
    "country_code": "MK",
      "name":"Republic of Macedonia",
      "currency":"MKD",
      "symbol": 'ден',
  }  ,
  {
    "country_code": "MG",
      "name":"Madagascar",
      "currency":"MGA",
      "symbol": 'Ar',
  }  ,
  {
    "country_code": "MW",
      "name":"Malawi",
      "currency":"MWK",
      "symbol": 'MK',
  }  ,
  {
    "country_code": "MY",
      "name":"Malaysia",
      "currency":"MYR",
      "symbol": 'RM',
  } ,
  {
    "country_code": "MV",
      "name":"Maldives",
      "currency":"MVR",
      "symbol": 'Rf',
  }  ,
  {
    "country_code": "ML",
      "name":"Mali",
      "currency":"XOF",
      "symbol": 'CFA',
  } ,
  {
    "country_code": "MT",
      "name":"Malta",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "MH",
      "name":"Marshall Islands",
      "currency":"USD",
      "symbol": '$',
  }  ,
  {
    "country_code": "MQ",
      "name":"Martinique",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "MR",
      "name":"Mauritania",
      "currency":"MRO",
      "symbol": 'UM',
  }  ,
  {
    "country_code": "MU",
      "name":"Mauritius",
      "currency":"MUR",
      "symbol": '₨',
  }  ,
  {
    "country_code": "MX",
      "name":"Mexico",
      "currency":"MXN",
      "symbol": '$',
  } ,
  {
    "country_code": "FM",
      "name": "Federated States of Micronesia",
      "currency":"USD",
      "symbol": '$',
  } ,
  {
    "country_code": "MD",
      "name":"Republic of Moldova",
      "currency":"MDL",
      "symbol": 'lei',
  }  ,
  {
    "country_code": "MC",
      "name":"Monaco",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "MN",
      "name":"Mongolia",
      "currency":"MNT",
      "symbol": '₮',
  }  ,
  {
    "country_code": "ME",
      "name":"Montenegro",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "MS",
      "name": "Montserrat",
      "currency":"XCD",
      "symbol": '$',
  } ,
  {
    "country_code": "MA",
      "name":"Morocco",
      "currency":"MAD",
      "symbol": 'MAD',
  }  ,
  {
    "country_code": "MZ",
      "name":"Mozambique",
      "currency":"MZN",
      "symbol": 'MT',
  } ,
  {
    "country_code": "MM",
      "name":"Myanmar",
      "currency":"MMK",
      "symbol": 'K',
  }  ,
  {
    "country_code": "NA",
      "name":"Namibia",
      "currency":"NAD",
      "symbol": '$',
  }  ,
  {
    "country_code": "NR",
      "name":"Nauru",
      "currency":"AUD",
      "symbol": '$',
  }  ,
  {
    "country_code": "NP",
      "name":"Nepal",
      "currency":"NPR",
      "symbol": '₨',
  }  ,
  {
    "country_code": "NL",
      "name":"Netherlands",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "NZ",
      "name":"New Zealand",
      "currency":"NZD",
      "symbol": '$',
  }  ,
  {
    "country_code": "NI",
      "name":"Nicaragua",
      "currency":"NIO",
      "symbol": 'C$',
  }  ,
  {
    "country_code": "NE",
      "name":"Niger",
      "currency":"XOF",
      "symbol": 'CFA',
  }  ,
  {
    "country_code": "NG",
      "name":"Nigeria",
      "currency":"NGN",
      "symbol": '₦',
  }  ,
  {
    "country_code": "NU",
      "name": "Niue",
      "currency":"NZD",
      "symbol": '$',
  } ,
  {
    "country_code": "NF",
      "name":"Norfolk Island",
      "currency":"AUD",
      "symbol": '$',
  }  ,
  {
    "country_code": "MP",
      "name": "Northern Mariana Islands",
      "currency":"USD",
      "symbol": '$',
  } ,
  {
    "country_code": "NO",
      "name":"Norway",
      "currency":"NOK",
      "symbol": 'kr',
  }  ,
  {
    "country_code": "OM",
      "name":"Oman",
      "currency":"OMR",
      "symbol": '﷼',
  }  ,
  {
    "country_code": "PK",
      "name":"Pakistan",
      "currency":"PKR",
      "symbol": '₨',
  }  ,
  {
    "country_code": "PW",
      "name": "Palau",
      "currency":"USD",
      "symbol": '$',
  } ,
  {
    "country_code": "PS",
      "name": "Palestinian Territory",
      "currency":"ILS",
      "symbol": '₪',
  } ,
  {
    "country_code": "PA",
      "name":"Panama",
      "currency":"PAB",
      "symbol":  'B/.',
  }  ,
  {
    "country_code": "PG",
      "name":"Papua New Guinea",
      "currency":"PGK",
      "symbol": 'K',
  }  ,
  {
    "country_code": "PY",
      "name":"Paraguay",
      "currency":"PYG",
      "symbol": 'Gs',
  }  ,
  {
    "country_code": "PE",
      "name":"Peru",
      "currency":"PEN",
      "symbol": 'S/.',
  }  ,
  {
    "country_code": "PH",
      "name":"Philippines",
      "currency":"PHP",
      "symbol": '₱',
  }  ,
  {
    "country_code": "PN",
      "name":"Pitcairn",
      "currency":"NZD",
      "symbol": '$',
  }  ,
  {
    "country_code": "PL",
      "name":"Poland",
      "currency":"PLN",
      "symbol": 'zł',
  }  ,
  {
    "country_code": "PT",
      "name":"Portugal",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "PR",
      "name":"Puerto Rico",
      "currency":"USD",
      "symbol": '$',
  }  ,
  {
    "country_code": "QA",
      "name":"Qatar",
      "currency":"QAR",
      "symbol": '﷼',
  }  ,
  {
    "country_code": "RO",
      "name":"Romania",
      "currency":"RON",
      "symbol": 'lei',
  }  ,
  {
    "country_code": "RU",
      "name":"Russian",
      "currency":"RUB",
      "symbol": '₽',
  }  ,
  {
    "country_code": "RW",
      "name":"Rwanda",
      "currency":"RWF",
      "symbol": 'R₣',
  }  ,
  {
    "country_code": "KN",
      "name":"Saint Kitts and Nevis",
      "currency":"XCD",
      "symbol": '$',
  }  ,
  {
    "country_code": "WS",
      "name":"Samoa",
      "currency":"WST",
      "symbol": 'WS$',
  }  ,
  {
    "country_code": "SM",
      "name":"San Marino",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "ST",
      "name":"Sao Tome and Principe",
      "currency":"STD",
      "symbol": 'Db',
  }  ,
  {
    "country_code": "SA",
      "name":"Saudi Arabia",
      "currency":"SAR",
      "symbol": '﷼',
  }  ,
  {
    "country_code": "SN",
      "name":"Senegal",
      "currency":"XOF",
      "symbol": 'CFA',
  }  ,
  {
    "country_code": "RS",
      "name": "Serbia",
      "currency":"RSD",
      "symbol": 'Дин.',
  } ,
  {
    "country_code": "SC",
      "name":"Seychelles",
      "currency":"SCR",
      "symbol": '₨',
  }  ,
  {
    "country_code": "SL",
      "name":"Sierra Leone",
      "currency":"SLL",
      "symbol": 'Le',
  }  ,
  {
    "country_code": "SG",
      "name": "Singapore",
      "currency":"SGD",
      "symbol": '$',
  } ,
  {
    "country_code": "SX",
      "name":"Sint Maarten",
      "currency":"ANG",
      "symbol": 'ƒ',
  }  ,
  {
    "country_code": "SK",
      "name":"Slovakia",
      "currency":"EUR",
      "symbol": '€',
  }  ,
  {
    "country_code": "SI",
      "name": "Slovenia",
      "currency":"EUR",
      "symbol": '€',
  } ,
  {
    "country_code": "SB",
      "name":"Solomon Islands",
      "currency":"SBD",
      "symbol": '$',
  }  ,
  {
    "country_code": "SO",
      "name":"Somalia",
      "currency":"SOS",
      "symbol": 'S',
  },
  {
    "country_code": "ZA",
      "name":"South Africa",
      "currency":"ZAR",
      "symbol": 'R',
  },
  {
    "country_code": "SS",
      "name":"South Sudan",
      "currency":"SSP",
      "symbol": '',
  },
  {
    "country_code": "ES",
      "name":"Spain",
      "currency":"EUR",
      "symbol": '€',
  },
  {
    "country_code": "LK",
      "name":"Sri Lanka",
      "currency":"LKR",
      "symbol": '₨',
  } ,
  {
    "country_code": "SD",
      "name":"Sudan",
      "currency":"SDG",
      "symbol": 'ج.س.',
  },
  {
    "country_code": "SR",
      "name":"Suriname",
      "currency":"SRD",
      "symbol": '$',
  }  ,
  {
    "country_code": "SZ",
      "name":"Swaziland",
      "currency":"SZL",
      "symbol": 'E',
  }  ,
  {
    "country_code": "SE",
      "name":"Sweden",
      "currency":"SEK",
      "symbol": 'kr',
  }  ,
  {
    "country_code": "CH",
      "name":"Switzerland",
      "currency":"CHF",
      "symbol": 'CHF',
  }  ,
  {
    "country_code": "SY",
      "name":"Syria",
      "currency":"SYP",
      "symbol": '£',
  }  ,
  {
    "country_code": "TW",
      "name":"Taiwan, Province of China",
      "currency":"TWD",
      "symbol": 'NT$',
  },
  {
    "country_code": "TJ",
      "name":"Tajikistan",
      "currency":"TJS",
      "symbol": 'SM',
  },
  {
    "country_code": "TZ",
      "name":"Tanzania",
      "currency":"TZS",
      "symbol": 'TSh',
  },
  {
    "country_code": "TH",
      "name":"Thailand",
      "currency":"THB",
      "symbol": '',
  },
  {
    "country_code": "TG",
      "name":"Togo",
      "currency":"XOF",
      "symbol": 'CFA',
  },
  {
    "country_code": "TK",
      "name":"Tokelau",
      "currency":"NZD",
      "symbol": '$',
  },
  {
    "country_code": "TO",
      "name":"Tonga",
      "currency":"TOP",
      "symbol": 'T$',
  },
  {
    "country_code": "TT",
      "name":"Trinidad and Tobago",
      "currency":"TTD",
      "symbol": 'TT$',
  },
  {
    "country_code": "TN",
      "name":"Tunisia",
      "currency":"TND",
      "symbol": 'د.ت',
  },
  {
    "country_code": "TR",
      "name":"Turkey",
      "currency":"TRY",
      "symbol": '₺',
  },
  {
    "country_code": "TM",
      "name":"Turkmenistan",
      "currency":"TMT",
      "symbol": 'T',
  },
  {
    "country_code": "TC",
      "name":"Turks and Caicos Islands",
      "currency":"USD",
      "symbol": '$',
  },
  {
    "country_code": "TV",
      "name":"Tuvalu",
      "currency":"AUD",
      "symbol": '$',
  },
  {
    "country_code": "UG",
      "name":"Uganda",
      "currency":"UGX",
      "symbol": 'USh',
  },
  {
    "country_code": "UA",
      "name":"Ukraine",
      "currency":"UAH",
      "symbol":  '₴',
  },
  {
    "country_code": "AE",
      "name":"United Arab Emirates",
      "currency":"AED",
      "symbol": 'د.إ',
  },
  {
    "country_code": "GB",
      "name":"United Kingdom",
      "currency":"GBP",
      "symbol": '£',
  },
  {
    "country_code": "US",
      "name":"United States",
      "currency":"USD",
      "symbol": '$',
  },
  {
    "country_code": "UY",
      "name":"Uruguay",
      "currency":"UYU",
      "symbol": '$U',
  },
  {
    "country_code": "UZ",
      "name":"Uzbekistan",
      "currency":"UZS",
      "symbol": 'лв',
  },
  {
    "country_code": "VU",
      "name":"Vanuatu",
      "currency":"VUV",
      "symbol": 'VT',
  },
  {
    "country_code": "VE",
      "name":"Venezuela, Bolivarian Republic of",
      "currency":"VEF",
      "symbol": 'Bs',
  },
  {
    "country_code": "VN",
      "name":"Viet Nam",
      "currency":"VND",
      "symbol": '₫',
  },
  {
    "country_code": "VI",
      "name":"Virgin Islands",
      "currency":"USD",
      "symbol": '$',
  },
  {
      "country_code": "YE",
      "name":"Yemen",
      "currency":"YER",
      "symbol": '﷼',
  },
  {
      "country_code": "ZM",
      "name":"Zambia",
      "currency":"ZMK",
      "symbol": '',
  },
  {
      "country_code": "ZW",
      "name":"Zimbabwe",
      "currency":"ZWL",
      "symbol": '',
  }
];


export const countryPhoneCodes = [
  { "value": "+93", "label": "Afghanistan", "flag": "fi-af" },
  { "value": "+355", "label": "Albania", "flag": "fi-al" },
  { "value": "+213", "label": "Algeria", "flag": "fi-dz" },
  { "value": "+1684", "label": "American Samoa", "flag": "fi-as" },
  { "value": "+376", "label": "Andorra", "flag": "fi-ad" },
  { "value": "+244", "label": "Angola", "flag": "fi-ao" },
  { "value": "+1264", "label": "Anguilla", "flag": "fi-ai" },
  { "value": "+672", "label": "Antarctica", "flag": "fi-aq" },
  { "value": "+1268", "label": "Antigua and Barbuda", "flag": "fi-ag" },
  { "value": "+54", "label": "Argentina", "flag": "fi-ar" },
  { "value": "+374", "label": "Armenia", "flag": "fi-am" },
  { "value": "+297", "label": "Aruba", "flag": "fi-aw" },
  { "value": "+61", "label": "Australia", "flag": "fi-au" },
  { "value": "+43", "label": "Austria", "flag": "fi-at" },
  { "value": "+994", "label": "Azerbaijan", "flag": "fi-az" },
  { "value": "+1242", "label": "Bahamas", "flag": "fi-bs" },
  { "value": "+973", "label": "Bahrain", "flag": "fi-bh" },
  { "value": "+880", "label": "Bangladesh", "flag": "fi-bd" },
  { "value": "+1246", "label": "Barbados", "flag": "fi-bb" },
  { "value": "+375", "label": "Belarus", "flag": "fi-by" },
  { "value": "+32", "label": "Belgium", "flag": "fi-be" },
  { "value": "+501", "label": "Belize", "flag": "fi-bz" },
  { "value": "+229", "label": "Benin", "flag": "fi-bj" },
  { "value": "+1441", "label": "Bermuda", "flag": "fi-bm" },
  { "value": "+975", "label": "Bhutan", "flag": "fi-bt" },
  { "value": "+591", "label": "Bolivia", "flag": "fi-bo" },
  { "value": "+387", "label": "Bosnia and Herzegovina", "flag": "fi-ba" },
  { "value": "+267", "label": "Botswana", "flag": "fi-bw" },
  { "value": "+55", "label": "Brazil", "flag": "fi-br" },
  { "value": "+246", "label": "British Indian Ocean Territory", "flag": "fi-io" },
  { "value": "+1284", "label": "British Virgin Islands", "flag": "fi-vg" },
  { "value": "+673", "label": "Brunei", "flag": "fi-bn" },
  { "value": "+359", "label": "Bulgaria", "flag": "fi-bg" },
  { "value": "+226", "label": "Burkina Faso", "flag": "fi-bf" },
  { "value": "+257", "label": "Burundi", "flag": "fi-bi" },
  { "value": "+855", "label": "Cambodia", "flag": "fi-kh" },
  { "value": "+237", "label": "Cameroon", "flag": "fi-cm" },
  { "value": "+1", "label": "Canada", "flag": "fi-ca" },
  { "value": "+238", "label": "Cape Verde", "flag": "fi-cv" },
  { "value": "+1345", "label": "Cayman Islands", "flag": "fi-ky" },
  { "value": "+236", "label": "Central African Republic", "flag": "fi-cf" },
  { "value": "+235", "label": "Chad", "flag": "fi-td" },
  { "value": "+56", "label": "Chile", "flag": "fi-cl" },
  { "value": "+86", "label": "China", "flag": "fi-cn" },
  { "value": "+61", "label": "Christmas Island", "flag": "fi-cx" },
  { "value": "+61", "label": "Cocos Islands", "flag": "fi-cc" },
  { "value": "+57", "label": "Colombia", "flag": "fi-co" },
  { "value": "+269", "label": "Comoros", "flag": "fi-km" },
  { "value": "+682", "label": "Cook Islands", "flag": "fi-ck" },
  { "value": "+506", "label": "Costa Rica", "flag": "fi-cr" },
  { "value": "+385", "label": "Croatia", "flag": "fi-hr" },
  { "value": "+53", "label": "Cuba", "flag": "fi-cu" },
  { "value": "+599", "label": "Curacao", "flag": "fi-cw" },
  { "value": "+357", "label": "Cyprus", "flag": "fi-cy" },
  { "value": "+420", "label": "Czech Republic", "flag": "fi-cz" },
  { "value": "+243", "label": "Democratic Republic of the Congo", "flag": "fi-cd" },
  { "value": "+45", "label": "Denmark", "flag": "fi-dk" },
  { "value": "+253", "label": "Djibouti", "flag": "fi-dj" },
  { "value": "+1767", "label": "Dominica", "flag": "fi-dm" },
  { "value": "+1809", "label": "Dominican Republic", "flag": "fi-do" },
  { "value": "+1829", "label": "Dominican Republic", "flag": "fi-do" },
  { "value": "+1849", "label": "Dominican Republic", "flag": "fi-do" },
  { "value": "+670", "label": "East Timor", "flag": "fi-tl" },
  { "value": "+593", "label": "Ecuador", "flag": "fi-ec" },
  { "value": "+20", "label": "Egypt", "flag": "fi-eg" },
  { "value": "+503", "label": "El Salvador", "flag": "fi-sv" },
  { "value": "+240", "label": "Equatorial Guinea", "flag": "fi-gq" },
  { "value": "+291", "label": "Eritrea", "flag": "fi-er" },
  { "value": "+372", "label": "Estonia", "flag": "fi-ee" },
  { "value": "+251", "label": "Ethiopia", "flag": "fi-et" },
  { "value": "+500", "label": "Falkland Islands", "flag": "fi-fk" },
  { "value": "+298", "label": "Faroe Islands", "flag": "fi-fo" },
  { "value": "+679", "label": "Fiji", "flag": "fi-fj" },
  { "value": "+358", "label": "Finland", "flag": "fi-fi" },
  { "value": "+33", "label": "France", "flag": "fi-fr" },
  { "value": "+689", "label": "French Polynesia", "flag": "fi-pf" },
  { "value": "+241", "label": "Gabon", "flag": "fi-ga" },
  { "value": "+220", "label": "Gambia", "flag": "fi-gm" },
  { "value": "+995", "label": "Georgia", "flag": "fi-ge" },
  { "value": "+49", "label": "Germany", "flag": "fi-de" },
  { "value": "+233", "label": "Ghana", "flag": "fi-gh" },
  { "value": "+350", "label": "Gibraltar", "flag": "fi-gi" },
  { "value": "+30", "label": "Greece", "flag": "fi-gr" },
  { "value": "+299", "label": "Greenland", "flag": "fi-gl" },
  { "value": "+1473", "label": "Grenada", "flag": "fi-gd" },
  { "value": "+1671", "label": "Guam", "flag": "fi-gu" },
  { "value": "+502", "label": "Guatemala", "flag": "fi-gt" },
  { "value": "+441481", "label": "Guernsey", "flag": "fi-gg" },
  { "value": "+224", "label": "Guinea", "flag": "fi-gn" },
  { "value": "+245", "label": "Guinea-Bissau", "flag": "fi-gw" },
  { "value": "+592", "label": "Guyana", "flag": "fi-gy" },
  { "value": "+509", "label": "Haiti", "flag": "fi-ht" },
  { "value": "+504", "label": "Honduras", "flag": "fi-hn" },
  { "value": "+852", "label": "Hong Kong", "flag": "fi-hk" },
  { "value": "+36", "label": "Hungary", "flag": "fi-hu" },
  { "value": "+354", "label": "Iceland", "flag": "fi-is" },
  { "value": "+91", "label": "India", "flag": "fi-in" },
  { "value": "+62", "label": "Indonesia", "flag": "fi-id" },
  { "value": "+98", "label": "Iran", "flag": "fi-ir" },
  { "value": "+964", "label": "Iraq", "flag": "fi-iq" },
  { "value": "+353", "label": "Ireland", "flag": "fi-ie" },
  { "value": "+44-1624", "label": "Isle of Man", "flag": "fi-im" },
  { "value": "+972", "label": "Israel", "flag": "fi-il" },
  { "value": "+39", "label": "Italy", "flag": "fi-it" },
  { "value": "+225", "label": "Ivory Coast", "flag": "fi-ci" },
  { "value": "+1876", "label": "Jamaica", "flag": "fi-jm" },
  { "value": "+81", "label": "Japan", "flag": "fi-jp" },
  { "value": "+441534", "label": "Jersey", "flag": "fi-je" },
  { "value": "+962", "label": "Jordan", "flag": "fi-jo" },
  { "value": "+7", "label": "Kazakhstan", "flag": "fi-kz" },
  { "value": "+254", "label": "Kenya", "flag": "fi-ke" },
  { "value": "+686", "label": "Kiribati", "flag": "fi-ki" },
  { "value": "+383", "label": "Kosovo", "flag": "fi-xk" },
  { "value": "+965", "label": "Kuwait", "flag": "fi-kw" },
  { "value": "+996", "label": "Kyrgyzstan", "flag": "fi-kg" },
  { "value": "+856", "label": "Laos", "flag": "fi-la" },
  { "value": "+371", "label": "Latvia", "flag": "fi-lv" },
  { "value": "+961", "label": "Lebanon", "flag": "fi-lb" },
  { "value": "+266", "label": "Lesotho", "flag": "fi-ls" },
  { "value": "+231", "label": "Liberia", "flag": "fi-lr" },
  { "value": "+218", "label": "Libya", "flag": "fi-ly" },
  { "value": "+423", "label": "Liechtenstein", "flag": "fi-li" },
  { "value": "+370", "label": "Lithuania", "flag": "fi-lt" },
  { "value": "+352", "label": "Luxembourg", "flag": "fi-lu" },
  { "value": "+853", "label": "Macau", "flag": "fi-mo" },
  { "value": "+389", "label": "Macedonia", "flag": "fi-mk" },
  { "value": "+261", "label": "Madagascar", "flag": "fi-mg" },
  { "value": "+265", "label": "Malawi", "flag": "fi-mw" },
  { "value": "+60", "label": "Malaysia", "flag": "fi-my" },
  { "value": "+960", "label": "Maldives", "flag": "fi-mv" },
  { "value": "+223", "label": "Mali", "flag": "fi-ml" },
  { "value": "+356", "label": "Malta", "flag": "fi-mt" },
  { "value": "+692", "label": "Marshall Islands", "flag": "fi-mh" },
  { "value": "+222", "label": "Mauritania", "flag": "fi-mr" },
  { "value": "+230", "label": "Mauritius", "flag": "fi-mu" },
  { "value": "+262", "label": "Mayotte", "flag": "fi-yt" },
  { "value": "+52", "label": "Mexico", "flag": "fi-mx" },
  { "value": "+691", "label": "Micronesia", "flag": "fi-fm" },
  { "value": "+373", "label": "Moldova", "flag": "fi-md" },
  { "value": "+377", "label": "Monaco", "flag": "fi-mc" },
  { "value": "+976", "label": "Mongolia", "flag": "fi-mn" },
  { "value": "+382", "label": "Montenegro", "flag": "fi-me" },
  { "value": "+1664", "label": "Montserrat", "flag": "fi-ms" },
  { "value": "+212", "label": "Morocco", "flag": "fi-ma" },
  { "value": "+258", "label": "Mozambique", "flag": "fi-mz" },
  { "value": "+95", "label": "Myanmar", "flag": "fi-mm" },
  { "value": "+264", "label": "Namibia", "flag": "fi-na" },
  { "value": "+674", "label": "Nauru", "flag": "fi-nr" },
  { "value": "+977", "label": "Nepal", "flag": "fi-np" },
  { "value": "+31", "label": "Netherlands", "flag": "fi-nl" },
  { "value": "+599", "label": "Netherlands Antilles", "flag": "fi-an" },
  { "value": "+687", "label": "New Caledonia", "flag": "fi-nc" },
  { "value": "+64", "label": "New Zealand", "flag": "fi-nz" },
  { "value": "+505", "label": "Nicaragua", "flag": "fi-ni" },
  { "value": "+227", "label": "Niger", "flag": "fi-ne" },
  { "value": "+234", "label": "Nigeria", "flag": "fi-ng" },
  { "value": "+683", "label": "Niue", "flag": "fi-nu" },
  { "value": "+850", "label": "North Korea", "flag": "fi-kp" },
  { "value": "+1670", "label": "Northern Mariana Islands", "flag": "fi-mp" },
  { "value": "+47", "label": "Norway", "flag": "fi-no" },
  { "value": "+968", "label": "Oman", "flag": "fi-om" },
  { "value": "+92", "label": "Pakistan", "flag": "fi-pk" },
  { "value": "+680", "label": "Palau", "flag": "fi-pw" },
  { "value": "+970", "label": "Palestine", "flag": "fi-ps" },
  { "value": "+507", "label": "Panama", "flag": "fi-pa" },
  { "value": "+675", "label": "Papua New Guinea", "flag": "fi-pg" },
  { "value": "+595", "label": "Paraguay", "flag": "fi-py" },
  { "value": "+51", "label": "Peru", "flag": "fi-pe" },
  { "value": "+63", "label": "Philippines", "flag": "fi-ph" },
  { "value": "+64", "label": "Pitcairn", "flag": "fi-pn" },
  { "value": "+48", "label": "Poland", "flag": "fi-pl" },
  { "value": "+351", "label": "Portugal", "flag": "fi-pt" },
  { "value": "+1787", "label": "Puerto Rico", "flag": "fi-pr" },
  { "value": "+1939", "label": "Puerto Rico", "flag": "fi-pr" },
  { "value": "+974", "label": "Qatar", "flag": "fi-qa" },
  { "value": "+242", "label": "Republic of the Congo", "flag": "fi-cg" },
  { "value": "+262", "label": "Reunion", "flag": "fi-re" },
  { "value": "+40", "label": "Romania", "flag": "fi-ro" },
  { "value": "+7", "label": "Russia", "flag": "fi-ru" },
  { "value": "+250", "label": "Rwanda", "flag": "fi-rw" },
  { "value": "+590", "label": "Saint Barthelemy", "flag": "fi-bl" },
  { "value": "+290", "label": "Saint Helena", "flag": "fi-sh" },
  { "value": "+1869", "label": "Saint Kitts and Nevis", "flag": "fi-kn" },
  { "value": "+1758", "label": "Saint Lucia", "flag": "fi-lc" },
  { "value": "+590", "label": "Saint Martin", "flag": "fi-mf" },
  { "value": "+508", "label": "Saint Pierre and Miquelon", "flag": "fi-pm" },
  { "value": "+1784", "label": "Saint Vincent and the Grenadines", "flag": "fi-vc" },
  { "value": "+685", "label": "Samoa", "flag": "fi-ws" },
  { "value": "+378", "label": "San Marino", "flag": "fi-sm" },
  { "value": "+239", "label": "Sao Tome and Principe", "flag": "fi-st" },
  { "value": "+966", "label": "Saudi Arabia", "flag": "fi-sa" },
  { "value": "+221", "label": "Senegal", "flag": "fi-sn" },
  { "value": "+381", "label": "Serbia", "flag": "fi-rs" },
  { "value": "+248", "label": "Seychelles", "flag": "fi-sc" },
  { "value": "+232", "label": "Sierra Leone", "flag": "fi-sl" },
  { "value": "+65", "label": "Singapore", "flag": "fi-sg" },
  { "value": "+1721", "label": "Sint Maarten", "flag": "fi-sx" },
  { "value": "+421", "label": "Slovakia", "flag": "fi-sk" },
  { "value": "+386", "label": "Slovenia", "flag": "fi-si" },
  { "value": "+677", "label": "Solomon Islands", "flag": "fi-sb" },
  { "value": "+252", "label": "Somalia", "flag": "fi-so" },
  { "value": "+27", "label": "South Africa", "flag": "fi-za" },
  { "value": "+82", "label": "South Korea", "flag": "fi-kr" },
  { "value": "+211", "label": "South Sudan", "flag": "fi-ss" },
  { "value": "+34", "label": "Spain", "flag": "fi-es" },
  { "value": "+94", "label": "Sri Lanka", "flag": "fi-lk" },
  { "value": "+249", "label": "Sudan", "flag": "fi-sd" },
  { "value": "+597", "label": "Suriname", "flag": "fi-sr" },
  { "value": "+47", "label": "Svalbard and Jan Mayen", "flag": "fi-sj" },
  { "value": "+268", "label": "Swaziland", "flag": "fi-sz" },
  { "value": "+46", "label": "Sweden", "flag": "fi-se" },
  { "value": "+41", "label": "Switzerland", "flag": "fi-ch" },
  { "value": "+963", "label": "Syria", "flag": "fi-sy" },
  { "value": "+886", "label": "Taiwan", "flag": "fi-tw" },
  { "value": "+992", "label": "Tajikistan", "flag": "fi-tj" },
  { "value": "+255", "label": "Tanzania", "flag": "fi-tz" },
  { "value": "+66", "label": "Thailand", "flag": "fi-th" },
  { "value": "+670", "label": "Timor-Leste", "flag": "fi-tl" },
  { "value": "+228", "label": "Togo", "flag": "fi-tg" },
  { "value": "+690", "label": "Tokelau", "flag": "fi-tk" },
  { "value": "+676", "label": "Tonga", "flag": "fi-to" },
  { "value": "+1868", "label": "Trinidad and Tobago", "flag": "fi-tt" },
  { "value": "+216", "label": "Tunisia", "flag": "fi-tn" },
  { "value": "+90", "label": "Turkey", "flag": "fi-tr" },
  { "value": "+993", "label": "Turkmenistan", "flag": "fi-tm" },
  { "value": "+1649", "label": "Turks and Caicos Islands", "flag": "fi-tc" },
  { "value": "+688", "label": "Tuvalu", "flag": "fi-tv" },
  { "value": "+256", "label": "Uganda", "flag": "fi-ug" },
  { "value": "+380", "label": "Ukraine", "flag": "fi-ua" },
  { "value": "+971", "label": "United Arab Emirates", "flag": "fi-ae" },
  { "value": "+44", "label": "United Kingdom", "flag": "fi-gb" },
  { "value": "+1", "label": "United States of America", "flag": "fi-us" },
  { "value": "+598", "label": "Uruguay", "flag": "fi-uy" },
  { "value": "+998", "label": "Uzbekistan", "flag": "fi-uz" },
  { "value": "+678", "label": "Vanuatu", "flag": "fi-vu" },
  { "value": "+58", "label": "Venezuela", "flag": "fi-ve" },
  { "value": "+84", "label": "Vietnam", "flag": "fi-vn" },
  { "value": "+1284", "label": "Virgin Islands (British)", "flag": "fi-vg" },
  { "value": "+1340", "label": "Virgin Islands (U.S.)", "flag": "fi-vi" },
  { "value": "+681", "label": "Wallis and Futuna", "flag": "fi-wf" },
  { "value": "+967", "label": "Yemen", "flag": "fi-ye" },
  { "value": "+260", "label": "Zambia", "flag": "fi-zm" },
  { "value": "+263", "label": "Zimbabwe", "flag": "fi-zw" }
];
