import React from "react";
import { Input, Form } from 'antd';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Spinner } from "reactstrap";

const GooglePlacesInput = ({ form, googleMapsReady, isRequired, countryRestrictionSearch, formData, setFormData, ...props }) => {
  
  const handleLocationChange = (address) => {
    form.setFieldsValue({
      location: address
    });
    setFormData({ ...formData, location: address });
  };

  const handleLocationSelect = (address) => {
    let _formData = formData;
    _formData.location = address;
    
    form.setFieldsValue({
      location: address
    });

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        _formData.lat = latLng.lat;
        _formData.lng = latLng.lng;

        setFormData({ ...formData, _formData });
      })
      .catch(error => {
        setFormData({ ...formData, _formData });
      });

  };

  let searchOptions = countryRestrictionSearch ? {
    componentRestrictions: { country: countryRestrictionSearch },
    // types: ['city']
  }: {}

  return (
  <Form.Item>
  {
    googleMapsReady ? 
    (
      <Form.Item name={'location'}
        noStyle
        rules={[
          {required: isRequired, message: 'Location is required'}
        ]}>
        <PlacesAutocomplete
          value={formData.location}
          onChange={handleLocationChange}
          onSelect={handleLocationSelect}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              
                <Input size="large"
                  {...getInputProps({
                    placeholder: 'Enter location...',
                    className: 'location-search-input form-control form-control-lg',
                  })}
                />
              
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active mb-1 p-1'
                    : 'suggestion-item mb-1 p-1';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span><em className="icon ni ni-map-pin text-light"></em> {suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Item>
    )
    :
    (<div className="pt-2 nk-block-des"><Spinner size="sm" className="me-1" color="light" />  Loading Google Maps...</div>)
  }  
  </Form.Item>
  );
};


export default GooglePlacesInput;