import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { PayoutsTable } from "./components/tables/PayoutsTable";

const Payouts = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  
  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Payouts" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      <BlockHead size="sm">
        <div className="nk-block-between">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              Payouts
            </BlockTitle>
            
          </BlockHeadContent>
        </div>
      </BlockHead>

      <PayoutsTable history={history} businessID={businessID} currency={businessInfo.currency} businessVerified={businessInfo.is_verified} />
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default Payouts;