import React from "react";
import Oops from "../../images/Oops.png";
import { Block } from "../../components/Component";

export const ErrorBlock = (props) => {
  return (
    <Block>
      <div className="text-center m-5">
        <div className="error">
          <img src={Oops} className="w-250px" alt="Error" />
        </div>
        <div className="price-plan-info">
          <h5 className="title fw-normal">Something went wrong</h5>
          <span>{props.errorMessage}</span>
        </div>
        <div className="price-plan-action">
          <button className="btn btn-outline-primary" onClick={() => {window.location.reload();}}>Try again</button>                                
        </div>
      </div>
    </Block>
  )
}