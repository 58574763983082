import React, { useEffect, useState } from "react";
import { getAxiosHeaders, orderStatusOptions } from "../../../utils/Utils";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import { 
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input, Select, Checkbox, Form, InputNumber } from 'antd';
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { LinkItUrl } from 'react-linkify-it';
import OrderOutstandingIcon from "../../../images/cart.png";
import PendingApprovalIcon from "../../../images/receipt.png";
import ProcessingIcon from "../../../images/processing.png";
import ReadyForPickupIcon from "../../../images/ready_pickup.png";
import InTransitIcon from "../../../images/in_transit.png";
import DeliveredIcon from "../../../images/delivered.png";
const { Option } = Select;
const { TextArea } = Input;

const OrderInfo = ({ orderInfo, setOrderInfo, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleOrderFormSubmit = (_formData) => {
    setLoading(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/purchase-orders/order-status/${orderInfo.order_id}`, {
      business_id: orderInfo.business_id,
      order_status: _formData.order_status,
      order_note: _formData.order_note,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let orderData = orderInfo;

      orderData.order_log = responseInfo.data.order_log.reverse();
      orderData.order_status = responseInfo.data.order_status;
      setOrderInfo({...orderData})

      toggleModal('successModal');
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'status',
              errors: errorResponse.errors.hasOwnProperty("status") ? [errorResponse.errors.status] : [],
            },
            {
              name: 'order_note',
              errors: errorResponse.errors.hasOwnProperty("customer_note") ? [errorResponse.errors.customer_note] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case 'reviewing':
        return "Reviewing Order";

      case 'pending_approval':
        return "Pending Approval";
    
      case 'approved':
        return "Approved";

      case 'pending_delivery':
        return "Pending Delivery";
    
      case 'delivered':
        return "Delivered";
      
      default:
        return "Reviewing Order";
    }
  }
  
  return (
    <div className="fm-footer" style={{backgroundColor: "#1c2b46"}}>
      <div className="nk-help">
        <div className="nk-help-img w-100px mr-0">
          {
            orderInfo.order_status === 'reviewing' && 
            <img src={OrderOutstandingIcon} width="80px" alt={getOrderStatus(orderInfo.order_status)} />
          }

          {
            orderInfo.order_status === 'pending_approval' && 
            <img src={PendingApprovalIcon} width="80px" alt={getOrderStatus(orderInfo.order_status)} />
          }

          {
            orderInfo.order_status === 'approved' && 
            <img src={ReadyForPickupIcon} width="80px" alt={getOrderStatus(orderInfo.order_status)} />
          }

          {
            orderInfo.order_status === 'pending_delivery' && 
            <img src={ProcessingIcon} width="80px" alt={getOrderStatus(orderInfo.order_status)} />
          }

          {
            orderInfo.order_status === 'delivered' && 
            <img src={DeliveredIcon} width="80px" alt={getOrderStatus(orderInfo.order_status)} />
          }
        </div>
        <div className="nk-help-text">
          <span className="sub-title text-white">Order Status:</span>  
          <h5 className="title text-white">{getOrderStatus(orderInfo.order_status)}</h5>
          {orderInfo.order_log[0].notes && <LinkItUrl className="text-warning"><p className="sub-title text-white fs-12px mt-2 mb-1" style={{whiteSpace: "pre-wrap"}}>{orderInfo.order_log[0].notes}</p></LinkItUrl>}
          <p className="mb-0"><small className="text-white">Updated By: {orderInfo.order_log[0].updated_by}</small></p>
        </div>
        {
          orderInfo.order_status !== 'delivered' &&
          <div className="dark-mode nk-help-action text-md-center">
            <button onClick={() => toggleModal('orderModal')} className="btn btn-md btn-primary">Update Order Status</button>
          </div>
        }
      </div>

      {/* order modal */}
      {
        activeModal === "orderModal" &&
        <Modal backdrop="static" isOpen={true} id="order-modal">
          <ModalHeader
            toggle={() => toggleModal("orderModal")}
            close={
              <button className="close" onClick={() => toggleModal("orderModal")}>
                <Icon name="cross" />
              </button>
            }
          >
            Update Order Status
          </ModalHeader>
          <ModalBody>
            {errorMessage && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorMessage}{" "}
                </Alert>
              </div>
            )}
            <Form name="update-order"
              form={form} className="is-alter"
              onFinish={handleOrderFormSubmit}
              initialValues={{
                order_note: orderInfo.order_note,
                order_status: orderInfo.order_status,       
              }}
              onFinishFailed={onFinishFailed}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Order Status <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                      <Form.Item name={'order_status'}
                        noStyle
                        rules={[
                          {required: true, message: 'Order status is required',}
                        ]}>
                        <Select required size="large" 
                          name="country"
                          placeholder="Select order status"
                          style={{ width: "100%" }}
                          options={[
                            {
                              key: 'reviewing',
                              label: 'Reviewing',
                              value: 'reviewing',
                            },
                            {
                              key: 'pending_approval',
                              label: 'Pending Approval',
                              value: 'pending_approval',
                            },
                            {
                              key: 'approved',
                              label: 'Approved',
                              value: 'approved',
                            },
                            {
                              key: 'pending_delivery',
                              label: 'Pending Delivery',
                              value: 'pending_delivery',
                            },
                            {
                              key: 'delivered',
                              label: 'Delivered',
                              value: 'delivered',
                            },                              
                          ]} 
                          showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Note <span className="text-muted">(optional)</span></label>
                      </div>
                      <div className="form-control-wrap">
                      <Form.Item name={'order_note'}
                        noStyle
                        rules={[
                          {required: false}
                        ]}>
                          <TextArea rows={4} placeholder="Order Note" />
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-start">
                    <Button className="m-1" disabled={loading} type="submit" color="primary" size="md" >
                      {loading ? <Spinner size="sm" color="light" /> : "Update Order"}
                    </Button>
                    <Button className="m-1" disabled={loading} type="button" onClick={() => toggleModal("orderModal")} color="light" size="md" >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter className="bg-light justify-content-start">
            <span className="sub-text">Use the form above to update the status of this order.</span>
          </ModalFooter>
        </Modal>
      }

      {/* success modal */}
      {
        activeModal === "successModal" &&
        <SuccessModal 
          showModal={true}
          toggleModal={() => toggleModal('successModal')}
          headerText={"Order Updated"}
          descriptionText={"Order has been updated successfully."} />
      }
    </div>
  );
};


export default OrderInfo;