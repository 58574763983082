import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";
import { Link, useHistory, useLocation } from "react-router-dom";
import Notification, { getNotifications } from "./dropdown/notification/Notification";
import MyBusinesses from "./dropdown/businesses/Businesses";
import Toggle from "../sidebar/Toggle";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import { getAxiosHeaders } from "../../utils/Utils";

const Header = ({ fixed, theme, className, sidebarToggle, setVisibility, themeState, setThemeState, showSideNav, ...props }) => {
  let history = useHistory();
  const [businessID, setBusinessID] = useState('');
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  const getBusinesses = () => {
    axios.get(baseURLs.API_URL + "/businesses", getAxiosHeaders())
    .then(async(response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/create-business`);
        return;
      }

      let responseInfo = response.data;
      let businesses = responseInfo.data.businesses;
      localStorage.setItem('my_businesses', JSON.stringify(businesses));
      
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    // Function to check business ID and stop when found
    const checkBusinessID = () => {
      const id = localStorage.getItem('current_business_id');
      if (id) {
        setBusinessID(id); // Set business ID and trigger useEffect below
        return true; // Return true to indicate it's found
      }
      return false; // Return false to keep checking
    };

    // Interval to keep checking every 1000ms (1 second) until found
    const intervalId = setInterval(() => {
      if (checkBusinessID()) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Runs only once on component mount

  useEffect(() => {
    getBusinesses();
    if (showSideNav && businessID) {
      getNotifications(businessID);      
    }
  }, [businessID]);

  return (
    <div className={headerClass}>
      <div className="container-lg wide-xl">
        <div className="nk-header-wrap">
          <div className="nk-header-brand">
            <Logo size={"sm"} />
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              { 
                showSideNav &&
                <>
                  <li className="notification-dropdown" onClick={() => setVisibility(false)}>
                    <MyBusinesses />
                  </li>
                  <li className="notification-dropdown" onClick={() => setVisibility(false)}>
                    <Notification />
                  </li> 
                </>
              }
              <li className="user-dropdown" onClick={() => setVisibility(false)}>
                <User themeState={themeState} setThemeState={setThemeState} />
              </li>
              { showSideNav && <li className="d-lg-none">
                <Toggle icon="menu" className="toggle nk-quick-nav-icon me-n1" click={sidebarToggle} />
              </li> }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
