import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosUploadHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import { SuccessModal } from "./components/AlertModals";
import { Input, Upload } from 'antd';
const { Dragger } = Upload;

const ImportRawMaterials = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [showImportErrors, setShowImportErrors] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [importInfo, setImportInfo] = useState({});
  const [importErrors, setImportErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };


  const uploadCSV = () => {
    if(fileList.length === 0 || fileList[0].status === "error") {
      setErrorMessage(fileList.length === 0 ? `Upload a CSV file` : fileList[0].error);
      return;
    }

    setUploading(true);
    setErrorMessage("");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('material_csv', file);
    });

    formData.set("business_id", businessID);

    axios.post(baseURLs.API_URL + "/raw-materials/import", formData, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;

      setSuccessMessage(`Total records processed: ${responseInfo.data.total_records}`);
      setImportInfo(responseInfo.data);
      setImportErrors(responseInfo.data.errors);
      toggleModal("successModal");
      setFileList([]);
      setUploading(false);
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setUploading(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    if(fileList.length === 0){
      setErrorMessage("");
    }
  }, [fileList])

  const uploadProps = {
    fileList,
    maxCount: 1,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: file => {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        file.status = 'error';
        file.error = 'You can only upload a CSV file.';
        setErrorMessage('You can only upload a CSV file.');
      }
      
      const isSizeValid = file.size / 1024 / 1024 < 2;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 2MB!';
        setErrorMessage('File must be smaller than 2MB!');
      }

      if(isCSV && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setErrorMessage("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      setFileList([file]);
      return false;
    },
  };

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Import Raw Materials" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Import Raw Materials
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/raw-materials/b/${businessID}/add`}>
                        <Button color="primary">
                          <Icon name="package"></Icon>
                          <span>Add a Raw Material</span>
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/raw-materials/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block className="wide-sm m-auto">
          <div className="card-bordered card mb-2">
            <div className="card-inner">
              <div className="nk-help">
                <div className="nk-help-img m-0">
                  <lord-icon
                    src="https://cdn.lordicon.com/ckatldkn.json"
                    trigger="loop"
                    delay="1000"
                    colors="primary:#e4e4e4,secondary:#ffffff,tertiary:#3080e8"
                    style={{width:"120px",height:"120px"}}>
                  </lord-icon>
                </div>
                <div className="nk-help-text">
                  <h5> Download CSV Template</h5>
                  <p className="text-soft">
                    Import raw materials using our simple CSV template. 
                    Just download, enter your raw materials info, and import hassle-free!
                  </p>
                </div>
                <div className="nk-help-action">
                <button className="btn btn-outline-primary" onClick={() => window.open(baseURLs.RAW_MATERIAL_IMPORT_TEMPLATE_LINK, '_blank').focus()}>
                  Download
                </button>
                </div>
              </div>
            </div>
          </div>
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Import Complete`} descriptionText={successMessage} 
          >
            <div className="text-start mt-3">
              <Alert color="light" className="alert-icon">
                {" "}
                <Icon name="check-circle" />
                Added Materials: <b>{importInfo.added_materials}</b>
              </Alert>
              <Alert color="light" className="alert-icon">
                {" "}
                <Icon name="check-circle" />
                Updated Materials: <b>{importInfo.updated_materials}</b>
              </Alert>
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" />
                Failed: <b>{importInfo.failed}.</b> <span className="alert-link pointer-cursor" onClick={() => setShowImportErrors(!showImportErrors)}>Click to {showImportErrors ? `hide` : `view`} {importInfo.failed > 1 ? `errors` : `error`}</span>
              </Alert>
            </div>
            {
              showImportErrors &&
              <div className="mt-4 text-start">
                {
                  importErrors.map((error, index) => {
                    return (
                      <Alert color="danger" className="alert-icon" key={index}>
                        {" "}
                        <Icon name="alert-circle" /> 
                          <span className="d-block">Raw Materials: {error.material_name}</span>
                          {
                            error.errors.map((msg, msgIndex) => {
                              return (
                                <span className="d-block" key={msgIndex}>{msgIndex + 1}. {msg}</span>
                              )
                            })
                          }
                        
                      </Alert>
                    )
                  })
                }
              </div>
            }
          </SuccessModal>
        }

        <Block className="wide-sm m-auto">          
          <div className="card card-bordered">
            <div className="fm-head bg-lighter">
              <div className="fm-count"><Icon name="upload-cloud fs-22px"></Icon></div>
              <div className="fm-title">
                <h5 className="title fw-normal">Upload Raw Materials CSV</h5>
                <p className="nk-block-des">Use the form below to upload the CSV file of your raw materials.</p></div>
            </div>
            <div className="fm-content">
              {errorMessage && (
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorMessage}{" "}
                </Alert>
              )}
              <div className="row g-4">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label">Raw Materials CSV <span className="text-danger">*</span></label>
                    </div>
                    <Dragger {...uploadProps}>
                      <div className="p-3">
                        <p className="ant-upload-drag-icon fs-1 text-muted">
                          <Icon name="upload-cloud"></Icon>
                        </p>
                        <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint text-muted">
                          Accepted file type for upload must be in CSV format, and the file size should not exceed 2MB.
                        </p>

                      </div>
                    </Dragger>
                  </div>
                </div>
              </div>
            </div>
            <div className="fm-footer">
              <div className="pt-2 text-center">
                <Button disabled={uploading} onClick={uploadCSV} color="primary" size="lg" >
                  {uploading ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default ImportRawMaterials;