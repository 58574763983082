import React, { useState, useEffect } from "react";
import { Empty } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders } from "../../../utils/Utils";
import { 
  Badge, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter 
} from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import { PaginationWithOnclick } from "./Pagination";
import { TableLoader } from "../../../utils/Loaders";

export const ProductDamagedLogsTable = ({ history, businessID, productID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, damaged_logs:[]});
  const [loading, setLoading] = useState(true);
  const [hasFilters, setHasFilters] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getDamagedLogs(params);
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getDamagedLogs(params);
  }

  const filterLogs = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getDamagedLogs(filters);
  }
 
  const getDamagedLogs = (_filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/products/damaged-logs/${productID}`, {
      params: {
        business_id: businessID,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        page: _filters.page,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, damaged_logs:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, damaged_logs:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    getDamagedLogs(filters);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <div className="nk-block">
          <div className="card-title-group mb-3">
            <h6 className="lead-text m-0">
              {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Records` : `Record`}`}
            </h6>
            <div className="card-tools me-n1">
              <ul className="btn-toolbar">
                <li>
                  <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                    { hasFilters && <div className="dot dot-primary"></div> }
                    <Icon name="search"></Icon>
                  </Button>
                  {
                    activeModal === "filterModal" &&
                    <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                      <ModalHeader
                        toggle={() => toggleModal('filterModal')}
                        close={
                          <button className="close" onClick={() => toggleModal('filterModal')}>
                            <Icon name="cross" />
                          </button>
                        }
                      >
                        Damaged Logs Filter
                      </ModalHeader>
                      <ModalBody>
                        <form>
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">From Date</label>                          
                                <div className="form-control-wrap">
                                  <DatePicker
                                    selected={fromDate}
                                    dateFormat="dd-MMM-yyyy"
                                    onChange={date => {
                                      onDateChange(date, 'from');
                                    }}
                                    
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    className="form-control form-control-lg date-picker"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">To Date</label>                          
                                <div className="form-control-wrap">
                                  <DatePicker
                                    selected={toDate}
                                    dateFormat="dd-MMM-yyyy"
                                    onChange={date => {
                                      onDateChange(date, 'to');
                                    }}
                                    
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    className="form-control form-control-lg date-picker"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ModalBody>
                      <ModalFooter className="bg-lighter justify-content-center">
                        <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterLogs();} } size="lg">
                          Apply Filter
                        </Button>

                        {
                          hasFilters &&
                          <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                            Reset Filter
                          </Button>
                        }
                      </ModalFooter>
                    </Modal>
                  }
                </li>
              </ul>
            </div>
          </div>
        {
          tableData.damaged_logs.length === 0 ?
          <div className="text-center m-5">
            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
            <div className="price-plan-info">
              <span className="title fw-normal">No log found</span>
            </div>
          </div>
          :
          <>
            <div className="card card-bordered mb-4">
              <div className="card-inner">
                <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                  <div className="analytic-data text-md-center m-1 pointer-cursor">
                    <div className="title text-success">Total Damages</div>
                    <div className="amount fs-4">
                      <span>{tableData.meta.total_records}</span>
                    </div>
                  </div>
                  <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                  <div className="analytic-data text-md-center m-1 pointer-cursor">
                    <div className="title text-danger">Total Amount</div>
                    <div className="amount fs-4">
                      <small className="text-muted fw-light me-1">{currency}</small> {tableData.meta.total_amount}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="nk-tb-list nk-tb-ulist is-compact border round-sm">
              <div className="nk-tb-item nk-tb-head">
                <div className="nk-tb-col">
                  <span className="sub-text">Quantity</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">Total</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">Damaged Date</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">Log Date</span>
                </div>
              </div>
              
                {
                  tableData.damaged_logs.map((data, index) => {
                    

                    return (
                      <div className="nk-tb-item" key={index}>
                        <div className="nk-tb-col">
                          <span>
                            <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                            <span className="">{data.quantity}</span>
                            <span className="d-sm-none ms-1 text-muted"><br/>
                              {`Total Amount: `}
                              <small className="text-muted">{currency} </small>{data.total_amount}
                              {` •  Log Date: ${moment(data.damaged_date).format("Do MMM, YYYY")}`} 
                              
                            </span>
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          <span className="amount">
                            <small className="text-muted">{currency} </small>
                            {data.total_amount}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          <span className="amount">
                            {moment(data.damaged_date).format("Do MMM, YYYY")}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          <span className="amount">
                            {moment(data.created_at).format("Do MMM, YYYY")}
                          </span>
                        </div>
                      </div>
                    )
                  })
                }
                
            </div>
            <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
          </>
        }
        </div>
      }
    </Block>
  );
};
