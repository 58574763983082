import React, { useState, useEffect } from "react";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import Logo from "../../layout/logo/Logo";
import { Input, Form } from 'antd';
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, Icon, PreviewCard } from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { Link } from "react-router-dom";

const ResetPassword = ({ history, match }) => {
  const [preLoading, setPreLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState(true);
  const [fullName, setFullName] = useState("");
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");

  const handleFormSubmit = (_formData) => {
    setLoading(true);
    setFormError("");

    axios.post(baseURLs.API_URL + "/auth/reset-password", {token: match.params.token, password: _formData.password})
    .then((response) => {
      history.push(`${process.env.PUBLIC_URL}/reset-password-success`)
    }).catch((error) => {
      setLoading(false);

      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);

      }catch(e){
        setFormError("Error resetting your password.");
      }
    });
    
  };

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const validateResetPasswordToken = (token) => {
    axios.post(baseURLs.API_URL + "/auth/verify-reset-password-token", {token: token})
    .then((response) => {
      let responseInfo = response.data;
      setFullName(responseInfo.data.full_name);
      setInvalidToken(false);
      setPreLoading(false);
    }).catch((error) => {
      setFormError("Invalid password reset token");
      setPreLoading(false);
    });
  }

  useEffect(() => {
    const token = match.params.token;
    if (token == undefined || null || "") {
      setFormError("Invalid password reset token");
      setInvalidToken(true);
      setPreLoading(false);
    } else {
      validateResetPasswordToken(token);
    }
  }, [match.params.token]);

  return (
    <React.Fragment>
      <Head title="Reset Password" />
      <PageContainer>
        {
          preLoading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs">
            <Spinner  color="dark" />
          </Block>
          :
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center">
              <Logo />
            </div>
            {
              invalidToken ?
              <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                <BlockHead>
                  <BlockContent className="text-center mb-4">
                    <BlockTitle tag="h4">Reset password</BlockTitle>
                  </BlockContent>
                </BlockHead>
                {formError && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {formError}{" "}
                    </Alert>
                  </div>
                )}
                <div className="form-note-s2 text-center pt-4">
                  <Link to={`${process.env.PUBLIC_URL}/login`}>
                    <Button color="primary" size="lg" className="btn-block">
                      Back to Login
                    </Button>
                  </Link>
                </div>
              </PreviewCard>
              :
              <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                <BlockHead>
                  <BlockContent className="text-center mb-4">
                    <BlockTitle tag="h4">Reset password</BlockTitle>
                    <BlockDes>
                      <p>Hi <b>{fullName}</b>, set a new password for your account.</p>
                    </BlockDes>
                  </BlockContent>
                </BlockHead>
                {formError && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {formError}{" "}
                    </Alert>
                  </div>
                )}
                <Form form={form} className="is-alter"
                  onFinish={handleFormSubmit}
                  onFinishFailed={onFinishFailed}>
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          New Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'password'}
                          noStyle
                          rules={[
                            {required: true, message: 'Password is required'}
                          ]}>
                          <Input.Password size="large"
                            type="password" placeholder="Enter a new password"
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <Button type="submit" color="primary" size="lg" className="btn-block">
                      {loading ? <Spinner size="sm" color="light" /> : "Save"}
                    </Button>
                  </div>
                </Form>
                <div className="form-note-s2 text-center pt-4">
                  <Link to={`${process.env.PUBLIC_URL}/login`}>
                    <strong>Return to login</strong>
                  </Link>
                </div>
              </PreviewCard>
            }
            
          </Block>
        }
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ResetPassword;
