import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Card, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { Empty } from 'antd';
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from "./components/manufacturing-order/Header";
import Body from "./components/manufacturing-order/Body";

const ManufacturingOrderDetails = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [orderNumber, setOrderNumber] = useState("...");
  const [orderDetails, setOrderDetails] = useState({
    business_id: '',
    currency: '',
    order_id: '',
    order_number: '',
    order_name: '',
    product_name: '',
    product_quantity: '',
    due_date: '',
    issue_date: '',
    items: [],
    order_status: '',
    unit_cost: '',
    total_cost: '',
    order_log: [],
    notes: '',
    updated_by: '',
    created_at: ''
  });
  const [errorMessage, setErrorMessage] = useState("");

  const getManufacturingOrderDetails = (businessInfo, orderID) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/manufacturing-orders/${orderID}`, {
      params: {
        business_id: businessInfo.business_id
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('Order not found');
      } else {
        let responseInfo = response.data;
        let orderInfo = responseInfo.data.order_info;
        let _orderDetails = orderDetails;
  
        _orderDetails.business_id = businessInfo.business_id;
        _orderDetails.currency = businessInfo.currency;
  
        _orderDetails.order_id = orderID;
        _orderDetails.order_number = orderInfo.order_number;
        _orderDetails.order_name = orderInfo.order_name;
        _orderDetails.product_name = orderInfo.product_name;
        _orderDetails.product_quantity = orderInfo.product_quantity;
        _orderDetails.issue_date = orderInfo.order_date;
        _orderDetails.due_date = orderInfo.due_date;
        _orderDetails.items = orderInfo.materials;
        _orderDetails.order_status = orderInfo.order_status;
        _orderDetails.total_cost = orderInfo.total_cost;
        _orderDetails.unit_cost = orderInfo.unit_cost;
        _orderDetails.created_at = orderInfo.created_at;
        _orderDetails.notes = orderInfo.notes;
        _orderDetails.updated_by = orderInfo.updated_by;
        _orderDetails.order_log = orderInfo.order_log.length > 1 ? orderInfo.order_log.reverse() : orderInfo.order_log;
        _orderDetails.created_at = orderInfo.created_at;
  
        setOrderDetails({..._orderDetails});
        setOrderNumber(orderInfo.order_number);
      }
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const _businessID = parseInt(match.params.businessID, 10);
    const orderID = match.params.orderID;
    if ( Number.isInteger(_businessID) && orderID ) {
      localStorage.setItem('current_business_id', _businessID);
      let businesses = JSON.parse(localStorage.getItem('my_businesses'));
      let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setBusinessInfo({...currentBusiness});
      setBusinessID(_businessID);
      getManufacturingOrderDetails(currentBusiness, orderID);
    } else {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }
  }, [match.params.business]);

  return (<React.Fragment>
    <Head title="Manufacturing Order Details" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                <span>Manufacturing Order Details</span>
                <span className="fw-normal ms-1">#{orderNumber}</span>
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/manufacturing-orders/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {
            errorMessage ?
            <Card className="card-bordered">
              <div className="card-inner">
                <div className="text-center m-5">
                  <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                  <div className="price-plan-info">
                    <h5 className="title fw-normal">Manufacturing order not found</h5>
                    <span>The manufacturing order you are looking for could not be found.</span>
                    <p className="text-soft">If you need help, you can reach us on our <a href={baseURLs.LIVE_CHAT}>live chat</a>.</p>
                  </div>
                  <div className="price-plan-action">
                    <Link to={`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}`}>
                      <button className="btn btn-outline-primary">Go to Manufacturing Orders</button>
                    </Link>                    
                  </div>
                </div>
              </div>
            </Card>
            :
            <Card className="card-bordered">
              <Header headerInfo={orderDetails}  />
              <Body history={history} orderInfo={orderDetails} setOrderInfo={setOrderDetails} />
            </Card>
          }


        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default ManufacturingOrderDetails;