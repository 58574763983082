import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, Divider, Space, Form } from 'antd';

const AddTeamMembers = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [memberFields, setMemberFields] = useState([{ email: '', role: null }]);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    members: [{ email: '', role: null }]
  });
  const [errorMessage, setErrorMessage] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleAddMember = () => {
    const oldFields = form.getFieldValue("members");
    const newFields = [...oldFields, {
      email: '',
      role: null
    }];

    // Update fields state
    setMemberFields(newFields);

    // Update form fields
    form.setFieldsValue({
      members: newFields,
    });
  };

  const handleRemoveMember = (index) => {
    const oldFields = form.getFieldValue("members");
    const newFields = [...oldFields];
    newFields.splice(index, 1);
    setMemberFields(newFields);

    form.setFieldsValue({
      members: newFields,
    });
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/teams/add`, {
      business_id: businessID,
      members: JSON.stringify(_formData.members)
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      setRequesting(false);
      try{
        let errorResponse = error.response.data;
        let membersError = '';
        if(errorResponse.hasOwnProperty("errors")){
          
          if(errorResponse.errors.hasOwnProperty("members")) {
            membersError = errorResponse.errors.members;
          }
        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = `${errorResponse.error} ${membersError}`;
        }

        setErrorMessage(errorMessage);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const handleRoleChange = (index, newRole) => {
    const updatedMembers = [...memberFields];
    updatedMembers[index].role = newRole;
    setMemberFields(updatedMembers);
  };

  useEffect(() => {
    if(memberFields.length === 0){
      handleAddMember();
    }
  }, [memberFields]);

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setLoading(false);
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Invite Team Members" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Invite Team Members
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/team/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Invitation Sent`} descriptionText={`Invitation has been sent successfully.`}
          />
        }

        <Block className="wide-sm m-auto">
          <Form name="create-service"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="user-add fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Members Information</h5>
                  <p className="nk-block-des">Use the form below to sent invitation to new members.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-bordered card-full card">
                      <ul className="nk-support">
                        {
                          memberFields.map((member, index) => (
                            <li className="nk-support-item d-block" key={index}>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveMember(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-7">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                          <label className="form-label">Member's Email <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['members', index, 'email']}
                                        noStyle
                                        rules={[
                                          {required: true, message: `Email is required`}
                                        ]}>
                                        <Input size="large" className="form-control form-control-lg" placeholder="Enter member's email" />
                                      </Form.Item>
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                          <label className="form-label">Role <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['members', index, 'role']}
                                        noStyle
                                        rules={[
                                          {required: true, message: `Role is required`}
                                        ]}>
                                        <Select className="select-tags" size="large"
                                          placeholder="eg. Sales Representative"
                                          style={{ width: "100%" }} 
                                          popupMatchSelectWidth={false}
                                          onChange={(value) => handleRoleChange(index, value)}
                                          options={[
                                            {
                                              key: 'manager',
                                              label: 'Manager',
                                              value: 'manager',
                                            },
                                            {
                                              key: 'sales_representative',
                                              label: 'Sales Representative',
                                              value: 'sales_representative',
                                            },
                                            {
                                              key: 'accountant',
                                              label: 'Accountant/Bookkeeper',
                                              value: 'accountant',
                                            },
                                            {
                                              key: 'customer_service_representative',
                                              label: 'Customer Service Representative',
                                              value: 'customer_service_representative',
                                            }                            
                                          ]}
                                          showSearch />   
                                      </Form.Item>
                                    </Form.Item>
                                  </div>
                                </div>
                                {
                                  member.role && (
                                  <div className="col-md-12">
                                    {
                                      member.role === 'manager' && 
                                      <p className="nk-block-des fs-12px d-flex">
                                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                                        <span>The Manager role grants access to oversee all aspects of the business, including sales, inventory, expenses, and customer management, ensuring smooth operations and effective decision-making.</span>
                                      </p>
                                    }

                                    {
                                      member.role === 'sales_representative' && 
                                      <p className="nk-block-des fs-12px d-flex">
                                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                                        <span>Sales Representatives focus on generating revenue by managing sales activities, accessing customer information, and processing orders to drive business growth and meet sales targets.</span>
                                      </p>
                                    }

                                    {
                                      member.role === 'accountant' && 
                                      <p className="nk-block-des fs-12px d-flex">
                                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                                        <span>Accountants/Bookkeepers handle financial transactions and reporting, managing expenses, sales, and inventory to ensure accurate financial records and compliance with accounting standards.</span>
                                      </p>
                                    }

                                    {
                                      member.role === 'customer_service_representative' && 
                                      <p className="nk-block-des fs-12px d-flex">
                                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                                        <span>Customer Service Representatives provide assistance and support to customers, managing customer inquiries, messages, and orders to ensure high levels of satisfaction and retention.</span>
                                      </p>
                                    }
                                  </div>
                                )}
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 text-center mt-4">
                    <Button className="m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddMember()} >
                      <span>Add another Member</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="fm-footer">
                <div className="pt-2 text-center">
                  <Button disabled={requesting} type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : "Send Invitation"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default AddTeamMembers;