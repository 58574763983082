import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { UncontrolledPopover, Card, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert, Badge } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { Upload, InputNumber, Input, Select, Checkbox, Form, Divider, Space } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { createSubdomain, findUpper, getAxiosHeaders, getTextColorBaseOnTheme, isValidDomain, phoneNumberFormatter, recommendedColors, validateNoZero, validateSubdomain, validateURL } from "../../../utils/Utils";
import { AlertModal, LoadingModal, SuccessModal } from "../AlertModals";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from 'axios';
const { TextArea } = Input;

export const StorefrontDomain = ({ history, sm, updateSm, businessID }) => {

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerified, setIsVerified] = useState("not-verified");
  const [noStorefrontFound, setNoStorefrontFound] = useState(false);  
  const [customDomainInfo, setCustomDomainInfo] = useState({
    domain: '',
    status: '',
    txt_record: ''
  });
  const [copy, setCopy] = useState(false);
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onCopyClick = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
  };

  const handleDomainSubmit = () => {
    let domain = customDomainInfo.domain.trim();
    if(!isValidDomain(domain)) {
      setErrorMessage("The Domain is invalid");
      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    setRequesting(true);
    setErrorMessage("");

    setLoadingMessage("Please wait while your domain is being added.");
    toggleModal('loadingModal');

    axios.post(baseURLs.API_URL + `/businesses/storefront-domain/${businessID}`, {
      custom_domain: domain
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let domainStatus = responseInfo.data.status;

      let _customDomainInfo = customDomainInfo;
      _customDomainInfo.status = domainStatus

      setCustomDomainInfo({..._customDomainInfo});
      setSuccessTitle('Step 1 Completed');
      setSuccessMessage('Your domain has been added successfully');
      toggleModal('successModal');
      setRequesting(false);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage); 
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
        toggleModal(null);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
  }

  const handleVerifyDomain = () => {
    setRequesting(true);
    setErrorMessage("");

    setLoadingMessage("Please wait while your domain is being verified.");
    toggleModal('loadingModal');

    axios.post(baseURLs.API_URL + `/businesses/storefront-domain/verify/${businessID}`, null, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let domainStatus = responseInfo.data.domain_status;

      let _customDomainInfo = customDomainInfo;
      _customDomainInfo.status = domainStatus

      setCustomDomainInfo({..._customDomainInfo});
      setSuccessTitle('Step 2 Completed');
      setSuccessMessage('Your domain has been verified successfully');
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage); 
        if(errorMessage){
          toggleModal('errorModal')
        };
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
  }

  const handleConnectDomain = () => {
    setRequesting(true);
    setErrorMessage("");

    setLoadingMessage("Please wait while your domain is being connected to your storefront.");
    toggleModal('loadingModal');

    axios.post(baseURLs.API_URL + `/businesses/storefront-domain/connect/${businessID}`, null, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let domainStatus = responseInfo.data.domain_status;

      let _customDomainInfo = customDomainInfo;
      _customDomainInfo.status = domainStatus

      setCustomDomainInfo({..._customDomainInfo});
      setSuccessTitle('Domain Connected');
      setSuccessMessage('Your domain has been connected to your storefront successfully');
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage); 
        if(errorMessage){
          toggleModal('errorModal')
        };
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);        
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
  }

  const handleRemoveDomain = () => {
    setRequesting(true);
    setErrorMessage("");

    setLoadingMessage("Please wait while your domain is being removed.");
    toggleModal('loadingModal');

    axios.delete(baseURLs.API_URL + `/businesses/storefront-domain/${businessID}`, {headers: getAxiosHeaders().headers})
    .then((response) => {
      let _customDomainInfo = customDomainInfo;
      _customDomainInfo.domain = '';
      _customDomainInfo.status = 'none';

      setCustomDomainInfo({..._customDomainInfo});
      setSuccessTitle('Domain Removed');
      setSuccessMessage('The domain has been removed successfully');
      toggleModal('successModal');
      setRequesting(false);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage); 
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
        toggleModal(null);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
  }

  const getStorefrontSettings = () => {
    axios.get(baseURLs.API_URL + `/businesses/storefront-settings`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then(async(response) => {
      if (response.status === 204) {
        setIsVerified(businessInfo.is_verified);
        setNoStorefrontFound(true);
      } else {
        let responseInfo = response.data;
        let isVerified = responseInfo.data.is_verified;
        setIsVerified(isVerified);
        let customDomainSettings = responseInfo.data.storefront_info;
  
        let _customDomainInfo = customDomainInfo;
        _customDomainInfo.domain = customDomainSettings.custom_domain;
        _customDomainInfo.status = customDomainSettings.url_type === 'storefront_subdomain' && !customDomainSettings.custom_domain ? 'none' : customDomainSettings.domain_status;
        _customDomainInfo.txt_record = customDomainSettings.verification_code;        
  
        setCustomDomainInfo({..._customDomainInfo});
      }

      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    
    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    getStorefrontSettings();
  }, []);


  return (
    <React.Fragment>
      <Head title="Storefront Fulfillment Settings"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Custom Domain Settings</BlockTitle>
            <BlockDes>
              <p>Use the form below to add a custom domain to your storefront.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      {
        errorMessage &&
        <Block>
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorMessage}{" "}
            </Alert>
          </div>
        </Block>
      }
      
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          {
            isVerified !== 'verified' || noStorefrontFound ?
            <Block>
              {
                noStorefrontFound ? 
                <div className="text-center m-5">
                  <div className="price-plan-media">
                    <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                  </div>
                  <div className="price-plan-info">
                    <span className="title fw-normal">No Storefront found. Setup your Storefront then update the fulfillment settings.</span>
                  </div>
                  <div className="price-plan-action">
                    <Link to={`${process.env.PUBLIC_URL}/storefront-settings/b/${businessID}`}>
                      <button className="btn btn-outline-primary">Setup Storefront</button>
                    </Link>                    
                  </div>
                </div>
                :
                <div className="text-center m-5">
                  <div className="price-plan-media">
                    <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                  </div>
                  <div className="price-plan-info">
                    <span className="title fw-normal">Verify your business to start receiving orders online.</span>
                  </div>
                  <div className="price-plan-action">
                    <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                      <button className="btn btn-outline-primary">Verify Business</button>
                    </Link>                    
                  </div>
                </div>
              }
              
            </Block>
            :
            <>
              <Block>
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="nk-refwg-title">
                      <h6 className="title">Step 1/2: Add Domain</h6>
                      <div className="title-sub">Enter the custom domain you'd like to connect for your Storefront.</div>
                    </div>
                    <div className="form-label-group mt-4">
                        <label className="form-label">Domain<span className="text-danger">*</span></label>
                    </div>
                    <div className="form-group">
                      <Space.Compact className="w-100">
                        <Input size="large" 
                          disabled={customDomainInfo.status !== 'none'}
                          value={customDomainInfo.domain} 
                          onChange={(e) => {
                            const { value } = e.target;
                            setCustomDomainInfo({
                              ...customDomainInfo,
                              domain: value, // Update domain state with new value
                            });
                          }} addonBefore="https://" 
                          placeholder={`${createSubdomain(businessInfo.name)}.com`} 
                          className="w-100" />
                        {
                          ['pending','verified','connected'].includes(customDomainInfo.status) ?                              
                          <Button disabled={requesting} color="danger" onClick={() => {toggleModal('removeModal')}}>Remove</Button>
                          :
                          <Button disabled={requesting} color="primary" onClick={handleDomainSubmit}>Submit</Button>                          
                        }
                      </Space.Compact>
                    </div>
                  </div>
                </Card>
              </Block>
              
              {/* {
                ['pending','verified','connected'].includes(customDomainInfo.status) &&
                <Block>
                  <Card className="card-bordered">
                    <div className="nk-refwg-invite card-inner">
                      <div className="nk-refwg-head g-3">
                        <div className="nk-refwg-title">
                          <h6 className="title">Step 2/3: Verification</h6>
                          <div className="title-sub">Verify the domain to confirm ownership.</div>
                        </div>
                        <div className="nk-refwg-action">
                          {
                            customDomainInfo.status !== 'pending' ?
                            <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Verified</span></Badge>
                            :
                            <Button disabled={requesting} onClick={handleVerifyDomain} color="success" className="btn-dim">Verify TXT Record</Button>
                          }
                        </div>
                      </div>
                      <div className="nk-refwg-url">
                        <div className="form-label-group">
                            <label className="form-label">TXT Verification String<span className="text-danger">*</span></label>
                        </div>
                        <div className={`form-control-wrap ${copy ? "clipboard-success" : ""}`}>
                          <div className="form-clip">
                            <CopyToClipboard
                              text={customDomainInfo.txt_record}
                              className="clipboard-init me-2"
                              onCopy={onCopyClick}
                            >
                              <div>
                                <Icon name="copy" className="clipboard-icon"></Icon>
                                <span className="clipboard-text">{copy ? "Copied" : "Copy"}</span>
                              </div>
                            </CopyToClipboard>
                          </div>
                          
                          <div className="form-icon">
                            <Icon name="shield-star"></Icon>
                          </div>
                          <input
                            type="text"
                            className="form-control copy-text"
                            id="txt_record"
                            defaultValue={customDomainInfo.txt_record}
                            value={customDomainInfo.txt_record}/>
                        </div>
                      </div>
                      {
                        customDomainInfo.status === 'pending' &&
                        <div className="bg-lighter mt-2 p-2">
                          <p className="fw-semibold mb-1">How to Verify your Domain.</p>
                          <ul className="list list-checked">  
                            <li>Log in to your domain registrar or DNS provider (where you manage your domain settings).</li>
                            <li>Find the option to manage DNS records for your domain.</li>
                            <li>Add a new TXT record: 
                              <ul>
                                <p className="mb-1"><b>• Record Type:</b> Select <b>TXT</b> as the record type.</p>
                                <p className="mb-1"><b>• Name/Host:</b> Enter <b>@</b>.</p>
                                <p className="mb-1"><b>• Value:</b> Enter the unique <b>"TXT Verification String"</b> provided above. This code is specific to your account and domain.</p>
                                <p className="mb-1"><b>• TTL (Time to Live):</b> You can leave this as the default value (usually 1 hour). Then save the changes</p>
                              </ul>
                            </li>
                            <li>Once added, click the <b>"Verify TXT Record"</b> button above. We'll check for the TXT record to confirm ownership. This usually takes a few minutes, but it can sometimes take up to an hour due to DNS propagation.</li>
                          </ul>
                        </div>
                      }
                    </div>
                  </Card>
                </Block>
              } */}

              {
                ['pending','connected'].includes(customDomainInfo.status) &&
                <Block>
                  <Card className="card-bordered">
                    <div className="nk-refwg-invite card-inner">
                      <div className="nk-refwg-head g-3">
                        <div className="nk-refwg-title">
                          <h6 className="title">Step 2/2: Link Domain to Storefront</h6>
                          <div className="title-sub">Point your domain to your storefront.</div>
                        </div>
                        <div className="nk-refwg-action">
                          {
                            customDomainInfo.status === 'connected' ?
                            <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Connected</span></Badge>
                            :
                            <Button disabled={requesting} onClick={handleConnectDomain} color="success" className="btn-dim">Connect</Button>
                          }
                        </div>
                      </div>
                      <div className="nk-refwg-url">
                        <div className="form-label-group">
                            <label className="form-label">CNAME Value<span className="text-danger">*</span></label>
                        </div>
                        <div className={`form-control-wrap ${copy ? "clipboard-success" : ""}`}>
                          <div className="form-clip">
                            <CopyToClipboard
                              text={baseURLs.CNAME_DOMAIN}
                              className="clipboard-init me-2"
                              onCopy={onCopyClick}
                            >
                              <div>
                                <Icon name="copy" className="clipboard-icon"></Icon>
                                <span className="clipboard-text">{copy ? "Copied" : "Copy"}</span>
                              </div>
                            </CopyToClipboard>
                          </div>
                          
                          <div className="form-icon">
                            <Icon name="shield-star"></Icon>
                          </div>
                          <input
                            type="text"
                            className="form-control copy-text"
                            id="txt_record"
                            defaultValue={baseURLs.CNAME_DOMAIN}
                            value={baseURLs.CNAME_DOMAIN}/>
                        </div>
                      </div>
                      {
                        customDomainInfo.status === 'connected' &&
                        <div className="bg-lighter mt-2 p-2">
                          <p className="fw-semibold mb-1">How to Link your Domain to your Storefront.</p>
                          <ul className="list list-checked">  
                            <li>Add a CNAME record with the value "shop.onlinestore.com" in your DNS settings. This directs visitors to your online store when they type in your custom domain.</li>
                            <li>Once added, click the <b>"Connect"</b> button above. We'll run a quick check to see if your DNS changes have propagated. This usually takes a few minutes, but it can sometimes take up to an hour due to DNS propagation.</li>
                          </ul>
                        </div>
                      }
                    </div>
                  </Card>
                </Block>
              }
            </>
          } 
        </>
      }
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {/* remove domain modal */}
      { 
        activeModal === "removeModal" &&
        <AlertModal 
          index="alert-key"
          showModal={true} 
          toggleModal={() => toggleModal('removeModal')}
          headerText={`Remove Domain?`}
          descriptionText={`Are you sure you want to remove your custom domain?`}
          leftButtonText={`Remove`}
          leftButtonOnClick={handleRemoveDomain} />
      }

      {/* error modal */}
      { 
        activeModal === "errorModal" &&
        <AlertModal 
          index="alert-key"
          showModal={true} 
          toggleModal={() => toggleModal('alertModal')}
          headerText={`Error`}
          descriptionText={errorMessage} />
      }

      {/* loading modal */}
      { 
        activeModal === "loadingModal" &&
        <LoadingModal showModal={true} headerText={`Please wait...`} descriptionText={loadingMessage} />
      }
    </React.Fragment>
  )
}