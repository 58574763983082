import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import classnames from "classnames";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs, subscriptionCurrencies, subscriptionPackages } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import moment from 'moment';
import { Link } from "react-router-dom";
import axios from 'axios';
import StartUpPackageIcon from "../images/start_up.png";
import GrowthPackageIcon from "../images/growth.png";
import ExpansionPackageIcon from "../images/expansion.png";
import {
  AlertModal,
  SuccessConfettiModal,
  LoadingModal
} from "./components/AlertModals";
import { SubscriptionTransactions } from "./components/tables/SubscriptionTransactions";
import { Numeral } from "react-numeral";

const Subscription = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [activeTab, setActiveTab] = useState("monthly");
  const [newTransaction, setNewTransaction] = useState("");
  const [subscriptionPricing, setSubscriptionPricing] = useState({
    currency: 'USD',
    start_up: {
      id: 1,
      cost: {
        monthly: 3,
        six_months: 17,
        yearly: 33,
      },
      package_name: 'Start-up'
    },
    growth: {
      id: 2,
      cost: {
        monthly: 6,
        six_months: 33,
        yearly: 66,
      },
      package_name: 'Growth'
    },
    expansion: {
      id: 3,
      cost: {
        monthly: 14,
        six_months: 77,
        yearly: 154,
      },
      package_name: 'Expansion'
    },
  })

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const payWithPaystack = (paymentConfig) => {
    var handler = window.PaystackPop.setup({
      key: paymentConfig.key,
      email: paymentConfig.email,
      amount: paymentConfig.amount,
      currency: paymentConfig.currency,
      ref: paymentConfig.reference,
      label: paymentConfig.label,
      metadata: paymentConfig.metadata,
      callback: function(response) {
        // verify payment
        var reference = response.reference;
        verifyPayment(reference);
      },
      onClose: function() {
        setErrorMessage(`Transaction was not completed, window closed.`);
        toggleModal('alertModal');
      },
    });
    handler.openIframe();
  }

  const initPayment = (packageName, duration) => {
    toggleModal('loadingModal');
    axios.post(baseURLs.API_URL + `/payments/subscription/initiate/${businessID}`, {
      package_name: packageName,
      duration: duration,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let paystackConfig = responseInfo.data.payment_config;
      toggleModal(null);
      
      payWithPaystack(paystackConfig);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const verifyPayment = (reference) => {
    toggleModal('verifyModal');
    axios.get(baseURLs.API_URL + `/payments/subscription/verify/${reference}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      toggleModal('successModal');
      getBusinesses(businessID, 'new_subscription');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const getBusinesses = (businessID, action = null) => {
    axios.get(baseURLs.API_URL + "/businesses", getAxiosHeaders())
    .then(async(response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/create-business`);
        return;

      }

      let responseInfo = response.data;
      let businesses = responseInfo.data.businesses;
      
      await localStorage.setItem('my_businesses', JSON.stringify(businesses));
      localStorage.setItem('current_business_id', businessID);
      let currentBusiness = businesses?.find((business) => business.business_id == businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setBusinessInfo(currentBusiness)
      setLoading(false);

      if(action) {
        let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=1&sub=successful`;
        history.replace(url);
      }
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const _businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(_businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', _businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    const subscriptionCurrency = subscriptionCurrencies.includes(currentBusiness.currency) ? currentBusiness.currency : 'USD';
    const subscriptionPrices = subscriptionPackages.PRICING[subscriptionCurrency];

    setSubscriptionPricing(subscriptionPrices);
    setBusinessID(_businessID);
    getBusinesses(_businessID);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Subscription" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Subscription
              </BlockTitle>
              
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block>
          <Nav className="nav-tabs-s2" tabs>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "monthly" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleTab("monthly");
                }}
              >
                <span>Monthly</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "six_months" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleTab("six_months");
                }}
              >
                <span>6 Months</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "twelve_months" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleTab("twelve_months");
                }}
              >
                <span>12 Months</span>
              </NavLink>
            </NavItem>
          </Nav>
          <div className="gap"></div>
          <TabContent activeTab={activeTab}>
            {/* monthly */}
            <TabPane tabId="monthly">
              <div className="row g-gs">
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'start_up' ? `border-primary` : ``}`}>
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={StartUpPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Start-up</h5>
                        <span className="sub-text text-dark">Perfect for brand new<br/> businesses and solo owners.</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>50</b> Sales</p>
                        <p className="mb-1"><b>2</b> Team Members</p>
                        <p className="mb-1"><b>20</b> Inventory / Services</p>
                        <p className="mb-1"><b>10</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span>
                        <Numeral value={subscriptionPricing.start_up.cost.monthly.toString()} format={"0,0"} />
                          {/* {subscriptionPricing.start_up.cost.monthly} */}
                        </span>
                      </h4>                      
                      {
                        businessInfo.subscription.package === 'start_up' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('start_up', 'monthly')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Monthly</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('start_up', 'monthly')}}>Subscribe</button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'growth' ? `border-primary` : ``}`}>                
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={GrowthPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Growth</h5>
                        <span className="sub-text text-dark">Tailored for small<br/> businesses experiencing growth</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>500</b> Sales</p>
                        <p className="mb-1"><b>5</b> Team Members</p>
                        <p className="mb-1"><b>500</b> Inventory / Services</p>
                        <p className="mb-1"><b>150</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.growth.cost.monthly.toString()} format={"0,0"} /></span>
                      </h4>
                      {
                        businessInfo.subscription.package === 'growth' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('growth', 'monthly')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Monthly</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('growth', 'monthly')}}>Subscribe</button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'expansion' ? `border-primary` : ``}`}>
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={ExpansionPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Expansion</h5>
                        <span className="sub-text text-dark">Great for businesses<br/> ready to expand their operations</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>Unlimited</b> Sales</p>
                        <p className="mb-1"><b>Unlimited</b> Team Members</p>
                        <p className="mb-1"><b>Unlimited</b> Inventory / Services</p>
                        <p className="mb-1"><b>1K</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.expansion.cost.monthly.toString()} format={"0,0"} /></span>
                      </h4>
                      {
                        businessInfo.subscription.package === 'expansion' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('expansion', 'monthly')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Monthly</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('expansion', 'monthly')}}>Subscribe</button>
                          </div>
                        </>
                      }                  
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>

            {/* 6 months */}
            <TabPane tabId="six_months">
              <div className="row g-gs">
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'start_up' ? `border-primary` : ``}`}>
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={StartUpPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Start-up</h5>
                        <span className="sub-text text-dark">Perfect for brand new<br/> businesses and solo owners.</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>300</b> Sales</p>
                        <p className="mb-1"><b>2</b> Team Members</p>
                        <p className="mb-1"><b>20</b> Inventory / Services</p>
                        <p className="mb-1"><b>60</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.start_up.cost.six_months.toString()} format={"0,0"} /></span>
                      </h4>
                      
                        {
                          businessInfo.subscription.package === 'start_up' ?
                          <>
                            <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                            <div className="price-plan-action">
                              <button className="btn btn-outline-primary" onClick={() => {initPayment('start_up', 'six_months')}}>Renew Subscription</button>
                            </div>
                          </>
                          :
                          <>
                            <span className="text-muted">Billed Every 6 Months</span>
                            <div className="price-plan-action">
                              <button className="btn btn-primary" onClick={() => {initPayment('start_up', 'six_months')}}>Subscribe</button>
                            </div>
                          </>
                        }
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'growth' ? `border-primary` : ``}`}>                
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={GrowthPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Growth</h5>
                        <span className="sub-text text-dark">Tailored for small<br/> businesses experiencing growth</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>3000</b> Sales</p>
                        <p className="mb-1"><b>5</b> Team Members</p>
                        <p className="mb-1"><b>500</b> Inventory / Services</p>
                        <p className="mb-1"><b>900</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.growth.cost.six_months.toString()} format={"0,0"} /></span>
                      </h4>
                      {
                        businessInfo.subscription.package === 'growth' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('growth', 'six_months')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Every 6 Months</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('growth', 'six_months')}}>Subscribe</button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'expansion' ? `border-primary` : ``}`}>
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={ExpansionPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Expansion</h5>
                        <span className="sub-text text-dark">Great for businesses<br/> ready to expand their operations</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>Unlimited</b> Sales</p>
                        <p className="mb-1"><b>Unlimited</b> Team Members</p>
                        <p className="mb-1"><b>Unlimited</b> Inventory / Services</p>
                        <p className="mb-1"><b>6K</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.expansion.cost.six_months.toString()} format={"0,0"} /></span>
                      </h4>
                      {
                        businessInfo.subscription.package === 'expansion' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('expansion', 'six_months')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Every 6 Months</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('expansion', 'six_months')}}>Subscribe</button>
                          </div>
                        </>
                      }                  
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            
            {/* 12 months */}
            <TabPane tabId="twelve_months">
              <div className="row g-gs">
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'start_up' ? `border-primary` : ``}`}>
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={StartUpPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Start-up</h5>
                        <span className="sub-text text-dark">Perfect for brand new<br/> businesses and solo owners.</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>600</b> Sales</p>
                        <p className="mb-1"><b>2</b> Team Members</p>
                        <p className="mb-1"><b>20</b> Inventory / Services</p>
                        <p className="mb-1"><b>120</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.start_up.cost.yearly.toString()} format={"0,0"} /></span>
                      </h4>
                      
                        {
                          businessInfo.subscription.package === 'start_up' ?
                          <>
                            <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                            <div className="price-plan-action">
                              <button className="btn btn-outline-primary" onClick={() => {initPayment('start_up', 'yearly')}}>Renew Subscription</button>
                            </div>
                          </>
                          :
                          <>
                            <span className="text-muted">Billed Every 12 Months</span>
                            <div className="price-plan-action">
                              <button className="btn btn-primary" onClick={() => {initPayment('start_up', 'yearly')}}>Subscribe</button>
                            </div>
                          </>
                        }
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'growth' ? `border-primary` : ``}`}>                
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={GrowthPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Growth</h5>
                        <span className="sub-text text-dark">Tailored for small<br/> businesses experiencing growth</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>6000</b> Sales</p>
                        <p className="mb-1"><b>5</b> Team Members</p>
                        <p className="mb-1"><b>500</b> Inventory / Services</p>
                        <p className="mb-1"><b>1800</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.growth.cost.yearly.toString()} format={"0,0"} /></span>
                      </h4>
                      {
                        businessInfo.subscription.package === 'growth' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('growth', 'yearly')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Every 12 Months</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('growth', 'yearly')}}>Subscribe</button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`price-plan card card-bordered text-center ${businessInfo.subscription.package === 'expansion' ? `border-primary` : ``}`}>
                    <div className="card-inner">
                      <div className="price-plan-media">
                        <img src={ExpansionPackageIcon} width="100" alt=""/>
                      </div>
                      <div className="price-plan-info">
                        <h5 className="title">Expansion</h5>
                        <span className="sub-text text-dark">Great for businesses<br/> ready to expand their operations</span>
                        <div className="gap"></div>
                        <p className="mb-1"><b>Unlimited</b> Sales</p>
                        <p className="mb-1"><b>Unlimited</b> Team Members</p>
                        <p className="mb-1"><b>Unlimited</b> Inventory / Services</p>
                        <p className="mb-1"><b>12K</b> Promotion Messages</p>
                      </div>
                      <h4 className="amount">
                        <small className="font-weight-bold">{subscriptionPricing.currency} </small>
                        <span><Numeral value={subscriptionPricing.expansion.cost.yearly.toString()} format={"0,0"} /></span>
                      </h4>
                      {
                        businessInfo.subscription.package === 'expansion' ?
                        <>
                          <span className={`${ moment(businessInfo.subscription.expiration).diff(moment(), 'days') <= 10 ? `text-danger` : `text-primary`} `}>Expires: {moment(businessInfo.subscription.expiration).format("Do MMM YYYY")}</span>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-primary" onClick={() => {initPayment('expansion', 'yearly')}}>Renew Subscription</button>
                          </div>
                        </>
                        :
                        <>
                          <span className="text-muted">Billed Every 12 Months</span>
                          <div className="price-plan-action">
                            <button className="btn btn-primary" onClick={() => {initPayment('expansion', 'yearly')}}>Subscribe</button>
                          </div>
                        </>
                      }                  
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </Block>
        <SubscriptionTransactions history={history} businessID={businessID} currency={subscriptionPricing.currency} newTransaction={newTransaction} />
      </>
    }
    </Content>

    { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={`Initiating Payment...`} descriptionText={`Please wait while payment is being initiated`} /> }

    { activeModal === "verifyModal" && <LoadingModal showModal={true} headerText={`Verifying Payment...`} descriptionText={`Please wait while payment is being verified`} /> }
    
    { 
      activeModal === "successModal" &&
      <SuccessConfettiModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Payment Successful`} descriptionText={`Your subscription has been updated successfully.`} 
      />
    }

    {
      activeModal === "alertModal" &&
      <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
        headerText={"Error"} descriptionText={errorMessage}                       
      >
        <small>Please contact <a href="https://tawk.to/chat/5e76db0e8d24fc226589341c/default" target="_blank">support</a> if your account has been charged.</small>
        
      </AlertModal>
    }
  </React.Fragment>)
}

export default Subscription;