import React, { useState, useEffect } from "react";
import { Empty } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders } from "../../../utils/Utils";
import { 
  Badge, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter 
} from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { PaginationWithOnclick } from "../tables/Pagination";
import { TableLoader } from "../../../utils/Loaders";

export const SentMessages = ({ history, businessInfo, aside, setAside, ...props }) => {
  const businessID = businessInfo.business_id;

  const [tableData, setTableData] = useState({meta: {total_records: 0}, messages:[]});
  const [loading, setLoading] = useState(true);
  const [hasFilters, setHasFilters] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getSentMessages(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getSentMessages(params);
  }

  const filterMessages = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getSentMessages(filters);
  }
 
  const getSentMessages = (_filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/customers/messages`, {
      params: {
        business_id: businessID,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        page: _filters.page,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, messages:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, items:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    getSentMessages(filters);
  }, []);

  
  return (
    <div className="nk-ibx-body bg-white">
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <div className="nk-ibx-list">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="lead-text m-0">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Messages` : `Message`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Sent Messages Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">From Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={fromDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'from');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">To Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={toDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'to');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterMessages();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getSentMessages(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep d-lg-none"></li>
                      <li className="me-n1 d-lg-none">
                        <Button className="btn-icon btn-trigger" title="Menu" onClick={() => {setAside(!aside);}}>
                          <Icon name="menu-alt-r"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.messages.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No message found</h5><span>Add a customer using the button below.</span>
                    </div>
                    {/* <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/customer/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Customer</button>
                      </Link>                    
                    </div> */}
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow>
                          <span className="sub-text">Message</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Customers</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Cost</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Sent By</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.messages.map((data, index) => {
                          
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow>
                                <div className="user-card alignment-top">
                                  <span className="me-1 text-muted fw-semibold">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <div className="user-info">
                                    <span>{data.message}</span>
                                    <div className="d-md-none mt-2"><small>{`Customers: ${data.customers_messaged}`}</small></div>
                                    <div className="d-md-none"><small>{`Cost: ${data.messages_used}`}</small></div>                                  
                                    <div className="d-lg-none"><small>{moment(data.created_at).format("Do MMM YYYY")}</small></div>                                  
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.customers_messaged}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.messages_used}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.sent_by}</span>
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{moment(data.created_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </div>
        </>
        // <div className="nk-ibx-list">
        //   <div className="card-inner-group">
        //     {
        //       tableData.messages.length === 0 ?
        //       <div className="nk-block">
        //         <div className="text-center m-5">
        //           <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
        //           <div className="price-plan-info">
        //             <span className="title fw-normal">No message found</span>
        //           </div>
        //           {/* <div className="price-plan-action">
        //             <Link to={`${process.env.PUBLIC_URL}/record-sale/b/${businessID}`}>
        //               <button className="btn btn-outline-primary btn-sm">Record a Sale</button>
        //             </Link>                    
        //           </div> */}
        //         </div>
        //       </div>
        //       :
        //       <div className="nk-block">
        //         <div className="card-title-group mb-3">
        //           <h6 className="lead-text m-0">
        //             {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Messages` : `Message`}`}
        //           </h6>
        //           <div className="card-tools me-n1">
        //             <ul className="btn-toolbar">
        //               <li>
        //                 <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
        //                   { hasFilters && <div className="dot dot-primary"></div> }
        //                   <Icon name="search"></Icon>
        //                 </Button>
        //                 {
        //                   activeModal === "filterModal" &&
        //                   <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
        //                     <ModalHeader
        //                       toggle={() => toggleModal('filterModal')}
        //                       close={
        //                         <button className="close" onClick={() => toggleModal('filterModal')}>
        //                           <Icon name="cross" />
        //                         </button>
        //                       }
        //                     >
        //                       Sent Messages Filter
        //                     </ModalHeader>
        //                     <ModalBody>
        //                       <form>
        //                         <div className="form-group">
        //                           <label className="form-label">Date Range</label>                          
        //                           <div className="form-control-wrap">
        //                             <DatePicker
        //                               selectsRange={true}
        //                               startDate={dateFrom}
        //                               endDate={dateTo}
        //                               onChange={(date) => {
        //                                 setDateRange(date);
        //                               }}
        //                               dateFormat="dd/MM/yyyy"
        //                               showMonthDropdown
        //                               showYearDropdown
        //                               isClearable
        //                               // monthsShown={2}
        //                               className="form-control form-control-lg date-picker"
        //                             />
        //                           </div>
        //                           <div className="form-note fs-10px">
        //                             Date Format: <code>dd/mm/yyyy</code>
        //                           </div>
        //                         </div>
        //                         <div className="form-group">
        //                           <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterMessages();} } size="md">
        //                             Apply Filter
        //                           </Button>

        //                           {
        //                             hasFilters &&
        //                             <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
        //                               Reset Filter
        //                             </Button>
        //                           }
        //                         </div>
        //                       </form>
        //                     </ModalBody>
        //                     <ModalFooter className="bg-light justify-content-start">
        //                       <span className="sub-text">Use the form above to filter sent messages.</span>
        //                     </ModalFooter>
        //                   </Modal>
        //                 }
        //               </li>
        //             </ul>
        //           </div>
        //         </div>
        //         <div className="nk-tb-list nk-tb-ulist is-compact border round-sm">
        //           <div className="nk-tb-item nk-tb-head">
        //             <div className="nk-tb-col">
        //               <span className="sub-text">Message</span>
        //             </div>
        //             <div className="nk-tb-col tb-col-sm">
        //               <span className="sub-text">No. Customers</span>
        //             </div>
        //             <div className="nk-tb-col tb-col-sm">
        //               <span className="sub-text">Cost</span>
        //             </div>
        //             <div className="nk-tb-col tb-col-sm">
        //               <span className="sub-text">Sent By</span>
        //             </div>
        //             <div className="nk-tb-col tb-col-sm">
        //               <span className="sub-text">Date</span>
        //             </div>
        //           </div>
                  
        //             {
        //               tableData.messages.map((data, index) => {
                        
        //                 return (
        //                   <div className="nk-tb-item" key={data.message_id}>
        //                     <div className="nk-tb-col">
        //                       <span>
        //                         {data.message}
        //                         <span className="d-sm-none ms-1 text-muted"><br/>
        //                           {`No. Customers: ${data.customers_messaged}  •  Cost: ${data.messages_used}  •  Total: `}
        //                         </span>
        //                       </span>
        //                     </div>
        //                     <div className="nk-tb-col tb-col-sm">
        //                       <span className="amount">{data.customers_messaged}</span>
        //                     </div>
        //                     <div className="nk-tb-col tb-col-sm">
        //                       <span className="amount">
        //                         {data.messages_used}
        //                       </span>
        //                     </div>
        //                     <div className="nk-tb-col tb-col-sm">
        //                       <span className="amount">
        //                         {data.sent_by}
        //                       </span>
        //                     </div>
        //                     <div className="nk-tb-col tb-col-sm">
        //                       <span className="amount">
        //                         {moment(data.created_at).format("Do MMM YYYY")}
        //                       </span>
        //                     </div>
        //                   </div>
        //                 )
        //               })
        //             }
                    
        //         </div>
        //         <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
        //       </div>
        //     }
        //   </div>
        // </div>
      }
    </div>
  );
};
