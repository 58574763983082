import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { findUpper, phoneNumberFormatter } from "../../../utils/Utils";
import {
  Icon,
  UserAvatar,
} from "../../../components/Component";

const Header = ({headerInfo, color, ...props }) => {
  return (
    <>
      <div className="fm-head bg-lighter">
          <div className="fm-count"><Icon name="pie fs-22px"></Icon></div>
          <div className="fm-title">
            <span className="sub-title text-muted">Title:</span>  
            <h5 className="title fw-normal">
              {headerInfo.expense_title}
            </h5>
            <p className="sub-title mb-0">{moment(headerInfo.issue_date).format("Do MMM, YYYY h:mm a")}</p>
          </div>   
      </div>
      {
        headerInfo.has_supplier === 'yes' &&
        <div className="fm-head bg-lighter">
          <div className="fm-count"><Icon name="user-list fs-22px"></Icon></div>
          <div className="fm-title">
            <span className="sub-title text-muted">Supplier:</span>  
            <h5 className="title fw-normal">
              {headerInfo.supplier_info.supplier_name}
            </h5>
            {headerInfo.supplier_info.phone_number && 
              <p className="sub-title mb-0">{phoneNumberFormatter(headerInfo.supplier_info.phone_number)}</p>
            }
            {headerInfo.supplier_info.email && 
              <p className="sub-title mb-0">{headerInfo.supplier_info.email}</p>
            }
            {headerInfo.supplier_info.other_info && 
              <small className="text-muted">Other Info: {headerInfo.supplier_info.other_info}</small>
            }
          </div>        
        </div>
      }
    </>
  );
};


export default Header;