import React, { useState, useRef, useEffect } from "react";
import Icon from "../../components/icon/Icon";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import { generateVariants, getAxiosHeaders, getPhoneCodeAndNumber, sumVariantTotalQuantity } from "../../utils/Utils";
import {
  Alert,
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import { PlusOutlined } from '@ant-design/icons';
import { Empty, Input, Select, InputNumber, Divider, Space, Form, Tag, Tooltip } from 'antd';
const { TextArea } = Input;

export const CreateNewProductModal = ({ businessID, currency, showModal, toggleModal, updateProductList, ...props }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/products/add`, {
      business_id: businessID,
      product_name: _formData.product_name,
      description: _formData.description,
      cost_price: _formData.cost_price,
      selling_price: _formData.selling_price,
      quantity: 0,
      restock_level: 1,
      stock_limit: 'limited'
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      updateProductList(_formData.product_name);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'product_name',
              errors: errorResponse.errors.hasOwnProperty("product_name") ? [errorResponse.errors.product_name] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'cost_price',
              errors: errorResponse.errors.hasOwnProperty("cost_price") ? [errorResponse.errors.cost_price] : [],
            },
            {
              name: 'selling_price',
              errors: errorResponse.errors.hasOwnProperty("selling_price") ? [errorResponse.errors.selling_price] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  return (
    <Modal backdrop="static" isOpen={showModal} id="create_product_modal">
      <ModalHeader
        toggle={toggleModal}
        close={
          <button className="close" disabled={requesting} onClick={toggleModal}>
            <Icon name="cross" />
          </button>
        }
      >
        Add a New Product
      </ModalHeader>
      <Form name="add-product"
          form={form} className="is-alter"
          initialValues={{
            product_name: '',
            description: '',
            cost_price: 0,
            selling_price: 0,
          }} 
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Product Name <span className="text-danger">*</span></label>
                  </div>
                  <Form.Item name={'product_name'}
                    noStyle
                    rules={[
                      {required: true, message: 'Product name is required',}
                    ]}>
                    <Input size="large"
                      type="text" className="form-control form-control-lg" 
                      placeholder="Awesome Product"
                    />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                  </div>
                  <Form.Item name={'description'}
                    noStyle
                    rules={[
                      {required: false}
                    ]}>
                    <TextArea rows={4} placeholder="enter a description of the product" />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Cost Price <span className="text-muted">(optional)</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'cost_price'}
                      noStyle
                      rules={[
                        {required: false}
                      ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Selling Price <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'selling_price'}
                      noStyle
                      rules={[
                        {required: true, message: 'Selling price is required'}
                      ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export const CreateNewProductFullModal = ({ businessID, currency, showModal, toggleModal, updateProductList, ...props }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/products/add`, {
      business_id: businessID,
      product_name: _formData.product_name,
      description: _formData.description,
      cost_price: _formData.cost_price,
      selling_price: _formData.selling_price,
      quantity: _formData.quantity,
      restock_level: 1,
      stock_limit: 'limited'
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      updateProductList(_formData.product_name);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'product_name',
              errors: errorResponse.errors.hasOwnProperty("product_name") ? [errorResponse.errors.product_name] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'cost_price',
              errors: errorResponse.errors.hasOwnProperty("cost_price") ? [errorResponse.errors.cost_price] : [],
            },
            {
              name: 'selling_price',
              errors: errorResponse.errors.hasOwnProperty("selling_price") ? [errorResponse.errors.selling_price] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  return (
    <Modal backdrop="static" isOpen={showModal} id="create_product_modal">
      <ModalHeader
        toggle={toggleModal}
        close={
          <button className="close" disabled={requesting} onClick={toggleModal}>
            <Icon name="cross" />
          </button>
        }
      >
        Add a New Product
      </ModalHeader>
      <Form name="add-product"
          form={form} className="is-alter"
          initialValues={{
            product_name: '',
            description: '',
            cost_price: 0,
            selling_price: 0,
            quantity: 1,
          }} 
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Product Name <span className="text-danger">*</span></label>
                  </div>
                  <Form.Item name={'product_name'}
                    noStyle
                    rules={[
                      {required: true, message: 'Product name is required',}
                    ]}>
                    <Input size="large"
                      type="text" className="form-control form-control-lg" 
                      placeholder="Awesome Product"
                    />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                  </div>
                  <Form.Item name={'description'}
                    noStyle
                    rules={[
                      {required: false}
                    ]}>
                    <TextArea rows={4} placeholder="enter a description of the product" />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Cost Price <span className="text-muted">(optional)</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'cost_price'}
                      noStyle
                      rules={[
                        {required: false}
                      ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Selling Price <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'selling_price'}
                      noStyle
                      rules={[
                        {required: true, message: 'Selling price is required'}
                      ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Quantity <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'quantity'}
                      noStyle
                      rules={[
                        {required: true, message: 'Quantity is required'}
                      ]}>
                      <InputNumber min={1} step={1} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export const AddProductVariant = ({ showModal, toggleModal, variantTypes, setVariantTypes, variants, setVariants, productName, costPrice, sellingPrice, mainForm, setTotalVariantQuantity }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const handleVariantTypeFormSubmit = (_formData) => {
    
    setErrorMessage("");

    let _variantTypes = variantTypes;
    let variantType = (_formData.type).toLowerCase();    
    let typeExist = _variantTypes.some(variant => variant.type === variantType);
    
    if(variantTypes.length >= 3) {
      setErrorMessage(`You have reached the variant types limit of 3. Remove one variant type to add a new one.`);
      return;
    }

    if(tags.length === 0){
      setErrorMessage(`Variant options is required`);
      return;
    }

    if (typeExist) {
      setErrorMessage(`${variantType} already exists.`);
    } else {
      _variantTypes.push({
        type: variantType,
        options: tags
      })

      let productVariants = generateVariants(_variantTypes, variants, productName, costPrice, sellingPrice);    

      setVariants([...productVariants]);
      setVariantTypes([..._variantTypes]);
      setTotalVariantQuantity(sumVariantTotalQuantity(productVariants));
      mainForm.setFieldsValue({
        variants: productVariants,
      });
  
      toggleModal();
    }
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue((e.target.value).toLowerCase());
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue.toLowerCase()]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e) => {
    setEditInputValue((e.target.value).toLowerCase());
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const tagInputStyle = {
    minWidth: 50,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const tagPlusStyle = {
    height: 22,
    borderStyle: 'dashed',
  };

  return (
    <Modal backdrop="static" isOpen={true} id="add_variant_type_modal">
      <ModalHeader
        toggle={() => toggleModal()}
        close={
          <button className="close" disabled={requesting} onClick={() => toggleModal()}>
            <Icon name="cross" />
          </button>
        }
      >
        Add Variant Type
      </ModalHeader>
      <Form name="add-product-variant"
        form={form} className="is-alter"
        onFinish={handleVariantTypeFormSubmit}
        onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
          <div className="col-md-12">
            <div className="form-group">
              <Form.Item>
                <label className="form-label">Type <span className="text-danger">*</span></label>
                <Form.Item name={'type'}
                  noStyle
                  rules={[
                    {required: true, message: 'Type name is required',}
                  ]}>
                  <Input size="large" style={{textTransform: "lowercase"}} placeholder="eg. Size" />
                </Form.Item>
              </Form.Item>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label">Options <span className="text-danger">*</span></label>
              </div>
              <Space size={[0, 8]} wrap>
                {tags.map((tag, index) => {
                  if (editInputIndex === index) {
                    return (
                      <Input
                        ref={editInputRef}
                        key={tag}
                        size="small"
                        style={tagInputStyle}
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                      />
                    );
                  }
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={true}
                      style={{
                        userSelect: 'none',
                      }}
                      onClose={() => handleClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          setEditInputIndex(index);
                          setEditInputValue(tag);
                          e.preventDefault();
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

                {inputVisible ? (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                ) : (
                  <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                    New Option
                  </Tag>
                )}
              </Space>
            </div>
          </div>
          
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal()}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export const EditProductVariant = ({ showModal, toggleModal, editVariantTypeIndex, variantTypes, setVariantTypes, variants, setVariants, productName, costPrice, sellingPrice, mainForm, setTotalVariantQuantity }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const handleVariantTypeFormSubmit = (_formData) => {
    // setRequesting(true);
    setErrorMessage("");

    let _variantTypes = variantTypes;
    let variantType = (_formData.type).toLowerCase();    
    let typeExist = _variantTypes.some((variant, index) => variant.type === variantType && index !== editVariantTypeIndex);
    
    if(tags.length === 0){
      setErrorMessage(`Variant options is required`);
      return;
    }

    if (typeExist) {
      setErrorMessage(`${variantType} already exists.`);
    } else {
      _variantTypes[editVariantTypeIndex] = {
        type: variantType,
        options: tags
      }

      let productVariants = generateVariants(_variantTypes, variants, productName, costPrice, sellingPrice);

      setVariants([...productVariants]);
      setVariantTypes([..._variantTypes]);
      setTotalVariantQuantity(sumVariantTotalQuantity(productVariants));
      mainForm.setFieldsValue({
        variants: productVariants,
      });
  
      toggleModal();
    }
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  useEffect(() => {
    let _variantType = variantTypes[editVariantTypeIndex];
    form.setFieldValue("type", _variantType.type);
    setTags([..._variantType.options]);

  }, [])

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const tagInputStyle = {
    minWidth: 50,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const tagPlusStyle = {
    height: 22,
    borderStyle: 'dashed',
  };

  return (
    <Modal backdrop="static" isOpen={true} id="add_variant_type_modal">
      <ModalHeader
        toggle={() => toggleModal()}
        close={
          <button className="close" disabled={requesting} onClick={() => toggleModal()}>
            <Icon name="cross" />
          </button>
        }
      >
        Edit Variant Type
      </ModalHeader>
      <Form name="edit-product-variant"
        form={form} className="is-alter"
        onFinish={handleVariantTypeFormSubmit}
        onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
          <div className="col-md-12">
            <div className="form-group">
              <Form.Item>
                <label className="form-label">Type <span className="text-danger">*</span></label>
                <Form.Item name={'type'}
                  noStyle
                  rules={[
                    {required: true, message: 'Type name is required',}
                  ]}>
                  <Input size="large" style={{textTransform: "lowercase"}} placeholder="eg. Size" />
                </Form.Item>
              </Form.Item>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label">Options <span className="text-danger">*</span></label>
              </div>
              <Space size={[0, 8]} wrap>
                {tags.map((tag, index) => {
                  if (editInputIndex === index) {
                    return (
                      <Input
                        ref={editInputRef}
                        key={tag}
                        size="small"
                        style={tagInputStyle}
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                      />
                    );
                  }
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={true}
                      style={{
                        userSelect: 'none',
                      }}
                      onClose={() => handleClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          setEditInputIndex(index);
                          setEditInputValue(tag);
                          e.preventDefault();
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

                {inputVisible ? (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                ) : (
                  <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                    New Option
                  </Tag>
                )}
              </Space>
            </div>
          </div>
          
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal()}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}