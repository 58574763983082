import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, orderStatusOptions } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody,
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const AbandonedCartsTable = ({ history, businessID, currency, storefrontView = false, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      total_amount: 0,      
    }, 
    carts: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    range: null,
    rangeStart: null,
    rangeEnd: null,
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    let params = filters;

    if(!moment(start).isValid() || !moment(end).isValid()){
      params.range = null;
      params.rangeStart = null;
      params.rangeEnd = null;
      setFilters({...params});
      return;
    }

    params.range = `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`;
    params.rangeStart = start;
    params.rangeEnd = end;
    setFilters({...params});
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.orders.map(item => item.order_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let range = filters.range !== null ? `&r=${filters.range}` : ``;
    
    if(range !== '' && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getCarts(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      range: null,
      rangeStart: null,
      rangeEnd: null
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getCarts(params);
  }

  const filterOrders = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getCarts(filters);
  }

  const getCarts = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/storefront/abandoned-carts", {
      params: {
        business_id: businessID,
        page: filters.page,
        date_from: !moment(filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD")
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_amount: 0,      
          }, 
          carts: []
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_amount: 0,      
          }, 
          carts: []
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteOrdersByIDs = () => {
    toggleModal('cancelLoadingModal');

    axios.delete(baseURLs.API_URL + "/orders/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        orders: JSON.stringify(checkedBoxes)
      }      
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getCarts(filters);
        toggleModal('successCancelModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getCarts(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `orders` : `order`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `orders` : `order`} failed to get deleted`);
        toggleModal('cancelErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `orders` : `order`}. The selected ${failedIDs.length > 1 ? `orders were` : `order was`} not found.`);
        toggleModal('cancelErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('cancelErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const downloadOrders = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/storefront/abandoned-carts/download", {
      params: {
        business_id: businessID,
        date_from: !moment(filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD"),
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No carts found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    onRangeChange(dateRange);
  }, [dateRange])

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let rangeStart = null;
    let rangeEnd = null;

    if(range !== null){
      let rangeSplit = range.split(',');
      rangeStart = new Date(rangeSplit[0]);
      rangeEnd = new Date(rangeSplit[1]);
      setDateRange([rangeStart, rangeEnd]);
    }

    let params = filters;
    params.page = page;
    params.range = range;
    params.rangeStart = rangeStart;
    params.rangeEnd = rangeEnd;
    
    setFilters(params)
    getCarts(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <div className="row g-gs mb-5">
            <div className="col-md-12">
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-dark">Total Amount</div>
                      <div className="amount">
                        <small className="text-muted fw-light me-1">{currency}</small>
                        {tableData.meta.total_amount}
                      </div>
                    </div>
                    <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-dark">Total Carts</div>
                      <div className="amount">{tableData.meta.total_records}</div>
                    </div>
                  </div>
                </div>
              </Card>   
            </div>
          </div>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Carts` : `Cart`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Orders Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label">Date Range</label>                          
                                  <div className="form-control-wrap">
                                    <DatePicker
                                      selectsRange={true}
                                      startDate={dateFrom}
                                      endDate={dateTo}
                                      onChange={(date) => {
                                        setDateRange(date);
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      // monthsShown={2}
                                      className="form-control form-control-lg date-picker"
                                    />
                                  </div>
                                  <div className="form-note fs-10px">
                                    Date Format: <code>dd/mm/yyyy</code>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterOrders();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter abandoned carts.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getCarts(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadOrders} title="Download Orders">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Orders"} descriptionText={"Please wait while your orders are being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Orders Ready"} descriptionText={"Orders CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.carts.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No abandoned cart found</h5>
                    </div>
                  </div>
                  :
                  <>                   
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow>
                          <span className="sub-text">Customer</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Phone Number</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Cart Items</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total Amount</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <span className="sub-text"><em className="icon ni ni-more-h"></em></span>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.carts.map((data, index) => {
                          
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/storefront/b/${businessID}/abandoned-carts/details/${data.cart_id}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                      <span className="title fw-bold text-dark">{data.customer_name}</span>
                                      
                                      <div className="price d-md-none"><small>{currency} </small> {data.total_amount} • Cart Items: {data.total_items}</div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.customer_phone_number}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">{data.total_items}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.total_amount}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{moment(data.created_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/storefront/b/${businessID}/abandoned-carts/details/${data.cart_id}`}>
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                    
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
