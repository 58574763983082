import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const SuppliersTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, suppliers:[]});
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    supplier_search: '',
    status: 'all',
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.customers.map(item => item.supplier_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let supplier_search = filters.supplier_search.length > 0 ? `&sp=${filters.supplier_search}` : ``;
    let status = filters.status.length > 0 ? `&ct=${filters.status}` : ``;
    
    if( (supplier_search !== '' || filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${supplier_search}${status}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getSuppliers(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      supplier_search: '',
      status: 'all',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getSuppliers(params);
  }

  const filterSuppliers = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getSuppliers(filters);
  }

  const getSuppliers = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/suppliers", {
      params: {
        business_id: businessID,
        page: filters.page,
        supplier: filters.supplier_search,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, suppliers:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, suppliers:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteSuppliersByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + "/suppliers/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        suppliers: JSON.stringify(checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_supplier_ids;
      let failedIDs = responseInfo.data.failed_supplier_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getSuppliers(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getSuppliers(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `customers` : `customer`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `customers` : `customer`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `suppliers` : `supplier`}. The selected ${failedIDs.length > 1 ? `suppliers were` : `supplier was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteSuppliersByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/suppliers/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        supplier: filters.supplier_search,
        status: filters.status
      }
    })
    .then((response) => {
      getSuppliers(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSingleDelete = (supplierID) => {
    setCheckedBoxes([supplierID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const handleEditSupplier = (supplierID) => {

  }

  const downloadSuppliers = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/suppliers/download", {
      params: {
        business_id: businessID,
        supplier: filters.supplier_search,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No supplier found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let supplier_search = queryParams.hasOwnProperty('sp') ? queryParams.sp : '';
    let status = queryParams.hasOwnProperty('s') ? queryParams.s : 'all';

    let params = filters;
    params.page = page;
    params.supplier_search = supplier_search;
    params.status = status;
    setFilters(params);
    getSuppliers(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.suppliers.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Amount Paid</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_paid}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-danger">Amount Owed</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_owed}</div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Suppliers` : `Supplier`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Suppliers Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="supplier_search">
                                    Supplier Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="supplier_search" className="form-control form-control-lg" id="supplier_search" defaultValue={filters.supplier_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="status">
                                    Status
                                  </label>
                                  <div className="form-control-wrap">
                                  <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "paid", label: "Paid Suppliers"},
                                        {value: "owed", label: "Owed Suppliers"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterSuppliers();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getSuppliers(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadSuppliers} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Suppliers"} descriptionText={"Please wait while your suppliers list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Suppliers Ready"} descriptionText={"Suppliers CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.suppliers.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No suppliers found</h5><span>Add a supplier using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/suppliers/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Supplier</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Name</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Purchase Orders</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Expenses</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Balance Due</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Created At</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('delete')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Suppliers</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Suppliers` : `Delete Supplier`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} suppliers. This action will also delete everything associated to the suppliers and can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected suppliers` : `this selected supplier`}. This action will also delete everything associated to the ${checkedBoxes.length > 1 ? `suppliers` : `supplier`} and can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteSuppliersByFilter : deleteSuppliersByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No supplier has been selected. Select a supplier and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `suppliers are` : `supplier is`} being deleted`} /> }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.suppliers.map((data, index) => {
                          let balanceStatusDot = data.total_balance_owed !== '0.00' ? <span className="dot dot-danger me-1"></span> : '';
                          let SupplierName = data.supplier_name;
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.supplier_id)}
                                    onChange={() => handleCheckboxChange(data.supplier_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.supplier_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.supplier_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <div className="user-info">
                                    <span className="tb-lead">{SupplierName}</span>
                                    <span className="d-md-none">{data.phone_number}</span>
                                    
                                    {
                                      data.total_balance_owed !== '0.00' &&
                                      <div className={`price d-md-none ${data.total_balance_owed !== '0.00' && `text-danger`}`}><small>Balance Due: </small> {balanceStatusDot} <small> {currency} </small> {data.total_balance_owed} </div>
                                    }
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {
                                  data.purchase_orders.amount_owed === '0.00' ?
                                  <span className="tb-amount">
                                    <small>{currency} </small><b>{data.purchase_orders.total_amount}</b>
                                  </span>
                                  :
                                  <span className="tb-amount">
                                    <small>{currency} </small><b>{data.purchase_orders.amount_paid}</b> / {data.purchase_orders.total_amount}
                                  </span>
                                }
                              </DataTableRow>
                              <DataTableRow size="md">
                                {
                                  data.expenses.amount_owed === '0.00' ?
                                  <span className="tb-amount">
                                    <small>{currency} </small><b>{data.expenses.total_amount}</b>
                                  </span>
                                  :
                                  <span className="tb-amount">
                                    <small>{currency} </small><b>{data.expenses.amount_paid}</b> / {data.expenses.total_amount}
                                  </span>
                                }
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className={`${data.total_balance_owed !== '0.00' && `text-danger`}`}>{balanceStatusDot} <small> {currency} </small> {data.total_balance_owed}</span>
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{data.created_at && moment(data.created_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/purchase-orders/b/${businessID}?sp=${data.supplier_name}`}>                                            
                                          <Icon name="contact"></Icon>
                                          <span>View Purchases Orders</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}?sp=${data.supplier_name}`}>                                            
                                          <Icon name="pie"></Icon>
                                          <span>View Expenses</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/suppliers/b/${businessID}/edit/${data.supplier_id}`}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.supplier_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }

      { 
        activeModal === "successDeleteModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Deleted`} descriptionText={`Supplier(s) deleted successfully.`} 
        />
      }
      
      {
        activeModal === "deleteErrorModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }
    </Block>
  );
};
