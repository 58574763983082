import React, { useState, useEffect } from "react";
import { Empty, Input } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, findUpper } from "../../../utils/Utils";
import { 
  Badge, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledDropdown, 
  DropdownItem, 
  DropdownToggle, 
  DropdownMenu, 
} from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import { PaginationWithOnclick } from "./Pagination";
import { 
  AlertModal,
  SuccessModal,
  LoadingModal 
} from "../AlertModals";

const { TextArea } = Input;

export const CustomerNotes = ({ history, businessID, customerID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, notes:[]});
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [note, setNote] = useState("");
  const [noteID, setNoteID] = useState("");
  const [page, setPage] = useState(1);
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const loadNextPage = (page) => {
    setPage(page);
    getNotes(page);
  }

  const handleEditNote = (data) => {
    setNoteID(data.note_id);
    setNote(data.note);
    toggleModal('editNoteModal');
  }

  const handleDeleteNote = (data) => {
    setNoteID(data.note_id);
    toggleModal('deleteAlertModal');
  }

  const updateNote = (e) => {
    let _note = e.target.value;
    setNote(_note);
  }
 
  const getNotes = (page) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/customers/notes/${customerID}`, {
      params: {
        business_id: businessID,
        page: page,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, notes:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, notes:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const addNote = () => {
    setRequesting(true);

    axios.post(baseURLs.API_URL + `/customers/note/${customerID}`, {
      business_id: businessID,
      note: note
    }, getAxiosHeaders())
    .then((response) => {

      setSuccessTitle(`Note Added`);
      setSuccessMessage(`Note has been added to the Customer's notes.`);
      toggleModal("successModal");
      setRequesting(false);
      getNotes(1);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setRequesting(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const editNote = () => {
    setRequesting(true);

    axios.put(baseURLs.API_URL + `/customers/note/${noteID}`, {
      business_id: businessID,
      note: note
    }, getAxiosHeaders())
    .then((response) => {

      setSuccessTitle(`Note Updated`);
      setSuccessMessage(`The note has been updated successfully.`);
      toggleModal("successModal");
      setRequesting(false);
      getNotes(1);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setRequesting(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteNote = () => {
    toggleModal("deleteLoadingModal");

    axios.delete(baseURLs.API_URL + `/customers/note/${noteID}`, {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID
      }
    })
    .then((response) => {

      setSuccessTitle(`Note Deleted`);
      setSuccessMessage(`The note has been deleted successfully.`);
      toggleModal("successModal");
      getNotes(1);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    getNotes(page);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
        {
          tableData.notes.length === 0 ?
          <div className="nk-block">
            <div className="text-center m-5">
              <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
              <div className="price-plan-info">
                <span className="title fw-normal">No notes found</span>
              </div>
              <div className="price-plan-action">
                <button className="btn btn-outline-primary btn-sm" onClick={() => toggleModal('newNoteModal')}>Add a Note</button>
              </div>
            </div>
          </div>
          :
          <div className="nk-block">
            <div className="card-title-group mb-3">
              <h6 className="lead-text m-0">
                {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Notes` : `Note`}`}
              </h6>
              <div className="card-tools">
                <ul className="btn-toolbar">
                  <li>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => toggleModal('newNoteModal')}>Add a Note</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-bordered card-full card">
              <ul className="nk-support">
                {
                  tableData.notes.map((data, index) => {                    
                    return (
                      <li className="nk-support-item" key={data.note_id}>
                        <div className="user-avatar bg-gray-dim sm">
                          <span>{findUpper(data.created_by)}</span>
                        </div>
                        <div className="nk-support-content">
                          <div className="title">
                            <span>{data.created_by}</span>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem tag="a" className="pointer-cursor" onClick={() => handleEditNote(data)}>
                                      <Icon name="edit-alt" />
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleDeleteNote(data)}>
                                      <Icon name="trash" />
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <p>{data.note}</p>
                          <span className="time">{moment(data.created_at).format("Do MMM YYYY")}</span>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <PaginationWithOnclick currentPage={page} pageCount={totalPages} loadNextPage={loadNextPage} />          
          </div>
        }
        </>
      }

      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {
        activeModal === "deleteAlertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
          headerText={`Delete Note`} 
          descriptionText={`Are you sure you want to delete this note`}
          leftButtonText={`Delete`}
          leftButtonOnClick={deleteNote} />
      }

      {
        activeModal === "alertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }

      { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the note is being deleted`} /> }

      {
        activeModal === "newNoteModal" &&
        <Modal isOpen={true} toggle={() => toggleModal('newNoteModal')}>
          <ModalHeader
            toggle={() => toggleModal('newNoteModal')}
            close={
              <button className="close" onClick={() => toggleModal('newNoteModal')}>
                <Icon name="cross" />
              </button>
            }
          >
            New Note
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="form-label" htmlFor="item_name">
                Note
              </label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  onChange={updateNote}
                  value={note}
                />
              </div>
            </div>
            <div className="form-group">
              <Button disabled={requesting} color="primary" onClick={() => {addNote()}} size="md">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      }

      {
        activeModal === "editNoteModal" &&
        <Modal isOpen={true} toggle={() => toggleModal('editNoteModal')}>
          <ModalHeader
            toggle={() => toggleModal('editNoteModal')}
            close={
              <button className="close" onClick={() => toggleModal('editNoteModal')}>
                <Icon name="cross" />
              </button>
            }
          >
            New Note
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="form-label" htmlFor="item_name">
                Note
              </label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  onChange={updateNote}
                  value={note}
                />
              </div>
            </div>
            <div className="form-group">
              <Button disabled={requesting} color="primary" onClick={() => {editNote()}} size="md">
                {requesting ? <Spinner size="sm" color="light" /> : "Save"}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      }
    </Block>
  );
};
