let menu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/dashboard/b",
    permission: ["access_dashboard"],
  },
  {
    icon: "growth",
    text: "Analytics & Reports",
    permission: ["access_analytics"],
    active: false,
    subMenu: [
      {
        text: "Analytics",
        link: "/analytics/b",
        permission: ["access_analytics"],
      },
      {
        text: "Profit & Loss",
        link: "/profit-and-loss/b",
      },
    ],
  },
  {
    icon: "happy",
    text: "Customers",
    permission: ["access_customers"],
    active: false,
    subMenu: [
      {
        text: "Customers",
        link: "/customers/b",
        permission: ["access_customers"],
      },
      {
        text: "Message Customers",
        link: "/message-customers/b",
        permission: ["message_customers"],
      },
      {
        text: "Customer Feedback",
        link: "/customer-feedback/b",
        permission: ["access_customers"],
      },
    ],
  },
  {
    icon: "coins",
    text: "Sales & Orders",
    permission: [
      "access_sales",
      "record_sales",
      "access_orders",
      "access_storefront"
    ],
    active: false,
    subMenu: [
      {
        text: "Sales",
        link: "/sales/b",
        permission: ["access_sales"],
      },
      {
        text: "Create Invoice / Receipt",
        link: "/create-invoice-receipt/b",
        permission: ["record_sales"],
      },
      {
        text: "POS",
        link: "/pos/b",
        permission: ["add_pos_sales"],
      },
      {
        text: "Orders",
        link: "/orders/b",
        permission: ["access_orders"],
      },
      {
        text: "Create an Order",
        link: "/create-sale-order/b",
        permission: ["add_sale_orders"],
      },
      {
        text: "Storefront",
        link: "/storefront/b",
        permission: ["access_storefront"],
      },
      {
        text: "Payments Received",
        link: "/sales-payments-received/b",
        permission: ["access_sales_payment"],
      },
    ],
  },
  // {
  //   icon: "wallet-saving",
  //   text: "Payouts",
  //   link: "/payouts/b",
  //   permission: ["access_payouts"],
  // },
  {
    icon: "package",
    text: "Inventory",
    permission: [
      "access_inventory", 
      "access_services", 
      "access_raw_materials"
    ],
    active: false,
    subMenu: [
      {
        text: "Products",
        link: "/products/b",
        permission: ["access_inventory"],
      },
      {
        text: "Raw Materials",
        link: "/raw-materials/b",
        permission: ["access_raw_materials"],
      },
      {
        text: "Manufacturing Orders",
        link: "/manufacturing-orders/b",
        permission: ["access_raw_materials"],
      },
      {
        text: "Damages & Loss",
        link: "/damages-loss/b",
        permission: ["access_damages_loss"],
      },
    ]
  },
  {
    icon: "briefcase",
    text: "Services",
    link: "/services/b",
    permission: ["access_services"],
  },
  {
    icon: "pie",
    text: "Expenses",
    permission: [
      "access_purchase_orders", 
      "access_expenses",
    ],
    active: false,
    subMenu: [
      {
        text: "Expenses",
        link: "/expenses/b",
        permission: ["access_expenses"],
      },
      {
        text: "Purchase Orders",
        link: "/purchase-orders/b",
        permission: ["access_purchase_orders"],
      },
      {
        text: "Suppliers",
        link: "/suppliers/b",
        permission: ["access_expenses"],
      },
    ]
  },
  {
    icon: "users",
    text: "Team Management",
    permission: [
      "access_team_members"
    ],
    active: false,
    subMenu: [
      {
        text: "Team Members",
        link: "/team/b",
        permission: ["access_team_members"],
      },
      {
        text: "Activity Logs",
        link: "/team-activity-logs/b",
        permission: ["access_team_members"],
      },
    ]
  },
  {
    icon: "setting",
    text: "Business Settings",
    permission: ["access_business_settings"],
    active: false,
    subMenu: [
      {
        text: "Business Profile",
        link: "/business-profile/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Business Verification",
        link: "/business-verification/b",
        permission: ["access_business_settings"],
      },
      {
        text: "SMS Sender Name",
        link: "/sms-sender-name/b",
        permission: ["access_business_settings"],
      },
      {
        text: "API Key",
        link: "/api-key/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Sales Settings",
        link: "/sales-settings/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Tax Profiles",
        link: "/tax-profiles/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Delivery & Pick-up",
        link: "/delivery-pickup/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Online Payment",
        link: "/online-payment/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Offline Payment",
        link: "/offline-payment/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Storefront Settings",
        link: "/storefront-settings/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Storefront Fulfillment",
        link: "/storefront-fulfillment-settings/b",
        permission: ["access_business_settings"],
      },
      {
        text: "Storefront Appearance",
        link: "/storefront-appearance-settings/b",
        permission: ["access_business_settings"],
      },
    ]
  },
  {
    icon: "notify",
    text: "Subscription",
    link: "/subscription/b",
    permission: ["access_subscriptions"],
  },
];

export default menu;
