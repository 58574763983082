import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import imagePlaceholder from "../../../images/placeholder.png";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert } from "reactstrap";
import moment from "moment";
import { ColorPicker, Input, Select, Checkbox, Form } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { findUpper, getAxiosHeaders, getTextColorBaseOnTheme, phoneNumberFormatter, recommendedColors } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';

export const SalesSettings = ({ history, match }) => {

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [textColor, setTextColor] = useState("#ffffff"); 
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    restrict_to_inventory: false,
    restrict_to_services: false,
    inventory_reduction: "",
    theme_color: "",
    // show_ad: ""
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setFormError("");

    axios.put(baseURLs.API_URL + `/businesses/sales-settings/${businessID}`, {
      restrict_to_inventory: _formData.restrict_to_inventory ? "yes" : "no",
      restrict_to_services: _formData.restrict_to_services ? "yes" : "no",
      inventory_reduction: _formData.inventory_reduction,
      theme_color: _formData.theme_color
    }, getAxiosHeaders())
    .then((response) => {
      // let responseInfo = response.data;
      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'restrict_to_inventory',
              errors: errorResponse.errors.hasOwnProperty("restrict_to_inventory") ? [errorResponse.errors.restrict_to_inventory] : [],
            },
            {
              name: 'restrict_to_services',
              errors: errorResponse.errors.hasOwnProperty("restrict_to_services") ? [errorResponse.errors.restrict_to_services] : [],
            },
            {
              name: 'inventory_reduction',
              errors: errorResponse.errors.hasOwnProperty("inventory_reduction") ? [errorResponse.errors.inventory_reduction] : [],
            },
            {
              name: 'theme_color',
              errors: errorResponse.errors.hasOwnProperty("theme_color") ? [errorResponse.errors.theme_color] : [],
            }            
          ]);
          
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getSalesSettings = (businessID) => {
    axios.get(baseURLs.API_URL + `/businesses/sales-settings`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then(async(response) => {
      
      let responseInfo = response.data;
      let salesSettings = responseInfo.data;

      let _formData = formData;
      _formData.theme_color = salesSettings.theme_color;
      _formData.inventory_reduction = salesSettings.inventory_reduction;
      _formData.restrict_to_inventory = salesSettings.restrict_to_inventory === 'yes' ? true : false;
      _formData.restrict_to_services = salesSettings.restrict_to_services === 'yes' ? true : false;
      // _formData.show_ad = salesSettings.show_ad;

      setFormData(_formData);
      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {    
    // Determine text color based on the theme color
    setTextColor(getTextColorBaseOnTheme(formData.theme_color));
  }, [formData.theme_color])

  useEffect(() => {
    setLoading(true);
    
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    
    // Determine text color based on the theme color
    setTextColor(getTextColorBaseOnTheme(formData.theme_color));
    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getSalesSettings(businessID);
  }, [match.params.businessID]);


  return (
    <React.Fragment>
      <Head title="Sales Settings"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Sales Settings
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update your sales settings.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              formError &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {formError}{" "}
                  </Alert>
                </div>
              </Block>
            }

            <Form name="update-sales-settings"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}>
              <div className="card card-bordered">
                <div className="fm-content">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Reduce Inventory On<span className="text-danger">*</span>
                                <Button type="button" id="inventory_reduction_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                  <Icon name="info" />
                                </Button>
                                <UncontrolledPopover target="inventory_reduction_info_popover" placement="bottom" trigger="focus">
                                  <PopoverBody>
                                    <div className="mb-2">Choose when items should be deducted from your inventory:</div>
                                    <ul className="list mb-2">
                                      <li><b>Invoice:</b> Inventory reduction occurs when the invoice is created.</li>
                                      <li><b>Part Payment:</b> Inventory is reduced when a partial payment is made.</li>
                                      <li><b>Receipt:</b> Inventory decreases only upon receipt of full payment.</li>
                                    </ul>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </label>
                          </div>
                          <Form.Item name={'inventory_reduction'}
                            noStyle
                            rules={[
                              {required: true, message: 'Reduce inventory is required'}
                            ]}>
                            <Select size="large"                          
                              placeholder="Select reduction type"
                              style={{ width: "100%" }} 
                              name="source"
                              filterOption={(input, option) =>
                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              options={[
                                {value: "invoice", label: "Invoice"},
                                {value: "part_payment", label: "Part Payment"},
                                {value: "receipt", label: "Receipt"},
                              ]} 
                              showSearch /> 
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Restrict Sale Items to 
                                <span className="text-danger">*</span>
                                <Button type="button" id="restrict_to_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                  <Icon name="info" />
                                </Button>
                                <UncontrolledPopover target="restrict_to_info_popover" placement="bottom" trigger="focus">
                                  <PopoverBody>
                                    <div className="mb-2">Specify the limitations for adding items to a sale:</div>
                                    <ul className="list mb-2">
                                      <li><b>Inventory:</b> When checked, only products from your inventory can be added to the sale. Prices are fixed and cannot be changed.</li>
                                      <li><b>Services:</b> When checked, only services from your services can be added to the sale. Prices for services remain uneditable</li>
                                    </ul>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </label>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <Form.Item name="restrict_to_inventory"
                                noStyle
                                valuePropName="checked"
                                rules={[
                                  {required: false}
                                ]}>
                                <div className="form-group">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={formData.restrict_to_inventory}
                                      onChange={() => {
                                        const newState = !formData.restrict_to_inventory;
                                        setFormData(prevState => ({ ...prevState, restrict_to_inventory: newState }));
                                        form.setFieldValue('restrict_to_inventory', newState);
                                      }}
                                      id="restrict_to_inventory" />
                                    <label className="custom-control-label" htmlFor="restrict_to_inventory">
                                      Inventory
                                    </label>
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <Form.Item name="restrict_to_services"
                                noStyle
                                valuePropName="checked"
                                rules={[
                                  {required: false}
                                ]}>
                                <div className="form-group">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={formData.restrict_to_services}
                                      onChange={() => {
                                        const newState = !formData.restrict_to_services;
                                        setFormData(prevState => ({ ...prevState, restrict_to_services: newState }));
                                        form.setFieldValue('restrict_to_services', newState);
                                      }}
                                      id="restrict_to_services" />
                                    <label className="custom-control-label" htmlFor="restrict_to_services">
                                      Services
                                    </label>
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Theme<span className="text-danger">*</span></label>
                          </div>
                          <div className="card card-bordered">

                            <div className="fm-head">
                              <div className="fm-count d-none d-md-inline-flex"><Icon name="color-palette fs-22px"></Icon></div>
                              <div className="fm-title w-100">
                                <div className="d-flex flex-wrap justify-content-start">
                                  <Form.Item name={'theme_color'}
                                    noStyle
                                    rules={[
                                      {required: true, message: 'theme is required'}
                                    ]}>
                                    <ColorPicker showText={(color) => color.toHexString()}
                                      disabled={loading}
                                      presets={[
                                        {
                                          label: "Recommended",
                                          colors: recommendedColors()
                                        }
                                      ]}
                                      format={"hex"}
                                      onChange={(value) => {
                                        let _formData = formData;
                                        let newColor = value.toHexString();
                                        _formData.theme_color = newColor;
                                        setFormData({..._formData});
                                        form.setFieldValue('theme_color', newColor);
                                      }} />                                  
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div className="fm-head position-relative justify-content-between" style={{backgroundColor: formData.theme_color}}>
                              <div className="business-info d-flex" style={{zIndex: "1"}}>
                                <div className="d-flex justify-content-center align-center me-2 w-25">
                                  <UserAvatar contained={false} theme="lighter" image={businessInfo.logo ?? imagePlaceholder} imagePlaceholder={imagePlaceholder} />
                                </div>
                                <div className="fm-title flex-column d-flex justify-content-center" style={{color: textColor}}>
                                  <h5 className="title fw-normal" style={{color: textColor}}>{businessInfo.name}</h5>
                                  <p className="sub-title mb-0">{businessInfo.location}</p>
                                  <small className="sub-title">{phoneNumberFormatter(businessInfo.phone_number)}</small>
                                </div>
                              </div>
                              <div className="fm-title text-end d-none d-md-block" style={{zIndex: "1", color: textColor}}>
                                <h5 className="title fw-normal text-uppercase" style={{color: textColor}}>Receipt</h5>
                                <p className="sub-title mb-0">#1</p>
                                <small className="sub-title">{moment().format("Do MMM, YYYY h:mm a")}</small>
                              </div>
                            </div>

                            <div className="fm-title ps-4 pe-4 pt-2 pb-2 d-flex d-md-none justify-content-between" style={{color: textColor, backgroundColor: formData.theme_color, position: "relative"}}>
                              <div style={{zIndex: "1"}}>
                                <h5 className="title text-center fw-normal text-uppercase" style={{color: textColor}}>Receipt</h5>
                                <p className="sub-title mb-0">#1</p>
                              </div>
                              <small className="sub-title" style={{lineHeight: "4", zIndex: "1"}}>{moment().format("Do MMM, YYYY h:mm a")}</small>
                            </div>

                            <div className="fm-head bg-lighter">
                              <div className="me-1">
                                <em className="icon ni ni-happy text-muted" style={{fontSize: "45px"}}></em>
                              </div>
                              <div className="fm-title">
                                <span className="sub-title text-muted">Made to:</span>  
                                <h5 className="title fw-normal">
                                  Customer Name
                                  <small><em className="text-muted icon ni ni-eye ms-1" title="Viewed"></em></small>
                                </h5>
                                <small className="text-muted">Issued By: </small>
                              </div>
                              
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fm-footer">
                  <div className="pt-2 text-center">
                    <Button disabled={requesting} type="submit" color="primary" size="lg" >
                      {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Block>
        </>
      }
      </Content>

      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Updated Successfully`} descriptionText={`Sales settings have been updated successfully.`} 
        />
      }
    </React.Fragment>
  )
}