import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import imagePlaceholder from "../../../images/product_placeholder.png";
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ServicesPerformanceTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0
    }, 
    services: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    service_name: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const currentUrl = (filters) => {
    let service_name = filters.service_name.length > 0 ? `&s=${filters.service_name}` : ``;
    let date_range = filters.date_range !== null ? `&d=${filters.date_range}` : ``;
    
    if( (service_name !== '' || date_range !== '') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${service_name}${date_range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getServices(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      service_name: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getServices(params);
  }

  const filterServices = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getServices(filters);
  }

  const getServices = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/services/analytics", {
      params: {
        business_id: businessID,
        page: filters.page,
        service_name: filters.service_name,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          services:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          services:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const downloadServices = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/services/analytics/download", {
      params: {
        business_id: businessID,
        service_name: filters.service_name,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No service found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let service_name = queryParams.hasOwnProperty('s') ? queryParams.s : '';
    let date_range = queryParams.hasOwnProperty('d') ? queryParams.d : null;
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setFromDate(dateRangeStart);
      setToDate(dateRangeEnd);
    }

    let params = filters;
    params.page = page;
    params.service_name = service_name;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;

    setFilters(params);
    getServices(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.services.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-dark">Total Quantity</div>
                        <div className="amount">{tableData.meta.total_quantity}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Total Amount</div>
                        <div className="amount">
                          <small className="text-muted fw-light me-1">{currency}</small> 
                          {tableData.meta.total_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Services` : `Service`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Services Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="service_name">
                                    Service Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="service_name" className="form-control form-control-lg" id="service_name" defaultValue={filters.service_name} />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">From Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={fromDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'from');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">To Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={toDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'to');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterServices();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getServices(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadServices} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Services"} descriptionText={"Please wait while your services list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Services Ready"} descriptionText={"Services CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.services.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No service found</h5><span>Add a service using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/services/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Service</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow>
                          <span className="sub-text">Service</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Times Sold</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Quantity</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total</span>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.services.map((data, index) => {
                          
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow>
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <div className="user-info">
                                    <span className={`tb-lead fw-normal`}>
                                      {data.service_name}{" "}
                                    </span>
                                    <div className="price d-md-none">Times Sold: {data.times_sold} • Qty: {data.quantity} • Total: <small>{currency} </small> {data.total_amount}</div>
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.times_sold}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.quantity}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.total_amount}
                                </span>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
