import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import logoPlaceholder from "../images/crop.png";
import Head from "../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BackTo,
  BlockTitle,
  Icon,
  Button
} from "../components/Component";
import { Input, Select, Checkbox, Form } from 'antd';
import { baseURLs } from '../utils/Constants';
import { countries } from '../utils/Countries';
import axios from 'axios';
import { Spinner, Alert } from "reactstrap";
import GooglePlacesInput from "./components/GooglePlacesInput";
import PhoneNumberInput from "./components/PhoneNumberInput";
import { getAxiosUploadHeaders, getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";

const CreateBusiness = ({ history }) => {

  const [loading, setLoading] = useState(false);
  // const [imageName, setImageName] = useState("");
  const [imageSrc, setImageSrc] = useState(logoPlaceholder);
  const [imageUploadError, setImageUploadError] = useState("");
  const [invitations, setInvitations] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [countryRestrictionSearch, setCountryRestrictionSearch] = useState(['gh']);
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    logo: "",
    business_name: "",
    location: "",
    country: null,
    currency: null,
    phone_code: "+233",
    phone_number: "",
    email: "",
    lat: null,
    lng: null
  });

  const getBusinesses = (businessID, isNewUser) => {
    axios.get(baseURLs.API_URL + "/businesses", getAxiosHeaders())
    .then(async(response) => {
      let responseInfo = response.data;
      let businesses = responseInfo.data.businesses;        
      await localStorage.setItem('my_businesses', JSON.stringify(businesses));
      
      if(isNewUser === 'yes'){
        history.push(`${process.env.PUBLIC_URL}/dashboard/b/${businessID}`);
      }else{
        history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
      }

      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleFormSubmit = (_formData) => {
    setLoading(true);
    setImageUploadError("");
    setFormError("");

    let currency = _formData.currency ? _formData.currency.split('-')[0] : ``;

    const formInfo = new FormData();
    formInfo.append('logo',formData.logo);
    formInfo.set("business_name", _formData.business_name);
    formInfo.set("location", _formData.location);
    formInfo.set("lat", formData.lat);
    formInfo.set("lng", formData.lng);
    formInfo.set("phone_number", `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`);
    formInfo.set("email", _formData.email);
    formInfo.set("currency", currency);
    formInfo.set("country", _formData.country);

    axios.post(baseURLs.API_URL + "/businesses/create", formInfo, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let businessID = responseInfo.data.business_id;
      let isNewUser = responseInfo.data.new_user;

      getBusinesses(businessID, isNewUser)
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'logo',
              errors: errorResponse.errors.hasOwnProperty("logo") ? [errorResponse.errors.logo] : [],
            },
            {
              name: 'business_name',
              errors: errorResponse.errors.hasOwnProperty("business_name") ? [errorResponse.errors.business_name] : [],
            },
            {
              name: 'country',
              errors: errorResponse.errors.hasOwnProperty("country") ? [errorResponse.errors.country] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            },
            {
              name: 'currency',
              errors: errorResponse.errors.hasOwnProperty("currency") ? [errorResponse.errors.currency] : [],
            },
            
          ]);
          
          if(errorResponse.errors.hasOwnProperty("logo")){
            setImageUploadError(errorResponse.errors.logo);
          }


        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setLoading(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const updateCountryRestrictionSearch = (value) => {
    let newCountryRestrictionSearch = [];
      
    let locationIndex = countries.findIndex(obj => obj.name == value);
    if(locationIndex >= 0){
      let countryISO = countries[locationIndex].country_code.toLowerCase();
      newCountryRestrictionSearch.push(countryISO);
      setCountryRestrictionSearch(newCountryRestrictionSearch);
    }
  }

  // const handleCheckboxChange = (name) => {
  //   setFormData({ ...formData, [name]: formData[name] ? false : true })
  // };

  const handleBusinessLogoUpload = e => {    
    if (e.target.files && e.target.files.length > 0){
      setFormData({ ...formData, logo: e.target.files[0] })
      // setImageName(e.target.files[0].name);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }    
  }

  const generateCountryOptions = () => {
    let options = [];
    for(let i = 0; i < countries.length; i++){
      options.push({
        label: countries[i].name, value: countries[i].name, key: i
      })
    }

    setCountryOptions(options);
  }

  const generateCurrencyOptions = () => {
    let options = [];
    for(let i = 0; i < countries.length; i++){
      options.push({
        key: countries[i].country_code, label: `${countries[i].name} - ${countries[i].currency}`, value: `${countries[i].currency}-${countries[i].country_code}`
      })
    }
    setCurrencyOptions(options);
  }

  // const CustomCheckbox = ({ checked, title, description }) => (
  //   <>
  //     <div className="title mb-0">
  //       <h6 className="mb-0">{title}</h6>
  //       {checked && (
  //         <span className="badge-dot badge-dot-xs badge bg-primary">
  //           Selected
  //         </span>
  //       )}
  //     </div>
  //     <p className="nk-block-des">{description}</p> 
  //   </>
  // );

  const getInvitations = () => {
    axios.get(baseURLs.API_URL + `/businesses/invitations`, {headers: getAxiosHeaders().headers})
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        setInvitations(responseInfo.data.meta.total_records);
      }
    }).catch((error) => {
      console.log(error);
      // history.push(`${process.env.PUBLIC_URL}/server-offline`);
    });
  }

  useEffect(() => {
    generateCountryOptions();
    generateCurrencyOptions();
    getInvitations();
    loadGoogleMapsApi();
  }, []);

  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }

  return (
    <React.Fragment>
      <Head title="Create Business" />
      <Content>        
        <BlockHead size="sm" className="wide-sm m-auto">
          <BlockHeadContent>
            <BackTo history={history} link="/my-businesses" icon="arrow-left">
              My Businesses
            </BackTo>
            <BlockTitle tag="h2" className="fw-normal text-center mt-3">
              Create a new Business
            </BlockTitle>
            <BlockDes>
              <p className="text-center">Fill the form below to create your new business.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>

        {
          invitations > 0 && 
          <Block className="wide-sm m-auto">
            <div className="card-bordered card mb-2">
              <div className="card-inner">
                <div className="nk-help">
                  <div className="nk-help-img m-0">
                    <lord-icon
                      src="https://cdn.lordicon.com/tmqaflqo.json"
                      trigger="loop"
                      delay="2000"
                      colors="primary:#4bb3fd,secondary:#ebe6ef,tertiary:#e4e4e4"
                      style={{width:"120px",height:"120px"}}>
                    </lord-icon>
                  </div>
                  <div className="nk-help-text">
                    <h5> You have <b>{invitations}</b> {`${invitations > 1 ? `Invitations` : `Invitation`}`}.</h5>
                    <p className="text-soft">Click to view invitations from businesses.</p>
                  </div>
                  <div className="nk-help-action">
                    <Link to={`${process.env.PUBLIC_URL}/invitations`}>
                      <button className="btn btn-outline-primary">
                        View Invitations
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        }

        <Block className="wide-sm m-auto">
          {formError && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {formError}{" "}
              </Alert>
            </div>
          )}
        </Block>
        <Block className="wide-sm m-auto">
          <Form name="create-business"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="home-alt fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Business Details</h5>
                  <p className="nk-block-des">Your business information to inform and help your customers reach you.</p></div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="businessLogo">Upload Business Logo <span className="text-muted">(optional - jpeg, jpg, png - Max size: 2mb)</span></label>
                      {imageUploadError && <p className="form-error-text fs-14px">{imageUploadError}</p>}
                      <div className="nk-activity-item">
                        <div className="nk-activity-media user-avatar bg-light" style={{width: "80px", height: "80px"}}>
                          <img src={imageSrc} alt="" />
                        </div>
                        <div className="nk-activity-data">
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <div className="form-file">
                                <input id="businessLogo" 
                                  type="file" onChange={handleBusinessLogoUpload} 
                                  accept="image/*" className="form-control" />
                              </div>
                              <div className="input-group-append">
                                <label className="input-group-text" htmlFor="businessLogo">Upload</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Business Name <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'business_name'}
                          noStyle
                          rules={[
                            {required: true, message: 'Business name is required',}
                          ]}>
                          <Input size="large"
                            type="text" className="form-control form-control-lg" 
                            placeholder="My Awesome Business"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Country <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'country'}
                          noStyle
                          rules={[
                            {required: true, message: 'Country is required',}
                          ]}>
                          <Select required size="large" 
                            name="country"
                            placeholder="Select your country"
                            style={{ width: "100%" }} 
                            onChange={updateCountryRestrictionSearch}
                            options={countryOptions} 
                            showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Business Location <span className="text-danger">*</span></label>
                      </div>
                      <GooglePlacesInput form={form} isRequired={true} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={countryRestrictionSearch} />
                    </div>
                  </div> 
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <PhoneNumberInput form={form} formData={formData} isRequired={true} setFormData={setFormData} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Email <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'email'}
                          noStyle
                          rules={[
                            {required: false}, {type: 'email', message: 'Email is invalid'}
                          ]}>
                          <Input size="large" 
                            type="email" name="email" className="form-control form-control-lg" 
                            placeholder="mybusiness@email.com"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Currency <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'currency'}
                              noStyle
                              rules={[
                                {required: true, message: 'Currency is required',}
                              ]}>
                            <Select size="large"                          
                            placeholder="Select a currency"
                            style={{ width: "100%" }} 
                            filterOption={(input, option) =>
                              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={currencyOptions} 
                            showSearch /> 
                          </Form.Item>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-footer">
                <div className="pt-2 text-center">
                  <Button disabled={loading} type="submit" color="primary" size="lg" >
                    {loading ? <Spinner size="sm" color="light" /> : "Create Business"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CreateBusiness;
