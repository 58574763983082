import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs, units } from '../utils/Constants';
import { getAxiosHeaders, moneyToFloat } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, InputNumber, Divider, Space, Form } from 'antd';
import PhoneNumberInput from "./components/PhoneNumberInput";
const { TextArea } = Input;

const EditRawMaterial = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [materialID, setMaterialID] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    material_name: '',
    note: "",
    unit_price: '',
    quantity: 0,
    unit: '',
    restock_level: 1,
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/raw-materials/edit/${materialID}`, {
      business_id: businessID,
      material_name: _formData.material_name,
      note: _formData.note,
      unit_price: _formData.unit_price,
      unit: _formData.unit,
      quantity: _formData.quantity,
      restock_level: _formData.restock_level
    }, getAxiosHeaders())
    .then((response) => {
      toggleModal("successModal");
      setRequesting(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'material_name',
              errors: errorResponse.errors.hasOwnProperty("material_name") ? [errorResponse.errors.material_name] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            },
            {
              name: 'unit_price',
              errors: errorResponse.errors.hasOwnProperty("unit_price") ? [errorResponse.errors.unit_price] : [],
            },
            {
              name: 'unit',
              errors: errorResponse.errors.hasOwnProperty("unit") ? [errorResponse.errors.unit] : [],
            },
            {
              name: 'quantity',
              errors: errorResponse.errors.hasOwnProperty("quantity") ? [errorResponse.errors.quantity] : [],
            },
            {
              name: 'restock_level',
              errors: errorResponse.errors.hasOwnProperty("restock_level") ? [errorResponse.errors.restock_level] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const getMaterialDetails = (businessID, materialID) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/raw-materials/${materialID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;

      } else {
        let responseInfo = response.data;
        let material_info = responseInfo.data.raw_material_info;
        
        // update form data 
        setFormData({
          material_name: material_info.material_name,
          note: material_info.note,
          unit_price: moneyToFloat(material_info.unit_cost),
          unit: material_info.unit,
          quantity: material_info.quantity,
          restock_level: material_info.restock_level
        });
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const groupedOptions = units.reduce((acc, option) => {
    if (!acc[option.category]) {
      acc[option.category] = [];
    }
    acc[option.category].push(option);
    return acc;
  }, {});

  const selectOptions = Object.keys(groupedOptions).map(category => ({
    label: category,
    options: groupedOptions[category].map(unit => ({
      value: unit.abbreviation,
      label: `${unit.singular} (${unit.abbreviation})`,
    })),
  }));

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const materialID = parseInt(match.params.materialID, 10);
    if ( !Number.isInteger(businessID) && !Number.isInteger(materialID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setMaterialID(materialID);
    getMaterialDetails(businessID, materialID);
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Edit Raw Material" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Edit Raw Material
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/raw-materials/b/${businessID}/import`}>
                        <Button color="primary">
                          <Icon name="upload-cloud"></Icon>
                          <span>Import Raw Materials</span>
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/raw-materials/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Material Added`} descriptionText={`The raw material has been added successfully.`}
          />
        }

        <Block className="wide-sm m-auto">
          <Form name="create-material"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="layers fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Material Information</h5>
                  <p className="nk-block-des">Use the form below to update a raw material.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Material Name <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'material_name'}
                          noStyle
                          rules={[
                            {required: true,}
                          ]}>
                          <Input size="large" className="form-control form-control-lg" placeholder="Enter material name" />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Cost Price <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'unit_price'}
                            noStyle
                            rules={[
                              {required: true, message: 'Unit price is required'}
                            ]}>
                            <InputNumber prefix={businessInfo.currency} min={0} step={0.0001} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Unit <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'unit'}
                            noStyle
                            rules={[
                              {required: true, message: 'unit is required'}
                            ]}>
                            <Select size="large" 
                              placeholder="Select a material unit"
                              style={{ width: "100%" }}
                              options={selectOptions}
                              filterOption={(inputValue, option) =>
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                              }
                              showSearch
                              allowClear
                            />
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Quantity <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'quantity'}
                            noStyle
                            rules={[
                              {required: true, message: 'Quantity is required'}
                            ]}>
                            <InputNumber min={0} step={0.01} size="large" className="w-100" allowClear /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Restock Level <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'restock_level'}
                            noStyle
                            rules={[
                              {required: true, message: 'Restock level is required'}
                            ]}>
                            <InputNumber min={1} step={0.01} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Note <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'note'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <TextArea rows={4} placeholder="enter a note" />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-footer">
                <div className="pt-2 text-center">
                  <Button disabled={requesting} type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default EditRawMaterial;