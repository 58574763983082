import React, { useState, useEffect } from "react";
import { Empty, Input, Select, Checkbox, Form, InputNumber } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Numeral from 'react-numeral';
import { getAxiosHeaders, findUpper, moneyToFloat, formatTo2DP } from "../../../utils/Utils";
import { 
  Alert,
  Badge, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledDropdown, 
  DropdownItem, 
  DropdownToggle, 
  DropdownMenu, 
} from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import { PaginationWithOnclick } from "./Pagination";
import { 
  AlertModal,
  SuccessModal,
  LoadingModal 
} from "../AlertModals";

const { TextArea } = Input;

export const CustomerCreditLogs = ({ history, businessID, businessInfo, customerInfo, currency, setCustomerInfo, ...props }) => {
  const [tableData, setTableData] = useState(
    {
      meta: {
        total_records: 0, 
        total_credit: '0.00', 
        sale_credit: '0.00', 
        discount_credit: '0.00'
      }, 
      logs:[]
    }
  );
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [creditType, setCreditType] = useState("");
  const [creditUpdateType, setCreditUpdateType] = useState("");
  const [creditMessage, setCreditMessage] = useState("");
  const [creditAmount, setCreditAmount] = useState("");
  const [creditAmountBalance, setCreditAmountBalance] = useState(0);
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const loadNextPage = (page) => {
    setPage(page);
    getCreditLogs(page);
  }
 
  const getCreditLogs = (page) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/customers/credit-logs/${customerInfo.customer_id}`, {
      params: {
        business_id: businessID,
        page: page,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0, 
            total_credit: '0.00', 
            sale_credit: '0.00', 
            discount_credit: '0.00'
          }, 
          logs:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0, 
            total_credit: '0.00', 
            sale_credit: '0.00', 
            discount_credit: '0.00'
          }, 
          logs:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleFormSubmit = (_formData) => {
    
    if (creditAmountBalance < 0) {
      form.setFields([
        {
          name: 'amount',
          errors: [`Amount cannot be reduced less than ${_formData.credit_type === 'discount_credit' ? tableData.meta.discount_credit : tableData.meta.sale_credit}`]
        }
      ]);

      return;
    } 

    setRequesting(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/customers/credit/${customerInfo.customer_id}`, {
      business_id: businessID,
      credit_type: _formData.credit_type,
      update_type: _formData.update_type,
      amount: _formData.amount,
      message: _formData.message,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let creditInfo = responseInfo.data.credit_info;
      let updatedCustomerInfo = { ...customerInfo, credit_info: creditInfo };

      setCustomerInfo(updatedCustomerInfo);
      getCreditLogs(1);
      toggleModal('successModal');
      setRequesting(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'credit_type',
              errors: errorResponse.errors.hasOwnProperty("credit_type") ? [errorResponse.errors.credit_type] : [],
            },
            {
              name: 'update_type',
              errors: errorResponse.errors.hasOwnProperty("update_type") ? [errorResponse.errors.update_type] : [],
            },
            {
              name: 'amount',
              errors: errorResponse.errors.hasOwnProperty("amount") ? [errorResponse.errors.amount] : [],
            },
            {
              name: 'message',
              errors: errorResponse.errors.hasOwnProperty("message") ? [errorResponse.errors.message] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const creditUpdate = (allValues) => {
    const baseValue = moneyToFloat(allValues.credit_type === 'discount_credit' ? tableData.meta.discount_credit : tableData.meta.sale_credit);
    const changeValue = allValues.update_type === 'increase' ? allValues.amount : -allValues.amount;
    
    return formatTo2DP(baseValue + (changeValue ?? 0));
}

const handleFormValuesChange = (changedValues, allValues) => {
    const { update_type, amount, credit_type } = changedValues;
    
    // Compute credit balance only once and only if necessary fields have changed.
    if (changedValues.hasOwnProperty('update_type') || changedValues.hasOwnProperty('amount') || changedValues.hasOwnProperty('credit_type')) {
        const creditBalance = creditUpdate(allValues);

        if (creditBalance < 0) {
            form.setFields([
              {
                name: 'amount',
                errors: [`Amount cannot be reduced less than ${allValues.credit_type === 'discount_credit' ? tableData.meta.discount_credit : tableData.meta.sale_credit}`]
              }
            ]);
        } else {
          form.setFields([
            {
              name: 'amount',
              errors: []
            }
          ]);
        }

        setCreditAmountBalance(creditBalance);
    }

    // Handle other changes
    if (changedValues.hasOwnProperty('message')) {
        setCreditMessage(changedValues.message ?? '');
    }
    if (changedValues.hasOwnProperty('amount')) {
        setCreditAmount(amount ?? 0);
    }
    if (changedValues.hasOwnProperty('update_type')) {
        setCreditUpdateType(update_type);
    }
    if (changedValues.hasOwnProperty('credit_type')) {
        setCreditType(credit_type);
    }
};


  useEffect(() => {
    setLoading(true);
    getCreditLogs(page);    
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
        <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly">
          <div className="profile-balance-sub">
            <div className="profile-balance-subtitle text-success">Total Credit</div>
            <div className="profile-balance-amount text-dark">
              <div className="amount fs-6">
                <small className="text-muted fw-light me-1">{currency}</small> {tableData.meta.total_credit}
              </div>
            </div>
          </div>
          <div className="profile-balance-sub">
            <div className="profile-balance-subtitle">Sale Credit</div>
            <div className="profile-balance-amount text-dark">
              <div className="amount fs-6">
                <small className="text-muted fw-light me-1">{currency}</small> {tableData.meta.sale_credit}
              </div>
            </div>
          </div>
          <div className="profile-balance-sub">
            <div className="profile-balance-subtitle">Discount Credit</div>
            <div className="profile-balance-amount text-dark">
              <div className="amount fs-6">
                <small className="text-muted fw-light me-1">{currency}</small> {tableData.meta.discount_credit}
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        {
          tableData.logs.length === 0 ?
          <div className="nk-block">
            <div className="text-center m-5">
              <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
              <div className="price-plan-info">
                <span className="title fw-normal">No logs found</span>
              </div>
              <div className="price-plan-action">
                <button className="btn btn-outline-primary btn-sm" onClick={() => toggleModal('creditModal')}>Add Credit</button>
              </div>
            </div>
          </div>
          :
          <div className="nk-block">
            <div className="card-title-group mb-3">
              <h6 className="lead-text m-0">
                {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Logs` : `Log`}`}
              </h6>
              <div className="card-tools">
                <ul className="btn-toolbar">
                  <li>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => toggleModal('creditModal')}>Update Credit</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-bordered card-full card">
              <ul className="nk-support">
                {
                  tableData.logs.map((data, index) => {                    
                    return (
                      <li className="nk-support-item" key={index}>
                        <div className="user-avatar bg-success-dim sm">
                          <Icon name="arrow-up-right" />
                        </div>
                        <div className="nk-support-content">
                          <div className="title">Discount Credit <span className="badge badge-dim bg-success">Increased</span></div>
                          <p>{data.description}</p>
                          <p>Created by: {data.created_by}</p>
                          <span className="time">{moment(data.created_at).format("Do MMM YYYY")}</span>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <PaginationWithOnclick currentPage={page} pageCount={totalPages} loadNextPage={loadNextPage} />          
          </div>
        }
        </>
      }

      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={'Updated'} descriptionText={`Customer's credit has been updated successfully`} 
        />
      }

      {/* update credit modal */}
      {
        activeModal === "creditModal" &&
        <Modal isOpen={true} toggle={() => toggleModal('creditModal')}>
          <ModalHeader
            toggle={() => toggleModal('creditModal')}
            close={
              <button className="close" onClick={() => toggleModal('creditModal')}>
                <Icon name="cross" />
              </button>
            }
          >
            Update Credit
          </ModalHeader>
          <ModalBody>
            {errorMessage && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorMessage}{" "}
                </Alert>
              </div>
            )}
            <Form name="add-order"
              form={form} className="is-alter"
              onValuesChange={handleFormValuesChange}
              onFinish={handleFormSubmit}
              initialValues={{
                credit_type: '',
                update_type: '',
                amount: '',
                message: ''      
              }}
              onFinishFailed={onFinishFailed}>
              <div className="form-group">
                <Form.Item>
                  <label className="form-label" htmlFor="item_name">
                    Credit Type
                  </label>
                  <div className="form-control-wrap">
                    <Form.Item name={'credit_type'}
                      noStyle
                      rules={[
                        {required: true, message: 'Credit type is required',}
                      ]}>
                      <Select required size="large"
                        placeholder="Select credit type"
                        style={{ width: "100%" }}
                        options={[
                          { value: 'discount_credit', label: 'Discount Credit' },
                          { value: 'sale_credit', label: 'Sale Credit' },
                        ]}  
                        showSearch /> 
                    </Form.Item>
                  </div>
                </Form.Item>                
              </div>
              <div className="form-group">
                <Form.Item>
                  <label className="form-label" htmlFor="item_name">
                    Update Type
                  </label>
                  <div className="form-control-wrap">
                    <Form.Item name={'update_type'}
                      noStyle
                      rules={[
                        {required: true, message: 'Update type is required',}
                      ]}>
                      <Select required size="large" 
                        placeholder="Select update type"
                        style={{ width: "100%" }}
                        options={[
                          { value: 'increase', label: 'Increase Credit', disabled: creditType === 'sale_credit' },
                          { value: 'reduce', label: 'Reduce Credit' },
                        ]}  
                        showSearch /> 
                    </Form.Item>
                  </div>
                </Form.Item>                
              </div>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Amount <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                  <Form.Item name={'amount'}
                    noStyle
                    rules={[
                      {required: true, message: 'Amount is required',}
                    ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.01} size="large" className="w-100" />
                    </Form.Item>                       
                  </div>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Message to customer <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    {
                      creditUpdateType === 'increase' &&
                      <p className="p-3 bg-light rounded">🎁 [New Credit]: You've received <b>{currency} {creditAmount > 0 ? creditAmount : `[#amount]`}</b> in credits from {businessInfo.name} for <b>{creditMessage.length > 0 ? creditMessage : `[#enter the reason using the input below]`}</b>. Use it to save on your next purchase. Happy shopping!</p>
                    }
                    {
                      creditUpdateType === 'reduce' &&
                      <p className="p-3 bg-light rounded">❗️ [Credit Update]: Your {businessInfo.name} credit balance was adjusted from <b>{currency} {creditAmount > 0 ? creditAmount : `[#amount]`} to {creditAmountBalance > 0 ? creditAmountBalance : `[#balance amount]`}</b> for <b>{creditMessage.length > 0 ? creditMessage : `[#enter the reason using the input below]`}</b>. Feel free to reach out if you have any questions.</p>
                    }
                    <Form.Item name={'message'}
                      noStyle
                      rules={[
                        {required: true, message: 'Message is required',}
                    ]}>
                      <TextArea rows={4} showCount maxLength={100} placeholder="Message to customer" />
                    </Form.Item>                       
                  </div>
                </Form.Item>
              </div>
              {
                creditType &&
                <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly mt-5">
                  {
                    creditType === 'sale_credit' &&
                    <div className="profile-balance-sub text-center">
                      <div className="profile-balance-subtitle">Old Sale Balance</div>
                      <div className="profile-balance-amount text-dark">
                        <div className="amount fs-6">
                          <small className="text-muted fw-light me-1">{currency}</small> 
                          {tableData.meta.sale_credit}
                        </div>
                      </div>
                    </div>
                  }

                  {
                    creditType === 'discount_credit' &&
                    <div className="profile-balance-sub text-center">
                      <div className="profile-balance-subtitle">Old Discount Balance</div>
                      <div className="profile-balance-amount text-dark">
                        <div className="amount fs-6">
                          <small className="text-muted fw-light me-1">{currency}</small> 
                          {tableData.meta.discount_credit}
                        </div>
                      </div>
                    </div>
                  }
                  <div className={`profile-balance-sub text-center ${creditAmountBalance < 0 ? `text-danger` : `text-dark`}`}>
                    <div className="profile-balance-subtitle">New Balance</div>
                    <div className="profile-balance-amount">
                      <div className="amount fs-6">
                        <small className="text-muted fw-light me-1">{currency}</small> 
                        <Numeral value={creditAmountBalance.toString()} format={"0,0.00"} />
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="form-group mt-5 text-center">
                <Button disabled={requesting} color="primary" type="submit" size="md">
                  {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      }
    </Block>
  );
};
