import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Empty } from 'antd';
import Icon from "../../../../components/icon/Icon";
import axios from 'axios';
import { getAxiosHeaders, getDateAgo, getNotificationIcons, getRouteFromUrl } from "../../../../utils/Utils";
import { baseURLs } from "../../../../utils/Constants";

const getNotifications = (business_id) => {
  axios.get(baseURLs.API_URL + `/notifications/${business_id}`, {
    headers: getAxiosHeaders().headers
  })
  .then((response) => {
    if (response.status === 204) {
      let localStorageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]'); 
      if(localStorageNotifications) {
        let businessIndex = localStorageNotifications?.findIndex((notification) => notification.business_id == business_id);
        if(businessIndex > -1){
          localStorageNotifications[businessIndex].notifications = [];
          localStorage.setItem('notifications', JSON.stringify(localStorageNotifications))
        }
      }

    } else {
      let responseInfo = response.data;
      let notifications = responseInfo.data.notifications;
      let localStorageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]'); 
      if(localStorageNotifications) {
        let businessIndex = localStorageNotifications?.findIndex((notification) => notification.business_id == business_id);
        if(businessIndex > -1){
          localStorageNotifications[businessIndex].notifications = notifications;
        } else {
          localStorageNotifications.push({
            business_id,
            notifications
          })
        }

        localStorage.setItem('notifications', JSON.stringify(localStorageNotifications))
      }
    }

    window.dispatchEvent(new CustomEvent('notificationUpdate'));
    return true;

  }).catch((error) => {
    console.error(error)
    return true;
  });
}

const markAllNotificationsRead = (business_id) => {
  let localStorageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]'); 
  if(localStorageNotifications) {
    let businessIndex = localStorageNotifications?.findIndex((notification) => notification.business_id == business_id);
    if(businessIndex > -1){
      localStorageNotifications[businessIndex].notifications = [];
    }

    localStorage.setItem('notifications', JSON.stringify(localStorageNotifications))
  }

  window.dispatchEvent(new CustomEvent('notificationUpdate'));

  axios.put(baseURLs.API_URL + `/notifications/mark-all-read/${business_id}`, null, getAxiosHeaders())
  .then((response) => {

  }).catch((error) => {
    console.error(error)
  });
}

const markNotificationRead = (business_id, notificationID) => {
  let localStorageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]'); 
  if(localStorageNotifications) {
    let businessIndex = localStorageNotifications?.findIndex((notification) => notification.business_id == business_id);
    if(businessIndex > -1){
      let notifications = localStorageNotifications[businessIndex].notifications
      notifications = notifications.filter(item => item.notification_id !== notificationID);
      localStorageNotifications[businessIndex].notifications = notifications;
      localStorage.setItem('notifications', JSON.stringify(localStorageNotifications))
    }
  }

  window.dispatchEvent(new CustomEvent('notificationUpdate'));

  axios.put(baseURLs.API_URL + `/notifications/mark-read/${notificationID}/${business_id}`, null,getAxiosHeaders())
  .then((response) => {    

  }).catch((error) => {
    console.error(error)
  });
}


const addNotificationToken = (token) => {
  axios.post(baseURLs.API_URL + `/notifications/token/${token}`, null, getAxiosHeaders())
  .then((response) => {    
    localStorage.setItem('user_note_token', token);
  }).catch((error) => {
    console.error(error)
  });
}

const NotificationItem = (props) => {
  const { icon, iconStyle, text, link, time, id, viewNotification } = props;
  return (
    <div onClick={viewNotification} className="nk-notification-item pointer-cursor" key={id} id={id}>
      <div className="nk-notification-icon">
        <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{time}</div>
      </div>
    </div>
  );
};

const Notification = () => {
  const location = useLocation();
  let history = useHistory();
  const [notificationList, setNotificationList] = useState([]);
  const [businessID, setBusinessID] = useState(localStorage.getItem('current_business_id') || '');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  
  const markAllRead = () => {
    setNotificationList([]);
    markAllNotificationsRead(businessID);
  }

  const viewNotification = (notification_id, link) => {
    markNotificationRead(businessID, notification_id);
    if(link) {
      history.push(`${process.env.PUBLIC_URL}${link}`);
    }

    let _notificationsList = notificationList.filter(item => item.notification_id !== notification_id);
    setNotificationList([..._notificationsList]);
  }

  useEffect(() => {
    let _businessID = localStorage.getItem('current_business_id');
    if(_businessID && _businessID !== businessID){
      setBusinessID(_businessID);
      setNotificationList([]);
      getNotifications(_businessID);
    }
  }, [location]);

  useEffect(() => {
    const handleStorageChange = () => {
      let notifications = JSON.parse(localStorage.getItem('notifications') || '[]');
      if(notifications){
        let currentBusinessNotifications = notifications?.find((notification) => notification.business_id == businessID);
        if(currentBusinessNotifications) {
          setNotificationList(currentBusinessNotifications.notifications);
        }
      } else {
        setNotificationList([]);
      }
    };

    window.addEventListener('notificationUpdate', handleStorageChange);
    handleStorageChange(); // Call initially to load any existing notifications

    return () => {
      window.removeEventListener('notificationUpdate', handleStorageChange);
    };

  }, [businessID])

  return (
    <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        {
          notificationList.length > 0 ?
          <div className="icon-status icon-status-danger">
            <Icon name="bell" />
          </div>
          :
          <Icon name="bell" />
        }
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          {
            notificationList.length > 0 &&
            <a href="#" onClick={(ev) => {ev.preventDefault(); markAllRead(); setDropdownOpen(false);}}>
              Mark All as Read
            </a>
          }
        </div>
        {
          notificationList.length === 0 ?
          <div className="dropdown-body">
            <div className="nk-notification">
              <div className="text-center m-5">
                <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                <div className="price-plan-info">
                  <p>All caught up!</p>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="dropdown-body">
            <div className="nk-notification">
              {notificationList.map((item) => {
                let iconColorInfo = getNotificationIcons(item.type);
                return (
                  <NotificationItem
                    key={item.notification_id}
                    id={item.notification_id}
                    icon={iconColorInfo.icon}
                    iconStyle={iconColorInfo.bg_color}
                    text={item.title}
                    link={item.link ? getRouteFromUrl(item.link) : ``}
                    time={getDateAgo(item.created_at)}
                    viewNotification={() => {
                      viewNotification(item.notification_id, item.link ? getRouteFromUrl(item.link) : ``); 
                      setDropdownOpen(false);
                    }}
                  />
                );
              })}
            </div>
          </div>
        }

        {
          notificationList.length > 0 &&
          <div className="dropdown-foot center">
            <Link to={`${process.env.PUBLIC_URL}/notifications/b/${businessID}`} onClick={() => setDropdownOpen(false)}>
              <span>View All</span>
            </Link>
          </div>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Notification;
export {
  addNotificationToken,
  markNotificationRead,
  markAllNotificationsRead,
  getNotifications
}; 