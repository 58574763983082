import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { Icon, UserAvatar, Button, LinkItem, LinkList } from "../../../components/Component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  Col,
  Badge
} from "reactstrap";
import { CustomersList } from "./CustomersList";
import { SentMessages } from "./SentMessages";

const MessageBody = ({
  aside,
  setAside,
  currentTab,
  toggleModal,
  history, 
  businessInfo,
  getUsage
}) => {

  
  return (
    <>
    {
      currentTab === 'customers' &&
      <CustomersList history={history} businessInfo={businessInfo} aside={aside} setAside={setAside} getUsage={getUsage} />
    }

    {/* sent SMS/Email */}
    {
      currentTab === 'm_sent' &&
      <SentMessages history={history} businessInfo={businessInfo} aside={aside} setAside={setAside} />
    }
    </>
  );
};

export default MessageBody;
