import React from "react";
import Icon from "../../components/icon/Icon";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";


export const LoadingModal = ({ index = 'kus', showModal, headerText, descriptionText, ...props }) => {
  return (
    <Modal backdrop="static" isOpen={showModal} id={`loading-modal-${index}`}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <em className="nk-modal-icon icon-circle icon-circle-xxl bg-light">
            <Spinner  color="secondary" style={{borderWidth: "4px"}} />
          </em>
          <h4 className="nk-modal-title fw-normal">{headerText}</h4>
          <div className="nk-modal-text mb-5">
            <div className="caption-text">
              {descriptionText}
            </div>
            {props.children}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export const InfoModal = ({ index = 'kus', showModal, toggleModal, headerText, descriptionText, leftButtonText, leftButtonOnClick, rightButtonText, rightButtonOnClick, noteText, ...props }) => {
  return (
    <Modal isOpen={showModal} toggle={toggleModal} id={`info-modal-${index}`}>
      <button className="close" onClick={toggleModal}><em className="icon ni ni-cross"></em></button>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-light"></Icon>
          <h4 className="nk-modal-title fw-normal">{headerText}</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              {descriptionText}
            </div>
            {props.children}
          </div>
          <div className="nk-modal-action mt-5">
            <Button color="dark" size="lg" className="btn-mw m-1" onClick={leftButtonOnClick}>
              {leftButtonText}
            </Button>

            <Button color="light" size="lg" className="btn-mw m-1" onClick={rightButtonOnClick}>
              {rightButtonText}
            </Button>
          </div>
        </div>
      </ModalBody>
      {
        noteText &&
        <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>{noteText}</p>
          </div>
        </ModalFooter>
      }
    </Modal>
  )
}

export const SuccessModal = ({ index = 'kus', showModal, toggleModal, headerText, descriptionText, leftButtonText, leftButtonOnClick, rightButtonText, rightButtonOnClick, noteText, ...props }) => {
  return (
    <Modal isOpen={showModal} toggle={toggleModal} id={`success-modal-${index}`}>
      <button className="close" onClick={toggleModal}><em className="icon ni ni-cross"></em></button>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
          <h4 className="nk-modal-title fw-normal">{headerText}</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              {descriptionText}
            </div>
            {props.children}
          </div>
          <div className="nk-modal-action mt-5">
            {
              leftButtonText &&
              <Button color="dark" size="lg" className="btn-mw m-1" onClick={leftButtonOnClick}>
                {leftButtonText}
              </Button>
            }

            <Button color="light" size="lg" className="btn-mw m-1" onClick={toggleModal}>
              Close
            </Button>
          </div>
        </div>
      </ModalBody>
      {
        noteText &&
        <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>{noteText}</p>
          </div>
        </ModalFooter>
      }
    </Modal>
  )
}

export const SuccessConfettiModal = ({ index = 'kus', showModal, toggleModal, headerText, descriptionText, leftButtonText, leftButtonOnClick, rightButtonText, rightButtonOnClick, noteText, ...props }) => {
  return (
    <Modal isOpen={showModal} toggle={toggleModal} id={`success-modal-${index}`}>
      <button className="close" onClick={toggleModal}><em className="icon ni ni-cross"></em></button>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <lord-icon
            src="https://cdn.lordicon.com/tqywkdcz.json"
            trigger="loop"
            delay="500"
            colors="primary:#1663c7,secondary:#e86830,tertiary:#66a1ee,quaternary:#eeaa66"
            style={{width:"180px",height:"180px"}}>
          </lord-icon>
          <h4 className="nk-modal-title fw-normal">{headerText}</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              {descriptionText}
            </div>
            {props.children}
          </div>
          <div className="nk-modal-action mt-5">
            {
              leftButtonText &&
              <Button color="dark" size="lg" className="btn-mw m-1" onClick={leftButtonOnClick}>
                {leftButtonText}
              </Button>
            }

            <Button color="light" size="lg" className="btn-mw m-1" onClick={toggleModal}>
              Close
            </Button>
          </div>
        </div>
      </ModalBody>
      {
        noteText &&
        <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>{noteText}</p>
          </div>
        </ModalFooter>
      }
    </Modal>
  )
}

export const AlertModal = ({ index = 'kus', showModal, toggleModal, headerText, descriptionText, leftButtonText, leftButtonOnClick, rightButtonText, rightButtonOnClick, noteText, ...props }) => {
  return (
    <Modal isOpen={showModal} toggle={toggleModal} id={`alert-modal-${index}`}>
      <button className="close" onClick={toggleModal}><em className="icon ni ni-cross"></em></button>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-danger"></Icon>
          <h4 className="nk-modal-title fw-normal">{headerText}</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              {descriptionText}
            </div>
            {props.children}
          </div>
          <div className="nk-modal-action mt-5">
            {
              leftButtonText &&
              <Button color="danger" size="lg" className="btn-mw m-1" onClick={leftButtonOnClick}>
                {leftButtonText}
              </Button>
            }

            <Button color="light" size="lg" className="btn-mw m-1" onClick={toggleModal}>
              Close
            </Button>
          </div>
        </div>
      </ModalBody>
      {
        noteText &&
        <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>{noteText}</p>
          </div>
        </ModalFooter>
      }
    </Modal>
  )
}