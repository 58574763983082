import React, { useEffect, useState } from "react";
import { getAxiosHeaders, recommendedColors } from "../../../utils/Utils";
import {
  Icon
} from "../../../components/Component";
import { ColorPicker } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { Spinner } from "reactstrap";
import axios from 'axios';

const ColorTheme = ({ businessID, color, setHeaderColor, ...props }) => {
  const [colorHex, setColorHex] = useState(color);
  const [updateStatus, setUpdateStatus] = useState('none');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const saveSaleTheme = () => {
    setLoading(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/businesses/sales-theme/${businessID}`, {
      theme_color: colorHex
    }, getAxiosHeaders())
    .then((response) => {
      let businesses = JSON.parse(localStorage.getItem('my_businesses'));
      let index = businesses.findIndex(b => b.business_id == businessID);
      businesses[index].brand_color = colorHex;
      localStorage.setItem('my_businesses', JSON.stringify(businesses));

      setUpdateStatus('saved');
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  useEffect(() => {
    setHeaderColor(colorHex);
    setUpdateStatus(colorHex === color ? 'none' : 'draft');
  }, [colorHex])
  
  return (
    <div className="fm-head">
      <div className="fm-count d-none d-md-inline-flex"><Icon name="color-palette fs-22px"></Icon></div>
      <div className="fm-title w-100">
        <div className="d-flex flex-wrap justify-content-start">
          <span className="fs-15px me-3 align-self-center">Theme:</span>
          <ColorPicker showText={(color) => color.toHexString()}
            disabled={loading}
            presets={[
              {
                label: "Recommended",
                colors: recommendedColors()
              }
            ]}
            format={"hex"}
            value={colorHex}
            onChange={(value) => setColorHex(value.toHexString()) } />
          {
            updateStatus === "draft" &&
            <>
              {
                loading ?
                <button className="btn btn-sm btn-outline-light ms-3"><Spinner size="sm" color="light" /></button>
                :
                <button className="btn btn-sm btn-outline-light ms-3" onClick={saveSaleTheme}>Save Changes</button>
              }
            </>
          } 

          {
            updateStatus === "saved" &&
            <span className="text-success ps-2 pt-1 align-self-center"><Icon name="check-fill-c"/> saved</span>
          } 

          {
            updateStatus === "error" &&
            <span className="text-danger ps-2 pt-1 align-self-center"><Icon name="cross-fill-c"/> Failed</span>
          } 

        </div>
        {
          errorMessage && <p className="text-danger mt-1">{errorMessage}</p>
        }
      </div>
    </div>
  );
};


export default ColorTheme;