import React, { useState } from "react";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import Logo from "../../layout/logo/Logo";
import { Input, Form } from 'antd';
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, Icon, PreviewCard } from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const ForgotPassword = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");

  const handleFormSubmit = (_formData) => {
    setLoading(true);
    setFormError("");

    axios.post(baseURLs.API_URL + "/auth/request-password-reset", {email: _formData.email})
    .then((response) => {
      history.push(`${process.env.PUBLIC_URL}/forgot-password-success`)
    }).catch((error) => {
      setLoading(false);

      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);

      }catch(e){
        setFormError("Error sending reset password email.");
      }
    });
    
  };

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <React.Fragment>
      <Head title="Forgot Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Logo />
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent className="text-center mb-4">
                <BlockTitle tag="h4">Forgot your password?</BlockTitle>
                <BlockDes>
                  <p>If you forgot your password, don't worry, we’ll email you instructions to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {formError && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {formError}{" "}
                </Alert>
              </div>
            )}
            <Form form={form} className="is-alter"
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'email'}
                      noStyle
                      rules={[
                        {required: true, message: 'Email is required'},
                        {type: "email", message: 'Email is invalid'}
                      ]}>
                        <Input size="large"
                          type="email" className="form-control form-control-lg" 
                          placeholder="Enter your email"
                        />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="form-group">
                <Button type="submit" color="primary" size="lg" className="btn-block">
                  {loading ? <Spinner size="sm" color="light" /> : "Send Reset Link"}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
