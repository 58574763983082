import React, { useState, useEffect } from "react";
import { Empty, Input, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import imagePlaceholder from "../../../images/product_placeholder.png";
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Alert, Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Spinner } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ProductCategoryTable = ({ history, businessID, addedCategoryCount, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
    }, 
    categories: []
  });
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    category_name: ''
  });
  const [currentCategory, setCurrentCategory] = useState({
    category_id: '',
    category_name: ''
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.categories.map(item => item.category_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let category_name = filters.category_name.length > 0 ? `&c=${filters.category_name}` : ``;
    
    if(category_name !== '' && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${category_name}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getCategories(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      category_name: ''
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getCategories(params);
  }

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filterCategories = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getCategories(filters);
  }

  const getCategories = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/products/category", {
      params: {
        business_id: businessID,
        page: filters.page,
        category_name: filters.category_name
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          categories:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          categories:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const downloadCategories = (filters) => {
    setErrorMessage("");
    toggleModal('loadingModal');

    axios.get(baseURLs.API_URL + "/products/category/download", {
      params: {
        business_id: businessID,
        category_name: filters.category_name
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No category found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }


  const handleEditCategorySubmit = () => {
    if(currentCategory.category_name.length === 0){
      setErrorMessage("Category name is required");
      return;
    }

    setRequesting(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/products/category/${currentCategory.category_id}`, {
      business_id: businessID,
      category_name: currentCategory.category_name,      
    }, getAxiosHeaders())
    .then((response) => {
      setRequesting(false);
      setCurrentCategory({
        category_id: '',
        category_name: ''
      })
      toggleModal("editSuccessModal");

      getCategories(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const deleteCategoryByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + "/products/category/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        categories: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_category_ids;
      let failedIDs = responseInfo.data.failed_category_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getCategories(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getCategories(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `categories` : `category`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `categories` : `category`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `categories` : `category`}. The selected ${failedIDs.length > 1 ? `categories were` : `category was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteCategoryByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/products/category/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        category_name: filters.category_name
      }
    })
    .then((response) => {
      getCategories(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const handleSingleDelete = (categoryID) => {
    setCheckedBoxes([categoryID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  const editCategory = (category) => {
    setCurrentCategory({
      ...currentCategory,
      category_id: category.category_id,
      category_name: category.category_name
    })

    toggleModal('editModal');
  }

  useEffect(() => {
    if(addedCategoryCount > 0) {
      getCategories(filters);
    }
  }, [addedCategoryCount])

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let category_name = queryParams.hasOwnProperty('c') ? queryParams.c : '';

    let params = filters;
    params.page = page;
    params.category_name = category_name;

    setFilters(params);
    getCategories(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Categories` : `Category`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Category Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="category_name">
                                    Category Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="category_name" className="form-control form-control-lg" id="category_name" defaultValue={filters.category_name} />
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterCategories();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getCategories(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadCategories} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Categories"} descriptionText={"Please wait while your categories list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Categories Ready"} descriptionText={"Categories CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.categories.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No category found</h5><span>Add a category using the button below.</span>
                    </div>
                    {/* <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/category/add`}>
                        <button className="btn btn-outline-primary">Add a Category</button>
                      </Link>                    
                    </div> */}
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Category</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total Products</span>
                        </DataTableRow>
                        {/* <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow> */}
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Products</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Categories` : `Delete Category`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} categories. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected categories` : `this selected category`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteCategoryByFilter : deleteCategoryByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No category have been selected. Select a category and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `categories are` : `category is`} being deleted`} /> }
                        
                              { 
                                activeModal === "successDeleteModal" &&
                                <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={`Deleted`} descriptionText={`Category deleted successfully.`} 
                                />
                              }
                              
                              {
                                activeModal === "deleteErrorModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={"Error"} descriptionText={errorMessage}                       
                                />
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.categories.map((data, index) => {
                          
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.category_id)}
                                    onChange={() => handleCheckboxChange(data.category_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.category_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.category_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <div className="user-info">
                                    <span className={`tb-lead fw-normal`}>
                                      {data.category_name}{" "}
                                    </span>
                                    <div className="price d-md-none">Total Products: {data.total_products}</div>
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  {data.total_products}
                                </span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}?c=${data.category_name}`}>                                            
                                          <Icon name="package"></Icon>
                                          <span>View Products</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => editCategory(data)}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.category_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }

      {
        activeModal === "editModal" &&
        <Modal backdrop="static" isOpen={true} id="edit_category_modal">
          <ModalHeader
            toggle={() => toggleModal('editModal')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModal('editCategoryModal')}>
                <Icon name="cross" />
              </button>
            }>
            Edit Category
          </ModalHeader>
          <ModalBody>
            {errorMessage && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorMessage}{" "}
                </Alert>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label">Category Name <span className="text-danger">*</span></label>
                  </div>
                  <Input size="large" placeholder="eg. Bags" value={currentCategory.category_name} onChange={(e) => setCurrentCategory({...currentCategory, category_name: e.target.value})} />
                </div>
              </div>
            </div>          
          </ModalBody>
          <ModalFooter className="bg-lighter justify-content-center">
            <Button disabled={requesting} onClick={handleEditCategorySubmit} color="primary" size="md" className="btn-mw m-1">
              {requesting ? <Spinner size="sm" color="light" /> : "Save"}
            </Button>
            <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }

      { 
        activeModal === "editSuccessModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal('editSuccessModal')}
          headerText={"Updated"} descriptionText={"The category has been updated successfully."} />
      }
    </Block>
  );
};
