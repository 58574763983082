import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import moment from "moment";
import { SalesLineChart } from "./components/AnalyticsCharts";
import { SalesTable } from "./components/tables/SalesTable";
import receiptIcon from "../images/receipt.png";
import inventoryIcon from "../images/inventory.png";
import storefrontIcon from "../images/cart.png";
import customerIcon from "../images/customer.png";
import { CardLoader } from "../utils/Loaders";

const Dashboard = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSalesStats, setLoadingSalesStats] = useState(true);
  const [onboarding, setOnboarding] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [dashboardStats, setDashboardStats] = useState({});
  const [salesDuration, setSalesDuration] = useState("week");
  const [errorMessage, setErrorMessage] = useState("");

  const getOnboardingStatus = (businessID) => {
    axios.get(baseURLs.API_URL + `/businesses/onboarding`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let onboarding = responseInfo.data.onboarding_status;
      let onboardingStatus = onboarding.completed_record_sale || 
      onboarding.completed_add_product || 
      onboarding.completed_add_service || 
      onboarding.completed_add_customer;

      setOnboarding(onboardingStatus);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getDashboardStats = (businessID) => {
    setLoadingSalesStats(true);
    axios.get(baseURLs.API_URL + "/statistics/dashboard", {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;

      setDashboardStats(responseInfo.data);
      setLoadingSalesStats(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingSalesStats(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getOnboardingStatus(businessID);
    getDashboardStats(match.params.businessID);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Dashboard" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">      
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      {
        !onboarding ?
        <>
          <BlockHead size="sm">
            <div className="nk-block-between-md justify-content-center text-center">
              <BlockHeadContent>
                <div className="nk-block-head-sub"><span>Hi, {businessInfo.name}</span></div>
                <BlockTitle page tag="h2" className="mb-2">
                  Ready to Keep up?
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>Get started with one of the options below.</p>
                </BlockDes>
              </BlockHeadContent>
            </div>
          </BlockHead>
          
          <Block>
            <Row className="g-gs mt-4 justify-content-center">
              <Col lg="11">
                <Row>
                  <Col lg="4">
                    <div className="price-plan card card-bordered border-lighter text-center h-100">
                      <div className="card-inner">
                        <div className="price-plan-media"><img src={receiptIcon} alt="" /></div>
                        <div className="price-plan-info">
                          <h5 className="title">Create an Invoice</h5><span>Create and send an invoice, a quote, or a receipt to your customers.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Create Invoice / Receipt</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="price-plan card card-bordered border-lighter text-center h-100">
                      <div className="card-inner">
                        <div className="price-plan-media"><img src={inventoryIcon} alt="" /></div>
                        <div className="price-plan-info">
                          <h5 className="title">Add a Product</h5><span>Add products to your inventory effortlessly and manage stock levels. </span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Add a Product</button>
                          </Link>
                        </div>
                      </div>                      
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="price-plan card card-bordered border-lighter text-center h-100">
                      <div className="card-inner">
                        <div className="price-plan-media"><img src={storefrontIcon} alt="" /></div>
                        <div className="price-plan-info">
                          <h5 className="title">Setup Online Store</h5><span>Create an online store for your products and start selling online.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/storefront/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Setup Storefront</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div className="gap-lg"></div>

                    <a href={baseURLs.TUTORIAL_LINK} target="_blank">
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help">
                            <div className="nk-help-img m-0">
                              <lord-icon
                                src="https://cdn.lordicon.com/ciqmrlkr.json"
                                trigger="loop"
                                delay="2000"
                                // colors="primary:#3098fc,secondary:#ffffff"
                                style={{width:"100px",height:"100px"}}>
                              </lord-icon>
                            </div>
                            <div className="nk-help-text">
                              <h5> Step-by-Step Tutorials</h5>
                              <p className="text-soft">
                              Follow our step-by-step tutorials to understand and utilize every feature of Keepup Store. Make your business management seamless and efficient.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Block>
        </>
        :
        <>        
          <BlockHead size="sm">
            <div className="nk-block-between">
              <BlockHeadContent>
                <BlockDes className="text-soft">
                  <p>{businessInfo.name}</p>
                </BlockDes>
                <BlockTitle page tag="h3">
                  Dashboard
                </BlockTitle>
                
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="more-v"></Icon>
                  </Button>
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                            <Icon className="d-none d-sm-inline" name="link"></Icon>
                            <span>
                              Shortcuts
                            </span>
                            <Icon className="dd-indc" name="chevron-down"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}`}>                                  
                                  <Icon name="reports-alt"></Icon>
                                  <span>Create Invoice / Receipt</span>
                                </Link>
                              </li>
                              <li>
                                <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}`}>
                                  <Icon name="coins"></Icon>
                                  <span>View Sales</span>
                                </Link>
                              </li>
                              <li>
                                <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/add`}>                                  
                                  <Icon name="package"></Icon>
                                  <span>Add a Product</span>
                                </Link>
                              </li>
                              <li>
                                <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}`}>                                  
                                  <Icon name="pie"></Icon>
                                  <span>View Expenses</span>
                                </Link>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </div>
          </BlockHead>

          <Block>
            {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
            )}
          </Block>

          <Block>
            {
              loadingSalesStats ?
              <Row className="g-gs">
                <Col lg="7"><CardLoader /></Col>
                <Col lg="5"><CardLoader /></Col>
              </Row>
              :
              <Row className="g-gs">
                <Col lg="7">
                  <PreviewAltCard>
                    <div className="card-title-group pb-3 g-2">
                      <div className="card-title">
                        <h6 className="title">Total Revenue ({moment().format('Y')})</h6>
                        <p>Breakdown of your Revenue.</p>
                      </div>
                      <div className="card-tools shrink-0 ">
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-sm btn-white btn-dim btn-outline-light">
                            <Icon name="calender-date"></Icon>
                            <span className="text-capitalize">
                              This {salesDuration}
                            </span>
                            <Icon className="dd-indc" name="chevron-right"></Icon>
                          </DropdownToggle>
                          <DropdownMenu>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  href="#this-week"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSalesDuration("week");

                                  }}
                                >
                                  This Week
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  href="#this-month"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSalesDuration("month");
                                  }}
                                >
                                  This Month
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  href="#this-year"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSalesDuration("year");
                                  }}
                                >
                                  This Year
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    <div className="sales-stats">
                      <div className="nk-sale-data-group flex-md-nowrap g-4">
                        <div className="nk-sale-data">
                          <div className="title fw-bold">Total Revenue</div>
                          <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {dashboardStats?.sales_stats.charts.meta.total_year_sales}</div>
                          {/* <div className="change up">
                            <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                          </div> */}
                        </div>

                        {
                          salesDuration === "week" &&
                          <div className="nk-sale-data">
                            <div className="title fw-bold">Revenue this Week</div>
                            <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {dashboardStats?.sales_stats.charts.meta.total_week_sales}</div>
                            {/* <div className="change up">
                              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                            </div> */}
                          </div>
                        }

                        {
                          salesDuration === "month" &&
                          <div className="nk-sale-data">
                            <div className="title fw-bold">Revenue this Month</div>
                            <div className="amount"><small className="fs-16px">{businessInfo.currency} </small> {dashboardStats?.sales_stats.charts.meta.total_month_sales}</div>
                            {/* <div className="change up">
                              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                            </div> */}
                          </div>
                        }

                        {/* {
                          salesDuration === "year" &&
                          <div className="nk-sale-data">
                            <div className="title">Revenue this Year</div>
                            <div className="amount"><small className="fs-16px">{businessInfo.currency} </small> {dashboardStats?.sales_stats.charts.meta.total_year_sales}</div>
                          </div>
                        } */}
                      </div>
                      <div className="mt-5">
                        <SalesLineChart duration={salesDuration} chatData={dashboardStats?.sales_stats.charts.chart_info} />
                      </div>
                    </div>
                  </PreviewAltCard>
                  <PreviewAltCard >
                    <div className="nk-wg-action">
                      <div className="nk-wg-action-content w-100">
                        <div className="card-title">
                        {
                          dashboardStats?.profit_loss_stats.is_net_profit ? 
                          <>
                            <em className="icon ni ni-trend-up text-teal"></em>
                            <div className="title fw-bold">Profit This Month</div>
                          </>
                          :
                          <>
                            <em className="icon ni ni-trend-down text-danger"></em>
                            <div className="title fw-bold">Loss This Month</div>
                          </>
                        }
                        </div>
                        
                        <h4 className="amount mb-2 fw-normal">
                          {
                            dashboardStats?.profit_loss_stats.is_net_profit ?
                            <span className="text-success">
                              <small className="fs-16px">{businessInfo.currency} </small>{dashboardStats?.profit_loss_stats.net_profit} 
                            </span>
                            :
                            <span className="text-danger">
                              - <small className="fs-16px">{businessInfo.currency} </small>{dashboardStats?.profit_loss_stats.net_profit.replace('-', '')} 
                            </span>
                          }
                          <small className="text-muted fs-16px"> / {dashboardStats?.sales_stats.charts.meta.total_month_sales} <small> Revenue</small></small></h4>
                        <table className="analytics-map-data-list">
                          <tbody>
                            <tr className="analytics-map-data">
                              <td className="country fs-12px">Cost of Goods Sold</td>
                              <td className="amount fs-12px"><small className="text-muted">{businessInfo.currency} </small>{dashboardStats?.profit_loss_stats.cost_of_goods_sold}</td>
                            </tr>
                            <tr className="analytics-map-data">
                              <td className="country fs-12px">Operating Expenses</td>
                              <td className="amount fs-12px"><small className="text-muted">{businessInfo.currency} </small>{dashboardStats?.profit_loss_stats.operating_expenses.total_operating_expenses}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </PreviewAltCard>
                </Col>
                <Col lg="5">
                  <PreviewAltCard>
                    <div className="nk-wg-action">
                      <div className="nk-wg-action-content">
                        <em className="icon ni ni-coins"></em>
                        <div className="title fw-bold">Today's Revenue</div>
                        <h4 className="amount fw-normal"><small className="text-muted fs-16px">{businessInfo.currency} </small>{dashboardStats?.sales_stats.today_sales}</h4>
                      </div>
                    </div>
                    <hr className="mt-4 mb-4"/>
                    <div className="nk-wg-action">
                      <div className="nk-wg-action-content">
                        <em className="icon ni ni-percent"></em>
                        <div className="title fw-bold">Discounts This Year</div>
                        <h4 className="amount fw-normal">
                          {dashboardStats?.sales_stats.discounts.total_count}
                          <small className="text-muted fs-16px"> / {businessInfo.currency} {dashboardStats?.sales_stats.discounts.total_amount} </small>
                        </h4>
                      </div>
                    </div>
                    <hr className="mt-4 mb-4"/>
                    <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?t=invoice`} className="text-base">
                      <div className="nk-wg-action">
                        <div className="nk-wg-action-content">
                          <em className={classNames("icon ni ni-alert-c ", { "text-danger": dashboardStats?.sales_stats.total_balance !== '0.00'})}></em>
                          <div className="title fw-bold">Balance to Receive</div>
                          <h4 className="amount fw-normal"><small className="text-muted fs-16px">{businessInfo.currency} </small>{dashboardStats?.sales_stats.total_balance}</h4>
                        </div>
                      </div>
                    </Link>
                    <hr className="mt-4 mb-4"/>
                    <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}`} className="text-base">
                      <div className="nk-wg-action">
                        <div className="nk-wg-action-content w-100">
                          <em className="icon ni ni-package"></em>
                          <div className="title fw-bold">Products</div>
                          <h4 className="amount fw-normal">{dashboardStats?.inventory_stats.products_in_stock} <small className="text-muted fs-16px">Prod. In-Stock</small> </h4>
                          <table className="analytics-map-data-list">
                            <tbody>
                              <tr className="analytics-map-data text-success">
                                <td className="country fs-12px fw-bold">Value at Cost Price</td>
                                <td className="amount fs-12px"><small className="text-muted">{businessInfo.currency} </small>{dashboardStats?.inventory_stats.products_in_stock_amount_at_cost_price}</td>
                              </tr>
                              <tr className="analytics-map-data">
                                <td className="country fs-12px fw-bold">Value at Selling Price</td>
                                <td className="amount fs-12px"><small className="text-muted">{businessInfo.currency} </small>{dashboardStats?.inventory_stats.products_in_stock_amount_at_selling_price}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Link>
                    <hr className="mt-4 mb-4"/>
                    <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}`} className="text-base">
                      <div className="nk-wg-action">
                        <div className="nk-wg-action-content w-100">
                          <em className="icon ni ni-pie"></em>
                          <div className="title fw-bold">Expenses This Year</div>
                          <h4 className="amount fw-normal"><small className="text-muted fs-16px">{businessInfo.currency} </small>{dashboardStats?.expenses_stats.total_expenses_amount}</h4>
                        </div>
                      </div>
                    </Link>
                    <hr className="mt-4 mb-4"/>
                    <div className="nk-wg-action">
                      <div className="nk-wg-action-content">
                        <em className="icon ni ni-happy"></em>
                        <div className="title fw-bold">Customers</div>
                        <h4 className="amount fw-normal">{dashboardStats?.customers_stats.total_customers} </h4>
                      </div>
                    </div>
                    
                  </PreviewAltCard>
                </Col>
              </Row>
            }
          </Block>

          <SalesTable history={history} businessID={businessID} showFilter={false} showPagination={false} showStats={false} title={"Recent Sales"} salesData={businessInfo.sales_records} />
        </>
      }
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default Dashboard;