import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber, formatTo2DP, moneyToFloat } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import Numeral from 'react-numeral';
import DatePicker from "react-datepicker";
import { SuccessModal } from "./components/AlertModals";
import { AutoComplete, Divider, Input, Select, Space, InputNumber, Empty, Spin, Form, Upload } from 'antd';
import PhoneNumberInput from "./components/PhoneNumberInput";
import { CreateNewProductModal } from "./components/ProductModals";
import { CreateNewMaterialModal } from "./components/RawMaterialModal";

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

export const EditManufacturingOrder = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [fetchingMaterials, setFetchingMaterials] = useState(false);
  const [issueDate, setIssueDate] = useState(new Date());
  const [issueDateTime, setIssueDateTime] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [dueDateTime, setDueDateTime] = useState(new Date());
  const [productsList, setProductsList] = useState([]);
  const [rawMaterialsList, setRawMaterialsList] = useState([]);
  const [form] = Form.useForm();
  const [selectedMaterials, setSelectedMaterials] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const [itemsErrorMessage, setItemsErrorMessage] = useState("");
  const [itemFields, setItemFields] = useState([{
    items_name: '',
    quantity: '1'
  }]);
  const [updateCostPriceCheck, setUpdateCostPriceCheck] = useState(true);
  const [formData, setFormData] = useState({
    issue_date: new Date(),
    issue_date_time: new Date(),
    due_date: new Date(),
    due_date_time: new Date(),
    order_name: '',
    order_status: 'in_progress',
    product_name: '',
    product_quantity: 0,
    update_cost_price: false,
    items: [{
      items_name: '',
      quantity: '1'
    }],
    note: ''
  });
  const [summaryInfo, setSummaryInfo] = useState({
    unit_cost: 0,
    total_cost: 0
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");
    setItemsErrorMessage("");

    const formInfo = {};
    formInfo.business_id = businessID;
    
    let _issueDate = `${moment(_formData.issue_date).format("YYYY-MM-DD")} ${moment(_formData.issue_date_time).format("HH:mm")}`;
    formInfo.issue_date = _issueDate;

    let _dueDate = `${moment(_formData.due_date).format("YYYY-MM-DD")} ${moment(_formData.due_date_time).format("HH:mm")}`;
    formInfo.due_date = _dueDate;


    formInfo.items = JSON.stringify(_formData.items);
    formInfo.order_name = _formData.order_name;
    formInfo.product_name = _formData.product_name;
    formInfo.product_quantity = _formData.product_quantity;
    // formInfo.update_cost_price = updateCostPriceCheck ? "yes" : "no";
    formInfo.note = _formData.note;
    formInfo.order_status = _formData.order_status;

    axios.put(baseURLs.API_URL + `/manufacturing-orders/edit/${orderID}`, formInfo, getAxiosHeaders())
    .then((response) => {  
      // redirect to order details page
      let responseInfo = response.data;
      // let orderID = responseInfo.data.order_id;
      history.push(`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}/details/${orderID}`);
      return;

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          errorResponse.errors.hasOwnProperty("items") ? setItemsErrorMessage(errorResponse.errors.items) : setItemsErrorMessage("");
          form.setFields([
            {
              name: 'issue_date',
              errors: errorResponse.errors.hasOwnProperty("issue_date") ? [errorResponse.errors.issue_date] : [],
            },
            {
              name: 'due_date',
              errors: errorResponse.errors.hasOwnProperty("due_date") ? [errorResponse.errors.due_date] : [],
            },
            {
              name: 'order_name',
              errors: errorResponse.errors.hasOwnProperty("order_name") ? [errorResponse.errors.order_name] : [],
            },
            {
              name: 'product_name',
              errors: errorResponse.errors.hasOwnProperty("product_name") ? [errorResponse.errors.product_name] : [],
            },
            {
              name: 'product_quantity',
              errors: errorResponse.errors.hasOwnProperty("product_quantity") ? [errorResponse.errors.product_quantity] : [],
            },
            {
              name: 'items',
              errors: errorResponse.errors.hasOwnProperty("items") ? [errorResponse.errors.items] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            },
            {
              name: 'order_status',
              errors: errorResponse.errors.hasOwnProperty("order_status") ? [errorResponse.errors.order_status] : [],
            },
            {
              name: 'update_cost_price',
              errors: errorResponse.errors.hasOwnProperty("update_cost_price") ? [errorResponse.errors.update_cost_price] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);        
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const updateProductList = (productName) => {
    toggleModal('successProductModal');
    getProducts({businessID, productName});
  }

  const updateMaterialList = (MaterialName) => {
    toggleModal('successMaterialModal');
    getRawMaterials({businessID, MaterialName});
  }

  const getProducts = ({businessID, productName}) => {

    axios.get(baseURLs.API_URL + "/products/variants", {
      params: {
        business_id: businessID,
        product_name: productName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let products = responseInfo.data.products;

        setProductsList([...products]);
      }

      if(fetchingProducts){
        setFetchingProducts(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingProducts){
          setFetchingProducts(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getRawMaterials = ({businessID, materialName}) => {

    axios.get(baseURLs.API_URL + "/raw-materials", {
      params: {
        business_id: businessID,
        material_name: materialName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let materials = responseInfo.data.materials;

        setRawMaterialsList([...materials]);
      }

      if(fetchingMaterials){
        setFetchingMaterials(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingMaterials){
          setFetchingMaterials(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items', 'product_quantity'];

    console.log(changedFields);
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      updateSummary();
    }    
  }

  const updateSummary = () => {
    const items = form.getFieldValue('items') || [];
    let productQuantity = formatTo2DP(form.getFieldValue('product_quantity') || 0);

    // Calculate total
    let totalCost = items.reduce((total, item) => {
      const price = item.unit_cost || 0;
      const quantity = item.quantity || 0;
      return total + (price * quantity);
    }, 0);

    // Calculate unit cost price for the finished product
    let unitCostPrice = formatTo2DP(totalCost / productQuantity);

    setSummaryInfo({
      ...summaryInfo,
      total_cost: totalCost,
      unit_cost: unitCostPrice
    })
  }

  const debouncedFetchProducts = debounce(getProducts, 1000);
  const debouncedFetchMaterials = debounce(getRawMaterials, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);


  const handleProductChange = (productName) => {
    // Fetch more products when the user enters a product name not found in the initial list
    if (!productsList.some(product => product.product_name === productName)) {
      debouncedFetchProducts({businessID, productName});
      setFetchingProducts(true);
    }
  };

  const handleProductSelect = (value, option) => {
    const selectedProduct = productsList.find(product => product.product_name === value);
    
    if (selectedProduct) {
      let { product_name } = selectedProduct;
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        product_name 
      });
    }
  };

  const handleMaterialChange = (materialName) => {
    // Fetch more material when the user enters a material name not found in the initial list
    if (!rawMaterialsList.some(material => material.material_name === materialName)) {
      debouncedFetchMaterials({businessID, materialName});
      setFetchingMaterials(true);
    }
  };

  const handleMaterialSelect = (value, option, index) => {
    const selectedMaterial = rawMaterialsList.find(material => material.material_name === value);
    
    if (selectedMaterial) {
      let { material_name, unit_cost, unit } = selectedMaterial;

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_name: material_name,
        unit_price: moneyToFloat(unit_cost),
        quantity: 1,
        update_cost_price: false,
        unit: unit ?? 'pcs'
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleAddItem = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, {
      item_name: '',
      quantity: ''
    }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleRemoveItem = (index) => {
    const oldItemFields = form.getFieldValue("items");
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);
    setItemFields(newFields);

    form.setFieldsValue({
      items: newFields,
    });

    updateSummary();
  };

  const getManufacturingOrderDetails = (businessID, orderID) => {
    axios.get(baseURLs.API_URL + `/manufacturing-orders/${orderID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      } else {
        let responseInfo = response.data;
        let orderInfo = responseInfo.data.order_info;
        let _formData = formData;
  
        _formData.due_date = moment(orderInfo.due_date);
        _formData.issue_date = moment(orderInfo.order_date);
        

        let items = [];
        for(let i = 0; i < orderInfo.materials.length; i++) {
          items.push({
            item_name: orderInfo.materials[i].material_name,
            quantity: orderInfo.materials[i].quantity
          })
        }
        _formData.items = items;
        _formData.order_name = orderInfo.order_name;
        _formData.product_name = orderInfo.product_name;
        _formData.product_quantity = orderInfo.product_quantity;        
        _formData.order_status = orderInfo.order_status;
        _formData.note = orderInfo.notes;
        
        setItemFields(items);
        setUpdateCostPriceCheck(orderInfo.update_cost_price === 'yes' ? true : false)
        setIssueDate(moment(orderInfo.order_date).toDate());
        setIssueDateTime(moment(orderInfo.order_date).toDate());
        setDueDate(moment(orderInfo.due_date).toDate());
        setDueDateTime(moment(orderInfo.due_date).toDate());
        setFormData({..._formData});

        setSummaryInfo({
          ...summaryInfo,
          total_cost: moneyToFloat(orderInfo.total_cost),
          unit_cost: moneyToFloat(orderInfo.unit_cost)
        })
      }

      setLoading(false);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    const { materialNames } = itemFields.reduce(
      (acc, item) => {
        if (item.item_name) {
          const targetSet = acc.materialNames;
          targetSet.add(item.item_name);
        }
        return acc;
      },
      {
        materialNames: new Set(),
      }
    );
  
    setSelectedMaterials(materialNames);
  }, [itemFields]);

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const orderID = match.params.orderID;
    if ( !Number.isInteger(businessID) || !orderID) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    }

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setOrderID(orderID);
    getManufacturingOrderDetails(businessID, orderID);
    getProducts({businessID: businessID});
    getRawMaterials({businessID: businessID});
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Edit Manufacturing Order" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Edit Manufacturing Order
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/manufacturing-orders/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block className="wide-sm m-auto mt-5">
          {errorMessage && (
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorMessage}{" "}
            </Alert>
          )}
          <Form name="create-order"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFieldsChange={(changedFields, allFields) => {
              debouncedHandleChangedFields(changedFields, allFields);       
            }}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              {/* Order details section */}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="contact fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Order Information</h5>
                  <p className="nk-block-des">Enter the details of the manufacturing order.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Order Name <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'order_name'}
                            noStyle
                            rules={[
                              {required: true, message: `order name is required`}
                            ]}>
                            <Input size="large" type="text" className="form-control form-control-lg" placeholder="Maame's Order" />                              
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Issue Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'issue_date'}
                              noStyle
                              rules={[
                                { required: true, message: 'Issue Date is required', }
                              ]}>
                              <DatePicker
                                selected={issueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setIssueDate(date);
                                  form.setFieldsValue({issue_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'issue_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Issue Date time is required',}
                              ]}>
                              <DatePicker
                                selected={issueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setIssueDateTime(time);
                                  form.setFieldsValue({issue_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-group">
                      <Form.Item className="mb-0">
                        <div className="form-label-group">
                          <label className="form-label">Finished Product <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'product_name'}
                          rules={[{ required: true, message: 'Please enter a product name' }]}>
                          <Select
                            size="large"
                            onChange={(value) => handleProductChange(value)}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0', }} />
                                <Space style={{ padding: '0 8px 4px', }}>
                                  <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewProductModal')}}>
                                    <Icon name="plus" />
                                    <span>Create New Product</span>
                                  </Button>
                                </Space>
                              </>
                            )}
                            options={
                              productsList.map(prod => {
                                // Check if the product is selected in another field
                                return {
                                  key: prod.product_id,
                                  label: prod.product_name,
                                  value: prod.product_name
                                };
                              })
                            }
                            onSelect={(value, option) => handleProductSelect(value, option)}
                            onSearch={handleProductChange}
                            showSearch={true}
                            notFoundContent={
                              fetchingProducts ? 
                              <div className="text-center m-5">
                                <Spin/>
                              </div>
                              :
                              <div className="text-center m-5">
                                <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                              </div>
                            }
                            placeholder="Enter product name"
                            className="w-100"
                          />
                        </Form.Item>                  
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Quantity <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'product_quantity'}
                          noStyle
                          rules={[
                            {required: true, message: 'Quantity is required'}
                          ]}>
                          <InputNumber min={1} step={1} size="large" className="w-100" /> 
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group mt-lg-4">
                      <Form.Item>
                        <Form.Item name={'update_cost_price'}
                          noStyle
                          valuePropName="checked"
                          rules={[
                            {required: false}
                          ]}>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={form.getFieldValue('update_cost_price')}
                                onChange={() => {
                                  setUpdateCostPriceCheck(!updateCostPriceCheck);
                                  form.setFieldValue('update_cost_price', !updateCostPriceCheck);
                                }}
                                id="update_cost_price" />
                              <label className="custom-control-label" htmlFor="update_cost_price">
                                Update Cost Price
                                <Button type="button" id={`update_cost_price_info_popover`} className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                  <Icon name="info" />
                                </Button>
                                <UncontrolledPopover target={`update_cost_price_info_popover`} placement="bottom" trigger="focus">
                                  <PopoverBody>
                                    <p>Check this box if you want to recalculate and update the cost price in the inventory based on the provided values for this item in the manufacturing order.</p>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </label>
                            </div>
                          </div>
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* raw materials section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="list fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Materials Items</h5>
                  <p className="nk-block-des">Add the raw materials you are using for this production.</p>
                </div>
              </div>
              <div className="fm-content">
                {
                  itemsErrorMessage &&
                  <p className="text-danger">{itemsErrorMessage}</p>
                }
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-bordered card-full card">
                      {
                        itemFields.length === 0 ?
                        <div className="nk-block">
                          <div className="text-center m-5">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <span className="title fw-normal">No raw material added</span>
                            </div>
                            <div className="price-plan-action">
                              <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddItem()}>
                                <Icon name="plus-sm" />
                                <span>Add Raw Material</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        :
                        <ul className="nk-support">
                          {
                            itemFields.map((item, index) => {
                              return (
                              <li className="nk-support-item d-block" key={index}>
                                <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label"><b>#{index + 1}.</b> Raw Material Name <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['items', index, 'item_name']}
                                        rules={[{ required: true, message: 'Please enter a raw material name' }]}>
                                        <Select
                                          size="large"
                                          onChange={(value) => handleMaterialChange(value, index)}
                                          options={
                                            rawMaterialsList.map(material => {
                                              // Check if the raw material is selected in another field
                                              const isAlreadySelected = selectedMaterials.has(material.material_name) && material.material_name !== itemFields[index].item_name;
                                              const outOfStock = material.stock_status === 'out_of_stock' ? true : false;
                                              
                                              return {
                                                key: material.material_id,
                                                label: isAlreadySelected ? `${material.material_name} - (${outOfStock ? `Out-of-Stock - `: ``}Selected)` : `${material.material_name} ${outOfStock ? `- (Out-of-Stock)`: ``}`,
                                                value: material.material_name,
                                                disabled: isAlreadySelected || outOfStock, // Disable the option if it's already selected
                                              };
                                            })
                                          }
                                          onSelect={(value, option) => handleMaterialSelect(value, option, index)}
                                          onSearch={handleMaterialChange}
                                          showSearch={true}
                                          notFoundContent={
                                            fetchingMaterials ? 
                                            <div className="text-center m-5">
                                              <Spin/>
                                            </div>
                                            :
                                            <div className="text-center m-5">
                                              <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                            </div>
                                          }
                                          placeholder="Enter raw material name"
                                          className="w-100"
                                        />
                                      </Form.Item>
                                    </div>                                        
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                        <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['items', index, 'quantity']}
                                        noStyle
                                        rules={[
                                          {required: true, message: 'Quantity is required'}
                                        ]}>
                                        <InputNumber suffix={item.unit} min={0} step={0.01} max={rawMaterialsList.find(material => material.material_name === item.item_name)?.quantity ?? {}} size="large" className="w-100" /> 
                                      </Form.Item>                                     
                                    </Form.Item>
                                    </div>
                                  </div>

                                </div>
                              </li>);
                            })
                          }
                        </ul>                            
                      }
                    </div>                    
                  </div>
                  {
                    itemFields.length > 0 && 
                    <div className="col-md-12 text-center">
                      <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddItem()} >
                        <Icon name="plus-sm"></Icon>
                        <span>Add Raw Material</span>
                      </Button>
                    </div>
                  }
                </div>
              </div>

              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="note-add fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Order Status and Note</h5>
                  <p className="nk-block-des">Add order status and note for this order.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Order Status <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'order_status'}
                            noStyle
                            rules={[
                              {required: true, message: `Select order status`}
                            ]}>
                            <Select required size="large" 
                              placeholder="Select order status"
                              style={{ width: "100%" }} 
                              options={[
                                {
                                  key: 'in_progress',
                                  label: 'In Progress',
                                  value: 'in_progress',
                                },
                                {
                                  key: 'on_hold',
                                  label: 'On Hold',
                                  value: 'on_hold',
                                },
                                {
                                  key: 'ready_for_pickup',
                                  label: 'Ready for pickup',
                                  value: 'ready_for_pickup',
                                },
                                {
                                  key: 'in_transit',
                                  label: 'In Transit',
                                  value: 'in_transit',
                                }, 
                                {
                                  key: 'delivered',
                                  label: 'Delivered',
                                  value: 'delivered',
                                },                              
                              ]}
                              showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Due Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'due_date'}
                              noStyle
                              rules={[
                                { required: true, message: 'Due Date is required', }
                              ]}>
                              <DatePicker
                                selected={dueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setDueDate(date);
                                  form.setFieldsValue({due_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'due_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Due Date time is required',}
                              ]}>
                              <DatePicker
                                selected={dueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setDueDateTime(time);
                                  form.setFieldsValue({due_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Note <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'note'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                            <TextArea rows={4} placeholder="Note" />
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              

              <div className="fm-content bg-lighter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="nk-iv-scheme-details">
                      <ul className="nk-iv-wg3-list w-100">
                        {/* <li className="px-0 py-2 fw-medium">
                          <div className="sub-text fs-16px">Unit Cost</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-16px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={summaryInfo.unit_cost.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li> */}
                        <li className="px-0 py-2 fw-bold">
                          <div className="sub-text fs-18px">Total Cost</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-18px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={summaryInfo.total_cost.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        

              <div className="fm-footer">
                <div className="text-center">
                  <Button disabled={requesting} className="m-1" type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : <span>Save</span>}
                  </Button>
                </div>
              </div>
            </div>

          </Form>
          
        </Block>
      </>
    }

    { 
      activeModal === "createNewProductModal" && 
      <CreateNewProductModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewProductModal')} updateProductList={updateProductList} showModal={true}  /> 
    }

    { 
      activeModal === "createNewMaterialModal" && 
      <CreateNewMaterialModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewMaterialModal')} updateMaterialList={updateMaterialList} showModal={true}  /> 
    }

    { 
      activeModal === "successProductModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The product has been created successfully.`} 
      />
    }

    { 
      activeModal === "successMaterialModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The raw material has been created successfully.`} 
      />
    }

    </Content>
    
  </React.Fragment>)
}