import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const CustomerFeedbackTable = ({ history, businessID, page, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, feedback:[]});
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    customer_search: '',
    phone_search: '',
    email_search: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  });

  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const currentUrl = (filters) => {
    let customer_search = filters.customer_search.length > 0 ? `&cs=${filters.customer_search}` : ``;
    let phone_search = filters.phone_search.length > 0 ? `&ps=${filters.phone_search}` : ``;
    let email_search = filters.email_search.length > 0 ? `&es=${filters.email_search}` : ``;
    let date_range = filters.date_range ? `&d=${filters.date_range}` : ``;
    
    if( (customer_search !== '' ||
    phone_search !== '' ||
    email_search !== '' ||
    date_range !== '') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${customer_search}${phone_search}${email_search}${date_range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getFeedbacks(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      customer_search: '',
      phone_search: '',
      email_search: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getFeedbacks(params);
  }

  const filterFeedbacks = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getFeedbacks(filters);
  }

  const getFeedbacks = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/customers/feedback", {
      params: {
        business_id: businessID,
        page: filters.page,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        email: filters.email_search,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, feedback:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, customers:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let customer_search = queryParams.hasOwnProperty('cs') ? queryParams.cs : '';
    let phone_search = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let email_search = queryParams.hasOwnProperty('es') ? queryParams.em : '';
    let date_range = queryParams.hasOwnProperty('d') ? queryParams.d : null;
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setFromDate(dateRangeStart);
      setToDate(dateRangeEnd);
    }

    let params = filters;
    params.page = page;
    params.customer_search = customer_search;
    params.phone_search = phone_search;
    params.email_search = email_search;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;

    setFilters(params);
    getFeedbacks(params);

  }, [window.location.href]);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Feedbacks` : `Feedback`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter Sales">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Feedback Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="customer_search">
                                    Customer Name / Customer ID
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="customer_search" className="form-control form-control-lg" id="customer_search" defaultValue={filters.customer_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Phone Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="phone_search" className="form-control form-control-lg" id="phone_search" defaultValue={filters.phone_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Email
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="email_search" className="form-control form-control-lg" id="email_search" defaultValue={filters.email_search} />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">From Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={fromDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'from');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">To Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={toDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'to');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterFeedbacks();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.feedback.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No feedback found</h5><span>Add a customer using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Customer</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow>
                          <span className="sub-text">Feedback</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <span className="sub-text"><em className="icon ni ni-more-h"></em></span>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.feedback.map((data, index) => {

                          return (
                            <DataTableItem key={index}>
                              <DataTableRow>                                
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <span className="text-gray me-2">
                                    <em className={`icon ni ${data.rate === null && `ni-happy text-light`} ${data.rate === "bad" && `ni-sad text-danger`} ${data.rate === "okay" && `ni-meh text-dark`} ${data.rate === "excellent" && `ni-happy text-success` }`} style={{fontSize: "40px", lineHeight: "1.5"}}></em>
                                  </span>
                                    <div className="user-info">
                                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/profile/${data.customer_id}`}>
                                        <span className={`tb-lead ${!data.customer_name && `fw-normal`}`}>
                                          {data.customer_name ? data.customer_name : `Customer`}{" "}                                        
                                        </span>
                                      </Link>
                                      <div><small>Satisfaction: <b>{data.rate}</b></small></div>
                                      <div><small>Message: <b>{data.message}</b></small></div>
                                      <div><small>{moment(data.created_at).format("Do MMM YYYY")}</small></div>
                                    </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`}>                                            
                                          <Icon name="coins"></Icon>
                                          <span>View Sale</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/profile/${data.customer_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View Customer</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/messages/b/${businessID}/compose/${data.customer_id}`}>
                                          <Icon name="msg"></Icon>
                                          <span>Send Message</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <a href={`tel:${data.customer_phone_number}`}>
                                          <Icon name="call"></Icon>
                                          <span>Call</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
