import React, { useState, useEffect } from "react";
import Icon from "../../components/icon/Icon";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import { getAxiosHeaders, getPhoneCodeAndNumber } from "../../utils/Utils";
import {
  Alert,
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import { Empty, Input, Select, InputNumber, Divider, Form } from 'antd';


export const CreateTaxProfileModal = ({ businessID, showModal, toggleModal, updateTaxProfiles, ...props }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [taxFields, setTaxFields] = useState([]);
  const [formData, setFormData] = useState({
    tax_profile_name: '',
    taxes: [],
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddTax = () => {
    const oldFields = form.getFieldValue("taxes") || [];
    const newFields = [...oldFields, {tax_name: ''}];

    // Update fields state
    setTaxFields(newFields);

    // Update form fields
    form.setFieldsValue({
      taxes: newFields,
    });
  };

  const handleRemoveTax = (index) => {
    const oldFields = form.getFieldValue("taxes") || [];
    const newFields = [...oldFields];
    newFields.splice(index, 1);
    setTaxFields(newFields);

    form.setFieldsValue({
      taxes: newFields,
    });
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/businesses/taxes/${businessID}`, {
      tax_profile_name: _formData.tax_profile_name,
      taxes: JSON.stringify(_formData.taxes)
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      updateTaxProfiles();
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'tax_profile_name',
              errors: errorResponse.errors.hasOwnProperty("tax_profile_name") ? [errorResponse.errors.tax_profile_name] : [],
            },
            {
              name: 'taxes',
              errors: errorResponse.errors.hasOwnProperty("taxes") ? [errorResponse.errors.taxes] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  useEffect(() => {
    if(taxFields.length === 0){
      handleAddTax();
    }
  }, [taxFields])

  return (
    <Modal backdrop="static" isOpen={showModal} id="create_tax_modal">
      <ModalHeader
        toggle={toggleModal}
        close={
          <button className="close" disabled={requesting} onClick={toggleModal}>
            <Icon name="cross" />
          </button>
        }
      >
        Create a Tax Profile
      </ModalHeader>
      <Form name="add-tax-profile"
          form={form} className="is-alter"
          initialValues={formData} 
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
            <div className="form-group">
              <Form.Item>
                <label className="form-label">
                  Tax Profile Name
                </label>
                <Form.Item name={'tax_profile_name'}
                  noStyle
                  rules={[
                    {required: true, message: 'Tax profile name is required',}
                  ]}>
                  <Input size="large" placeholder="eg. Standard Taxation" />
                </Form.Item>
              </Form.Item>
            </div>
            <div className="col-md-12 mt-5">
              <hr className="m-3 mx-0" />
              <h6 className="overline-title text-primary-alt my-4">Taxes</h6>
            </div>
            <div className="col-md-12">
              <div className="card-bordered card-full card">
                {
                  taxFields.length === 0 ?
                  <div className="nk-block">
                    <div className="text-center m-5">
                      <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">No tax added</span>
                      </div>
                      <div className="price-plan-action">
                        <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddTax()}>Add Tax</button>
                      </div>
                    </div>
                  </div>
                  :
                  <ul className="nk-support">
                    {
                      taxFields.map((tax, index) => (
                        <li className="nk-support-item" key={index}>
                          {
                            index > 0 && <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveTax(index)}><Icon name="trash"/><span>Remove</span></button>
                          }
                          
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">#{index + 1}. Tax Name <span className="text-danger">*</span></label>
                                </div>
                                <Form.Item name={['taxes', index, 'tax_name']}
                                  rules={[{ required: true, message: 'Please enter a tax name' }]}>
                                  <Input type="text" placeholder="eg. VAT" />
                                </Form.Item>
                              </div>                                        
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">Percentage <span className="text-danger">*</span></label>
                                </div>
                                <Form.Item name={['taxes', index, 'percentage']}
                                  rules={[{ required: true, message: 'Please enter a percentage' }]}
                                >
                                  <InputNumber min={0} max={100} step={0.01} addonAfter="%" placeholder="eg. 12" />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">Tax Type 
                                    <span className="text-danger">*</span>
                                    <Button type="button" id="exp_alert_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                      <Icon name="info" />
                                    </Button>
                                    <UncontrolledPopover target="exp_alert_info_popover" placement="bottom" trigger="focus">
                                      <PopoverBody>
                                        Select 'Compound Tax' if this tax affects the total amount including previously applied taxes. Choose 'Direct Tax' if this tax is applied directly to the subtotal of the items in the sale.
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </label>
                                </div>
                                <Form.Item name={['taxes', index, 'type']}
                                  noStyle
                                  rules={[
                                    {required: true, message: `Tax type is required`}
                                  ]}>
                                  <Select 
                                    placeholder="eg. Compound"
                                    style={{ width: "100%" }} 
                                    options={[
                                      {
                                        key: 'compound',
                                        label: 'Compound Tax',
                                        value: 'compound',
                                      },
                                      {
                                        key: 'direct',
                                        label: 'Direct Tax',
                                        value: 'direct',
                                      },                              
                                    ]}
                                    showSearch />   
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    }
                  </ul>                            
                }
              </div>
              
            </div>
            {
              taxFields.length > 0 && 
              <div className="col-md-12 text-center mt-4">
                <Button className="m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddTax()} >
                  <span>Add Tax</span>
                </Button>
              </div>
            }
          
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}