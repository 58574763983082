import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, orderStatusOptions } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody,
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const PayoutTransactionsTable = ({ history, businessID, currency, batchNumber, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      total_payout: 0    
    }, 
    transactions: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    sale_number_search: ''
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const currentUrl = (filters) => {
    let sale_number_search = filters.sale_number_search.length > 0 ? `&b=${filters.sale_number_search}` : ``;
    
    if( sale_number_search !== '' && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${sale_number_search}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getPayoutTransactions(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      sale_number_search: ''
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getPayoutTransactions(params);
  }

  const filterTransactions = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getPayoutTransactions(filters);
  }

  const getPayoutTransactions = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/payments/payouts/${batchNumber}`, {
      params: {
        business_id: businessID,
        page: filters.page,
        sale_number: filters.sale_number_search
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_payout: 0,
          }, transactions:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_payout: 0,
          }, transactions:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let sale_number_search = queryParams.hasOwnProperty('s') ? queryParams.s : '';
    

    let params = filters;
    params.page = page;
    params.sale_number_search = sale_number_search;
    
    setFilters(params);
    getPayoutTransactions(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <div className="row g-gs mb-5">
            <div className="col-md-12">
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-warning">Total Transactions</div>
                      <div className="amount">
                        {tableData.meta.total_records}
                      </div>
                    </div>
                    <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-success">Total Payout</div>
                      <div className="amount">
                        <small className="text-muted fw-light me-1">{currency}</small>
                        {tableData.meta.total_payout}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>   
            </div>
          </div>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Payouts` : `Payout`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Payout Transactions Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="sale_number_search">
                                    Sale Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="sale_number_search" className="form-control form-control-lg" id="sale_number_search" defaultValue={filters.sale_number_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterTransactions();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your payout transactions.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getPayoutTransactions(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.transactions.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No transaction found</h5>
                    </div>
                  </div>
                  :
                  <>                   
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow size="md">
                          <span className="sub-text">Sale No.#</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Customer</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Amount</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Collection Fee</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <Icon name="more-h"></Icon>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.transactions.map((data, index) => {
                          let status = data.status === 'paid' ? 
                          <Badge className="badge-dot me-2" color="success">Paid</Badge> 
                          : <Badge className="badge-dot me-2" color="warning">Pending</Badge>;                          

                          return (
                            <DataTableItem key={index}>
                              <DataTableRow size="md">
                                <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary">#{data.sale_number}</div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary d-md-none">#{data.sale_number}</div>
                                      <span className="text-dark fw-bold">{data.customer_name}</span> <br/>
                                      <div className={`price d-md-none`}>
                                        <span>
                                          {status}
                                          <span className="tb-amount">
                                            Amount: <small className="text-muted">{currency} </small>{data.amount}
                                          </span>

                                          <span className="tb-amount">
                                            Collection Fee: <small className="text-muted">{currency} </small>{data.collection_fee}
                                          </span>
                                        
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small className="text-muted">{currency} </small>{data.amount}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small className="text-muted">{currency} </small>{data.collection_fee}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{moment(data.created_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                    
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
