import React from "react";
import classNames from "classnames";
import LogoLight from "../../images/kus_logo_light.png";
import LogoDark from "../../images/kus_logo_dark.png";
import { Link } from "react-router-dom";

const Logo = ({ className, size, ...props }) => {
  const lightLogoClass = classNames({
    "logo-light logo-img": true,
    [`logo-img-${size}`]: size,
    [`${className}`]: className,
  });

  const darkLogoClass = classNames({
    "logo-dark logo-img": true,
    [`logo-img-${size}`]: size,
    [`${className}`]: className,
  });
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className={lightLogoClass} src={LogoLight} alt="logo" />
      <img className={darkLogoClass} src={LogoDark} alt="logo"/>
    </Link>
  );
};

export default Logo;
