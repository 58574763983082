import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import { ProductsTable } from "./components/tables/ProductsTable";

const Products = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [usage, setUsage] = useState({used: 0, total: 0, package: ''});

  const getUsage = (businessID) => {
    setLoadingUsage(true);
    axios.get(baseURLs.API_URL + `/businesses/subscription-usage`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let subscriptionPackage = responseInfo.data.plan_name;
      let inventory = responseInfo.data.inventory;
      setUsage({
        used: inventory.used, 
        total: inventory.total,
        package: subscriptionPackage
      });
      setLoadingUsage(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
        setLoadingUsage(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getUsage(businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Products" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      <BlockHead size="sm">
        <div className="nk-block-between">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              Products
            </BlockTitle>
            
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    {
                      loadingUsage ?
                      <button className={`btn btn-outline-light`}>
                        <span>Usage: <b>...</b></span>
                        <Icon name="meter" />
                      </button>
                      :
                      <Link to={`${process.env.PUBLIC_URL}/subscription/b/${businessID}`}>
                        <button className={`btn btn-outline-${usage.used >= usage.total ? `danger` : `light`}`}>
                          {
                            usage.package === 'expansion' ?
                            <span>Usage: <b>{usage.used}</b></span>
                            :
                            <span>Usage: <b>{usage.used}</b> <small>/ {usage.total}</small> </span>
                          }
                          <Icon name="meter" />
                        </button>
                      </Link>
                    }
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/add`}>
                      <Button color="primary">
                        <Icon name="package"></Icon>
                        <span>Add Product</span>
                      </Button>
                    </Link>
                  </li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/import`}>
                              <Icon name="upload-cloud"></Icon>
                              <span>Import Products</span>
                            </Link>
                          </li>
                          <li className="divider"></li>                          
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/category`}>
                              <Icon name="list-thumb"></Icon>
                              <span>Manage Category</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/analytics`}>
                              <Icon name="trend-up"></Icon>
                              <span>Product Performance</span>
                            </Link>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </div>
      </BlockHead>

      <ProductsTable history={history} businessID={businessID} currency={businessInfo.currency} />
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default Products;