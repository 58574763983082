import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';

export const SalesLineChart = ({ duration, chatData, currency = "GHS" }) => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    let dataObject;
    let labelObject;

    if(duration === 'week'){
      labelObject = chatData?.week_chart.labels;
      dataObject = chatData?.week_chart.data;

    } else if(duration === 'month'){
      labelObject = chatData?.month_chart.labels;
      dataObject = chatData?.month_chart.data;

    } else if(duration === 'year') {
      labelObject = chatData?.year_chart.labels;
      dataObject = chatData?.year_chart.data;
      
    } else {
      labelObject = chatData?.period_chart.labels;
      dataObject = chatData?.period_chart.data;
      
    }

    let optionsObject = {
      tooltip: {
        style: {
          fontSize: '12px'
        },
        theme: localStorage.getItem("theme") === null ? "light" : localStorage.getItem("theme"),
        y: {
            formatter: function (val) {
              val = parseInt(val) >= 1000 ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val;
              return `${currency} ${val}`
            }
        }
      },
      stroke: {
        curve: 'straight',
        show: true,
        width: 3,
      },
      chart: {
        id: "sales_chart",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: labelObject,
        labels: {
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          style: {
            fontSize: '10px',
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            val = parseInt(val) >= 1000 ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val;
            return val;
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 4,
      },
      grid: {
        borderColor: '#EFF2F5',
        strokeDashArray: 4,
        yaxis: {
          lines: {
              show: !0
          }
        }
      },
      states: {
        normal: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        hover: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
                value: 0
            }
        }
      }
    }

    setData([{
      name: "Sales",
      data: dataObject
    }]);
    setOptions(optionsObject);
  }, [duration, localStorage.getItem("theme")]);

  return (
    <Chart options={options} series={data} type="area" width={"100%"} />
  )
}

export const StorefrontOrdersLineChart = ({ duration, chatData, currency = "GHS" }) => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    let amountDataObject;
    let countDataObject;
    let labelObject;

    if(duration === 'week'){
      labelObject = chatData?.week_chart.labels;
      amountDataObject = chatData?.week_chart.amount_data;
      countDataObject = chatData?.week_chart.count_data;

    } else if(duration === 'month'){
      labelObject = chatData?.month_chart.labels;
      amountDataObject = chatData?.month_chart.amount_data;
      countDataObject = chatData?.month_chart.count_data;

    } else if(duration === 'year') {
      labelObject = chatData?.year_chart.labels;
      amountDataObject = chatData?.year_chart.amount_data;
      countDataObject = chatData?.year_chart.count_data;
      
    } else {
      labelObject = chatData?.period_chart.labels;
      amountDataObject = chatData?.period_chart.amount_data;
      countDataObject = chatData?.period_chart.count_data;
      
    }

    let optionsObject = {
      tooltip: {
        style: {
          fontSize: '12px'
        },
        theme: localStorage.getItem("theme") === null ? "light" : localStorage.getItem("theme"),
        y: [
          {
            formatter: function (val) {
              val = parseInt(val) >= 1000 ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val;
              return `${currency} ${val}`
            }
          },
          {
            formatter: function (val) {
              // No currency prefix for this data set
              return parseInt(val) >= 1000 ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val;
            }
          }
        ]
      },
      colors: ['#00E396', '#008FFB'],
      stroke: {
        curve: 'straight',
        show: true,
        width: 3,
      },
      chart: {
        id: "sales_chart",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: labelObject,
        labels: {
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          style: {
            fontSize: '10px',
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            val = parseInt(val) >= 1000 ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val;
            return val;
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 4,
      },
      grid: {
        borderColor: '#EFF2F5',
        strokeDashArray: 4,
        yaxis: {
          lines: {
              show: !0
          }
        }
      },
      states: {
        normal: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        hover: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
                value: 0
            }
        }
      }
    }

    setData([
      { name: "Total Amount", data: amountDataObject },
      { name: "Number of Orders", data: countDataObject },
    ]);
    setOptions(optionsObject);
  }, [duration, localStorage.getItem("theme")]);

  return (
    <Chart options={options} series={data} type="area" width={"100%"} />
  )
}