import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber, formatTo2DP, moneyToFloat } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import Numeral from 'react-numeral';
import DatePicker from "react-datepicker";
import { SuccessModal, LoadingModal, AlertModal } from "./components/AlertModals";
import { AutoComplete, Empty, Input, Select, InputNumber, Divider, Space, Spin, Form, Upload } from 'antd';
import PhoneNumberInput from "./components/PhoneNumberInput";

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

export const EditExpense = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [expenseID, setExpenseID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [fetchingSuppliers, setFetchingSuppliers] = useState(false);
  const [suppliersList, setSuppliersList] = useState([]);
  const [issueDate, setIssueDate] = useState(new Date());
  const [issueDateTime, setIssueDateTime] = useState(new Date());
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSupplierSection, setShowSupplierSection] = useState(false);
  const [itemFields, setItemFields] = useState([{
    expense_name: '',
    category: '',
    total_cost: ''
  }]);
  const [formData, setFormData] = useState({
    expense_title: `Expenses on ${moment().format("ddd Do MMM, YYYY")}`,
    issue_date: new Date(),
    issue_date_time: new Date(),
    supplier_name: '',
    phone_code: "+233",
    phone_number: '',
    email: '',
    supplier_other_info: '',
    items: [{
      expense_name: '',
      category: '',
      total_cost: '0'
    }],
    discount_amount: 0,
    tax_amount: 0,
    amount_paid: 0,
    payment_type: 'cash',
    note: ''
  });
  const [expenseSummary, setExpenseSummary] = useState({
    sub_total: 0,
    tax_amount: 0,
    discount_amount: 0,
    total_amount: 0,
    amount_paid: 0,
    balance_due: 0
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    const formInfo = new FormData();
    fileList.forEach((file) => {
      formInfo.append('attachment', file);
    });
    formInfo.set("business_id", businessID);
    formInfo.set("expense_title", _formData.expense_title);
    
    let _issueDate = `${moment(_formData.issue_date).format("YYYY-MM-DD")} ${moment(_formData.issue_date_time).format("HH:mm")}`;
    formInfo.set("issue_date", _issueDate);
    if(showSupplierSection) {
      let phoneNumber = '';
      if(_formData.phone_code && _formData.phone_number){
        phoneNumber = `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`;
      }

      formInfo.set("supplier_name", _formData.supplier_name);
      formInfo.set("phone_number", phoneNumber);
      formInfo.set("email", _formData.email);
      formInfo.set("supplier_other_info", _formData.supplier_other_info);
      formInfo.set("discount_amount", _formData.discount_amount);
      formInfo.set("tax_amount", _formData.tax_amount);
      formInfo.set("amount_paid", _formData.amount_paid);
      formInfo.set("payment_type", _formData.payment_type);
    }
    formInfo.set("items", JSON.stringify(_formData.items));
    formInfo.set("note", _formData.note);

    axios.put(baseURLs.API_URL + `/expenses/edit/${expenseID}`, formInfo, getAxiosUploadHeaders())
    .then((response) => {  
      // redirect to expense details page
      let responseInfo = response.data;
      let expenseID = responseInfo.data.expense_id;
      history.push(`${process.env.PUBLIC_URL}/expenses/b/${businessID}/details/${expenseID}`);
      return;

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'expense_title',
              errors: errorResponse.errors.hasOwnProperty("expense_title") ? [errorResponse.errors.expense_title] : [],
            },
            {
              name: 'issue_date',
              errors: errorResponse.errors.hasOwnProperty("issue_date") ? [errorResponse.errors.issue_date] : [],
            },
            {
              name: 'supplier_name',
              errors: errorResponse.errors.hasOwnProperty("supplier_name") ? [errorResponse.errors.supplier_name] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone_number] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            },
            {
              name: 'supplier_other_info',
              errors: errorResponse.errors.hasOwnProperty("supplier_other_info") ? [errorResponse.errors.supplier_other_info] : [],
            },
            {
              name: 'items',
              errors: errorResponse.errors.hasOwnProperty("items") ? [errorResponse.errors.items] : [],
            },
            {
              name: 'discount_amount',
              errors: errorResponse.errors.hasOwnProperty("discount_amount") ? [errorResponse.errors.discount_amount] : [],
            },
            {
              name: 'tax_amount',
              errors: errorResponse.errors.hasOwnProperty("tax_amount") ? [errorResponse.errors.tax_amount] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            },
            {
              name: 'payment_type',
              errors: errorResponse.errors.hasOwnProperty("payment_type") ? [errorResponse.errors.payment_type] : [],
            },
            {
              name: 'amount_paid',
              errors: errorResponse.errors.hasOwnProperty("amount_paid") ? [errorResponse.errors.amount_paid] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);        
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const sortByCategoryTypeAndCategory = (arr) => {
    return arr.sort((a, b) => {
      // First, sort by category_type
      if (a.category_type !== b.category_type) {
        return a.category_type.localeCompare(b.category_type);
      }
      // If category_type is the same, sort by category
      return a.category.localeCompare(b.category);
    });
  }

  const getCategory = (businessID) => {
    return axios.get(baseURLs.API_URL + "/expenses/category", {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let category = responseInfo.data.category;
      const sortedCategory = sortByCategoryTypeAndCategory(category);
      setCategories([...sortedCategory]);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const addNewCategory = (e) => {
    e.preventDefault();
    
    if(newCategory) {
      setRequesting(true);
      setErrorMessage("");
      setActiveModal("loadingModal");

      axios.post(baseURLs.API_URL + `/expenses/category/${newCategory}`, {
        business_id: businessID
      }, getAxiosHeaders())
      .then((response) => {
        setActiveModal("successModal");
        setNewCategory("");
        getCategory(businessID);
        setRequesting(false);
      }).catch((error) => {
        try{
          let errorResponse = error.response.data;

          let errorMessage = 'Error: Could not connect to server';
          if(errorResponse.hasOwnProperty("error")){
            errorMessage = errorResponse.error;
          }

          setErrorMessage(errorMessage);     
          setActiveModal("alertModal");   
        }catch(e){
          setErrorMessage("Error: Could not connect to server");
        }
        setRequesting(false);
      });
    }
  }

  const getSuppliers = ({businessID, supplierName}) => {

    return axios.get(baseURLs.API_URL + "/suppliers", {
      params: {
        business_id: businessID,
        supplier: supplierName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let suppliers = responseInfo.data.suppliers;

        setSuppliersList([...suppliers]);
      }

      if(fetchingSuppliers){
        setFetchingSuppliers(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingSuppliers){
          setFetchingSuppliers(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items', 'discount_amount', 'tax_amount', 'amount_paid'];
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      updateSummary();
    }    
  }

  const updateSummary = () => {
    const items = form.getFieldValue('items') || [];
    let taxAmount = formatTo2DP(form.getFieldValue('tax_amount') || 0);
    let discountAmount = formatTo2DP(form.getFieldValue('discount_amount') || 0);
    let amountPaid = formatTo2DP(form.getFieldValue('amount_paid') || 0);

    // Calculate Subtotal
    let subTotal = items.reduce((total, item) => {
      const price = parseFloat(item.total_cost) || 0;
      return total + price;
    }, 0);

    // Calculate Total Amount including Taxes and Discounts
    let totalAmount = formatTo2DP((subTotal + taxAmount) - discountAmount);

    // Calculate balance due
    let balanceDue = formatTo2DP(totalAmount - amountPaid);

    setExpenseSummary({
      ...expenseSummary,
      sub_total: subTotal,
      tax_amount: taxAmount,
      discount_amount: discountAmount,
      total_amount: totalAmount,
      amount_paid: amountPaid,
      balance_due: balanceDue
    })
  }

  const debouncedFetchSuppliers = debounce(getSuppliers, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const handleSupplierNameChange = (supplierName) => {
    // Fetch more suppliers when the user enters a supplier name not found in the initial list
    if (!suppliersList.some(supplier => supplier.supplier_name === supplierName)) {
      debouncedFetchSuppliers({businessID, supplierName});
      setFetchingSuppliers(true);
    }
  };

  const handleSupplierSelect = (value, option) => {
    const selected = suppliersList.find(supplier => supplier.supplier_name === value);
    
    if (selected) {
      const { phone_number, email, other_info } = selected;
      let phoneCode = formData.phone_code;
      let phoneNumber = '';

      if(phone_number) {
        let phoneNumberFormatted = getPhoneCodeAndNumber(phone_number);
        phoneCode = phoneNumberFormatted.phoneCode;
        phoneNumber = phoneNumberFormatted.phoneNumber;
        form.setFieldValue("phone_code", phoneCode);
        form.setFieldValue("phone_number", phoneNumber);

      }
      form.setFieldValue("email", email || '');
      form.setFieldValue("supplier_other_info", other_info || '');
      
      setFormData({
        ...formData,
        phone_code: phoneCode,
        phone_number: phoneNumber,
        email: email || '', 
        supplier_other_info: other_info || '', 
      });
    }
  };

  const handleAddItem = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, {
      expense_name: '',
      category: '',
      total_cost: ''
    }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleRemoveItem = (index) => {
    const oldItemFields = form.getFieldValue("items");
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);
    setItemFields(newFields);

    form.setFieldsValue({
      items: newFields,
    });

    updateSummary();
  };

  const uploadProps = {
    fileList,
    maxCount: 1,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: file => {
      const isValidFile = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(file.type);
      if (!isValidFile) {
        file.status = 'error';
        file.error = 'You can only upload a png, jpeg, jpg and pdf file.';
        setErrorMessage('You can only upload a .png, .jpeg, .jpg, .pdf file.');
      }
      
      const isSizeValid = file.size / 1024 / 1024 < 2;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 2MB!';
        setErrorMessage('File must be smaller than 2MB!');
      }

      if(isValidFile && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setErrorMessage("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      setFileList([file]);
      return false;
    },
  };

  const getExpenseDetails = (businessID, expenseID) => {
    return axios.get(baseURLs.API_URL + `/expenses/${expenseID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;

      } else {
        let responseInfo = response.data;
        let expenseInfo = responseInfo.data.expense_info;
        let _formData = formData;

        
  
        _formData.expense_title = expenseInfo.expense_title;
        _formData.issue_date = moment(expenseInfo.issue_date);
        _formData.issue_date_time = moment(expenseInfo.issue_date);
        if(expenseInfo.has_supplier === 'yes') {
          let phoneCode = formData.phone_code;
          let phoneNumber = '';

          if(expenseInfo.supplier_info.phone_number) {
            let phoneNumberFormatted = getPhoneCodeAndNumber(expenseInfo.supplier_info.phone_number);
            phoneCode = phoneNumberFormatted.phoneCode;
            phoneNumber = phoneNumberFormatted.phoneNumber;
          }

          _formData.supplier_name = expenseInfo.supplier_info.supplier_name;
          _formData.phone_code = phoneCode;
          _formData.phone_number = phoneNumber;
          _formData.email = expenseInfo.supplier_info.email ?? '';
          _formData.supplier_other_info = expenseInfo.supplier_info.other_info ?? '';
        }

        let items = [];
        for(let i = 0; i < expenseInfo.items.length; i++) {
          items.push({
            expense_name: expenseInfo.items[i].expense_name,
            category: expenseInfo.items[i].account_number,
            total_cost: moneyToFloat(expenseInfo.items[i].total_cost),
          })
        }
        _formData.items = items;

        _formData.amount_paid = moneyToFloat(expenseInfo.amount_paid);
        _formData.discount_amount = moneyToFloat(expenseInfo.discount_amount);
        _formData.tax_amount = moneyToFloat(expenseInfo.tax_amount);
        _formData.note = expenseInfo.note ?? '';
        
        if(expenseInfo.attachment_url) {
          let attachmentFile = {
            uid: '-1',
            url: expenseInfo.attachment_url
          }
          setFileList([attachmentFile]);
        }

        setItemFields(items);
        setShowSupplierSection(expenseInfo.has_supplier === 'yes' ? true : false)
        setIssueDate(moment(expenseInfo.issue_date).toDate());
        setIssueDateTime(moment(expenseInfo.issue_date).toDate());
        setFormData({..._formData});
      }
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    if(fileList.length === 0){
      setErrorMessage("");
    }
  }, [fileList])
  

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const expenseID = match.params.expenseID;
    if ( !Number.isInteger(businessID) || !expenseID ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setExpenseID(expenseID);

    Promise.all([
      getCategory(businessID),
      getExpenseDetails(businessID, expenseID),
      getSuppliers({businessID: businessID}),
    ]).finally(() => {
      setLoading(false);
      updateSummary();
    });

  }, [match.params.businessID]);

  const groupedCategories = categories.reduce((acc, category) => {
    if (!acc[category.category_type]) {
      acc[category.category_type] = [];
    }
    acc[category.category_type].push(category);
    return acc;
  }, {});

  const selectOptions = Object.entries(groupedCategories).map(([type, categories]) => ({
    label: type,
    options: categories.map(cat => ({
      label: cat.category,
      value: cat.category_id,
    })),
  }));

  return (<React.Fragment>
    <Head title="Edit Expenses" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Edit Expenses
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo history={history} link={`${process.env.PUBLIC_URL}/expenses/b/${businessID}`} type="button" icon="arrow-left">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block className="wide-sm m-auto mt-5">
          {errorMessage && (
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorMessage}{" "}
            </Alert>
          )}
          <Form name="record-expenses"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFieldsChange={(changedFields, allFields) => {
              debouncedHandleChangedFields(changedFields, allFields);       
            }}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              {/* Expenses details section */}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="pie fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Expenses Information</h5>
                  <p className="nk-block-des">Enter the details of the expense.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Title <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'expense_title'}
                          noStyle
                          rules={[
                            {required: true,}
                          ]}>
                          <Input size="large" className="form-control form-control-lg" placeholder="eg. Market expenses" />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'issue_date'}
                              noStyle
                              rules={[
                                { required: true, message: 'Issue Date is required', }
                              ]}>
                              <DatePicker
                                selected={issueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setIssueDate(date);
                                  form.setFieldsValue({issue_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'issue_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Issue Date time is required',}
                              ]}>
                              <DatePicker
                                selected={issueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setIssueDateTime(time);
                                  form.setFieldsValue({issue_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              {/* supplier details section */}
              <div className="fm-head bg-lighter justify-content-between">
                <div className="d-flex">
                  <div className="fm-count"><Icon name="user-list fs-22px"></Icon></div>
                  <div className="fm-title">
                    <h5 className="title fw-normal">Supplier Details</h5>
                    <p className="nk-block-des">Enter the details of the supplier your bought from.</p>
                  </div>
                </div>
                <button type="button" 
                  onClick={() => {setShowSupplierSection(!showSupplierSection)}}
                  className={`btn btn-sm btn-outline-${showSupplierSection ? `danger` : `primary`} m-1`}>
                    <span>{`${showSupplierSection ? `Remove` : `Add`}`}</span>
                  </button>
              </div>
              {
                showSupplierSection &&
                <div className="fm-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Supplier Name <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'supplier_name'}
                            noStyle
                            rules={[
                              {required: false,}
                            ]}>
                            <AutoComplete
                              size="large"
                              options={suppliersList.map(supplier => (
                                {
                                  key: supplier.supplier_id,
                                  label: `${supplier.supplier_name}`,
                                  value: `${supplier.supplier_name}`,
                                }
                              ))}
                              filterOption={(inputValue, option) =>
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                              }
                              notFoundContent={
                                fetchingSuppliers ? 
                                <div className="text-center m-5">
                                  <Spin/>
                                </div> : null}
                              onSelect={handleSupplierSelect}
                              onChange={handleSupplierNameChange}
                              placeholder="Enter supplier name"
                              className="w-100"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Phone Number <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <PhoneNumberInput form={form} formData={formData} isRequired={false} setFormData={setFormData} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Email <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'email'}
                            noStyle
                            rules={[
                              {required: false}, {type: 'email', message: 'Email is invalid'}
                            ]}>
                            <Input size="large" type="email" className="form-control form-control-lg" placeholder="supplier@email.com" />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Other Information <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'supplier_other_info'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                            <TextArea rows={4} placeholder="Other supplier information" />
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  </div>
                </div>
              }

              {/* products and services section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="list fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Expense Items</h5>
                  <p className="nk-block-des">Add the items you purchased or paid for.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-bordered card-full card">
                      {
                        itemFields.length === 0 ?
                        <div className="nk-block">
                          <div className="text-center m-5">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <span className="title fw-normal">No item added</span>
                            </div>
                            <div className="price-plan-action">
                              <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddItem()}>
                                <Icon name="plus-sm" />
                                <span>Add Item</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        :
                        <ul className="nk-support">
                          {
                            itemFields.map((item, index) => {
                              return (
                                <li className="nk-support-item d-block" key={index}>
                                  <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label"><b>#{index + 1}.</b> Expense Name <span className="text-danger">*</span></label>
                                        </div>
                                        <Form.Item name={['items', index, 'expense_name']}
                                          rules={[{ required: true, message: 'Please enter an expense name' }]}>
                                          <Input size="large" type="text" className="form-control form-control-lg" placeholder="eg. Salary"/>
                                        </Form.Item>
                                      </div>                                        
                                    </div>

                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Total Cost <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'total_cost']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Price is required'}
                                            ]}>
                                            <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                          </Form.Item> 
                                        </Form.Item>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                      <Form.Item>
                                        <div className="form-label-group">
                                          <label className="form-label">Category <span className="text-danger">*</span></label>
                                        </div>
                                        <Form.Item name={['items', index, 'category']}
                                          noStyle
                                          rules={[
                                            {required: true, message: 'Quantity is required'}
                                          ]}>
                                          <Select className="select-tags" size="large" 
                                            placeholder="Select category"
                                            style={{ width: "100%" }} 
                                            options={selectOptions}
                                            popupMatchSelectWidth={false}
                                            dropdownRender={(menu) => (
                                              <>
                                                {menu}
                                                <Divider
                                                  style={{
                                                    margin: '8px 0',
                                                  }}
                                                />
                                                <Space
                                                  style={{
                                                    padding: '0 8px 4px',
                                                  }}
                                                >
                                                  <Input
                                                    placeholder="New category"
                                                    value={newCategory}
                                                    onChange={(e) => setNewCategory(e.target.value)}
                                                  />
                                                  <Button disabled={requesting} className="btn-dim btn-blank" onClick={addNewCategory}>
                                                    <Icon name="plus" />
                                                    <span>Add</span>
                                                  </Button>
                                                </Space>
                                              </>
                                            )}
                                            filterOption={(inputValue, option) =>
                                              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                            }
                                            showSearch />
                                        </Form.Item>  
                                      </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>                            
                      }
                    </div>
                    
                  </div>
                  {
                    itemFields.length > 0 && 
                    <div className="col-md-12 text-center">
                      <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddItem()} >
                        <Icon name="plus-sm"></Icon>
                        <span>Add Item</span>
                      </Button>
                    </div>
                  }
                </div>
              </div>

              {
                showSupplierSection &&
                <>
                  {/* discount and tax section*/}
                  <div className="fm-head bg-lighter">
                    <div className="fm-count"><Icon name="percent fs-22px"></Icon></div>
                    <div className="fm-title">
                      <h5 className="title fw-normal">Discount / Tax</h5>
                      <p className="nk-block-des">Add discount and tax to this expense.</p>
                    </div>
                  </div>
                  <div className="fm-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Discount Amount <span className="text-muted">(optional)</span></label>
                            </div>
                            <Form.Item name={'discount_amount'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Tax Amount <span className="text-muted">(optional)</span></label>
                            </div>
                            <div className="form-control-wrap">
                              <Form.Item name={'tax_amount'}
                                noStyle
                                rules={[
                                  {required: false}
                                ]}>
                                <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" />    
                              </Form.Item>                       
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* payment section*/}
                  <div className="fm-head bg-lighter">
                    <div className="fm-count"><Icon name="cc-alt2 fs-22px"></Icon></div>
                    <div className="fm-title">
                      <h5 className="title fw-normal">Payment</h5>
                      <p className="nk-block-des">Add payment to this expense.</p>
                    </div>
                  </div>
                  <div className="fm-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Payment Type <span className="text-danger">*</span></label>
                            </div>
                            <Form.Item name={'payment_type'}
                              noStyle
                              rules={[
                                {required: true, message: `Select payment type`}
                              ]}>
                              <Select required size="large" 
                                placeholder="Select payment type"
                                style={{ width: "100%" }} 
                                options={[
                                  {
                                    key: 'cash',
                                    label: 'Cash',
                                    value: 'cash',
                                  },
                                  {
                                    key: 'check',
                                    label: 'Check',
                                    value: 'check',
                                  }, 
                                  {
                                    key: 'card payment',
                                    label: 'Card Payment',
                                    value: 'card payment',
                                  },
                                  {
                                    key: 'bank',
                                    label: 'Bank Transfer / Deposit',
                                    value: 'bank',
                                  }, 
                                  {
                                    key: 'mobile money',
                                    label: 'Mobile Money',
                                    value: 'mobile money',
                                  },
                                  {
                                    key: 'digital wallet',
                                    label: 'Digital Wallet',
                                    value: 'digital wallet',
                                  },                              
                                ]}
                                showSearch />  
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Amount Paid <span className="text-danger">*</span></label>
                            </div>
                            <Form.Item name={'amount_paid'}
                              noStyle
                              rules={[
                                {required: true, message: 'amount paid is required'}
                              ]}>
                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }

              {/* note and attachment section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="note-add fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Note and Attachment</h5>
                  <p className="nk-block-des">Add note and attach a receipt or invoice of the expense.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Note <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'note'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                            <TextArea rows={4} placeholder="Note" />
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Upload an invoice or receipt <span className="text-muted">(optional)</span></label>
                      </div>
                      <Dragger {...uploadProps}>
                        <div className="p-3">
                          <p className="ant-upload-drag-icon fs-1 text-muted">
                            <Icon name="upload-cloud"></Icon>
                          </p>
                          <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint text-muted">
                            Accepted file types for upload must be .jpg, .jpeg, .png, .pdf format, and the file size should not exceed 2MB.
                          </p>

                        </div>
                      </Dragger>
                    </div>
                  </div>
                </div>
              </div>

              {
                showSupplierSection ?
                <>
                  {/* summary section */}
                  <div className="fm-content bg-lighter">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="nk-iv-scheme-details">
                          <ul className="nk-iv-wg3-list w-100">                        
                            <li className="px-0 py-2 fw-medium">
                              <div className="sub-text fs-16px">Sub Total</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-16px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={expenseSummary.sub_total.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                            <li className="px-0 py-2 d-block">
                              <div className="d-flex justify-content-between fw-medium">
                                <div className="sub-text fs-14px">Total Tax</div>
                                <div className="nk-wg-action-content p-0">
                                  <h4 className="amount fs-14px">
                                    <small className="text-muted me-1">{businessInfo.currency}</small> 
                                    <Numeral value={expenseSummary.tax_amount.toString()} format={"0,0.00"} />
                                  </h4>
                                </div>
                              </div>
                            </li>
                            <li className="px-0 py-2">
                              <div className="sub-text fs-14px">Discount</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-14px">
                                  <small className="text-muted me-1">- {businessInfo.currency}</small>
                                  <Numeral value={expenseSummary.discount_amount.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                            <li className="px-0 py-2 fw-bold">
                              <div className="sub-text fs-18px">Total</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-18px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={expenseSummary.total_amount.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="nk-iv-scheme-details">
                          <ul className="nk-iv-wg3-list w-100">
                            <li className="px-0 py-2">
                              <div className="sub-text fs-14px">Amount Paid</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-14px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={expenseSummary.amount_paid.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                            <li className="px-0 py-2 fw-bold">
                              <div className={`sub-text fs-18px ${expenseSummary.balance_due > 0 ? `text-danger`: ``}`}>Balance Due</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className={`amount fs-18px ${expenseSummary.balance_due > 0 ? `text-danger`: ``}`}>
                                  <small className={`${expenseSummary.balance_due > 0 ? `text-danger`: `text-muted`} me-1`}>{businessInfo.currency}</small>
                                  <Numeral value={expenseSummary.balance_due.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <div className="fm-content bg-lighter">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="nk-iv-scheme-details">
                        <ul className="nk-iv-wg3-list w-100">
                          <li className="px-0 py-2 fw-bold">
                            <div className="sub-text fs-18px">Total Amount</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className="amount fs-18px">
                                <small className="text-muted me-1">{businessInfo.currency}</small> 
                                <Numeral value={expenseSummary.total_amount.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
        

              <div className="fm-footer">
                <div className="text-center">
                  <Button disabled={requesting} className="m-1" type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : <span>Save</span>}
                  </Button>
                </div>
              </div>
            </div>

          </Form>
          
        </Block>
      </>
    }

    { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={`Adding Category...`} descriptionText={`Please wait while new category is being added`} /> }

    { 
      activeModal === "successModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Added`} descriptionText={`Your new category has been added successfully.`} 
      />
    }

    {
      activeModal === "alertModal" &&
      <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
        headerText={"Error"} descriptionText={errorMessage} />
    }

    </Content>
    
  </React.Fragment>)
}