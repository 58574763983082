import React, { useState, useEffect } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import imagePlaceholder from "../../../../images/placeholder.png";
import { findUpper } from "../../../../utils/Utils";

const BusinessItem = (props) => {
  const { logo, businessName, location, businessID, switchBusiness } = props;
  return (
    <div onClick={switchBusiness} className="nk-notification-item" key={businessID} id={businessID}>
      <Link to={`${process.env.PUBLIC_URL}/dashboard/b/${businessID}`} className="d-flex">
        <div className="nk-notification-icon">
          <UserAvatar className="sq sm" theme="lighter" text={findUpper(businessName)} image={logo} imagePlaceholder={imagePlaceholder} />
        </div>
        <div className="nk-notification-content">
          <div className="nk-notification-text">{businessName}</div>
          <div className="nk-notification-time">{location}</div>
        </div>
      </Link>
    </div>
  );
};

const MyBusinesses = () => {
  const [businesses, setBusinesses] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  
  useEffect(() => {
    if(localStorage.getItem('my_businesses')){
      setBusinesses(JSON.parse(localStorage.getItem('my_businesses')))
    }
  }, [localStorage.getItem('my_businesses')]);

  return (
    <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <Icon name="home-alt" />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">My Businesses</span>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {businesses.map((business) => {
              return (
                <BusinessItem
                  key={business.business_id}
                  businessID={business.business_id}
                  logo={business.logo}
                  businessName={business.name}
                  location={business.location}
                  switchBusiness={() => {setDropdownOpen(false);}}
                />
              );
            })}
          </div>
        </div>
        <div className="dropdown-foot center">
          <Link to={`${process.env.PUBLIC_URL}/create-business`}>Create a new Business</Link>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default MyBusinesses;
