import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import imagePlaceholder from "../../../images/placeholder.png";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { 
  Badge,
  UncontrolledDropdown, 
  DropdownItem, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, 
  Alert 
} from "reactstrap";
import moment from "moment";
import { Empty, Input, InputNumber, Select, Form } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { findUpper, getAxiosHeaders } from "../../../utils/Utils";
import { 
  AlertModal,
  SuccessModal,
  LoadingModal 
 } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';
const { TextArea } = Input;

export const OfflinePayments = ({ history, match }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, payment_options:[]});
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const [paymentID, setPaymentID] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    account_type: '',
    account_name: '',
    account_number: '',
    other_info: '',
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleAddFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/businesses/offline-payment/${businessID}`, {
      account_type: _formData.account_type,
      account_name: _formData.account_name,
      account_number: _formData.account_number,
      other_info: _formData.other_info
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      
      setSuccessTitle(`Payment Option Added`);
      setSuccessMessage(`The offline payment option has been added successfully.`);
      toggleModal("successModal");

      getOfflinePaymentsOptions(businessID);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'account_type',
              errors: errorResponse.errors.hasOwnProperty("account_type") ? [errorResponse.errors.account_type] : [],
            },
            {
              name: 'account_name',
              errors: errorResponse.errors.hasOwnProperty("account_name") ? [errorResponse.errors.account_name] : [],
            },
            {
              name: 'account_number',
              errors: errorResponse.errors.hasOwnProperty("account_number") ? [errorResponse.errors.account_number] : [],
            },
            {
              name: 'other_info',
              errors: errorResponse.errors.hasOwnProperty("other_info") ? [errorResponse.errors.other_info] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const handleEditFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/businesses/offline-payment/${paymentID}`, {
      business_id: businessID,
      account_type: _formData.account_type,
      account_name: _formData.account_name,
      account_number: _formData.account_number,
      other_info: _formData.other_info
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();

      setSuccessTitle(`Payment Option Updated`);
      setSuccessMessage(`The offline payment option has been updated successfully.`);
      toggleModal("successModal");

      setRequesting(false);
      getOfflinePaymentsOptions(businessID);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'account_type',
              errors: errorResponse.errors.hasOwnProperty("account_type") ? [errorResponse.errors.account_type] : [],
            },
            {
              name: 'account_name',
              errors: errorResponse.errors.hasOwnProperty("account_name") ? [errorResponse.errors.account_name] : [],
            },
            {
              name: 'account_number',
              errors: errorResponse.errors.hasOwnProperty("account_number") ? [errorResponse.errors.account_number] : [],
            },
            {
              name: 'other_info',
              errors: errorResponse.errors.hasOwnProperty("other_info") ? [errorResponse.errors.other_info] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const deletePaymentOption = () => {
    toggleModal("deleteLoadingModal");

    axios.delete(baseURLs.API_URL + `/businesses/offline-payment/${paymentID}`, {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID
      }
    })
    .then((response) => {

      setSuccessTitle(`Payment Option Deleted`);
      setSuccessMessage(`The offline payment option has been deleted successfully.`);
      toggleModal("successModal");
      getOfflinePaymentsOptions(businessID);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getOfflinePaymentsOptions = (businessID) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/businesses/offline-payments`, {
      params: { business_id: businessID },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, payment_options:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, payment_options:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleEditPaymentOption = (data) => {
    setPaymentID(data.payment_id);

    setFormData({
      account_type: data.account_type,
      account_name: data.account_name,
      account_number: data.account_number,
      other_info: data.other_info
    });

    form.setFieldsValue({
      account_type: data.account_type,
      account_name: data.account_name,
      account_number: data.account_number,
      other_info: data.other_info
    });

    toggleModal('editPaymentModal');
  }

  const handleDeletePaymentOption = (data) => {
    setPaymentID(data.payment_id);
    toggleModal('deleteAlertModal');
  }

  const toggleModalClose = () => {
    setFormData({
      ...formData, 
      account_type: '',
      account_name: '',
      account_number: '',
      other_info: '',
    });

    form.setFieldsValue({
      account_type: '',
      account_name: '',
      account_number: '',
      other_info: '',
    });

    toggleModal(null);
  }

  useEffect(() => {
    setLoading(true);    
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getOfflinePaymentsOptions(businessID);
  }, [match.params.businessID]);


  return (
    <React.Fragment>
      <Head title="Offline Payment Options"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Offline Payment Options
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update your offline payment options.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              errorMessage &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              </Block>
            }

            <div className="card">
              <div className="fm-content">
                {
                  tableData.payment_options.length === 0 ?
                  <div className="nk-block">
                    <div className="text-center m-5">
                      <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">No offline payment option found</span>
                      </div>
                      <div className="price-plan-action">
                        <button className="btn btn-primary btn-sm" onClick={() => toggleModal('newPaymentModal')}>Add Offline Payment</button>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="nk-block">
                    <div className="card-title-group mb-3">
                      <h6 className="lead-text m-0">
                        {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Offline Payment Options` : `Offline Payment Option`}`}
                      </h6>
                      <div className="card-tools">
                        <ul className="btn-toolbar">
                          <li>
                            <button className="btn btn-primary btn-sm" onClick={() => toggleModal('newPaymentModal')}>Add a Payment Option</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-bordered card-full card">
                      <ul className="nk-support">
                        {
                          tableData.payment_options.map((data, index) => {                    
                            return (
                              <li className="nk-support-item" key={data.payment_id}>
                                <div className="user-avatar bg-gray-dim sm">
                                  <span>{findUpper(data.account_type)}</span>
                                </div>
                                <div className="nk-support-content">
                                  <div className="title">
                                    <span>{data.account_type}</span>
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <DropdownItem tag="a" className="pointer-cursor" onClick={() => handleEditPaymentOption(data)}>
                                              <Icon name="edit-alt" />
                                              <span>Edit</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleDeletePaymentOption(data)}>
                                              <Icon name="trash" />
                                              <span>Delete</span>
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <p>Account Name: <b>{data.account_name}</b></p>
                                  <p>Account Number: <b>{data.account_number}</b></p>
                                  <p>Other Information: <b>{data.other_info}</b></p>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                    {/* <PaginationWithOnclick currentPage={page} pageCount={totalPages} loadNextPage={loadNextPage} />           */}
                  </div>
                }
              </div>
            </div>
          </Block>
        </>
      }
      </Content>
      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {
        activeModal === "deleteAlertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
          headerText={`Delete Payment Option?`} 
          descriptionText={`Are you sure you want to delete this offline payment option? This action can not be reverted.`}
          leftButtonText={`Delete`}
          leftButtonOnClick={deletePaymentOption} />
      }

      {
        activeModal === "alertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }

      { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the payment option is being deleted`} /> }

      {
        activeModal === "newPaymentModal" &&
        <Modal backdrop="static" isOpen={true} id="create_offline_payment_modal">
          <ModalHeader
            toggle={toggleModalClose}
            close={
              <button className="close" disabled={requesting} onClick={toggleModalClose}>
                <Icon name="cross" />
              </button>
            }
          >
            Create an Offline Payment
          </ModalHeader>
          <Form name="add-payment-option"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleAddFormSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              
              <div className="form-group">
                <Form.Item>
                  <label className="form-label">
                    Account Type <span className="text-danger">*</span>
                  </label>
                  <Form.Item name={'account_type'}
                    noStyle
                    rules={[
                      {required: true, message: 'Account type is required',}
                    ]}>
                    <Input size="large" placeholder="eg. MTN Mobile Money" />
                  </Form.Item>
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item>
                  <label className="form-label">
                    Account Name <span className="text-danger">*</span>
                  </label>
                  <Form.Item name={'account_name'}
                    noStyle
                    rules={[
                      {required: true, message: 'Account name is required',}
                    ]}>
                    <Input size="large" placeholder="eg. Adwoa Mansah" />
                  </Form.Item>
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item>
                  <label className="form-label">
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <Form.Item name={'account_number'}
                    noStyle
                    rules={[
                      {required: true, message: 'Account number is required',}
                    ]}>
                    <Input size="large" placeholder="eg. 024 123 4567" />
                  </Form.Item>
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item>
                  <label className="form-label">
                    Other Information
                    <Button type="button" id="storefront_description_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                      <Icon name="info" />
                    </Button>
                    <UncontrolledPopover target="storefront_description_info_popover" placement="bottom" trigger="focus">
                      <PopoverBody>
                        <div className="mb-2">Enter a any relevant information that will make it easy for customers to use this payment option</div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </label>
                  <Form.Item name={'other_info'}
                    noStyle
                    rules={[
                      {required: false}
                    ]}>
                    <TextArea rows={4} placeholder="eg. Use your invoice number as the reference." />
                  </Form.Item>
                </Form.Item>
              </div>
              
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModalClose}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }

      {
        activeModal === "editPaymentModal" &&
        <Modal backdrop="static" isOpen={true} id="edit_payment_modal">
          <ModalHeader
            toggle={toggleModalClose}
            close={
              <button className="close" disabled={requesting} onClick={toggleModalClose}>
                <Icon name="cross" />
              </button>
            }
          >
            Edit Offline Payment Option
          </ModalHeader>
          <Form name="edit-payment-option"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleEditFormSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              
              <div className="form-group">
                  <Form.Item>
                    <label className="form-label">
                      Account Type <span className="text-danger">*</span>
                    </label>
                    <Form.Item name={'account_type'}
                      noStyle
                      rules={[
                        {required: true, message: 'Account type is required',}
                      ]}>
                      <Input size="large" placeholder="eg. MTN Mobile Money" />
                    </Form.Item>
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item>
                    <label className="form-label">
                      Account Name <span className="text-danger">*</span>
                    </label>
                    <Form.Item name={'account_name'}
                      noStyle
                      rules={[
                        {required: true, message: 'Account name is required',}
                      ]}>
                      <Input size="large" placeholder="eg. Adwoa Mansah" />
                    </Form.Item>
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item>
                    <label className="form-label">
                      Account Number <span className="text-danger">*</span>
                    </label>
                    <Form.Item name={'account_number'}
                      noStyle
                      rules={[
                        {required: true, message: 'Account number is required',}
                      ]}>
                      <Input size="large" placeholder="eg. 024 123 4567" />
                    </Form.Item>
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item>
                    <label className="form-label">
                      Other Information
                      <Button type="button" id="storefront_description_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                        <Icon name="info" />
                      </Button>
                      <UncontrolledPopover target="storefront_description_info_popover" placement="bottom" trigger="focus">
                        <PopoverBody>
                          <div className="mb-2">Enter a any relevant information that will make it easy for customers to use this payment option</div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </label>
                    <Form.Item name={'other_info'}
                      noStyle
                      rules={[
                        {required: false}
                      ]}>
                      <TextArea rows={4} placeholder="eg. Use your invoice number as the reference." />
                    </Form.Item>
                  </Form.Item>
                </div>
              
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModalClose}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </React.Fragment>
  )
}