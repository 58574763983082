import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAKEcIgGdecJbj96GKGd_1mR2TWK0msycE",
  authDomain: "keepup-web.firebaseapp.com",
  projectId: "keepup-web",
  storageBucket: "keepup-web.appspot.com",
  messagingSenderId: "789834819910",
  appId: "1:789834819910:web:52505ea08c22c743b69d47",
  measurementId: "G-NZFEYPFN4K"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default messaging;
