import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";

const SubscriptionAlert = () => {
  const location = useLocation();
  const [businessID, setBusinessID] = useState(localStorage.getItem('current_business_id') || '');
  const [trial, setTrial] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [daysToEnd, setDaysToEnd] = useState(null);
  const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(0);

  useEffect(() => {
    let _businessID = localStorage.getItem('current_business_id');
    if (_businessID && _businessID !== businessID) {
      setBusinessID(_businessID);
    }

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('sub') === 'successful') {
      setSubscriptionSuccessful(prevCount => prevCount + 1);
    }
  }, [location]);

  useEffect(() => {
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);

    if (currentBusiness) {
      setExpirationDate(currentBusiness.subscription.expiration);
      setTrial(currentBusiness.subscription.on_trial);

      // Calculate days to end
      if (currentBusiness.subscription.expiration) {
        const expDate = moment(currentBusiness.subscription.expiration);
        const today = moment();
        const diffDays = expDate.diff(today, 'days');
        setDaysToEnd(diffDays);
      }
    }
  }, [businessID, subscriptionSuccessful]);

  let alertClass = "alert subscription-alert-banner";
  let alertText = "";

  if (daysToEnd !== null) {
    if (daysToEnd < 0) {
      alertClass += " alert-danger";
      alertText = `Your ${trial ? 'free trial' : 'subscription'} ended ${moment(expirationDate).from(moment())}.`;
    } else if (daysToEnd <= 5) {
      alertClass += " alert-danger";
      alertText = `Your ${trial ? 'free trial' : 'subscription'} will end ${daysToEnd > 1 ? `in ${daysToEnd} days` : `${daysToEnd === 1 ? `tomorrow` : `today`}` } .`;
    } else if (daysToEnd <= 10) {
      alertClass += " alert-light";
      alertText = `Your ${trial ? 'free trial' : 'subscription'} will end in ${daysToEnd} days.`;
    } else {
      alertClass += " d-none"; // Hide if more than 10 days remaining
    }
  }

  return (
    <div className={alertClass}>
      <p>
        <b className="me-2">{alertText}</b>
        {alertText && (
          <Link to={`${process.env.PUBLIC_URL}/subscription/b/${businessID}`}>
            <span className="alert-link">Renew your subscription here</span>
          </Link>
        )}
        {!alertText && "Update failed! There are some technical issues."}
      </p>
    </div>
  );
};

export default SubscriptionAlert;
