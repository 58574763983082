import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { findUpper, phoneNumberFormatter } from "../../../utils/Utils";
import {
  Icon,
  UserAvatar,
} from "../../../components/Component";

const Header = ({headerInfo, color, ...props }) => {
  return (
    <div className="fm-head bg-lighter">
      <div className="fm-count"><Icon name="happy fs-22px"></Icon></div>
      <div className="fm-title">
        <span className="sub-title text-muted">Customer:</span>  
        <h5 className="title fw-normal">
          {headerInfo.customer_name}
        </h5>
        <p className="sub-title mb-0">{phoneNumberFormatter(headerInfo.customer_phone_number)}</p>
        <p className="sub-title mb-0">{moment(headerInfo.created_at).format("Do MMM, YYYY h:mm a")}</p>
      </div>        
    </div>
  );
};


export default Header;