import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import axios from 'axios';
import { 
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner } from "reactstrap";
import { getAxiosHeaders } from "../utils/Utils";
import { baseURLs } from '../utils/Constants';
import { Input } from 'antd';
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo
} from "../components/Component";
import { Link } from "react-router-dom";
import { ProductCategoryTable } from "./components/tables/ProductCategoryTable";
import {
  AlertModal,
  SuccessModal,
} from "./components/AlertModals";

const ProductCategory = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [addedCategoryCount, setAddedCategoryCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleAddCategorySubmit = () => {
    if(categoryName.length === 0){
      setErrorMessage("Category name is required");
      return;
    }

    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/products/category`, {
      business_id: businessID,
      category_name: categoryName,
      
    }, getAxiosHeaders())
    .then((response) => {
      setRequesting(false);
      setCategoryName("");
      setAddedCategoryCount(prevCount => prevCount + 1);
      toggleModal("successModal");

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Product Category" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Product Category
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button color={`primary`} onClick={() => toggleModal('addCategoryModal')}>
                        <Icon name="list-thumb"></Icon>
                        <span>Add Category</span>
                      </Button>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/products/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <ProductCategoryTable history={history} businessID={businessID} addedCategoryCount={addedCategoryCount} />

        {
          activeModal === "addCategoryModal" &&
          <Modal backdrop="static" isOpen={true} id="add_category_modal">
            <ModalHeader
              toggle={() => toggleModal('addCategoryModal')}
              close={
                <button className="close" disabled={requesting} onClick={() => toggleModal('addCategoryModal')}>
                  <Icon name="cross" />
                </button>
              }>
              Add Category
            </ModalHeader>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label">Category Name <span className="text-danger">*</span></label>
                    </div>
                    <Input size="large" placeholder="eg. Bags" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
                  </div>
                </div>
              </div>          
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} onClick={handleAddCategorySubmit} color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal(null)}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        }

      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal('successModal')}
          headerText={"Added"} descriptionText={"The category has been added successfully."} />
      }
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default ProductCategory;