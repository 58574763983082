import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import menuData from "../layout/menu/MenuData";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Spinner, Alert } from "reactstrap";
import BusinessCard from "./components/BusinessCard";
import { getAxiosHeaders } from "../utils/Utils";

const MyBusinesses = ({ history }) => {

  const [loading, setLoading] = useState(true);
  const [businesses, setBusinesses] = useState([]);
  const [invitations, setInvitations] = useState(0);
  const [errorVal, setError] = useState("");

  const getBusinesses = () => {
    axios.get(baseURLs.API_URL + "/businesses", getAxiosHeaders())
    .then(async(response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/create-business`);
        return;

      } else {
        let responseInfo = response.data;
        let businesses = responseInfo.data.businesses;
        let justLoggedIn = localStorage.getItem('justLoggedIn');
        
        await localStorage.setItem('my_businesses', JSON.stringify(businesses));
        
        if(businesses.length === 1 && justLoggedIn === true){
          localStorage.setItem('justLoggedIn', false);
          if(businesses[0].role == "owner"){
            history.push(`${process.env.PUBLIC_URL}/dashboard/b/${businesses[0].business_id}`);        
          
          } else {
            const objectWithPermission = menuData.find((item) =>
              item.permission.includes(businesses[0].permissions[0].permission) && item.link
            );
  
            if(objectWithPermission) {
              history.push(`${process.env.PUBLIC_URL}${objectWithPermission.link}/${businesses[0].business_id}`);            
            } else {
              history.push(`${process.env.PUBLIC_URL}/dashboard/b/${businesses[0].business_id}`);
            }
          }
          return;
        }
    
        setBusinesses(businesses);
        setLoading(false);
      }
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getInvitations = () => {
    axios.get(baseURLs.API_URL + `/businesses/invitations`, {headers: getAxiosHeaders().headers})
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        setInvitations(responseInfo.data.meta.total_records);
      }
    }).catch((error) => {
      console.log(error);
      // history.push(`${process.env.PUBLIC_URL}/server-offline`);
    });
  }


  useEffect(() => {
    getBusinesses();
    getInvitations();
  }, []);

  return (
    <React.Fragment>
      <Head title="My Businesses" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between flex-wrap g-3">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                My Businesses
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Below are your businesses.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools">
                <li className="nk-block-tools-opt">
                  <Link to={`${process.env.PUBLIC_URL}/create-business`}>
                    <Button color="primary">
                      <Icon name="home-alt"></Icon>
                      <span>Create a new business</span>
                    </Button>
                  </Link>
                </li>
              </ul>
            </BlockHeadContent>
          </div>
        </BlockHead>

        {
          invitations > 0 && 
          <Block className="wide-sm m-auto">
            <div className="card-bordered card mb-2">
              <div className="card-inner">
                <div className="nk-help">
                  <div className="nk-help-img m-0">
                    <lord-icon
                      src="https://cdn.lordicon.com/tmqaflqo.json"
                      trigger="loop"
                      delay="2000"
                      colors="primary:#4bb3fd,secondary:#ebe6ef,tertiary:#e4e4e4"
                      style={{width:"120px",height:"120px"}}>
                    </lord-icon>
                  </div>
                  <div className="nk-help-text">
                    <h5> You have <b>{invitations}</b> {`${invitations > 1 ? `Invitations` : `Invitation`}`}.</h5>
                    <p className="text-soft">Click to view invitations from businesses.</p>
                  </div>
                  <div className="nk-help-action">
                    <Link to={`${process.env.PUBLIC_URL}/invitations`}>
                      <button className="btn btn-outline-primary">
                        View Invitations
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        }

        {
          errorVal && 
          <Block>
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          </Block>
        }

        {
          loading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs">
            <div className="inner-pre-loader">
              <Spinner  color="dark" />          
            </div>
          </Block>
          :
          <Block>
            <Row className="g-gs my-3">
              {
                businesses.map((data, index) => {
                  return (<BusinessCard menuData={menuData} businessInfo={data} key={index} />)
                })
              }
            </Row>
          </Block>
        }
      </Content>
    </React.Fragment>
  );
};

export default MyBusinesses;
