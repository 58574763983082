import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Card, Spinner, Alert, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { Empty } from 'antd';
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getQueryParams } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import moment from "moment";
import ColorTheme from "./components/sale/ColorTheme";
import Header from "./components/sale/Header";
import Body from "./components/sale/Body";
import { SuccessModal } from "./components/AlertModals";

const SaleDetails = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [saleDetails, setSaleDetails] = useState({
    business_id: '',
    business_logo: '',
    business_name: '',
    business_location: '',
    business_phone_number: '',
    business_country_iso: '',
    currency: '',
    sale_id: '',
    sale_number: '', 
    status: '', 
    customer_uuid: '',
    customer_name: '', 
    customer_phone_number: '', 
    customer_email: '', 
    issued_by: '', 
    viewed: '', 
    viewed_at: '', 
    issued_date: '', 
    due_date: '', 
    created_at: '', 
    updated_at: '', 
    items: [], 
    delivery_pickup: null,
    payment_log: [], 
    order_log: [], 
    sub_total: '', 
    tax_amount: '', 
    taxes: [], 
    discount: {
      discount_type: 'fixed',
      discount_amount: '0.00',
      discount_percentage: '0',
    }, 
    total_amount: '', 
    amount_paid: '', 
    balance_due: '', 
    amount_received: '', 
    change: '', 
    sale_origin: '',
    feedback: {
      rate: null,
      message: null,
    },
    note: '',
    customer_note: '',
    share_link: '',
    a4_print_link: '',
    thermal_print_link: '',
    outstanding_balance: {},
    team_members: []
  });
  const [headerColor, setHeaderColor] = useState("");
  const [saleNumber, setSaleNumber] = useState("...");
  const [completedStatus, setCompletedStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getSaleDetails = (businessInfo, saleID, printSale = 'no') => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/sales/${saleID}`, {
      params: {
        business_id: businessInfo.business_id
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('Sale not found');
      } else {
        let responseInfo = response.data;
        let saleInfo = responseInfo.data.sale_info;
        let outstandingBalance = responseInfo.data.outstanding_balance;
        let _saleDetails = saleDetails;
  
        _saleDetails.business_id = businessInfo.business_id
        _saleDetails.business_logo = businessInfo.logo
        _saleDetails.business_name = businessInfo.name
        _saleDetails.business_location = businessInfo.location
        _saleDetails.business_phone_number = businessInfo.phone_number
        _saleDetails.currency = businessInfo.currency
        _saleDetails.business_country_iso = businessInfo.country_iso
  
        _saleDetails.sale_id = saleID
        _saleDetails.sale_number = saleInfo.sale_number;
        _saleDetails.status = saleInfo.status;
        _saleDetails.customer_uuid = saleInfo.customer_uuid;
        _saleDetails.customer_name = saleInfo.customer_name;
        _saleDetails.customer_phone_number = saleInfo.customer_phone_number;
        _saleDetails.customer_email = saleInfo.customer_email;
        _saleDetails.issued_by = saleInfo.issued_by;
        _saleDetails.viewed = saleInfo.viewed;
        _saleDetails.viewed_at = saleInfo.viewed_at;
        _saleDetails.issued_date = saleInfo.issued_date;
        _saleDetails.due_date = saleInfo.due_date;
        _saleDetails.created_at = saleInfo.created_at;
        _saleDetails.updated_at = saleInfo.updated_at;
        _saleDetails.items = saleInfo.items;
        _saleDetails.payment_log = saleInfo.payment_log.length > 1 ? saleInfo.payment_log.reverse() : saleInfo.payment_log;
        _saleDetails.order_log = saleInfo.order_log.length > 1 ? saleInfo.order_log.reverse() : saleInfo.order_log;
        _saleDetails.delivery_pickup = saleInfo.delivery_pickup;
        _saleDetails.sub_total = saleInfo.sub_total;
        _saleDetails.tax_amount = saleInfo.tax_amount;
        _saleDetails.taxes = saleInfo.taxes;
        _saleDetails.discount = saleInfo.discount;
        _saleDetails.total_amount = saleInfo.total_amount;
        _saleDetails.amount_paid = saleInfo.amount_paid;
        _saleDetails.balance_due = saleInfo.balance_due;
        _saleDetails.change = saleInfo.change;
        _saleDetails.amount_received = saleInfo.amount_received;
        _saleDetails.sale_origin = saleInfo.sale_origin;
        _saleDetails.note = saleInfo.note;
        _saleDetails.feedback = saleInfo.feedback;
        _saleDetails.customer_note = saleInfo.customer_note;
        _saleDetails.share_link = saleInfo.share_link;
        _saleDetails.a4_print_link = saleInfo.a4_print_link;
        _saleDetails.thermal_print_link = saleInfo.thermal_print_link;
        _saleDetails.outstanding_balance = outstandingBalance;
  
        setSaleNumber(saleInfo.sale_number);
        setSaleDetails({..._saleDetails});
        setHeaderColor(businessInfo.brand_color);
        
        if(printSale === 'yes') {
          window.open(saleInfo.thermal_print_link, "_blank");
        }
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const updateSales = (saleID) => {
    toggleModal('successModal');
    getSaleDetails(businessInfo, saleID);
  }

  const teamMembers = (business_id) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/teams/permissions/edit_update_orders`, {
      params: {
        business_id: business_id
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let teamMembers = responseInfo.data.team_members;
      let _saleDetails = saleDetails;

      _saleDetails.team_members = teamMembers;

      setSaleDetails({..._saleDetails});
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let isSaved = queryParams.hasOwnProperty('saved') ? queryParams.saved : '';
    let sentAlert = queryParams.hasOwnProperty('alerted') ? queryParams.alerted : '';
    let printSale = queryParams.hasOwnProperty('print') ? queryParams.print : '';

    const _businessID = parseInt(match.params.businessID, 10);
    const saleID = parseInt(match.params.saleID, 10);
    if ( Number.isInteger(_businessID) && Number.isInteger(saleID) ) {
      localStorage.setItem('current_business_id', _businessID);
      let businesses = JSON.parse(localStorage.getItem('my_businesses'));
      let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      if(isSaved === 'yes'){
        setCompletedStatus(sentAlert === 'yes' ? `alerted`  : `saved`);
        toggleModal('completedModal')
      }


      setBusinessInfo({...currentBusiness});
      setBusinessID(_businessID);
      getSaleDetails(currentBusiness, saleID, printSale);
      teamMembers(_businessID);
    } else {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }
  }, [match.params.business]);

  return (<React.Fragment>
    <Head title="Sale Details" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                <span>Sale</span>
                <span className="fw-normal ms-1">#{saleNumber}</span>
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/sales/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        
        <Block className="wide-sm m-auto">
          {
            errorMessage ? 
            <Card className="card-bordered">
              <div className="card-inner">
                <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">Sale not found</h5>
                      <span>The sale you are looking for could not be found.</span>
                      <p className="text-soft">If you need help, you can reach us on our <a href={baseURLs.LIVE_CHAT}>live chat</a>.</p>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}`}>
                        <button className="btn btn-outline-primary">Go to Sales</button>
                      </Link>                    
                    </div>
                  </div>
              </div>
            </Card>
            :
            <>
              {/* {
                businessInfo.is_verified !== 'verified' &&
                <div className="mb-3">                
                  <Alert color="warning" className="alert-icon">
                    <Icon name="alert-circle" /> 
                    <b>Your business is not yet verified. As a result, { !moment().isSameOrAfter(moment('2024-06-01'), 'day') ? `starting 1st June, 2024 the following message will appear ` : `the following message appears ` }to your customers on receipts, quotes, and invoices:</b><br/>
                    <i>Caution! This business has not yet been verified by Keepup Store. Please exercise caution and verify the authenticity of this business before proceeding with any transactions.</i><br/>
                    <p className="pt-2 fw-bold">Please complete the verification process to remove this message. <Link to={`${process.env.PUBLIC_URL}/business-settings/b/${businessID}/business-verification`}><span className="alert-link">Start Business Verification</span></Link>. <br/>
                      <span className="alert-link pointer-cursor" onClick={() => toggleModal('verificationReasonModal')}>Learn more</span>
                    </p>
                  </Alert>
                  {
                    activeModal === "verificationReasonModal" &&
                    <Modal isOpen={true} toggle={() => toggleModal('verificationReasonModal')}>
                      <ModalHeader
                        toggle={() => toggleModal('filterModal')}
                        close={
                          <button className="close" onClick={() => toggleModal('filterModal')}>
                            <Icon name="cross" />
                          </button>
                        }
                      >
                        Why Verify your Business
                      </ModalHeader>
                      <ModalBody>
                        <p>Verifying your business is an important step to ensure trust and confidence among your customers. When customers see that your business is verified, it reassures them of your credibility and legitimacy, enhances your professional image, and helps prevent impersonators from misrepresenting your business.</p>
                        <p className="fw-bold">It's important to note, while verification can provide these benefits, it's not mandatory.</p>
                      </ModalBody>
                    </Modal>
                  }
                </div>
              } */}
              <div className="card card-bordered">
                <ColorTheme businessID={businessID} color={businessInfo.brand_color} setHeaderColor={setHeaderColor}/>
                <Header headerInfo={saleDetails} color={headerColor} verificationStatus={businessInfo.is_verified} verificationType={businessInfo.verification_type} />
                <Body saleInfo={saleDetails} setSaleInfo={setSaleDetails} view={"business"} updateSales={() => updateSales(saleDetails.sale_id)} />
                { 
                  activeModal === "completedModal" &&
                  <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                    headerText={`${completedStatus === 'alerted' ? `Sent Successfully` : ` Saved Successfully`}`} descriptionText={`The ${saleDetails.status} has been saved ${completedStatus === 'alerted' ? `and sent to the customer` : ``} successfully.`} 
                  />
                }
              </div>            
            </>
          }

          {/* success modal */}
          {
            activeModal === "successModal" &&
            <SuccessModal 
              showModal={true}
              toggleModal={() => toggleModal('successModal')}
              headerText={"Fulfillment Updated"}
              descriptionText={"The fulfillment information has been updated successfully."} />
          }

          
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default SaleDetails;