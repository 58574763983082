import React, { useEffect, useState } from "react";
import { getAxiosHeaders, orderStatusOptions } from "../../../utils/Utils";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import { 
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input, Select, Checkbox, Form, InputNumber } from 'antd';
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { LinkItUrl } from 'react-linkify-it';
import OrderOutstandingIcon from "../../../images/cart.png";
import PendingPaymentIcon from "../../../images/cash.png";
import ProcessingIcon from "../../../images/processing.png";
import OnHoldIcon from "../../../images/on_hold.png";
import ReadyForPickupIcon from "../../../images/ready_pickup.png";
import InTransitIcon from "../../../images/in_transit.png";
import DeliveredIcon from "../../../images/delivered.png";
const { Option } = Select;
const { TextArea } = Input;

const OrderInfo = ({ businessID, teamMembers, saleInfo, setSaleInfo, view = 'business', ...props }) => {
  let orderInfo = saleInfo.order_log[0];

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dueDate, setDueDate] = useState(new Date(orderInfo.due_date));
  const [dueDateTime, setDueDateTime] = useState(new Date(orderInfo.due_date));
  const [reminderDate, setReminderDate] = useState(orderInfo.reminder_date ? new Date(orderInfo.reminder_date) : '');
  const [reminderTime, setReminderTime] = useState(orderInfo.reminder_date ? new Date(orderInfo.reminder_date) : '');
  const [checked, setChecked] = useState(true);
  const [alertTeamChecked, setAlertTeamChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleOrderFormSubmit = (_formData) => {
    setLoading(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/orders/${saleInfo.sale_id}`, {
      business_id: businessID,
      status: _formData.status,
      due_date: `${moment(_formData.due_date).format("YYYY-MM-DD")} ${moment(_formData.due_date_time).format("HH:mm")}`,
      reminder_date: !(_formData.reminder_date && _formData.reminder_time) ? null : `${moment(_formData.reminder_date).format("YYYY-MM-DD")} ${moment(_formData.reminder_time).format("HH:mm")}`,
      customer_note: _formData.customer_note,
      assign_to: _formData.assign_to,
      alert_customer: _formData.alert_customer ? 'yes' : 'no',
      alert_team: _formData.alert_team ? 'yes' : 'no',
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let saleData = saleInfo;      
      saleData.order_log = responseInfo.data.order_log.length > 1 ? responseInfo.data.order_log.reverse() : responseInfo.data.order_log;;
      setSaleInfo({...saleData});

      toggleModal('successModal');
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'status',
              errors: errorResponse.errors.hasOwnProperty("status") ? [errorResponse.errors.status] : [],
            },
            {
              name: 'due_date',
              errors: errorResponse.errors.hasOwnProperty("due_date") ? [errorResponse.errors.due_date] : [],
            },
            {
              name: 'reminder_date',
              errors: errorResponse.errors.hasOwnProperty("reminder_date") ? [errorResponse.errors.reminder_date] : [],
            },
            {
              name: 'customer_note',
              errors: errorResponse.errors.hasOwnProperty("customer_note") ? [errorResponse.errors.customer_note] : [],
            },
            {
              name: 'assign_to',
              errors: errorResponse.errors.hasOwnProperty("assign_to") ? [errorResponse.errors.assign_to] : [],
            },
            {
              name: 'alert_customer',
              errors: errorResponse.errors.hasOwnProperty("alert_customer") ? [errorResponse.errors.alert_customer] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case 'outstanding':
        return view === "business" ? "Outstanding" : "Queued";
    
      case 'pending_payment':
        return "Pending Payment";

      case 'processing':
        return "Processing";

      case 'on_hold':
        return "On Hold";
    
      case 'ready_for_pickup':
        return "Ready for Pickup";

      case 'in_transit':
        return "In Transit";
    
      case 'delivered':
        return "Delivered";
      
      default:
        return view === "business" ? "Outstanding" : "Queued";
    }
  }
  
  return (
    <div className="fm-footer" style={{backgroundColor: "#1c2b46"}}>
      <div className="nk-help">
        <div className="nk-help-img w-100px mr-0">
          {
            orderInfo.status === 'outstanding' && 
            <img src={OrderOutstandingIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }

          {
            orderInfo.status === 'pending_payment' && 
            <img src={PendingPaymentIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }

          {
            orderInfo.status === 'processing' && 
            <img src={ProcessingIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }

          {
            orderInfo.status === 'on_hold' && 
            <img src={OnHoldIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }

          {
            orderInfo.status === 'ready_for_pickup' && 
            <img src={ReadyForPickupIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }

          {
            orderInfo.status === 'in_transit' && 
            <img src={InTransitIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }

          {
            orderInfo.status === 'delivered' && 
            <img src={DeliveredIcon} width="80px" alt={getOrderStatus(orderInfo.status)} />
          }
        </div>
        <div className="nk-help-text">
          <span className="sub-title text-white">Order Status:</span>  
          <h5 className="title text-white">{getOrderStatus(orderInfo.status)}</h5>
          <p className="sub-title text-white fs-12px mb-2">Due Date: {moment(orderInfo.due_date).format("Do MMM YYYY hh:mm a")}</p>
          {orderInfo.notes != null && <LinkItUrl className="text-warning"><p className="sub-title text-white fs-16px mb-1" style={{whiteSpace: "pre-wrap"}}><b>Note:</b> {orderInfo.notes}</p></LinkItUrl>}
          {view === "business" && <p className="mb-0"><small className="text-white">Updated By: {orderInfo.updated_by}</small></p>}
          {view === "business" && <small className="text-white">Assigned To: {orderInfo.assigned_to}</small>}
        </div>
        {
          view === "business" &&
          <div className="dark-mode nk-help-action text-md-center">
            <button onClick={() => toggleModal('orderModal')} className="btn btn-md btn-primary">Update Order Status</button>
          </div>
        }
      </div>

      {
        view === "business" &&
        <>
          {/* order modal */}
          {
            activeModal === "orderModal" &&
            <Modal backdrop="static" isOpen={true} id="order-modal">
              <ModalHeader
                toggle={() => toggleModal("orderModal")}
                close={
                  <button className="close" onClick={() => toggleModal("orderModal")}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Update Order
              </ModalHeader>
              <ModalBody>
                {errorMessage && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errorMessage}{" "}
                    </Alert>
                  </div>
                )}
                <Form name="add-order"
                  form={form} className="is-alter"
                  onFinish={handleOrderFormSubmit}
                  initialValues={{
                    alert_customer: checked,
                    alert_team: false,
                    assign_to: orderInfo.assigned_to,
                    customer_note: orderInfo.notes,
                    due_date: dueDate,
                    due_date_time: dueDateTime,
                    status: orderInfo.status,
                    reminder_date: reminderDate,
                    reminder_time: reminderTime        
                  }}
                  onFinishFailed={onFinishFailed}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Order Status <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'status'}
                            noStyle
                            rules={[
                              {required: true, message: 'Order status is required',}
                            ]}>
                            <Select required size="large" 
                              name="country"
                              placeholder="Select order status"
                              style={{ width: "100%" }}
                              options={orderStatusOptions} 
                              showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label">Due Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item>
                              <Form.Item name={'due_date'}
                                noStyle
                                rules={[
                                  {required: true, message: 'Date is required',}
                                ]}>
                                <DatePicker
                                  selected={dueDate}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={date => {
                                    setDueDate(date);
                                    form.setFieldsValue({due_date: date}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                          <div className="col-5">
                            <Form.Item>
                              <Form.Item name={'due_date_time'}
                                noStyle
                                rules={[
                                  {required: true, message: 'time is required',}
                                ]}>
                                <DatePicker
                                  selected={dueDateTime}
                                  dateFormat="h:mm aa"
                                  onChange={time => {
                                    setDueDateTime(time);
                                    form.setFieldsValue({due_date_time: time}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label">Set Reminder Date <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item>
                              <Form.Item name={'reminder_date'}
                                rules={[
                                  {required: false}
                                ]}
                                noStyle>
                                <DatePicker
                                  selected={reminderDate}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={date => {
                                    setReminderDate(date);
                                    form.setFieldsValue({reminder_date: date}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                          <div className="col-5">
                            <Form.Item>
                              <Form.Item name={'reminder_time'}
                                rules={[
                                  {required: false}
                                ]}
                                noStyle>
                                <DatePicker
                                  selected={reminderTime}
                                  dateFormat="h:mm aa"
                                  onChange={time => {
                                    setReminderTime(time)
                                    form.setFieldsValue({reminder_time: time}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Note to customer <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'customer_note'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                              <TextArea rows={4} placeholder="Note for customer" />
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Assign To <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'assign_to'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Select required size="large"
                              placeholder="Select order status"
                              style={{ width: "100%" }}
                              showSearch >
                              {teamMembers.map(member => (
                                <Option key={member.uuid} value={member.uuid}>
                                  {member.full_name}
                                </Option>
                              ))}
                            </Select>   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="alert_customer" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={checked}
                              onChange={() => {
                                setChecked(!checked);
                                form.setFieldValue('alert_customer', !checked);
                              }}
                              id="new_order_alert_customer" />
                            <label className="custom-control-label" htmlFor="new_order_alert_customer">
                              Alert customer of order status
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="alert_team" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={alertTeamChecked}
                              onChange={() => {
                                setAlertTeamChecked(!alertTeamChecked);
                                form.setFieldValue('alert_team', !alertTeamChecked);
                              }}
                              id="new_order_alert_team" />
                            <label className="custom-control-label" htmlFor="new_order_alert_team">
                              Alert Team Members
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <div className="text-start">
                        <Button className="m-1" disabled={loading} type="submit" color="primary" size="md" >
                          {loading ? <Spinner size="sm" color="light" /> : "Update Order"}
                        </Button>
                        <Button className="m-1" disabled={loading} type="button" onClick={() => toggleModal("orderModal")} color="light" size="md" >
                          Close
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </ModalBody>
              <ModalFooter className="bg-light justify-content-start">
                <span className="sub-text">Use the form above to update this order information.</span>
              </ModalFooter>
            </Modal>
          }

          {/* success modal */}
          {
            activeModal === "successModal" &&
            <SuccessModal 
              showModal={true}
              toggleModal={() => toggleModal('successModal')}
              headerText={"Order Updated"}
              descriptionText={"Order has been updated successfully."} />
          }
        </>
      }
    </div>
  );
};


export default OrderInfo;