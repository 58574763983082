import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { Input, Select, Checkbox, Form, Upload } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';
const { Dragger } = Upload;

export const BusinessVerification = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [verificationInfo, setVerificationInfo] = useState({
    is_verified: '',
    verification_type: '',
    id_verification: '',
    reason: '',
    documents: []
  });
  const [verificationType, setVerificationType] = useState("");
  const [kycID, setKYCID] = useState("");
  const [idFileList, setIDFileList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormError] = useState({
    verificationType: '',
    idVerification: '',
    businessDocs: '',
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleSubmit = () => {
    let _formErrors = {
      verificationType: '',
      idVerification: '',
      businessDocs: '',
    };

    if(!verificationType) {
      _formErrors.verificationType = 'Select a verification type';
    }
    
    if(!kycID) {
      _formErrors.idVerification = 'Click on Start Verification to Verify your ID';
    }

    if(fileList.length === 0 && verificationType === 'business') {
      _formErrors.businessDocs = 'Upload your business registration documents';
    }

    if(_formErrors.verificationType || _formErrors.idVerification || _formErrors.businessDocs) {
      setFormError(_formErrors);
      setErrorMessage("Some of the fields are invalid. Check and try again");
      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    setFormError(_formErrors);
    setRequesting(true);
    setErrorMessage("");

    const formInfo = new FormData();
    
    // Append documents to formInfo
    fileList.forEach(file => {
      formInfo.append('documents', file);
    });
    
    formInfo.set("verification_type", verificationType);
    formInfo.set("KYC_id", kycID);

    axios.post(baseURLs.API_URL + `/businesses/KYC/${businessID}`, formInfo, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let verificationInfo = responseInfo.data.verification_info;
      setVerificationInfo({...verificationInfo});

      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          _formErrors.verificationType = errorResponse.errors.hasOwnProperty("verification_type") ? errorResponse.errors.verification_type : "";
          _formErrors.idVerification = errorResponse.errors.hasOwnProperty("KYC_id") ? errorResponse.errors.KYC_id : "";
          _formErrors.businessDocs = errorResponse.errors.hasOwnProperty("documents") ? errorResponse.errors.documents : "";          
        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setFormError(_formErrors);
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const handleManualVerificationSubmit = () => {
    let _formErrors = {
      verificationType: '',
      idVerification: '',
      businessDocs: '',
    };

    if(!verificationType) {
      _formErrors.verificationType = 'Select a verification type';
    }
    
    if(idFileList.length === 0) {
      _formErrors.idVerification = 'Upload your ID for verification';
    }

    if(fileList.length === 0 && verificationType === 'business') {
      _formErrors.businessDocs = 'Upload your business registration documents';
    }

    if(_formErrors.verificationType || _formErrors.idVerification || _formErrors.businessDocs) {
      setFormError(_formErrors);
      setErrorMessage("Some of the fields are invalid. Check and try again");
      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    setFormError(_formErrors);
    setErrorMessage("");
    setRequesting(true);

    const formInfo = new FormData();
    
    // Append documents and IDs to formInfo
    idFileList.forEach(file => {
      formInfo.append('ids', file);
    });

    fileList.forEach(file => {
      formInfo.append('documents', file);
    });
    
    formInfo.set("verification_type", verificationType);

    axios.put(baseURLs.API_URL + `/businesses/KYC/${businessID}`, formInfo, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let verificationInfo = responseInfo.data.verification_info;
      setVerificationInfo({...verificationInfo});

      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          _formErrors.verificationType = errorResponse.errors.hasOwnProperty("verification_type") ? errorResponse.errors.verification_type : "";
          _formErrors.idVerification = errorResponse.errors.hasOwnProperty("ids") ? errorResponse.errors.ids : "";
          _formErrors.businessDocs = errorResponse.errors.hasOwnProperty("documents") ? errorResponse.errors.documents : "";          
        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setFormError(_formErrors);
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const getBusinessVerificationStatus = (businessID) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/businesses/KYC/${businessID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let verificationInfo = responseInfo.data.verification_info;
      let _kycID = verificationInfo.kyc_id;
      setKYCID(_kycID);
      setVerificationType(verificationInfo.verification_type);
      setVerificationInfo({...verificationInfo});

      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getSmileToken = () => {
    setErrorMessage("");
    setRequesting(true);

    axios.get(baseURLs.API_URL + `/businesses/smile-token/${businessID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;

      if(response.status === 200){
        let tokenInfo = responseInfo.data;
        configureSmileIdentityWebIntegration(tokenInfo);

      } else {
        setRequesting(false);
        getBusinessVerificationStatus(businessID);
      }
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const CustomCheckbox = ({ checked, title, type, description }) => (
    <>
      <div className="title mb-0">
        <h6 className="mb-0">{title}</h6>
        {checked && (
          <span className="badge-dot badge-dot-xs badge bg-primary">
            Selected
          </span>
        )}
      </div>
      <span className="fs-12px">({type})</span>
      <p className="nk-block-des mt-2">{description}</p> 
    </>
  );

  const uploadIDProps = {
    idFileList,
    maxCount: 2,
    listType: "picture",
    onRemove: () => {
      setIDFileList([]);
    },
    beforeUpload: file => {
      const isValidFile = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(file.type);
      if (!isValidFile) {
        file.status = 'error';
        file.error = 'You can only upload a png, jpeg, jpg and pdf file.';
        setErrorMessage('ID Upload: You can only upload a .png, .jpeg, .jpg, .pdf file.');
      }
      const isSizeValid = file.size / 1024 / 1024 < 10;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 10MB!';
        setErrorMessage('ID Upload: File must be smaller than 10MB!');
      }

      if(isValidFile && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setErrorMessage("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      setIDFileList([file]);
      return false;
    },
  };

  const uploadProps = {
    fileList,
    maxCount: 3,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: file => {
      const isValidFile = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(file.type);
      if (!isValidFile) {
        file.status = 'error';
        file.error = 'You can only upload a png, jpeg, jpg and pdf file.';
        setErrorMessage('You can only upload a .png, .jpeg, .jpg, .pdf file.');
      }
      const isSizeValid = file.size / 1024 / 1024 < 10;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 10MB!';
        setErrorMessage('File must be smaller than 10MB!');
      }

      if(isValidFile && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setErrorMessage("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      setFileList([file]);
      return false;
    },
  };

  const configureSmileIdentityWebIntegration = (tokenInfo) => {
    try {
      window.SmileIdentity({
        token: tokenInfo.token,
        product: "doc_verification",
        callback_url: tokenInfo.callback_url,
        environment: process.env.REACT_APP_MODE === 'production' ? "live" : "sandbox",
        id_selection: {
          [`${tokenInfo.country_iso}`]: ["IDENTITY_CARD", "PASSPORT"]
        },
        partner_details: {
          partner_id: tokenInfo.smile_id,
          name: `Keepup Store KYC`,
          logo_url: `https://business-logo.s3.eu-central-1.amazonaws.com/kus_icon.png`,
          policy_url: `https://keepup.store/privacy-policy`,
          theme_color: '#ececec'
        },
        onSuccess: () => {
          setRequesting(false);
          setKYCID(tokenInfo.KYC_id);
        },
        onClose: () => {
          setRequesting(false);
        },
        onError: () => {
          setRequesting(false);
          let _formErrors = formErrors;
          _formErrors.idVerification = "ID verification consent was denied."
          setFormError({..._formErrors});
        }
      });      
    } catch (error) {
      console.log(error);
      setRequesting(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getBusinessVerificationStatus(businessID)
  }, [match.params.businessID]);

  return (
    <React.Fragment>
      <Head title="Business Profile"></Head>
      <Content>
        {
          loading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs">
            <div className="inner-pre-loader">
              <Spinner  color="dark" />          
            </div>
          </Block>
          :
          <>
            <BlockHead className="mb-4" size="sm">
              <BlockHeadContent className="text-center">
                <BlockDes className="text-soft">
                  <p>{businessInfo.name}</p>
                </BlockDes>
                <BlockTitle page tag="h3">
                  Business Verification
                </BlockTitle>
                <BlockDes>
                  <p>Use the form below to verify your business.</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>

            <Block className="wide-sm m-auto">
              {
                errorMessage &&
                <Block>
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errorMessage}{" "}
                    </Alert>
                  </div>
                </Block>
              }

              <div className="card card-bordered">
                <div className="fm-content">
                {
                  ['not-verified','failed'].includes(verificationInfo.is_verified) &&
                  <Block className="p-0">
                    { 
                      verificationInfo.is_verified === 'failed' &&
                      <Alert color="danger" className="alert-icon mb-5">
                        <Icon name="na" />
                        <h6>Verification Failed</h6>
                        <p>{verificationInfo.reason}.</p>
                        <p className="pointer-cursor"><span className="alert-link" onClick={() => {window.Tawk_API.toggle();}}>Contact Support for assistance. </span></p>
                      </Alert>
                    }
                    {/* automatic verification */}
                    {/* <div className="card card-bordered">
                      <div className="fm-head bg-lighter border-bottom">
                        {
                          ['individual','business'].includes(verificationType) ?
                          <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                          :
                          <div className="fm-count"><Icon name="shield-star fs-22px"></Icon></div>
                        }
                        <div className="fm-title">
                          <h5 className="title fw-normal">Select Verification Type</h5>
                        </div>
                      </div>
                      <div className="fm-content">
                        <div className="form-group">
                          <div className="row g-gs">
                            <div className="col-md-6">
                              <div className={classNames("card-bordered card h-100", {"bg-blue-dim": verificationType === 'individual'})}>
                                <div className="nk-support-item pointer-cursor d-block" onClick={() => setVerificationType('individual')}>
                                  <em className="icon ni ni-user-alt h2" />
                                  <div className="nk-support-content m-0 mt-3">
                                    <CustomCheckbox
                                      checked={verificationType === 'individual'}
                                      id="individual"
                                      title="Individual"
                                      type="Unregistered Business"
                                      description="For sole proprietors or individuals operating a business without formal registration."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className={classNames("card-bordered card h-100", {"bg-blue-dim": verificationType === 'business'})}>
                                <div className="nk-support-item pointer-cursor d-block" onClick={() => setVerificationType('business')}>
                                  <em className="icon ni ni-building h2" />
                                  <div className="nk-support-content m-0 mt-3">
                                    <CustomCheckbox
                                      checked={verificationType === 'business'}
                                      id="business"
                                      title="Business"
                                      type="Registered Business"
                                      description="For businesses that have officially registered with the relevant authorities."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            formErrors.verificationType &&
                            <p className="text-danger fs-13px">{formErrors.verificationType}</p>
                          }
                        </div>
                      </div>
                      <div className="fm-head bg-lighter border-bottom">
                        {
                          kycID ?
                          <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                          :
                          <div className="fm-count"><Icon name="cc-secure fs-22px"></Icon></div>
                        }
                        <div className="fm-title">
                          <h5 className="title fw-normal">Verify ID</h5>
                        </div>
                      </div>
                      <div className="fm-content">
                        {
                          verificationType === 'individual' &&
                          <div className="form-group">
                            <div className="nk-help mb-2 align-start">
                              <div className="me-3">
                                <em className="icon ni ni-cc-secure h2" />
                              </div>
                              <div className="nk-help-text">
                                <h6> ID Verification</h6>
                                <p className="text-soft">
                                  Verify your identity by providing a valid ID such as: National ID or Passport.
                                </p>
                                <small className="text-primary">Note: We recommend you complete your ID verification on a smart phone.</small>
                              </div>
                              <div className="nk-help-action pt-1">
                                {
                                  kycID && verificationInfo.id_verification !== 'not-verified' ? 
                                  <button disabled className="btn btn-outline-primary">
                                    <span>Submitted</span>
                                  </button>
                                  :
                                  <button disabled={requesting} className="btn btn-outline-primary" onClick={() => getSmileToken()}>
                                    {requesting ? <Spinner size="sm" color="light" /> : <span>Start Verification</span>}
                                  </button>
                                }
                              </div>
                            </div>
                            {
                              formErrors.idVerification &&
                              <p className="text-danger fs-13px">{formErrors.idVerification}</p>
                            }
                          </div>
                        }

                        {
                          verificationType === 'business' &&
                          <div className="form-group">
                            <div className="nk-help mb-2 align-start">
                              <div className="mx-3">
                                <em className="icon ni ni-cc-secure h2" />
                              </div>
                              <div className="nk-help-text">
                                <h6> Director ID Verification</h6>
                                <p className="text-soft">
                                  Verify the identity of one of the Directors of the business by providing a valid ID such as: National ID or Passport.
                                </p>
                              </div>
                              <div className="nk-help-action pt-1">
                              {
                                kycID && verificationInfo.id_verification !== 'not-verified' ?
                                <button disabled className="btn btn-outline-gray">
                                  <span>Submitted</span>
                                </button>
                                :
                                <button disabled={requesting} className="btn btn-outline-primary" onClick={() => getSmileToken()}>
                                  {requesting ? <Spinner size="sm" color="light" /> : <span>Start Verification</span>}
                                </button>
                              }
                              </div>
                            </div>
                            {
                              formErrors.idVerification &&
                              <p className="text-danger fs-13px">{formErrors.idVerification}</p>
                            }
                          </div>
                        }
                      </div>                            
                      {
                        verificationType === 'business' &&
                        <>
                          <div className="fm-head align-start bg-lighter border-bottom">
                            {
                              fileList.length > 0 ?
                              <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                              :
                              <div className="fm-count"><Icon name="file-docs fs-22px"></Icon></div>
                            }
                            <div className="fm-title">
                              <h5 className="title fw-normal">Upload Business Documents</h5>
                              <p className="nk-block-des">Upload your business registration certificate along with a document that confirms the uploaded ID 
                              belongs to one of the directors of the business.</p>
                            </div>
                          </div>
                          <div className="fm-content">
                            <div className="form-group">
                              <Dragger {...uploadProps}>
                                <div className="p-3">
                                  <p className="ant-upload-drag-icon fs-1 text-muted">
                                    <Icon name="upload-cloud"></Icon>
                                  </p>
                                  <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                                  <p className="ant-upload-hint text-muted">
                                    Accepted file types for upload must be .jpg, .jpeg, .png, .pdf format, and the file size should not exceed 2MB.
                                  </p>

                                </div>
                              </Dragger>
                              {
                                formErrors.businessDocs &&
                                <p className="text-danger fs-13px mt-2">{formErrors.businessDocs}</p>
                              }
                            </div>
                          </div>
                        </>
                      }

                      <div className="fm-footer">
                        <div className="text-center">
                          <Button disabled={requesting} onClick={() => {handleSubmit()}} color="primary" size="lg" >
                            {requesting ? <Spinner size="sm" color="light" /> : <span>Submit</span>}
                          </Button>
                        </div>
                      </div>
                    </div> */}

                    {/* manual verification */}
                    <div className="card card-bordered">
                      <div className="fm-head bg-lighter border-bottom">
                        {
                          ['individual','business'].includes(verificationType) ?
                          <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                          :
                          <div className="fm-count"><Icon name="shield-star fs-22px"></Icon></div>
                        }
                        <div className="fm-title">
                          <h5 className="title fw-normal">Select Verification Type</h5>
                        </div>
                      </div>
                      <div className="fm-content">
                        <div className="form-group">
                          <div className="row g-gs">
                            <div className="col-md-6">
                              <div className={classNames("card-bordered card h-100", {"bg-blue-dim": verificationType === 'individual'})}>
                                <div className="nk-support-item pointer-cursor d-block" onClick={() => setVerificationType('individual')}>
                                  <em className="icon ni ni-user-alt h2" />
                                  <div className="nk-support-content m-0 mt-3">
                                    <CustomCheckbox
                                      checked={verificationType === 'individual'}
                                      id="individual"
                                      title="Individual"
                                      type="Unregistered Business"
                                      description="For sole proprietors or individuals operating a business without formal registration."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className={classNames("card-bordered card h-100", {"bg-blue-dim": verificationType === 'business'})}>
                                <div className="nk-support-item pointer-cursor d-block" onClick={() => setVerificationType('business')}>
                                  <em className="icon ni ni-building h2" />
                                  <div className="nk-support-content m-0 mt-3">
                                    <CustomCheckbox
                                      checked={verificationType === 'business'}
                                      id="business"
                                      title="Business"
                                      type="Registered Business"
                                      description="For businesses that have officially registered with the relevant authorities."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            formErrors.verificationType &&
                            <p className="text-danger fs-13px">{formErrors.verificationType}</p>
                          }
                        </div>
                      </div>
                      <div className="fm-head bg-lighter border-bottom">
                        {
                          idFileList.length > 0 ?
                          <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                          :
                          <div className="fm-count"><Icon name="cc-secure fs-22px"></Icon></div>
                        }
                        {
                          verificationType === 'individual' ?
                          <div className="fm-title">
                            <h5 className="title fw-normal"> ID Upload</h5>
                            <p className="nk-block-des">
                              Verify your identity by providing a valid ID such as: National ID or Passport.
                            </p>
                          </div>
                          :
                          <div className="fm-title">
                            <h5 className="title fw-normal"> Director ID Upload</h5>
                            <p className="nk-block-des">
                              Verify the identity of one of the Directors of the business by providing a valid ID such as: National ID or Passport.
                            </p>
                          </div>
                        }
                      </div>
                      <div className="fm-content">
                        <div className="form-group">
                          <Dragger {...uploadIDProps}>
                            <div className="p-3">
                              <p className="ant-upload-drag-icon fs-1 text-muted">
                                <Icon name="upload-cloud"></Icon>
                              </p>
                              <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint text-muted">
                                Accepted file types for upload must be .jpg, .jpeg, .png, .pdf format, and the file size should not exceed 2MB.
                              </p>

                            </div>
                          </Dragger>
                          {
                            formErrors.businessDocs &&
                            <p className="text-danger fs-13px mt-2">{formErrors.businessDocs}</p>
                          }
                        </div>
                      </div>                            
                      {
                        verificationType === 'business' &&
                        <>
                          <div className="fm-head align-start bg-lighter border-bottom">
                            {
                              fileList.length > 0 ?
                              <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                              :
                              <div className="fm-count"><Icon name="file-docs fs-22px"></Icon></div>
                            }
                            <div className="fm-title">
                              <h5 className="title fw-normal">Upload Business Documents</h5>
                              <p className="nk-block-des">Upload your business registration certificate along with a document that confirms the uploaded ID 
                              belongs to one of the directors of the business.</p>
                            </div>
                          </div>
                          <div className="fm-content">
                            <div className="form-group">
                              <Dragger {...uploadProps}>
                                <div className="p-3">
                                  <p className="ant-upload-drag-icon fs-1 text-muted">
                                    <Icon name="upload-cloud"></Icon>
                                  </p>
                                  <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                                  <p className="ant-upload-hint text-muted">
                                    Accepted file types for upload must be .jpg, .jpeg, .png, .pdf format, and the file size should not exceed 2MB.
                                  </p>

                                </div>
                              </Dragger>
                              {
                                formErrors.businessDocs &&
                                <p className="text-danger fs-13px mt-2">{formErrors.businessDocs}</p>
                              }
                            </div>
                          </div>
                        </>
                      }

                      <div className="fm-footer">
                        <div className="text-center">
                          <Button disabled={requesting} onClick={() => {handleManualVerificationSubmit()}} color="primary" size="lg" >
                            {requesting ? <Spinner size="sm" color="light" /> : <span>Submit</span>}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Block>
                }

                {
                  ['manual_review','pending','verified'].includes(verificationInfo.is_verified) &&
                  <Block>
                    {
                      verificationInfo.is_verified === 'verified' &&
                      <Alert color="success" className="alert-icon mb-5">
                        <Icon name="check-circle-cut" />
                        <h6>Verified</h6>
                        <p>Your business has been verified.</p>
                      </Alert>
                    }

                    {
                      ['pending','manual_review'].includes(verificationInfo.is_verified) &&
                      <Alert color="warning" className="alert-icon mb-5">
                        <Icon name="info" />
                        <h6>Pending Verification</h6>
                        <p>Your business verification is pending and will be reviewed within 48 hours.</p>
                      </Alert>
                    }

                    <div className="form-group">
                      {/* <div className="form-label-group mb-2">
                        <label className="form-label">Verify Business as: <span className="text-danger">*</span></label>
                      </div> */}
                      <div className="row g-gs mb-2">
                        <div className="col-md-12">
                          {
                            verificationInfo.verification_type === 'individual' ?
                            <div className="card-bordered card bg-blue-dim">
                              <div className="nk-support-item pointer-cursor d-block">
                                <em className="icon ni ni-user-alt h2" />
                                <div className="nk-support-content m-0 mt-3">
                                  <CustomCheckbox
                                    checked={true}
                                    id="individual"
                                    title="Individual"
                                    type="Unregistered Business"
                                    description="For sole proprietors or individuals operating a business without formal registration."
                                  />
                                </div>
                              </div>
                            </div>
                            :
                            <div className="card-bordered card bg-blue-dim">
                              <div className="nk-support-item pointer-cursor d-block">
                                <em className="icon ni ni-building h2" />
                                <div className="nk-support-content m-0 mt-3">
                                  <CustomCheckbox
                                    checked={true}
                                    id="business"
                                    title="Business"
                                    type="Registered Business"
                                    description="For businesses that have officially registered with the relevant authorities."
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    {
                      verificationInfo.verification_type === 'individual' &&
                      <div className="form-group mt-5">
                        {/* <div className="form-label-group mb-2">
                          <label className="form-label">Verify ID: <span className="text-danger">*</span></label>
                        </div> */}
                        <div className="card-bordered card mb-2">
                          <div className="card-inner">
                            <div className="nk-help">
                              <div className="mx-3">
                                <em className="icon ni ni-cc-secure h2" />
                              </div>
                              <div className="nk-help-text">
                                <h6> ID Verification</h6>
                                <p className="text-soft">
                                  Verify your identity by providing a valid ID such as: National ID or Passport.
                                </p>
                              </div>
                              {
                                verificationInfo.is_verified === 'pending' ?
                                <div className="nk-help-action">
                                  <button disabled className="btn btn-outline-warning">
                                    <Icon name="info" />
                                    <span>Pending</span>
                                  </button>
                                </div>
                                :
                                <div className="nk-help-action">
                                  <button disabled className="btn btn-success">
                                    <Icon name="check" />
                                    <span>Verified</span>
                                  </button>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      verificationInfo.verification_type === 'business' &&
                      <>
                        <div className="form-group mt-5">
                          {/* <div className="form-label-group mb-2">
                            <label className="form-label">Director ID: <span className="text-danger">*</span></label>
                          </div> */}
                          <div className="card-bordered card mb-2">
                            <div className="card-inner">
                              <div className="nk-help">
                                <div className="mx-3">
                                  <em className="icon ni ni-cc-secure h2" />
                                </div>
                                <div className="nk-help-text">
                                  <h6> Director ID Verification</h6>
                                  <p className="text-soft">
                                    Verify the identity of one of the Directors of the business by providing a valid ID such as: National ID or Passport.
                                  </p>
                                </div>
                                {
                                  verificationInfo.is_verified === 'pending' ?
                                  <div className="nk-help-action">
                                    {
                                      verificationInfo.id_verification === 'verified' &&
                                      <button disabled className="btn btn-success">
                                        <Icon name="check" />
                                        <span>Verified</span>
                                      </button>
                                    }

                                    {
                                      verificationInfo.id_verification === 'failed' &&
                                      <button disabled className="btn btn-danger">
                                        <Icon name="na" />
                                        <span>Failed</span>
                                      </button>
                                    }

                                    {
                                      verificationInfo.id_verification === 'pending' &&
                                      <button disabled className="btn btn-outline-warning">
                                        <Icon name="info" />
                                        <span>Pending</span>
                                      </button>
                                    }
                                  </div>
                                  :
                                  <div className="nk-help-action">
                                    <button disabled className="btn btn-success">
                                      <Icon name="check" />
                                      <span>Verified</span>
                                    </button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="form-group mt-5">
                          <div className="form-label-group">
                            <label className="form-label">Uploaded Business Registration Documents</label>
                          </div>
                          {
                            verificationInfo.documents.length > 0 &&
                            <>
                              {
                                verificationInfo.documents.map((url, index) => {
                                  return (
                                    <Alert className="alert-icon pointer-cursor" color="light" key={index} onClick={() => {window.open(url, '_blank').focus()}}>
                                      <Icon name="download-cloud" />
                                      Business registration document {index + 1}. <a href="#" onClick={(ev) => { ev.preventDefault(); window.open(url, '_blank').focus()}} className="alert-link">Click to Download</a>
                                    </Alert>

                                  )
                                })
                              }
                            </>
                          }
                        </div>
                      </>
                    }
                  </Block>
                }

                </div>
              </div>
            </Block>
          </>
        }
      </Content>
      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Submitted`} descriptionText={`Business Verification information has been submitted for verification successfully.`} 
        />
      }
    </React.Fragment>
  )
}