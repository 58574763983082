import React, { useEffect, useState } from "react";
import { getAxiosHeaders, orderStatusOptions, goBack } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import { 
  Alert,
  UncontrolledDropdown, 
  Dropdown, DropdownToggle, 
  DropdownMenu, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner } from "reactstrap";
import axios from 'axios';
import moment from "moment";
import { Input, Select, Checkbox, Form, Empty } from 'antd';
import {
  AlertModal,
  InfoModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";

const Footer = ({ history, expenseInfo, setExpenseInfo, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");

  const [activeModal, setActiveModal] = useState(null);
  
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const deleteExpense = () => {
    setErrorMessage("");
    toggleModal('loadingModal');

    axios.delete(baseURLs.API_URL + "/expenses/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: expenseInfo.business_id,
        expenses: JSON.stringify([expenseInfo.expense_id]),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let failedIDs = responseInfo.data.failed_expense_ids;

      if(failedIDs.length === 0){
        toggleModal('successModal');
        setTimeout(() => {
          goBack({history, link: `/expenses/b/${expenseInfo.business_id}`})
        }, 2000);

      }  else {
        setErrorMessage(`Error deleting expense. Try again`);
        toggleModal('deleteModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal('deleteModal');
      } 
    });
  }

  
  return (
    <div className="fm-footer">
      <div className="fm-action">
        <ul className="d-flex g-3 justify-content-center">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/expenses/b/${expenseInfo.business_id}/edit/${expenseInfo.expense_id}`}>
              <button className="btn btn-lg btn-primary">
                <em className="icon ni ni-edit-alt"></em>
                <span>Edit</span>
              </button>
            </Link>
          </li>
          
          <li>
            <UncontrolledDropdown>
              <DropdownToggle className="dropdown-toggle btn btn-lg btn-outline-light" color="none">
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  {
                    expenseInfo.has_supplier === 'yes' &&
                    <>
                      <li>
                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("paymentLogsModal")}>
                          <Icon name="list"></Icon>
                          <span>Payment Logs</span>
                        </DropdownItem>
                      </li>                
                      <li className="divider"></li>
                    </>
                  }
                  <li>
                    <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => toggleModal("deleteModal")}>
                      <Icon name="trash"></Icon>
                      <span>Delete Expense</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>


      {/* delete modal */}
      {
        activeModal === "deleteModal" &&
        <AlertModal 
          index="delete"
          showModal={true} 
          toggleModal={() => toggleModal('deleteModal')}
          headerText={`Delete this Expense?`}
          descriptionText={`Are you sure you want to delete this expense? This action can not be reverted.`}
          leftButtonText={`Delete`}
          leftButtonOnClick={deleteExpense} />
      
      }

      
      {/* print order label modal */}


      {/* payment logs modal */}
      {
        activeModal === "paymentLogsModal" &&
        <Modal backdrop="static" isOpen={true} id="payment-logs-modal">
          <ModalHeader
            toggle={() => toggleModal('paymentLogsModal')}
            close={
              <button className="close" onClick={() => toggleModal('paymentLogsModal')}>
                <Icon name="cross" />
              </button>
            } >
            Payment Logs
          </ModalHeader>
          <ModalBody className="modal-body-md">
            {
              expenseInfo.payment_log.length === 0 ?
              <div className="text-center m-5">
                <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                <div className="price-plan-info">
                  <h5 className="title fw-normal">No payment logs</h5>
                  <span>Payments made on this expense will show here.</span>
                </div>
              </div>
              :
              <ul className="timeline-list">
                {expenseInfo.payment_log.map((log, index) => {
                  return (
                    <li className="timeline-item" key={index}>
                      <div className={`timeline-status bg-primary ${index === 0 ? "is-outline" : ""}`}></div>
                      <div className="timeline-data">
                        <h6 className="timeline-title text-primary">Made payment of <small>{expenseInfo.currency}</small> {log.amount_paid}</h6>
                        <div className="timeline-des">
                          <p>Payment Type: <b className="text-capitalize">{log.payment_type}</b></p>
                          <p>Balance Due: <b><small>{expenseInfo.currency}</small> {log.balance_remaining}</b></p>
                          <p className="fs-12px">Updated By: <b>{log.updated_by}</b></p>
                          <span className="time"><Icon name="clock"></Icon> {moment(log.created_at).format("Do MMM YYYY, hh:mm a")}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            }
          </ModalBody>
        </Modal>
      }

      {/* Loading modal */}
      {
        activeModal === "loadingModal" &&
        <LoadingModal 
          showModal={true}
          toggleModal={() => toggleModal('loadingModal')}
          headerText={`Deleting...`}
          descriptionText={`Please wait while this expense is being deleted...`} />
      }
      

      {/* success modal */}
      {
        activeModal === "successModal" &&
        <SuccessModal 
          showModal={true}
          toggleModal={() => toggleModal('successModal')}
          headerText={'Deleted'}
          descriptionText={'The expense has been deleted successfully'} />
      }

    </div>
  );
};


export default Footer;