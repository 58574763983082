import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
 } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getPhoneCodeAndNumber, getQueryParams, formatTo2DP, moneyToFloat, orderStatusOptions } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import moment from 'moment';
import Numeral from 'react-numeral';
import DatePicker from "react-datepicker";
import { AutoComplete, Empty, Input, Select, Space, InputNumber, Divider, Spin, Form } from 'antd';
import GooglePlacesInput from "./components/GooglePlacesInput";
import PhoneNumberInput from "./components/PhoneNumberInput";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "./components/AlertModals";
import { CreateTaxProfileModal } from "./components/TaxModals";
import { CreateNewProductFullModal } from "./components/ProductModals";
import { CreateNewServiceModal } from "./components/ServiceModals";

const { TextArea } = Input;
const { Option } = Select;

const CreateSaleOrder = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [fetchingCustomers, setFetchingCustomers] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [fetchingServices, setFetchingServices] = useState(false);
  const [fetchingLocations, setFetchingLocations] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [usage, setUsage] = useState({used: 0, total: 0, package: ''});
  const [form] = Form.useForm();
  const [dueDate, setDueDate] = useState(new Date());
  const [dueDateTime, setDueDateTime] = useState(new Date());
  const [issueDate, setIssueDate] = useState(new Date());
  const [issueDateTime, setIssueDateTime] = useState(new Date());

  const [fulfillmentDate, setFulfillmentDate] = useState(new Date());
  const [fulfillmentDateTime, setFulfillmentDateTime] = useState(new Date());
  const [orderReminderDate, setOrderReminderDate] = useState('');
  const [orderReminderDateTime, setOrderReminderDateTime] = useState('');

  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [taxesList, setTaxesList] = useState([]);
  const [inventoryRestriction, setInventoryRestriction] = useState(false);
  const [servicesRestriction, setServicesRestriction] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [selectedServices, setSelectedServices] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const [itemsErrorMessage, setItemsErrorMessage] = useState("");
  const [itemFields, setItemFields] = useState([]);
  const [selectedTaxProfile, setSelectedTaxProfile] = useState({
    tax_id: null,
    tax_profile_name: null,
    taxes: [],
  });
  const [saleSummary, setSaleSummary] = useState({
    sub_total: 0,
    total_direct_tax: 0,
    total_compound_tax: 0,
    total_tax: 0,
    taxes: [],
    discount_amount: 0,
    discount_percentage: 0,
    total_amount: 0,
    amount_received: 0,
    amount_paid: 0,
    balance_due: 0,
    change: 0
  });
  const [alertCustomerCheck, setAlertCustomerCheck] = useState(true);
  const [formData, setFormData] = useState({
    customer_name: '',
    phone_code: "+233",
    phone_number: '',
    customer_email: '',
    items: [],
    order_status: 'outstanding',
    fulfillment_type: 'delivery',
    location_name: '',
    location: '',
    lng: null,
    lat: null,
    fulfillment_cost: 0,
    fulfillment_date: new Date(),
    fulfillment_date_time: new Date(),
    order_reminder_date: new Date(),
    order_reminder_date_time: new Date(),
    customer_order_note: '',
    alert_team: true,
    discount_type: 'fixed',
    discount_amount: 0,
    tax_profile: null,
    note: '',
    issue_date: new Date(),
    issue_date_time: new Date(),
    due_date: new Date(),
    due_date_time: new Date(),
    payment_type: 'cash',
    amount_received: 0,
    alert_customer: true,
    sale_type: 'invoice',
  });
  const [alertTeamChecked, setAlertTeamChecked] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");
    setItemsErrorMessage("")

    let phoneNumber = '';
    if(_formData.phone_code && _formData.phone_number){
      phoneNumber = `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`;
    }

    axios.post(baseURLs.API_URL + `/orders/add`, {
      business_id: businessID,
      customer_name: _formData.customer_name,
      phone_number: phoneNumber,
      customer_email: _formData.customer_email,
      items: JSON.stringify(_formData.items),
      fulfillment_type: _formData.fulfillment_type,
      location_name: _formData.location_name,
      location: _formData.location,
      lat: formData.lat,
      lng: formData.lng,
      fulfillment_cost: _formData.fulfillment_cost,
      discount_type: _formData.discount_type,
      discount_amount: _formData.discount_amount,
      tax_profile: _formData.tax_profile,
      note: _formData.note,
      issue_date: `${moment(_formData.issue_date).format("YYYY-MM-DD")} ${moment(_formData.issue_date_time).format("HH:mm")}`,
      due_date: `${moment(_formData.due_date).format("YYYY-MM-DD")} ${moment(_formData.due_date_time).format("HH:mm")}`,
      payment_type: _formData.payment_type,
      amount_received: _formData.amount_received,
      alert_customer: _formData.alert_customer ? 'yes' : 'no',
      sale_type: _formData.sale_type,
      order_status: _formData.order_status,
      fulfillment_date: `${moment(_formData.fulfillment_date).format("YYYY-MM-DD")} ${moment(_formData.fulfillment_date_time).format("HH:mm")}`,
      order_reminder_date: !(_formData.order_reminder_date && _formData.order_reminder_date) ? null : `${moment(_formData.order_reminder_date).format("YYYY-MM-DD")} ${moment(_formData.order_reminder_time).format("HH:mm")}`,
      customer_order_note: _formData.customer_order_note,
      alert_team: _formData.alert_team ? 'yes' : 'no',
    }, getAxiosHeaders())
    .then((response) => {  
      // redirect to sale details page
      let responseInfo = response.data;
      let saleID = responseInfo.data.sale_id;
      history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${saleID}?saved=yes&alerted=${_formData.alert_customer ? 'yes' : 'no'}`);
      return;

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          
          if(errorResponse.errors.hasOwnProperty("items")){
            setItemsErrorMessage(errorResponse.errors.items)
          } else {
            setItemsErrorMessage("")
          }

          form.setFields([
            {
              name: 'customer_name',
              errors: errorResponse.errors.hasOwnProperty("customer_name") ? [errorResponse.errors.customer_name] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone_number] : [],
            },
            {
              name: 'customer_email',
              errors: errorResponse.errors.hasOwnProperty("customer_email") ? [errorResponse.errors.customer_email] : [],
            },
            {
              name: 'fulfillment_type',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_type") ? [errorResponse.errors.fulfillment_type] : [],
            },
            {
              name: 'location_name',
              errors: errorResponse.errors.hasOwnProperty("location_name") ? [errorResponse.errors.location_name] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'fulfillment_cost',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_cost") ? [errorResponse.errors.fulfillment_cost] : [],
            },
            {
              name: 'discount_type',
              errors: errorResponse.errors.hasOwnProperty("discount_type") ? [errorResponse.errors.discount_type] : [],
            },
            {
              name: 'discount_amount',
              errors: errorResponse.errors.hasOwnProperty("discount_amount") ? [errorResponse.errors.discount_amount] : [],
            },
            {
              name: 'tax_profile',
              errors: errorResponse.errors.hasOwnProperty("tax_profile") ? [errorResponse.errors.tax_profile] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            },
            {
              name: 'issue_date',
              errors: errorResponse.errors.hasOwnProperty("issue_date") ? [errorResponse.errors.issue_date] : [],
            },
            {
              name: 'due_date',
              errors: errorResponse.errors.hasOwnProperty("due_date") ? [errorResponse.errors.due_date] : [],
            },
            {
              name: 'payment_type',
              errors: errorResponse.errors.hasOwnProperty("payment_type") ? [errorResponse.errors.payment_type] : [],
            },
            {
              name: 'amount_received',
              errors: errorResponse.errors.hasOwnProperty("amount_received") ? [errorResponse.errors.amount_received] : [],
            },
            {
              name: 'order_status',
              errors: errorResponse.errors.hasOwnProperty("order_status") ? [errorResponse.errors.order_status] : [],
            },
            {
              name: 'fulfillment_date',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_date") ? [errorResponse.errors.fulfillment_date] : [],
            },
            {
              name: 'order_reminder_date',
              errors: errorResponse.errors.hasOwnProperty("order_reminder_date") ? [errorResponse.errors.order_reminder_date] : [],
            },
            {
              name: 'customer_order_note',
              errors: errorResponse.errors.hasOwnProperty("customer_order_note") ? [errorResponse.errors.customer_order_note] : [],
            },
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setRequesting(false);
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const updateProductList = (productName) => {
    toggleModal('successProductModal');
    getProducts({businessID, productName});
  }

  const updateServiceList = (ServiceName) => {
    toggleModal('successServiceModal');
    getServices({businessID, ServiceName});
  }

  const getUsage = (businessID) => {
    setLoadingUsage(true);
    axios.get(baseURLs.API_URL + `/businesses/subscription-usage`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let subscriptionPackage = responseInfo.data.plan_name;
      let sales = responseInfo.data.sales;
      setUsage({
        used: sales.used, 
        total: sales.total,
        package: subscriptionPackage
      });
      setLoadingUsage(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
        setLoadingUsage(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getSaleDetails = (businessID, saleID) => {
    return axios.get(baseURLs.API_URL + `/sales/${saleID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let saleInfo = responseInfo.data.sale_info;
      let _formData = formData;
      
      let phoneCode = formData.phone_code;
      let phoneNumber = '';
      if(saleInfo.customer_phone_number) {
        let phoneNumberFormatted = getPhoneCodeAndNumber(saleInfo.customer_phone_number);
        phoneCode = phoneNumberFormatted.phoneCode;
        phoneNumber = phoneNumberFormatted.phoneNumber;
      }

      _formData.customer_name = saleInfo.customer_name;
      _formData.phone_code = phoneCode;
      _formData.phone_number = phoneNumber;
      _formData.customer_email = saleInfo.customer_email;
      
      let items = [];
      for(let i = 0; i < saleInfo.items.length; i++) {
        items.push({
          item_type: saleInfo.items[i].item_type,
          item_id: saleInfo.items[i].item_id,
          item_name: saleInfo.items[i].item_name,
          quantity: parseInt(saleInfo.items[i].quantity),
          price: moneyToFloat(saleInfo.items[i].price),
          description: saleInfo.items[i].description,
        })
      }
      _formData.items = items;

      if(saleInfo.delivery_pickup) {
        _formData.fulfillment_type = saleInfo.delivery_pickup.location_type;
        _formData.location_name = saleInfo.delivery_pickup.location_address;
        _formData.location = saleInfo.delivery_pickup.location_landmark;
        _formData.lat = saleInfo.delivery_pickup.location_lat;
        _formData.lng = saleInfo.delivery_pickup.location_lng;
        _formData.fulfillment_cost = moneyToFloat(saleInfo.delivery_pickup.location_cost);
      }
      _formData.discount_type = saleInfo.discount.discount_type;
      _formData.discount_amount = moneyToFloat(saleInfo.discount.discount_amount);
      _formData.tax_profile = saleInfo.tax_profile_id;
      _formData.note = saleInfo.note;
      _formData.issue_date = moment(); // moment(saleInfo.issued_date);
      _formData.issue_date_time = moment(); // moment(saleInfo.issued_date);
      _formData.due_date = moment(); // moment(saleInfo.due_date);
      _formData.due_date_time = moment(); // moment(saleInfo.due_date);

      // setIssueDate(moment().toDate());
      // setIssueDateTime(moment().toDate());
      // setDueDate(moment().toDate());
      // setDueDateTime(moment().toDate());

      setItemFields(items);

      setFormData({..._formData});

      setTimeout(() => {
        updateSaleSummary();
      }, 500);
    }).catch((error) => {
      console.log(error);
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getCustomers = ({businessID, customerName}) => {

    return axios.get(baseURLs.API_URL + "/customers", {
      params: {
        business_id: businessID,
        customer: customerName,
        access: 'record_sales',
        limit: 100
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let customers = responseInfo.data.customers;

        setCustomersList([...customers]);
      }

      if(fetchingCustomers){
        setFetchingCustomers(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingCustomers){
          setFetchingCustomers(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getProducts = ({businessID, productName}) => {

    return axios.get(baseURLs.API_URL + "/products/variants", {
      params: {
        business_id: businessID,
        product_name: productName,
        access: 'record_sales',
        limit: 100
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let products = responseInfo.data.products;

        setProductsList([...products]);
      }

      if(fetchingProducts){
        setFetchingProducts(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingProducts){
          setFetchingProducts(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getServices = ({businessID, serviceName}) => {

    return axios.get(baseURLs.API_URL + "/services", {
      params: {
        business_id: businessID,
        service_name: serviceName,
        access: 'record_sales',
        limit: 100
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let services = responseInfo.data.services;

        setServicesList([...services]);
      }

      if(fetchingServices){
        setFetchingServices(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingServices){
          setFetchingServices(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getLocations = ({businessID, locationName, locationType}) => {

    return axios.get(baseURLs.API_URL + `/businesses/delivery-pickup-locations`, {
      params: {
        business_id: businessID,
        location_name: locationName,
        location_type: locationType,
        access: 'record_sales',
        limit: 100
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let locations = responseInfo.data.locations;

        setLocationsList([...locations]);
      }

      if(fetchingLocations){
        setFetchingLocations(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingLocations){
          setFetchingLocations(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getSalesSettings = (businessID) => {

    return axios.get(baseURLs.API_URL + `/businesses/sales-settings`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID,
        access: 'record_sales',
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let _inventoryRestriction = responseInfo.data.restrict_to_inventory === 'yes' ? true : false;
        let _servicesRestriction = responseInfo.data.restrict_to_services === 'yes' ? true : false;

        setInventoryRestriction(_inventoryRestriction);
        setServicesRestriction(_servicesRestriction);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getTaxProfiles = (businessID) => {

    return axios.get(baseURLs.API_URL + `/businesses/taxes`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID,
        access: 'record_sales',
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let taxes = responseInfo.data.taxes;

        setTaxesList([...taxes]);
        if(formData.tax_profile){
          const selectedTax = taxes.find(tax => tax.tax_id === formData.tax_profile);
          if (selectedTax) {
            let { tax_id, tax_profile_name, taxes } = selectedTax;
            setSelectedTaxProfile({
              ...selectedTaxProfile,
              tax_id: tax_id,
              tax_profile_name: tax_profile_name,
              taxes: taxes,
            });
          }
        }
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const updateTaxProfiles = () => {
    toggleModal('successTaxModal')
    getTaxProfiles(businessID);
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items', 'location_name', 'fulfillment_cost', 'discount_amount', 'discount_type', 'tax_profile', 'amount_received'];
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      updateSaleSummary();
    }    
  }

  const updateSaleSummary = () => {
    const items = form.getFieldValue('items') || [];
    let fulfillmentCost = formatTo2DP(form.getFieldValue('fulfillment_cost') || 0);
    let discountAmount = formatTo2DP(form.getFieldValue('discount_amount') || 0);
    const discountType = form.getFieldValue('discount_type') || '';
    let amountReceived = formatTo2DP(form.getFieldValue('amount_received') || 0);

    let calculatedTaxAmounts = [];
    
    // Calculate Subtotal including Fulfillment Cost
    let subTotal = items.reduce((total, item) => {
      const price = item.price || 0;
      const quantity = item.quantity || 0;
      return total + price * quantity;
    }, 0) + formatTo2DP(fulfillmentCost);
    
    const { taxes } = selectedTaxProfile;
    
    // Calculate Direct Taxes on Subtotal including Fulfillment Cost
    let directTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'direct') {
        let taxAmount = formatTo2DP((subTotal * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'direct',
        });
        
        directTaxesTotal += taxAmount;
        
      }
    });
  
    // Calculate Subtotal with Direct Taxes and Fulfillment Cost
    let subTotalWithDirectTaxes = formatTo2DP(subTotal + directTaxesTotal);
  
    // Calculate Compound Taxes on Subtotal with Direct Taxes and Fulfillment Cost
    let compoundTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'compound') {
        let taxAmount = formatTo2DP((subTotalWithDirectTaxes * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'compound',
        });

        compoundTaxesTotal += taxAmount;
      }
    });
  
    // Calculate Total Tax Amount
    let totalTaxAmount = formatTo2DP(directTaxesTotal + compoundTaxesTotal);
  
    // Calculate Total Amount including Taxes, Fulfillment Cost, and Discounts
    let totalAmount = formatTo2DP(subTotalWithDirectTaxes + compoundTaxesTotal);
    
    let discountPercentage = 0;
    if(totalAmount !== 0){
      if (discountType === 'fixed') {
        discountPercentage = formatTo2DP((discountAmount * 100) / totalAmount);
        totalAmount -= formatTo2DP(discountAmount); // Deduct fixed discount amount
      } else if (discountType === 'percentage') {
        discountPercentage = discountAmount;
        discountAmount = formatTo2DP((totalAmount * discountAmount) / 100);
        totalAmount -= discountAmount; // Deduct discount percentage
      }
    }

    // Calculate balance due
    let balanceDue = formatTo2DP(totalAmount - amountReceived);
    let amountPaid = totalAmount >= amountReceived ? amountReceived : totalAmount;
  
    // Update sale summary state
    setSaleSummary({
      ...saleSummary,
      sub_total: subTotal,
      total_direct_tax: directTaxesTotal,
      total_compound_tax: compoundTaxesTotal,
      total_tax: totalTaxAmount,
      taxes: calculatedTaxAmounts,
      discount_amount: discountAmount,
      discount_percentage: discountPercentage,
      total_amount: totalAmount,
      amount_received: amountReceived,
      amount_paid: amountPaid,
      balance_due: balanceDue < 0 ? 0 : balanceDue,
      change: balanceDue < 0 ? balanceDue * -1 : 0
    })
  }
  

  const debouncedFetchCustomers = debounce(getCustomers, 1000);
  const debouncedFetchProducts = debounce(getProducts, 1000);
  const debouncedFetchServices = debounce(getServices, 1000);
  const debouncedFetchLocations = debounce(getLocations, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const handleCustomerNameChange = (customerName) => {
    // Fetch more customers when the user enters a customer name not found in the initial list
    if (!customersList.some(customer => customer.customer_name === customerName)) {
      debouncedFetchCustomers({businessID, customerName});
      setFetchingCustomers(true);
    }
  };

  const handleCustomerSelect = (value, option) => {
    const selected = customersList.find(customer => customer.customer_name === value);
    
    if (selected) {
      const { customer_phone_number, customer_email } = selected;
      let phoneNumberFormatted = getPhoneCodeAndNumber(customer_phone_number);
      
      form.setFieldValue("phone_code", phoneNumberFormatted.phoneCode);
      form.setFieldValue("phone_number", phoneNumberFormatted.phoneNumber);
      form.setFieldValue("email", customer_email || '');
      
      setFormData({
        ...formData,
        phone_code: phoneNumberFormatted.phoneCode,
        phone_number: phoneNumberFormatted.phoneNumber,
        email: customer_email || '', 
      });
    }
  };

  const handleProductChange = (productName) => {
    // Fetch more products when the user enters a product name not found in the initial list
    if (!productsList.some(product => product.product_name === productName)) {
      debouncedFetchProducts({businessID, productName});
      setFetchingProducts(true);
    }
  };

  const handleServiceChange = (serviceName) => {
    // Fetch more services when the user enters a service name not found in the initial list
    if (!servicesList.some(service => service.service_name === serviceName)) {
      debouncedFetchServices({businessID, serviceName});
      setFetchingServices(true);
    }
  };

  const handleProductSelect = (value, option, index) => {
    const selectedProduct = productsList.find(product => product.product_name === value);
    
    if (selectedProduct) {
      let { product_id, product_name, selling_price } = selectedProduct;
      selling_price = moneyToFloat(selling_price);

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_type: updatedItemFields[index].item_type,
        item_id: product_id,
        item_name: product_name,
        price: selling_price,
        quantity: 1,
        description: null
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleServiceSelect = (value, option, index) => {
    const selectedService = servicesList.find(service => service.service_name === value);
    
    if (selectedService) {
      let { service_id, service_name, selling_price } = selectedService;
      selling_price = moneyToFloat(selling_price);

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_id: service_id,
        item_name: service_name,
        price: selling_price,
        quantity: updatedItemFields[index].quantity
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleLocationChange = (locationName) => {
    // Fetch more location when the user enters a location name not found in the initial list
    if (!locationsList.some(location => location.location_name === locationName)) {
      debouncedFetchLocations({businessID, locationName, locationType: form.getFieldValue('fulfillment_type')});
      setFetchingLocations(true);
    }
  };

  const handleLocationSelect = (value, option) => {
    const selected = locationsList.find(location => location.location_name === value);
    
    if (selected) {
      let { cost, type, landmark, lat, lng } = selected;
      cost = moneyToFloat(cost);

      if(type === 'pick_up'){
        form.setFieldValue("fulfillment_cost", 0);
        form.setFieldValue("location", landmark);
        setFormData({
          ...formData,
          fulfillment_cost: 0,
          location: landmark,
          lat,
          lng
        });

      } else {
        form.setFieldValue("fulfillment_cost", cost);
        form.setFieldValue("location", '');
        setFormData({
          ...formData,
          fulfillment_cost: cost,
          location: '',
          lat: null,
          lng: null
        });
      }
            
    }
  };

  const handleApplyTotal = () => {
    form.setFieldValue("amount_received", saleSummary.total_amount);
    updateSaleSummary();
  };

  const handleAddProduct = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, { item_type: 'product', item_id: null, item_name: null, quantity: 1, price: 0, description: null }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleAddService = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, { item_type: 'service', item_id: null, item_name: null, quantity: 1, price: 0, description: null }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleAddCustomItem = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, { item_type: 'custom', item_id: null, item_name: null, quantity: 1, price: 0, description: null }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleRemoveItem = (index) => {
    const oldItemFields = form.getFieldValue("items");
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);
    setItemFields(newFields);

    form.setFieldsValue({
      items: newFields,
    });

    updateSaleSummary();
  };

  const handleTaxProfileSelect = (value, option) => {
    const selectedTax = taxesList.find(tax => tax.tax_id === value);
    if (selectedTax) {
      let { tax_id, tax_profile_name, taxes } = selectedTax;

      setSelectedTaxProfile({
        ...selectedTaxProfile,
        tax_id: tax_id,
        tax_profile_name: tax_profile_name,
        taxes: taxes,
      });
      
    } else {
      setSelectedTaxProfile({
        ...selectedTaxProfile,
        tax_id: null,
        tax_profile_name: null,
        taxes: [],
      });
    }
  }

  const handleSaleSubmitType = (type) => {
    form.setFieldValue('sale_type', type);
    form.submit();
  }

  useEffect(() => {
    updateSaleSummary();
  },[selectedTaxProfile])

  useEffect(() => {
    const { productNames, serviceNames } = itemFields.reduce(
      (acc, item) => {
        if (item.item_name) {
          const targetSet = item.item_type === 'product' ? acc.productNames : acc.serviceNames;
          targetSet.add(item.item_name);
        }
        return acc;
      },
      {
        productNames: new Set(),
        serviceNames: new Set(),
      }
    );
  
    setSelectedProducts(productNames);
    setSelectedServices(serviceNames);
  }, [itemFields]);

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let saleID = queryParams.hasOwnProperty('s') ? queryParams.s : null;

    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getUsage(businessID);

    Promise.all([
      getSalesSettings(businessID),
      getCustomers({businessID: businessID}),
      getProducts({businessID: businessID}),
      getServices({businessID: businessID}),
      getLocations({businessID: businessID, locationType: 'delivery'}),
      getTaxProfiles(businessID),
      saleID ? getSaleDetails(businessID, saleID) : Promise.resolve() // Add getSaleDetails if saleID is present
    ]).finally(() => {
      setLoading(false);
    });

    
    loadGoogleMapsApi();
  }, [match.params.businessID]);

  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }

  const discountTypeOptions = (
    <Form.Item name={'discount_type'}
        noStyle
        rules={[
        {required: false}
      ]}>
      <Select className="select-after"
        popupMatchSelectWidth={false}
        style={{
          width: 'fit-content',
        }}>
        <Option value="fixed">{businessInfo.currency}</Option>
        <Option value="percentage">%</Option>
      </Select>
    </Form.Item>
  );

  return (<React.Fragment>
    <Head title="Create Sale Order" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Create Sale Order
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      {
                        loadingUsage ?
                        <button className={`btn btn-outline-light`}>
                          <span>Usage: <b>...</b></span>
                          <Icon name="meter" />
                        </button>
                        :
                        <Link to={`${process.env.PUBLIC_URL}/subscription/b/${businessID}`}>
                          <button className={`btn btn-outline-${usage.used >= usage.total ? `danger` : `light`}`}>
                            {
                              usage.package === 'expansion' ?
                              <span>Usage: <b>{usage.used}</b></span>
                              :
                              <span>Usage: <b>{usage.used}</b> <small>/ {usage.total}</small> </span>
                            }
                            <Icon name="meter" />
                          </button>
                        </Link>
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        {/* {
          businessInfo.is_verified !== 'verified' &&
          <Block className="wide-sm m-auto mt-3">
            <div className="mb-3">                
              <Alert color="warning" className="alert-icon">
                <Icon name="alert-circle" /> 
                <b>Your business is not yet verified. As a result, { !moment().isSameOrAfter(moment('2024-06-01'), 'day') ? `starting 1st June, 2024 the following message will appear ` : `the following message appears ` }to your customers on receipts, quotes, and invoices:</b><br/>
                <i>Caution! This business has not yet been verified by Keepup Store. Please exercise caution and verify the authenticity of this business before proceeding with any transactions.</i><br/>
                <p className="pt-2 fw-bold">Please complete the verification process to remove this message. <Link to={`${process.env.PUBLIC_URL}/business-settings/b/${businessID}/business-verification`}><span className="alert-link">Start Business Verification</span></Link>. <br/>
                  <span className="alert-link pointer-cursor" onClick={() => toggleModal('verificationReasonModal')}>Learn more</span>
                </p>
              </Alert>
            </div>

            {
              activeModal === "verificationReasonModal" &&
              <Modal isOpen={true} toggle={() => toggleModal('verificationReasonModal')}>
                <ModalHeader
                  toggle={() => toggleModal('filterModal')}
                  close={
                    <button className="close" onClick={() => toggleModal('filterModal')}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Why Verify your Business
                </ModalHeader>
                <ModalBody>
                  <p>Verifying your business is an important step to ensure trust and confidence among your customers. When customers see that your business is verified, it reassures them of your credibility and legitimacy, enhances your professional image, and helps prevent impersonators from misrepresenting your business.</p>
                  <p className="fw-bold">It's important to note, while verification can provide these benefits, it's not mandatory.</p>
                </ModalBody>
              </Modal>
            }
          </Block>
        } */}

        <Block className="wide-sm m-auto mt-5">
          {errorMessage && (
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorMessage}{" "}
            </Alert>
          )}
          <Form name="create-invoice-receipt"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFieldsChange={(changedFields, allFields) => {
              debouncedHandleChangedFields(changedFields, allFields);       
            }}
            onFinishFailed={onFinishFailed}>
            <Form.Item name="sale_type" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>
            <div className="card card-bordered">
              {/* customer details section */}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="user fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Customer Details</h5>
                  <p className="nk-block-des">Enter the details of the customer your are selling to.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Customer Name <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'customer_name'}
                          noStyle
                          rules={[
                            {required: false,}
                          ]}>
                          <AutoComplete
                            size="large"
                            options={customersList.map(customer => (
                              {
                                key: customer.customer_id,
                                label: `${customer.customer_name?.length > 0 ? customer.customer_name : `Customer`} - (${customer.customer_phone_number}) - ID${customer.customer_code}`,
                                value: `${customer.customer_name?.length > 0 ? customer.customer_name : `Customer`}`,
                              }
                            ))}
                            filterOption={(inputValue, option) =>
                              option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            notFoundContent={
                              fetchingCustomers ? 
                              <div className="text-center m-5">
                                <Spin/>
                              </div> : null}
                            onSelect={handleCustomerSelect}
                            onChange={handleCustomerNameChange}
                            placeholder="Enter customer name"
                            className="w-100"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Phone Number <span className="text-muted">(optional)</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <PhoneNumberInput form={form} formData={formData} isRequired={false} setFormData={setFormData} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Email <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'customer_email'}
                          noStyle
                          rules={[
                            {required: false}, {type: 'email', message: 'Customer email is invalid'}
                          ]}>
                          <Input size="large" type="email" className="form-control form-control-lg" placeholder="customer@email.com" />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              {/* products and services section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="list fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Products / Services</h5>
                  <p className="nk-block-des">Add items the customer is buying.</p>
                </div>
              </div>
              <div className="fm-content">
                { itemsErrorMessage &&
                  <p className="text-danger mb-2">{itemsErrorMessage}</p>
                }
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-bordered card-full card">
                      {
                        itemFields.length === 0 ?
                        <div className="nk-block">
                          <div className="text-center m-5">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <span className="title fw-normal">No item added</span>
                            </div>
                            <div className="price-plan-action">
                              <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddProduct()}>
                                <Icon name="package" />
                                <span>Add Product</span>
                              </button>
                              <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddService()}>
                                <Icon name="briefcase" />
                                <span>Add Service</span>
                              </button>
                              {
                                (!inventoryRestriction && !servicesRestriction) &&
                                <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddCustomItem()}>
                                  <Icon name="cards" />
                                  <span>Add Custom Item</span>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                        :
                        <ul className="nk-support">
                          {
                            itemFields.map((item, index) => {
                              let itemField = '';
                              if(item.item_type === 'product'){
                                itemField = (
                                  inventoryRestriction ?
                                  <>
                                    <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="form-label-group">
                                            <label className="form-label"><b>#{index + 1}.</b> Product Name <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'item_name']}
                                            rules={[{ required: true, message: 'Please enter a product name' }]}>
                                            <Select
                                              size="large"
                                              onChange={(value) => handleProductChange(value, index)}
                                              dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: '8px 0', }} />
                                                  <Space style={{ padding: '0 8px 4px', }}>
                                                    <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewProductModal')}}>
                                                      <Icon name="plus" />
                                                      <span>Create New Product</span>
                                                    </Button>
                                                  </Space>
                                                </>
                                              )}
                                              options={
                                                productsList.map(prod => {
                                                  // Check if the product is selected in another field
                                                  const isAlreadySelected = selectedProducts.has(prod.product_name) && prod.product_name !== itemFields[index].item_name;
                                                  const isOutOfStock = prod.stock_status === 'out_of_stock' ? true : false;

                                                  return {
                                                    key: prod.product_id,
                                                    label: isAlreadySelected ? `${prod.product_name} - (Selected)` : `${prod.product_name} ${isOutOfStock ? `- (Out of Stock)` : ``}`,
                                                    value: prod.product_name,
                                                    disabled: isAlreadySelected || isOutOfStock, // Disable the option if it's already selected
                                                  };
                                                })
                                              }
                                              onSelect={(value, option) => handleProductSelect(value, option, index)}
                                              onSearch={handleProductChange}
                                              showSearch={true}
                                              notFoundContent={
                                                fetchingProducts ? 
                                                <div className="text-center m-5">
                                                  <Spin/>
                                                </div>
                                                :
                                                <div className="text-center m-5">
                                                  <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                                </div>
                                              }
                                              placeholder="Enter product name"
                                              className="w-100"
                                            />
                                          </Form.Item>
                                        </div>                                        
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'description']}
                                              noStyle
                                              rules={[
                                                {required: false}
                                              ]}>
                                              <TextArea rows={2} placeholder="Enter description" />
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Price <span className="text-danger">*</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'price']}
                                              noStyle
                                              rules={[
                                                {required: true, message: 'Price is required'}
                                              ]}>
                                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} disabled size="large" className="w-100" /> 
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Quantity <span className="text-danger pe-2">*</span>
                                              {
                                                item.item_name && productsList.find(product => product.product_name === item.item_name)?.quantity &&
                                                <small className="text-muted">( In-Stock: {productsList.find(product => product.product_name === item.item_name)?.quantity ?? 0} )</small>
                                              } 
                                            </label>
                                          </div>
                                          <Form.Item name={['items', index, 'quantity']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Quantity is required'}
                                            ]}>
                                            <InputNumber min={1} step={1} max={productsList.find(product => product.product_name === item.item_name)?.quantity ?? {}} size="large" className="w-100" />                                    
                                          </Form.Item> 
                                          <Form.Item name={['items', index, 'item_type']} hidden><Input type="hidden" /></Form.Item> 
                                          <Form.Item name={['items', index, 'item_id']} hidden><Input type="hidden" /></Form.Item> 
                                        </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="form-label-group">
                                            <label className="form-label"><b>#{index + 1}.</b> Product Name <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'item_name']}
                                            rules={[{ required: true, message: 'Please enter a product name' }]}>
                                            <Select
                                              size="large"
                                              onChange={(value) => handleProductChange(value, index)}
                                              dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: '8px 0', }} />
                                                  <Space style={{ padding: '0 8px 4px', }}>
                                                    <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewProductModal')}}>
                                                      <Icon name="plus" />
                                                      <span>Create New Product</span>
                                                    </Button>
                                                  </Space>
                                                </>
                                              )}
                                              options={
                                                productsList.map(prod => {
                                                  // Check if the product is selected in another field
                                                  const isAlreadySelected = selectedProducts.has(prod.product_name) && prod.product_name !== itemFields[index].item_name;
                                                  
                                                  return {
                                                    key: prod.product_id,
                                                    label: isAlreadySelected ? `${prod.product_name} - (Selected)` : `${prod.product_name}`,
                                                    value: prod.product_name,
                                                    disabled: isAlreadySelected, // Disable the option if it's already selected
                                                  };
                                                })
                                              }
                                              onSelect={(value, option) => handleProductSelect(value, option, index)}
                                              onSearch={handleProductChange}
                                              showSearch={true}
                                              notFoundContent={
                                                fetchingProducts ? 
                                                <div className="text-center m-5">
                                                  <Spin/>
                                                </div>
                                                :
                                                <div className="text-center m-5">
                                                  <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                                </div>
                                              }
                                              placeholder="Enter product name"
                                              className="w-100"
                                            />
                                          </Form.Item>
                                        </div>                                        
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'description']}
                                              noStyle
                                              rules={[
                                                {required: false}
                                              ]}>
                                              <TextArea rows={2} placeholder="Enter description" />
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Price <span className="text-danger">*</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'price']}
                                              noStyle
                                              rules={[
                                                {required: true, message: 'Price is required'}
                                              ]}>
                                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Quantity <span className="text-danger pe-2">*</span>
                                              {
                                                item.item_name && productsList.find(product => product.product_name === item.item_name)?.quantity &&
                                                <small className="text-muted">( In-Stock: {productsList.find(product => product.product_name === item.item_name)?.quantity ?? 0} )</small>
                                              } 
                                            </label>
                                          </div>
                                          <Form.Item name={['items', index, 'quantity']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Quantity is required'}
                                            ]}>
                                            <InputNumber min={1} step={1} size="large" className="w-100" />
                                          </Form.Item> 
                                          <Form.Item name={['items', index, 'item_type']} hidden><Input type="hidden" /></Form.Item> 
                                          <Form.Item name={['items', index, 'item_id']} hidden><Input type="hidden" /></Form.Item> 
                                        </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  
                                )
                              } else if(item.item_type === 'service'){
                                itemField = (
                                  servicesRestriction ?
                                  <>
                                    <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="form-label-group">
                                            <label className="form-label"><b>#{index + 1}.</b> Service Name <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'item_name']}
                                            rules={[{ required: true, message: 'Please enter a service name' }]}>
                                            <Select
                                              size="large"
                                              onChange={(value) => handleServiceChange(value, index)}
                                              dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: '8px 0', }} />
                                                  <Space style={{ padding: '0 8px 4px', }}>
                                                    <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewServiceModal')}}>
                                                      <Icon name="plus" />
                                                      <span>Create New Service</span>
                                                    </Button>
                                                  </Space>
                                                </>
                                              )}
                                              options={
                                                servicesList.map(service => {
                                                  // Check if the service is selected in another field
                                                  const isAlreadySelected = selectedServices.has(service.service_name) && service.service_name !== itemFields[index].item_name;
                                                  
                                                  return {
                                                    key: service.service_id,
                                                    label: isAlreadySelected ? `${service.service_name} - (Selected)` : `${service.service_name}`,
                                                    value: service.service_name,
                                                    // disabled: isAlreadySelected, // Disable the option if it's already selected
                                                  };
                                                })
                                              }
                                              onSelect={(value, option) => handleServiceSelect(value, option, index)}
                                              onSearch={handleServiceChange}
                                              showSearch={true}
                                              notFoundContent={
                                                fetchingServices ? 
                                                <div className="text-center m-5">
                                                  <Spin/>
                                                </div>
                                                :
                                                <div className="text-center m-5">
                                                  <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                                </div>
                                              }
                                              placeholder="Enter service name"
                                              className="w-100"
                                            />
                                          </Form.Item>
                                        </div>                                        
                                      </div>
    
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'description']}
                                              noStyle
                                              rules={[
                                                {required: false}
                                              ]}>
                                              <TextArea rows={2} placeholder="Enter the description of the service" />
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Price <span className="text-danger">*</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'price']}
                                              noStyle
                                              rules={[
                                                {required: true, message: 'Price is required'}
                                              ]}>
                                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'quantity']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Quantity is required'}
                                            ]}>
                                            <InputNumber min={1} step={1} size="large" className="w-100" /> 
                                          </Form.Item> 
                                          <Form.Item name={['items', index, 'item_type']} hidden><Input type="hidden" /></Form.Item> 
                                          <Form.Item name={['items', index, 'item_id']} hidden><Input type="hidden" /></Form.Item> 
                                        </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="form-label-group">
                                            <label className="form-label"><b>#{index + 1}.</b> Service Name <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'item_name']}
                                            rules={[{ required: true, message: 'Please enter a service name' }]}>
                                            <Select
                                              size="large"
                                              onChange={(value) => handleServiceChange(value, index)}
                                              dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: '8px 0', }} />
                                                  <Space style={{ padding: '0 8px 4px', }}>
                                                    <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewServiceModal')}}>
                                                      <Icon name="plus" />
                                                      <span>Create New Service</span>
                                                    </Button>
                                                  </Space>
                                                </>
                                              )}
                                              options={
                                                servicesList.map(service => {
                                                  // Check if the service is selected in another field
                                                  const isAlreadySelected = selectedServices.has(service.service_name) && service.service_name !== itemFields[index].item_name;
                                                  
                                                  return {
                                                    key: service.service_id,
                                                    label: isAlreadySelected ? `${service.service_name} - (Selected)` : `${service.service_name}`,
                                                    value: service.service_name,
                                                    // disabled: isAlreadySelected, // Disable the option if it's already selected
                                                  };
                                                })
                                              }
                                              onSelect={(value, option) => handleServiceSelect(value, option, index)}
                                              onSearch={handleServiceChange}
                                              showSearch={true}
                                              notFoundContent={
                                                fetchingServices ? 
                                                <div className="text-center m-5">
                                                  <Spin/>
                                                </div>
                                                :
                                                <div className="text-center m-5">
                                                  <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                                </div>
                                              }
                                              placeholder="Enter service name"
                                              className="w-100"
                                            />
                                          </Form.Item>
                                        </div>                                        
                                      </div>
    
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'description']}
                                              noStyle
                                              rules={[
                                                {required: false}
                                              ]}>
                                              <TextArea rows={2} placeholder="Enter the description of the service" />
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <Form.Item>
                                            <div className="form-label-group">
                                              <label className="form-label">Price <span className="text-danger">*</span></label>
                                            </div>
                                            <Form.Item name={['items', index, 'price']}
                                              noStyle
                                              rules={[
                                                {required: true, message: 'Price is required'}
                                              ]}>
                                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                            </Form.Item> 
                                          </Form.Item>
                                        </div>
                                      </div>
    
                                      <div className="col-md-4">
                                        <div className="form-group">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'quantity']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Quantity is required'}
                                            ]}>
                                            <InputNumber min={1} step={1} size="large" className="w-100" /> 
                                          </Form.Item>
                                          <Form.Item name={['items', index, 'item_type']} hidden><Input type="hidden" /></Form.Item> 
                                          <Form.Item name={['items', index, 'item_id']} hidden><Input type="hidden" /></Form.Item> 
                                        </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              } else if(item.item_type === 'custom'){
                                itemField = <>
                                  <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label"><b>#{index + 1}.</b> Item Name <span className="text-danger">*</span></label>
                                        </div>
                                        <Form.Item name={['items', index, 'item_name']}
                                          rules={[{ required: true, message: 'Please enter an item name' }]}>
                                          <TextArea rows={2} placeholder="Enter an item name" />
                                        </Form.Item>
                                      </div>                                        
                                    </div>

                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Price <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['items', index, 'price']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'Price is required'}
                                            ]}>
                                            <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                          </Form.Item> 
                                        </Form.Item>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="form-group">
                                      <Form.Item>
                                        <div className="form-label-group">
                                          <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                        </div>
                                        <Form.Item name={['items', index, 'quantity']}
                                          noStyle
                                          rules={[
                                            {required: true, message: 'Quantity is required'}
                                          ]}>
                                          <InputNumber min={1} step={1} size="large" className="w-100" /> 
                                        </Form.Item>
                                        <Form.Item name={['items', index, 'item_type']} hidden><Input type="hidden" /></Form.Item> 
                                        <Form.Item name={['items', index, 'item_id']} hidden><Input type="hidden" /></Form.Item> 
                                      </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                              

                              return (
                                <li className="nk-support-item d-block" key={index}>{itemField}</li>
                              )
                            })
                          }
                        </ul>                            
                      }
                    </div>
                      
                  </div>
                  {
                    itemFields.length > 0 && 
                    <div className="col-md-12 text-center">
                      <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddProduct()} >
                        <Icon name="package"></Icon>
                        <span>Add Product</span>
                      </Button>
                      <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddService()} >
                        <Icon name="briefcase"></Icon>
                        <span>Add Service</span>
                      </Button>
                      {
                        (!inventoryRestriction && !servicesRestriction) &&
                        <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddCustomItem()} >
                          <Icon name="cards" />
                          <span>Add Custom Item</span>
                        </Button>
                      }
                    </div>
                  }
                </div>
              </div>

              {/* fulfillment section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="truck fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Order Fulfillment</h5>
                  <p className="nk-block-des">Add details of how and when the order will be delivered.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Fulfillment Type <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'fulfillment_type'}
                            noStyle
                            rules={[
                              {required: true, message: `Select delivery or pick up`}
                            ]}>
                            <Select required size="large" 
                              placeholder="Select delivery or pick up"
                              style={{ width: "100%" }} 
                              onChange={(value) => {getLocations({businessID, locationType: value})}}
                              options={[
                                {
                                  key: 'delivery',
                                  label: 'Delivery',
                                  value: 'delivery',
                                },
                                {
                                  key: 'pick_up',
                                  label: 'Pick Up',
                                  value: 'pick_up',
                                },                              
                              ]}
                              showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">City / Town <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'location_name'}
                          noStyle
                          rules={[
                            {required: true, message: `City / Town is required`}
                          ]}>
                          <AutoComplete
                            size="large"
                            
                            options={locationsList.map(location => (
                              {
                                key: location.location_id,
                                label: location.location_name,
                                value: location.location_name,
                              }
                            ))}
                            filterOption={(inputValue, option) =>
                              option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            notFoundContent={
                              fetchingLocations ? 
                              <div className="text-center m-5">
                                <Spin/>
                              </div> : null}
                            onSelect={handleLocationSelect}
                            onChange={handleLocationChange}
                            placeholder="Enter City / Town"
                            className="w-100"
                            allowClear
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Location <span className="text-danger">*</span></label>
                      </div>
                      <GooglePlacesInput form={form} isRequired={true} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={businessInfo.country_iso} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Cost <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'fulfillment_cost'}
                          noStyle
                          rules={[
                            {required: true, message: `${formData.fulfillment_type} cost is required`}
                          ]}>
                          <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <hr className="m-3 mx-0" />
                <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Order Status <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'order_status'}
                            noStyle
                            rules={[
                              {required: true, message: 'Order status is required',}
                            ]}>
                            <Select required size="large" 
                              name="order_status"
                              placeholder="Select order status"
                              style={{ width: "100%" }}
                              options={orderStatusOptions} 
                              showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label">Delivery / Pickup Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item>
                              <Form.Item name={'fulfillment_date'}
                                noStyle
                                rules={[
                                  {required: true, message: 'Fulfillment date is required',}
                                ]}>
                                <DatePicker
                                  selected={fulfillmentDate}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={date => {
                                    setFulfillmentDate(date);
                                    form.setFieldsValue({fulfillment_date: date}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                          <div className="col-5">
                            <Form.Item>
                              <Form.Item name={'fulfillment_date_time'}
                                noStyle
                                rules={[
                                  {required: true, message: 'time is required',}
                                ]}>
                                <DatePicker
                                  selected={fulfillmentDateTime}
                                  dateFormat="h:mm aa"
                                  onChange={time => {
                                    setFulfillmentDateTime(time);
                                    form.setFieldsValue({fulfillment_date_time: time}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label">Order Reminder Date <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item>
                              <Form.Item name={'order_reminder_date'}
                                rules={[
                                  {required: false}
                                ]}
                                noStyle>
                                <DatePicker
                                  selected={orderReminderDate}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={date => {
                                    setOrderReminderDate(date);
                                    form.setFieldsValue({order_reminder_date: date}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                          <div className="col-5">
                            <Form.Item>
                              <Form.Item name={'order_reminder_date_time'}
                                rules={[
                                  {required: false}
                                ]}
                                noStyle>
                                <DatePicker
                                  selected={orderReminderDateTime}
                                  dateFormat="h:mm aa"
                                  onChange={time => {
                                    setOrderReminderDateTime(time)
                                    form.setFieldsValue({order_reminder_date_time: time}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Order note to customer <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'customer_order_note'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                              <TextArea rows={4} placeholder="Note for customer" />
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="alert_team" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={alertTeamChecked}
                              onChange={() => {
                                setAlertTeamChecked(!alertTeamChecked);
                                form.setFieldValue('alert_team', !alertTeamChecked);
                              }}
                              id="new_order_alert_team" />
                            <label className="custom-control-label" htmlFor="new_order_alert_team">
                              Alert Team Members
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                </div>
              </div>

              {/* discount and tax section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="percent fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Discount / Tax</h5>
                  <p className="nk-block-des">Add discount and tax to this sale.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Discount <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'discount_amount'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <InputNumber addonAfter={discountTypeOptions} min={0} step={0.01}  size="large" className="w-100" placeholder="eg. 10" />
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Tax Profile <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'tax_profile'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Select size="large" 
                              placeholder="Select a tax profile"
                              style={{ width: "100%" }} 
                              allowClear={true}
                              onChange={handleTaxProfileSelect}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: '8px 0',
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: '0 8px 4px',
                                    }}
                                  >
                                    <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createTaxProfileModal')}}>
                                      <Icon name="plus" />
                                      <span>Create Tax Profile</span>
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={taxesList.map(tax => (
                                {
                                  key: tax.tax_id,
                                  label: tax.tax_profile_name,
                                  value: tax.tax_id,
                                }
                              ))}
                              showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              {/* date and payment section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="cc-alt2 fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Dates / Payment</h5>
                  <p className="nk-block-des">Add dates and payment to this sale.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Issue Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'issue_date'}
                              noStyle
                              rules={[
                                {required: true, message: 'Issue Date is required',}
                              ]}>
                              <DatePicker
                                selected={issueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setIssueDate(date);
                                  form.setFieldsValue({issue_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'issue_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Issue date time is required',}
                              ]}>
                              <DatePicker
                                selected={issueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setIssueDateTime(time);
                                  form.setFieldsValue({issue_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Due Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'due_date'}
                              noStyle
                              rules={[
                                { required: true, message: 'Due Date is required' },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    const issueDate = getFieldValue('issue_date');
                                    if (!issueDate || !value) {
                                      return Promise.resolve();
                                    }
                                    if (moment(value).isSameOrAfter(issueDate, 'day')) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Due Date must be after or on Issue Date'));
                                  },
                                }),
                              ]}>
                              <DatePicker
                                selected={dueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setDueDate(date);
                                  form.setFieldsValue({due_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'due_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Due date time is required',}
                              ]}>
                              <DatePicker
                                selected={dueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setDueDateTime(time);
                                  form.setFieldsValue({due_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Payment Type <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'payment_type'}
                          noStyle
                          rules={[
                            {required: true, message: `Select payment type`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select payment type"
                            style={{ width: "100%" }} 
                            options={[
                              {
                                key: 'cash',
                                label: 'Cash',
                                value: 'cash',
                              },
                              {
                                key: 'check',
                                label: 'Check',
                                value: 'check',
                              }, 
                              {
                                key: 'card payment',
                                label: 'Card Payment',
                                value: 'card payment',
                              },
                              {
                                key: 'bank',
                                label: 'Bank Transfer / Deposit',
                                value: 'bank',
                              }, 
                              {
                                key: 'mobile money',
                                label: 'Mobile Money',
                                value: 'mobile money',
                              },
                              {
                                key: 'digital wallet',
                                label: 'Digital Wallet',
                                value: 'digital wallet',
                              },                              
                            ]}
                            showSearch />  
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">                      
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Amount Received <span className="text-danger">*</span></label>
                        </div>
                        <Space.Compact >
                          <Form.Item name={'amount_received'}
                            noStyle
                            rules={[
                              {required: true, message: 'amount received is required'}
                            ]}>
                                <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                          </Form.Item> 
                          
                          <Button outline type="button" color="primary" className="text-nowrap" onClick={handleApplyTotal}>Full Amount</Button>
                        </Space.Compact>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Note to customer <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'note'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                            <TextArea rows={4} placeholder="Note for customer" />
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              {/* summary section */}
              <div className="fm-content bg-lighter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="nk-iv-scheme-details">
                      <ul className="nk-iv-wg3-list w-100">
                        <li className="px-0 py-2 fw-medium">
                          <div className="sub-text fs-16px">Sub Total</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-16px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={saleSummary.sub_total.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                        <li className="px-0 py-2 d-block">
                          {
                            saleSummary.taxes.length > 0 &&
                            <div className="mb-2">
                              {
                                saleSummary.taxes.map((tax, index) => {
                                  return(
                                    <div className="d-flex justify-content-between mb-1" key={index}>
                                      <div className="sub-text fs-14px">{tax.tax} <small>( {tax.percentage}% )</small></div>
                                      <div className="nk-wg-action-content p-0">
                                        <h4 className="amount fs-14px">
                                          <small className="text-muted me-1">{businessInfo.currency}</small> 
                                          <Numeral value={tax.amount.toString()} format={"0,0.00"} />
                                        </h4>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          }
                          

                          <div className="d-flex justify-content-between fw-medium">
                            <div className="sub-text fs-14px">Total Tax</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className="amount fs-14px">
                                <small className="text-muted me-1">{businessInfo.currency}</small> 
                                <Numeral value={saleSummary.total_tax.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </div>
                        </li>
                        <li className="px-0 py-2">
                          <div className="sub-text fs-14px">Discount</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-14px">
                              <small className="text-muted me-1">- {businessInfo.currency}</small>
                              <Numeral value={saleSummary.discount_amount.toString()} format={"0,0.00"} />
                              <small className="text-muted"> ({saleSummary.discount_percentage}%)</small>
                            </h4>
                          </div>
                        </li>
                        <li className="px-0 py-2 fw-bold">
                          <div className="sub-text fs-18px">Total</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-18px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={saleSummary.total_amount.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="nk-iv-scheme-details">
                      <ul className="nk-iv-wg3-list w-100">
                        <li className="px-0 py-2">
                          <div className="sub-text fs-14px">Amount Received</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-14px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={saleSummary.amount_received.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                        <li className="px-0 py-2">
                          <div className="sub-text fs-14px">Amount Paid</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-14px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={saleSummary.amount_paid.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                        <li className="px-0 py-2 fw-bold">
                          <div className={`sub-text fs-18px ${saleSummary.balance_due > 0 ? `text-danger`: ``}`}>Balance Due</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className={`amount fs-18px ${saleSummary.balance_due > 0 ? `text-danger`: ``}`}>
                              <small className={`${saleSummary.balance_due > 0 ? `text-danger`: `text-muted`} me-1`}>{businessInfo.currency}</small>
                              <Numeral value={saleSummary.balance_due.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                        <li className="px-0 py-2">
                          <div className="sub-text fs-14px">Change</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className={`amount fs-14px ${saleSummary.change > 0 ? `text-success`: ``}`}>
                              <small className={`${saleSummary.change > 0 ? `text-success`: `text-muted`} me-1`}>{businessInfo.currency}</small>
                              <Numeral value={saleSummary.change.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {
                    (form.getFieldValue('customer_email')?.length > 0 || form.getFieldValue('phone_number')?.length > 0) &&
                    <div className="col-md-12 mt-4 text-center">
                      <Form.Item name="alert_customer" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={alertCustomerCheck}
                              onChange={() => {
                                setAlertCustomerCheck(!alertCustomerCheck);
                                form.setFieldValue('alert_customer', !alertCustomerCheck);
                              }}
                              id="sale_alert_customer" />
                            <label className="custom-control-label" htmlFor="sale_alert_customer">
                              Send Alert to Customer
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  }
                </div>
              </div>

              <div className="fm-footer">
                <div className="text-center">
                  <Button disabled={requesting} className="m-1" type="button" color="primary" size="lg" onClick={() => handleSaleSubmitType('invoice')} >
                    {requesting ? <Spinner size="sm" color="light" /> : <span>{`${( form.getFieldValue('customer_email')?.length > 0 || form.getFieldValue('phone_number')?.length > 0 ) && alertCustomerCheck ? `Save & Send Order ` : `Save Order`}`}</span>}
                  </Button>
                </div>
              </div>
            </div>

          </Form>
          
        </Block>
      </>
    }

    { 
      activeModal === "createNewProductModal" && 
      <CreateNewProductFullModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewProductModal')} updateProductList={updateProductList} showModal={true}  /> 
    }

    { 
      activeModal === "successProductModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The product has been created successfully.`} 
      />
    }

    { 
      activeModal === "createNewServiceModal" && 
      <CreateNewServiceModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewServiceModal')} updateServiceList={updateServiceList} showModal={true}  /> 
    }

    { 
      activeModal === "successServiceModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The service has been created successfully.`} 
      />
    }

    { 
      activeModal === "createTaxProfileModal" && 
      <CreateTaxProfileModal businessID={businessID} toggleModal={() => toggleModal('createTaxProfileModal')} updateTaxProfiles={updateTaxProfiles} showModal={true}  /> 
    }
    
    { 
      activeModal === "successTaxModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The tax profile has been created successfully.`} 
      />
    }

    </Content>
    
  </React.Fragment>)
}

export default CreateSaleOrder;