import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import logoPlaceholder from "../../../images/crop.png";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../../components/Component";
import { Spinner, Alert } from "reactstrap";
import GooglePlacesInput from "../GooglePlacesInput";
import PhoneNumberInput from "../PhoneNumberInput";
import { Input, Select, Checkbox, Form } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { countries } from '../../../utils/Countries';
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import { Link } from "react-router-dom";
import axios from 'axios';

export const BusinessProfile = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [imageSrc, setImageSrc] = useState(logoPlaceholder);
  const [imageUploadError, setImageUploadError] = useState("");
  const [businessVerification, setBusinessVerification] = useState("not-verified");
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryRestrictionSearch, setCountryRestrictionSearch] = useState(['gh']);
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    logo: "",
    business_name: "",
    location: "",
    email: "",
    country: null,
    currency: null,
    phone_code: "+233",
    phone_number: "",
    lat: null,
    lng: null
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setImageUploadError("");
    setFormError("");

    const formInfo = new FormData();
    formInfo.append('logo',formData.logo);
    formInfo.set("business_name", _formData.business_name);
    formInfo.set("location", _formData.location);
    formInfo.set("lat", formData.lat);
    formInfo.set("lng", formData.lng);
    formInfo.set("phone_number", `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`);
    formInfo.set("email", _formData.email);
    formInfo.set("country", _formData.country);

    axios.put(baseURLs.API_URL + `/businesses/edit/${businessID}`, formInfo, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;
      toggleModal("successModal");
      setRequesting(false);
      getBusinesses(businessID);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'logo',
              errors: errorResponse.errors.hasOwnProperty("logo") ? [errorResponse.errors.logo] : [],
            },
            {
              name: 'business_name',
              errors: errorResponse.errors.hasOwnProperty("business_name") ? [errorResponse.errors.business_name] : [],
            },
            {
              name: 'country',
              errors: errorResponse.errors.hasOwnProperty("country") ? [errorResponse.errors.country] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            }
            
          ]);
          
          if(errorResponse.errors.hasOwnProperty("logo")){
            setImageUploadError(errorResponse.errors.logo);
          }


        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const updateCountryRestrictionSearch = (value) => {
    let newCountryRestrictionSearch = [];
      
    let locationIndex = countries.findIndex(obj => obj.name == value);
    if(locationIndex >= 0){
      let countryISO = countries[locationIndex].country_code.toLowerCase();
      newCountryRestrictionSearch.push(countryISO);
      setCountryRestrictionSearch(newCountryRestrictionSearch);
    }
  }

  const handleBusinessLogoUpload = e => {    
    if (e.target.files && e.target.files.length > 0){
      setFormData({ ...formData, logo: e.target.files[0] })
      // setImageName(e.target.files[0].name);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }    
  }

  const generateCountryOptions = () => {
    let options = [];
    for(let i = 0; i < countries.length; i++){
      options.push({
        label: countries[i].name, value: countries[i].name, key: i
      })
    }

    setCountryOptions(options);
  }

  const getBusinesses = (businessID) => {
    axios.get(baseURLs.API_URL + "/businesses", getAxiosHeaders())
    .then(async(response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/create-business`);
        return;

      }

      let responseInfo = response.data;
      let businesses = responseInfo.data.businesses;
      
      await localStorage.setItem('my_businesses', JSON.stringify(businesses));
      localStorage.setItem('current_business_id', businessID);
      let currentBusiness = businesses?.find((business) => business.business_id == businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      let _formData = formData;
      _formData.business_name = currentBusiness.name;
      _formData.location = currentBusiness.location;
      _formData.country = currentBusiness.country;
      _formData.email = currentBusiness.email;
      _formData.currency = currentBusiness.currency;

      let phoneNumberFormatted = getPhoneCodeAndNumber(currentBusiness.phone_number);
      _formData.phone_code = phoneNumberFormatted.phoneCode;
      _formData.phone_number = phoneNumberFormatted.phoneNumber;
      
      _formData.lat = currentBusiness.lat === "null" ? "" : currentBusiness.lat ?? "";
      _formData.lng = currentBusiness.lng === "null" ? "" : currentBusiness.lng ?? "";

      setBusinessVerification(currentBusiness.is_verified);
      setFormData(_formData);
      setImageSrc(currentBusiness.logo ?? logoPlaceholder);
      setCountryRestrictionSearch([currentBusiness.country_iso])
      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getBusinesses(businessID);
    generateCountryOptions();
    loadGoogleMapsApi();

  }, [match.params.businessID]);

  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }

  return (
    <React.Fragment>
      <Head title="Business Profile"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Business Profile
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update your business information.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              formError &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {formError}{" "}
                  </Alert>
                </div>
              </Block>
            }
            <Form name="update-business"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}>
              <div className="card card-bordered">
                <div className="fm-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-4">
                        <label className="form-label" htmlFor="businessLogo">Upload Business Logo <span className="text-muted">(optional - jpeg, jpg, png - Max size: 2mb)</span></label>
                        {imageUploadError && <p className="form-error-text fs-14px">{imageUploadError}</p>}
                        <div className="nk-activity-item">
                          <div className="nk-activity-media user-avatar bg-light" style={{width: "80px", height: "80px"}}>
                            <img src={imageSrc} alt="" />
                          </div>
                          <div className="nk-activity-data">
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <div className="form-file">
                                  <input id="businessLogo" 
                                    type="file" onChange={handleBusinessLogoUpload} 
                                    accept="image/*" className="form-control" />
                                </div>
                                <div className="input-group-append">
                                  <label className="input-group-text" htmlFor="businessLogo">Upload</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Business Name <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'business_name'}
                            noStyle                      
                            rules={[
                              {required: true, message: 'Business name is required',}
                            ]}>
                            <Input size="large" disabled={businessVerification === 'verified'}
                              type="text" className="form-control form-control-lg" 
                              placeholder="My Awesome Business"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Country <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'country'}
                            noStyle
                            rules={[
                              {required: true, message: 'Country is required',}
                            ]}>
                            <Select required size="large" 
                              name="country"
                              placeholder="Select your country"
                              style={{ width: "100%" }} 
                              onChange={updateCountryRestrictionSearch}
                              options={countryOptions} 
                              showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Business Location <span className="text-danger">*</span></label>
                        </div>
                        {
                          googleMapsReady ?
                          <GooglePlacesInput form={form} isRequired={true} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={countryRestrictionSearch} />
                          :
                          <p>Loading locations...</p>
                        }
                      </div>
                    </div> 
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <PhoneNumberInput form={form} formData={formData} isRequired={true} setFormData={setFormData} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Email <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'email'}
                            noStyle
                            rules={[
                              {required: false}, {type: 'email', message: 'Email is invalid'}
                            ]}>
                            <Input size="large" 
                              type="email" name="email" className="form-control form-control-lg" 
                              placeholder="mybusiness@email.com"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Currency <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                            <Form.Item name={'currency'} noStyle>
                              <Select size="large" style={{ width: "100%" }} disabled /> 
                            </Form.Item>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fm-footer">
                  <div className="pt-2 text-center">
                    <Button disabled={requesting} type="submit" color="primary" size="lg" >
                      {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
        </Block>
        </>
      }

      </Content>
      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Updated Successfully`} descriptionText={`Business information has been updated successfully.`} 
        />
      }
    </React.Fragment>
  )
}