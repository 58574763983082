import React, { useEffect } from 'react';

const TawkToWidget = () => {
  useEffect(() => {
    const tawkToScript = document.createElement('script');
    tawkToScript.src = 'https://embed.tawk.to/5e76db0e8d24fc226589341c/default';
    tawkToScript.async = true;
    document.head.appendChild(tawkToScript);

    tawkToScript.onload = () => {
      var Tawk_API = Tawk_API || {};
      Tawk_API.onLoad = function () {
        // Handle onLoad events if needed
      };
    };

    return () => {
      // Clean up when the component unmounts
      document.head.removeChild(tawkToScript);
    };
  }, []);

  return <></>; // This component doesn't render anything, it just handles the integration
};

export default TawkToWidget;