import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, manufacturingOrderStatusOptions } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody,
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ManufacturingOrdersTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      pending_orders: {
        total: 0,
        in_progress_orders: 0,
        on_hold_orders: 0,
        ready_for_pickup_orders: 0,
        in_transit_orders: 0,
      },
      delivered_orders: 0,      
    }, 
    orders: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    order_search: '',
    order_number_search: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    status: 'all'
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.orders.map(item => item.order_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let order_search = filters.order_search.length > 0 ? `&o=${filters.order_search}` : ``;
    let order_number_search = filters.order_number_search.length > 0 ? `&on=${filters.order_number_search}` : ``;
    let date_range = filters.date_range !== null ? `&r=${filters.date_range}` : ``;
    let status = filters.status !== null ? `&st=${filters.status}` : ``;
    
    if( (order_search !== '' ||
    order_number_search !== '' ||
    date_range !== '' ||
    filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${order_search}${order_number_search}${date_range}${status}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getOrders(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      order_search: '',
      order_number_search: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
      status: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getOrders(params);
  }

  const filterOrders = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getOrders(filters);
  }

  const getOrders = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/manufacturing-orders", {
      params: {
        business_id: businessID,
        page: filters.page,
        order_name: filters.order_search,
        order_number: filters.order_number_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        order_status: filters.type
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            pending_orders: {
              total: 0,
              in_progress_orders: 0,
              on_hold_orders: 0,
              ready_for_pickup_orders: 0,
              in_transit_orders: 0,
            },
            delivered_orders: 0,      
          }, 
          orders: []
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            pending_orders: {
              total: 0,
              in_progress_orders: 0,
              on_hold_orders: 0,
              ready_for_pickup_orders: 0,
              in_transit_orders: 0,
            },
            delivered_orders: 0,      
          }, 
          orders: []
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteOrdersByIDs = () => {
    toggleModal('cancelLoadingModal');

    axios.delete(baseURLs.API_URL + "/orders/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        orders: JSON.stringify(checkedBoxes)
      }      
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getOrders(filters);
        toggleModal('successCancelModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getOrders(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `orders` : `order`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `orders` : `order`} failed to get deleted`);
        toggleModal('cancelErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `orders` : `order`}. The selected ${failedIDs.length > 1 ? `orders were` : `order was`} not found.`);
        toggleModal('cancelErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('cancelErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteOrdersByFilter = () => {
    toggleModal('cancelLoadingModal');

    axios.delete(baseURLs.API_URL + "/orders/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        order_name: filters.order_search,
        order_number: filters.order_number_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        order_status: filters.status
      }      
    })
    .then((response) => {
      getOrders(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('cancelErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedOrderAction = () => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const handleSingleDelete = (orderID) => {
    setCheckedBoxes([orderID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');          
    }, 500);
  }

  const downloadOrders = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/manufacturing-orders/download", {
      params: {
        business_id: businessID,
        order_name: filters.order_search,
        order_number: filters.order_number_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        order_status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No orders found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let order_search = queryParams.hasOwnProperty('o') ? queryParams.o : '';
    let order_number_search = queryParams.hasOwnProperty('on') ? queryParams.on : '';
    let date_range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let status = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setFromDate(dateRangeStart);
      setToDate(dateRangeEnd);
    }

    let params = filters;
    params.page = page;
    params.order_search = order_search;
    params.order_number_search = order_number_search;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;
    params.status = status;

    setFilters(params)
    getOrders(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <div className="row g-gs mb-5">
            <div className="col-md-12">
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-warning">Pending Orders</div>
                      <div className="amount">
                        {tableData.meta.pending_orders.total}
                        <Button type="button" id="pending_orders_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                          <Icon name="info" />
                        </Button>
                        <UncontrolledPopover target="pending_orders_info_popover" placement="bottom" trigger="focus">
                          <PopoverBody>
                            <div className="mb-1">
                              <small className="mr-1">In Progress Orders: </small>
                              <b>{tableData.meta.pending_orders.in_progress_orders}</b>
                              <br/>                                 
                            </div>
                            <div className="mb-1">
                              <small className="mr-1">On Hold Orders: </small>
                              <b>{tableData.meta.pending_orders.on_hold_orders}</b>
                              <br/>                                 
                            </div>
                            <div className="mb-1">
                              <small className="mr-1">Ready for Pickup Orders: </small>
                              <b>{tableData.meta.pending_orders.ready_for_pickup_orders}</b>
                              <br/>                                 
                            </div>
                            <div className="mb-1">
                              <small className="mr-1">In Transit Orders: </small>
                              <b>{tableData.meta.pending_orders.in_transit_orders}</b>
                              <br/>                                 
                            </div>
                            <div><small>Note: The totals are base on the filter</small></div>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                    </div>
                    <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-success">Delivered Orders</div>
                      <div className="amount">{tableData.meta.delivered_orders}</div>
                    </div>
                  </div>
                </div>
              </Card>   
            </div>
          </div>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Orders` : `Order`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Orders Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="order_search">
                                    Order Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="order_search" className="form-control form-control-lg" id="order_search" defaultValue={filters.order_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="order_number_search">
                                    Order Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="number" onChange={onInputChange} name="order_number_search" className="form-control form-control-lg" id="order_number_search" defaultValue={filters.order_number_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone-no">
                                    Order Status
                                  </label>
                                  <div className="form-control-wrap">
                                  <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.type}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={manufacturingOrderStatusOptions} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">From Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={fromDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'from');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">To Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={toDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'to');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterOrders();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getOrders(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadOrders} title="Download Orders">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Orders"} descriptionText={"Please wait while your orders are being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Orders Ready"} descriptionText={"Orders CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.orders.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No orders found</h5>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Create an Order</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>                   
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">#</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Order</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Product</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total Cost</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedOrderAction()}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Orders</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Orders` : `Delete Order`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} orders. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected orders` : `this selected order`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteOrdersByFilter : deleteOrdersByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No order has been selected. Select an order and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `orders are` : `order is`} being deleted`} /> }
                        
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.orders.map((data, index) => {
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.order_id)}
                                    onChange={() => handleCheckboxChange(data.order_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.order_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.order_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <Link to={`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}/details/${data.order_id}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary">#{data.order_number}</div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}/details/${data.order_id}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary d-md-none">#{data.order_number}</div>
                                      <span className="title fw-bold text-dark">{data.order_name}</span>
                                      {
                                        data.viewed > 0 && <em className="text-muted icon ni ni-eye ms-1" title="Viewed"></em>
                                      }
                                      <div className="price d-md-none"><small className="text-muted" title="supplier"><em className="icon ni ni-user-alt me-1"></em>{data.updated_by}</small></div>
                                      
                                      <div className={`price d-md-none`}>
                                        <span className="tb-amount"><small className="text-muted">{currency} </small>{data.total_cost}</span>
                                        <span>

                                        {
                                          data.order_status === 'in_progress' &&
                                          <Badge className="badge-dot me-2" color="warning">In Progress</Badge>                                        
                                        }
                                        
                                        {
                                          data.order_status === 'ready_for_pickup' &&
                                          <Badge className="badge-dot me-2" color="warning">Ready for Pickup</Badge>
                                        }

                                        {
                                          data.order_status === 'in_transit' &&
                                          <Badge className="badge-dot me-2" color="warning">In Transit</Badge>
                                        }

                                        {
                                          data.order_status === 'on_hold' &&
                                          <Badge className="badge-dot me-2" color="warning">On Hold</Badge>
                                        }

                                        {
                                          data.order_status === 'delivered' &&
                                          <Badge className="badge-dot me-2" color="success">Delivered</Badge>
                                        }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.updated_by}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount"><small className="text-muted">{currency} </small>{data.total_cost}</span>
                              </DataTableRow>
                              <DataTableRow size="md" className={"text-start"}>
                                {
                                  data.order_status === 'in_progress' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot me-2" color="warning">In Progress</Badge>                                        
                                  </div>
                                }
                                
                                {
                                  data.order_status === 'ready_for_pickup' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot me-2" color="warning">Ready for Pickup</Badge>
                                  </div>
                                }

                                {
                                  data.order_status === 'in_transit' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot me-2" color="warning">In Transit</Badge>
                                  </div>
                                }

                                {
                                  data.order_status === 'on_hold' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot me-2" color="warning">On Hold</Badge>
                                  </div>
                                }

                                {
                                  data.order_status === 'delivered' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="success">Delivered</Badge>
                                  </div>
                                }
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{moment(data.updated_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}/details/${data.order_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                      {
                                        data.order_status !== 'delivered' &&
                                        <li>
                                          <Link to={`${process.env.PUBLIC_URL}/manufacturing-orders/b/${businessID}/edit/${data.order_id}`}>
                                            <Icon name="edit-alt"></Icon>
                                            <span>Edit</span>
                                          </Link>
                                        </li>
                                      }
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.order_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                    
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
