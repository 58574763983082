import React, { useState, useEffect } from "react";
import Icon from "../../components/icon/Icon";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import { getAxiosHeaders, getPhoneCodeAndNumber } from "../../utils/Utils";
import {
  Alert,
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import { Empty, Input, Select, InputNumber, Divider, Form } from 'antd';
const { TextArea } = Input;

export const CreateNewServiceModal = ({ businessID, currency, showModal, toggleModal, updateServiceList, ...props }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/services/add`, {
      business_id: businessID,
      service_name: _formData.service_name,
      description: _formData.description,
      selling_price: _formData.selling_price
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      updateServiceList(_formData.service_name);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'service_name',
              errors: errorResponse.errors.hasOwnProperty("service_name") ? [errorResponse.errors.service_name] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'selling_price',
              errors: errorResponse.errors.hasOwnProperty("selling_price") ? [errorResponse.errors.selling_price] : [],
            }            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  return (
    <Modal backdrop="static" isOpen={showModal} id="create_service_modal">
      <ModalHeader
        toggle={toggleModal}
        close={
          <button className="close" disabled={requesting} onClick={toggleModal}>
            <Icon name="cross" />
          </button>
        }
      >
        Add a New Service
      </ModalHeader>
      <Form name="add-service"
          form={form} className="is-alter"
          initialValues={{
            service_name: '',
            description: '',
            selling_price: 0,
          }} 
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Service Name <span className="text-danger">*</span></label>
                  </div>
                  <Form.Item name={'service_name'}
                    noStyle
                    rules={[
                      {required: true, message: 'Service name is required',}
                    ]}>
                    <Input size="large"
                      type="text" className="form-control form-control-lg" 
                      placeholder="Awesome Service"
                    />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                  </div>
                  <Form.Item name={'description'}
                    noStyle
                    rules={[
                      {required: false}
                    ]}>
                    <TextArea rows={4} placeholder="enter a description of the service" />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Selling Price <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'selling_price'}
                      noStyle
                      rules={[
                        {required: true, message: 'Selling price is required'}
                      ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}