import React, { useState, useEffect } from "react";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import { UncontrolledPopover, Card, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert, Badge } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import ErrorImage from "../../images/cart-error.svg";
import AuthFooter from "./AuthFooter";
import { 
  Block,
  BlockDes,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon, 
  PreviewCard } from "../../components/Component";
  import moment from 'moment';
  import Numeral from 'react-numeral';
  import DatePicker from "react-datepicker";
  import { AutoComplete, Empty, Input, Select, Space, InputNumber, Divider, Spin, Form } from 'antd';
  import GooglePlacesInput from "../components/GooglePlacesInput";
  import PhoneNumberInput from "../components/PhoneNumberInput";
  import {
    AlertModal,
    SuccessModal,
    LoadingModal
  } from "../components/AlertModals";
import { formatTo2DP, getPhoneCodeAndNumber, moneyToFloat } from "../../utils/Utils";

const { TextArea } = Input;
const { Option } = Select;

const Checkout = ({ history, match }) => {
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [invalidToken, setInvalidToken] = useState(true);
  const [checkoutReady, setCheckoutReady] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({
    country_iso: "GH"
  });
  const [cartToken, setCartToken] = useState("");
  const [fetchingLocations, setFetchingLocations] = useState(false);
  const [form] = Form.useForm();
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [selectedTaxProfile, setSelectedTaxProfile] = useState({
    tax_id: null,
    tax_profile_name: null,
    taxes: [],
  });
  const [selectedLocation, setSelectedLocation] = useState({
    location_name: '',
    cost: 0,
    type: '',
  });
  const [orderSummary, setOrderSummary] = useState({
    sub_total: 0,
    total_direct_tax: 0,
    total_compound_tax: 0,
    total_tax: 0,
    taxes: [],
    discount_amount: 0,
    discount_percentage: 0,
    total_amount: 0,
  });
  const [formData, setFormData] = useState({
    customer_name: '',
    phone_code: "+233",
    phone_number: '',
    customer_email: '',
    items: [],
    fulfillment_type: 'delivery',
    location_name: null,
    location: null,
    lng: null,
    lat: null,
    fulfillment_cost: 0,
    note: ''
  });
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    let phoneNumber = '';
    if(_formData.phone_code && _formData.phone_number){
      phoneNumber = `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`;
    }

    axios.post(baseURLs.API_URL + `/online-store/place-order/${cartToken}`, {
      customer_name: _formData.customer_name,
      customer_phone_number: phoneNumber,
      customer_email: _formData.customer_email,
      items: JSON.stringify(_formData.items),
      fulfillment_type: _formData.fulfillment_type,
      location_name: _formData.location_name,
      location: _formData.location,
      lat: formData.lat,
      lng: formData.lng,
      note: _formData.note
    })
    .then((response) => {  
      // redirect to sale details page
      let responseInfo = response.data;
      let orderURL = responseInfo.data.order_link;
      window.location.href = orderURL;
      return;

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'customer_name',
              errors: errorResponse.errors.hasOwnProperty("customer_name") ? [errorResponse.errors.customer_name] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("customer_phone_number") ? [errorResponse.errors.customer_phone_number] : [],
            },
            {
              name: 'customer_email',
              errors: errorResponse.errors.hasOwnProperty("customer_email") ? [errorResponse.errors.customer_email] : [],
            },
            {
              name: 'items',
              errors: errorResponse.errors.hasOwnProperty("items") ? [errorResponse.errors.items] : [],
            },
            {
              name: 'fulfillment_type',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_type") ? [errorResponse.errors.fulfillment_type] : [],
            },
            {
              name: 'location_name',
              errors: errorResponse.errors.hasOwnProperty("location_name") ? [errorResponse.errors.location_name] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const validateCartToken = (token) => {
    setErrorMessage("");

    axios.get(baseURLs.API_URL + `/online-store/checkout/${token}`)
    .then((response) => {
      let responseInfo = response.data;

      let cartInfo = responseInfo.data.cart_info;
      let _formData = formData;
      
      let phoneCode = formData.phone_code;
      let phoneNumber = '';
      if(cartInfo.customer_phone_number) {
        let phoneNumberFormatted = getPhoneCodeAndNumber(cartInfo.customer_phone_number);
        phoneCode = phoneNumberFormatted.phoneCode;
        phoneNumber = phoneNumberFormatted.phoneNumber;
      }

      _formData.customer_name = cartInfo.customer_name;
      _formData.phone_code = phoneCode;
      _formData.phone_number = phoneNumber;
      _formData.customer_email = cartInfo.customer_email;
      
      let items = [];
      for(let i = 0; i < cartInfo.items.length; i++) {
        items.push({
          item_id: cartInfo.items[i].item_id,
          item_name: cartInfo.items[i].item_name,
          quantity: parseInt(cartInfo.items[i].quantity),
          max_quantity: cartInfo.items[i].max_quantity,
          price: moneyToFloat(cartInfo.items[i].price),
          total: formatTo2DP( moneyToFloat(cartInfo.items[i].price) * parseInt(cartInfo.items[i].quantity) ),
        })
      }
      _formData.items = items;      

      // setItemFields(items);
      setFormData({..._formData});
      setBusinessID(cartInfo.business_info.business_id);
      setBusinessInfo(cartInfo.business_info);
      if(cartInfo.business_info.tax_profile) {
        setSelectedTaxProfile({
          ...selectedTaxProfile,
          tax_id: cartInfo.business_info.tax_profile.tax_id,
          tax_profile_name: cartInfo.business_info.tax_profile.tax_profile_name,
          taxes: cartInfo.business_info.tax_profile.taxes,
        });
      }

      Promise.all([
        cartInfo.business_info.fulfillment_info.delivery_enabled === "yes" ? 
        getLocations({businessID: cartInfo.business_info.business_id, locationType: 'delivery', cartToken: token}) :
        getLocations({businessID: cartInfo.business_info.business_id, locationType: 'pickup', cartToken: token})
      ]).finally(() => {
        setInvalidToken(false);
        setLoading(false);
        setCheckoutReady(true);   
      });

      
         
    }).catch((error) => {
      console.log(error);
      try {
        let errorResponse = error.response.data;
        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        setErrorMessage(errorMessage);
      } catch (e) {
        setErrorMessage("Cart not found");
      }      
      setLoading(false);
    });
  }

  const getLocations = ({businessID, locationName, locationType, cartToken}) => {

    if(formData.fulfillment_type !== locationType || formData.location_name !== locationName) {
      setFormData({
        ...formData,
        fulfillment_type: locationType,
      })
    }


    return axios.get(baseURLs.API_URL + `/online-store/delivery-pickup-locations/${cartToken}`, {
      params: {
        location_name: locationName,
        location_type: locationType,
      }
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let locations = responseInfo.data.locations;

        setLocationsList([...locations]);
      }

      if(fetchingLocations){
        setFetchingLocations(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingLocations){
          setFetchingLocations(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items', 'location_name', 'fulfillment_cost'];
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      if(!form.getFieldValue('location_name')){
        form.setFieldValue('fulfillment_cost', 0);
        setSelectedLocation({
          location_name: '',
          cost: 0,
          type: ''
        })
      }
      updateOrderSummary();
    }    
  }

  const handleRemoveItem = (index) => {
    const oldItemFields = formData.items;
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);

    setFormData({
      ...formData,
      items: newFields
    });

    form.setFieldsValue({
      items: newFields,
    });

    updateOrderSummary();
  };

  const handleLocationChange = (locationName) => {
    // Fetch more location when the user enters a location name not found in the initial list
    if (!locationsList.some(location => location.location_name === locationName)) {
      debouncedFetchLocations({businessID, locationName, locationType: formData.fulfillment_type, cartToken});
      setFetchingLocations(true);
    }
  };

  const handleLocationSelect = (value, option) => {
    const selected = locationsList.find(location => location.location_name === value);
    
    if (selected) {
      let { cost, location_name, type } = selected;
      if(type === "delivery") {
        if(businessInfo.fulfillment_info.delivery_config.type === "free") {
          cost = 0;
        } else if(businessInfo.fulfillment_info.delivery_config.type === "flat") {
          cost = moneyToFloat(businessInfo.fulfillment_info.delivery_config.config.flat_amount);
        } else {
          cost = moneyToFloat(cost);
        }

      } else { cost = 0; }
      

      form.setFieldValue("fulfillment_cost", cost);
      setSelectedLocation({cost, location_name, type});
      setFormData({
        ...formData,
        fulfillment_cost: cost,
        location_name
      });
    }
  };

  // const updateCartQuantity = (index, value) => {
  //   let _items = formData.items;
  //   _items[index].quantity = value;
  //   _items[index].total = formatTo2DP( formData.items[index].price * value );

  //   setFormData({
  //     ...formData,
  //     items: _items
  //   });
  // }

  const updateOrderSummary = () => {
    const items = form.getFieldValue('items') || [];
    let fulfillmentCost = formatTo2DP(form.getFieldValue('fulfillment_cost') || 0);

    let calculatedTaxAmounts = [];
    
    // Calculate Subtotal including Fulfillment Cost
    let preDeliverySubTotal = items.reduce((total, item) => {
      const price = item.price || 0;
      const quantity = item.quantity || 0;
      return total + price * quantity;
    }, 0);

    let _formData = formData;
    console.log(_formData);
  
    if(businessInfo.fulfillment_info) {
      if(formData?.fulfillment_type === "delivery" && 
        ['flat','location'].includes(businessInfo.fulfillment_info.delivery_config?.type) && 
        businessInfo.fulfillment_info.delivery_config?.config.has_free_above === "yes") {
          let freeAboveAmount = parseFloat(businessInfo.fulfillment_info.delivery_config?.config.free_above_amount);
        if(freeAboveAmount <= preDeliverySubTotal){
          form.setFieldValue('fulfillment_cost', 0);
          fulfillmentCost = 0;
        } else {
          form.setFieldValue('fulfillment_cost', selectedLocation.cost);
          fulfillmentCost = parseFloat(selectedLocation.cost);
        }
      }
    }

    let subTotal = preDeliverySubTotal + formatTo2DP(fulfillmentCost);

    const { taxes } = selectedTaxProfile;
    
    // Calculate Direct Taxes on Subtotal including Fulfillment Cost
    let directTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'direct') {
        let taxAmount = formatTo2DP((subTotal * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'direct',
        });
        
        directTaxesTotal += taxAmount;
        
      }
    });
  
    // Calculate Subtotal with Direct Taxes and Fulfillment Cost
    let subTotalWithDirectTaxes = formatTo2DP(subTotal + directTaxesTotal);
  
    // Calculate Compound Taxes on Subtotal with Direct Taxes and Fulfillment Cost
    let compoundTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'compound') {
        let taxAmount = formatTo2DP((subTotalWithDirectTaxes * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'compound',
        });

        compoundTaxesTotal += taxAmount;
      }
    });
  
    // Calculate Total Tax Amount
    let totalTaxAmount = formatTo2DP(directTaxesTotal + compoundTaxesTotal);
  
    // Calculate Total Amount including Taxes, Fulfillment Cost, and Discounts
    let totalAmount = formatTo2DP(subTotalWithDirectTaxes + compoundTaxesTotal);
    
    // Update sale summary state
    setOrderSummary({
      ...orderSummary,
      sub_total: subTotal,
      total_direct_tax: directTaxesTotal,
      total_compound_tax: compoundTaxesTotal,
      total_tax: totalTaxAmount,
      taxes: calculatedTaxAmounts,
      total_amount: totalAmount,
    })
  }

  const debouncedFetchLocations = debounce(getLocations, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const goToStore = () => {
    window.location.href = `${businessInfo.storefront_url}`;
    // window.location.href = `${businessInfo.storefront_url}/store/cart/${cartToken}`;
  }

  const goBack = () => {
    // Check if there is a previous page in the browser's history
    if (window.history.length > 1) {
      // Go back to the previous page
      window.history.back();
    } else {
      // Redirect to keepup URL
      window.location.href = baseURLs.KUS_URL;
    }
  }

  useEffect(() => {
    if(checkoutReady){
      updateOrderSummary();
    }
  }, [checkoutReady])

  useEffect(() => {
    document.body.className = `nk-body bg-white npc-default has-aside no-touch nk-nio-theme`;
    
    const cartToken = match.params.cart_token;
    if (cartToken == undefined || null || "") {
      setErrorMessage("Cart not found");
      setInvalidToken(true);
      setLoading(false);
      return;
    }

    setCartToken(cartToken);
    validateCartToken(cartToken);
    loadGoogleMapsApi();
    checkTawkAPIDefinition();
    
  }, [match.params.cart_token]);

  const checkTawkAPIDefinition = () => {
    // Check if window.Tawk_API is defined
    if (typeof window.Tawk_API !== 'undefined') {
      // Tawk_API is defined, do something
      window.Tawk_API.hideWidget();
      return;
    }
  
    // Tawk_API is not defined yet, schedule another check after a delay
    setTimeout(checkTawkAPIDefinition, 1000); // Check again after 1 second (1000 milliseconds)
  }

  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }

  return (
    <React.Fragment>
      <Head title="Checkout" />
      <Content className="pb-0">
        {
          loading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs vh-100 d-flex align-center">
            <div className="inner-pre-loader">
              <Spinner  color="dark" />          
            </div>
          </Block>
          :
          <>
            {
              invalidToken ?
              <Block className="nk-block-middle wide-md mx-auto">
                <BlockContent className="nk-error-ld text-center">
                  <img className="nk-error-gfx" src={ErrorImage} alt="error" />
                  <div className="wide-xs mx-auto">
                    <h3 className="nk-error-title">Your cart was not found</h3>
                    <p className="nk-error-text">
                      The cart you’re try to checkout cannot be found.
                    </p>
                    <Button color="primary" size="lg" className="m-2" onClick={goBack}>
                      Go Back
                    </Button>
                  </div>
                </BlockContent>
              </Block>
              :
              <Block>
                <Form name="checkout"
                  form={form} className="is-alter nk-split nk-split-page nk-split-lg w-100"
                  initialValues={formData} 
                  onFinish={handleFormSubmit}
                  onFieldsChange={(changedFields, allFields) => {
                    debouncedHandleChangedFields(changedFields, allFields);       
                  }}
                  onFinishFailed={onFinishFailed}>
                  <div className="nk-split-content w-50 d-flex justify-content-center justify-content-lg-end p-4 p-sm-5" style={{backgroundColor: businessInfo.brand_color ?? "#212121"}}>
                    <div className="wide-xs w-100">
                      <Card className="card-bordered">
                        <div className="card-inner">
                          <div className="d-flex">
                            <div className="pe-2 d-flex align-items-center pointer-cursor h6" onClick={goToStore}>
                              <Icon name="arrow-left" />
                              <span>Back to Store</span>
                              {/* {
                                businessInfo.header_logo ?
                                <div className="logo-link ms-3">
                                  <img className="logo-img" src={businessInfo.header_logo} height="60px" />
                                </div>
                                :
                                <span className="h6">Back to Store</span>
                              } */}
                            </div>
                          </div>
                          <hr className="m-3 mx-0" />
                          <BlockHeadContent className="mt-4">
                            <BlockDes className="text-soft">
                              <p>{businessInfo.business_name}</p>
                            </BlockDes>
                            <BlockTitle page tag="h4">
                              Checkout
                            </BlockTitle>                      
                          </BlockHeadContent>

                          {errorMessage && (
                            <Alert color="danger" className="alert-icon mt-2">
                              {" "}
                              <Icon name="alert-circle" /> {errorMessage}{" "}
                            </Alert>
                          )}

                          
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                        <label className="form-label">Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={'customer_name'}
                                      noStyle
                                      rules={[
                                        {required: true, message: "Your name is required"}
                                      ]}>
                                      <Input size="large" type="text" className="form-control form-control-lg" placeholder="Enter your name" />
                                    </Form.Item>
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                  </div>
                                  <div className="form-control-wrap">
                                    <PhoneNumberInput form={form} formData={formData} isRequired={true} setFormData={setFormData} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                        <label className="form-label">Email <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={'customer_email'}
                                      noStyle
                                      rules={[
                                        {required: true, message: 'Email is required'}, {type: 'email', message: 'Please enter a valid email address' }
                                      ]}>
                                      <Input size="large" type="email" className="form-control form-control-lg" placeholder="awesome.me@email.com" />
                                    </Form.Item>
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <hr className="m-4 mx-0" />
                                <h6 className="overline-title text-primary-alt mb-4 mt-4">Delivery &amp; Pick Up</h6>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                      <label className="form-label">Fulfillment Type <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="form-control-wrap">
                                      <Form.Item name={'fulfillment_type'}
                                        noStyle
                                        rules={[
                                          {required: true, message: `Select delivery or pick up`}
                                        ]}>
                                        <Select required size="large" 
                                          placeholder="Select delivery or pick up"
                                          style={{ width: "100%" }} 
                                          onChange={(value) => {getLocations({businessID, locationType: value, cartToken})}}
                                          options={[
                                            {
                                              key: 'delivery',
                                              label: 'Delivery',
                                              value: 'delivery',
                                              disabled: businessInfo.fulfillment_info.delivery_enabled === "no"
                                            },
                                            {
                                              key: 'pick_up',
                                              label: 'Pick Up',
                                              value: 'pick_up',
                                              disabled: businessInfo.fulfillment_info.pickup_enabled === "no"
                                            },                              
                                          ]}
                                          showSearch />   
                                      </Form.Item>                       
                                    </div>
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                        <label className="form-label">{`Select ${formData.fulfillment_type === "delivery" ? `City / Town` : `Pick up Center`}`} <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={'location_name'}
                                        rules={[{ required: true, message: 'Select your delivery location' }]}>
                                      <Select
                                        size="large"
                                        autoComplete="new-password"
                                        allowClear
                                        onChange={handleLocationChange}
                                        options={locationsList.map(location => (
                                          {
                                            key: location.location_id,
                                            label: location.location_name,
                                            value: location.location_name,
                                          }
                                        ))}
                                        filterOption={(inputValue, option) =>
                                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onSelect={handleLocationSelect}
                                        onSearch={handleLocationChange}
                                        showSearch={true}
                                        notFoundContent={
                                          fetchingLocations ? 
                                          <div className="text-center m-5">
                                            <Spin/>
                                          </div>
                                          :
                                          <div className="text-center m-5">
                                            <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                          </div>
                                        }
                                        placeholder="Select your location"
                                        className="w-100"
                                      />
                                    </Form.Item>
                                    <Form.Item name={'fulfillment_cost'} style={{ display: 'none' }}>
                                      <Input type="hidden" />
                                    </Form.Item>
                                  </Form.Item>
                                </div>
                              </div>
                              {
                                formData.fulfillment_type === "delivery" &&
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">Delivery Location <span className="text-danger">*</span></label>
                                    </div>
                                    <GooglePlacesInput form={form} isRequired={true} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={businessInfo.country_iso} />
                                  </div>
                                </div>
                              }

                              <div className="col-md-12">
                                <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                      <label className="form-label">Order Note <span className="text-muted">(optional)</span></label>
                                    </div>
                                    <div className="form-control-wrap">
                                    <Form.Item name={'note'}
                                      noStyle
                                      rules={[
                                        {required: false}
                                      ]}>
                                        <TextArea rows={4} placeholder="Enter any extra information regarding your order" />
                                      </Form.Item>                       
                                    </div>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>

                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="nk-split-content bg-white w-50 d-flex justify-content-center justify-content-lg-start p-4 p-sm-5">
                    <div className="wide-xs w-100">
                      <h6 className="overline-title text-primary-alt mb-3 mt-4">Cart</h6>
                      <ul className="nk-top-products mb-3">
                        {
                          formData.items.map((data, index) => {
                            let quantity = <span> • Qty: 
                              <Form.Item name={['items', index, 'quantity']}
                                noStyle
                                rules={[
                                  {required: true, message: 'Quantity is required'}
                                ]}>
                                <InputNumber min={1} step={1} max={data.max_quantity} size="small" className="ms-1" />                                        
                                {/* <InputNumber min={1} step={1} max={data.max_quantity} size="small" className="ms-1" onChange={(value) => {updateCartQuantity(index, value)}} />                                         */}
                              </Form.Item> 
                              <button className="btn btn-sm text-danger" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span className="ps-0">Remove</span></button>
                            </span>;
                            
                            return <li className="item" key={index}>                        
                                      <div className="info">
                                        <div className="title">{data.item_name}</div>
                                        <div className="price mt-2">
                                          <span className="me-1">{businessInfo.currency}</span> 
                                          <Numeral value={data.price.toString()} format={"0,0.00"} /> 
                                          {quantity}
                                        </div>                          
                                      </div>
                                      <div className="total">
                                        <div className="amount">
                                          <small className="text-muted me-1">{businessInfo.currency}</small> 
                                          <Numeral value={data.total.toString()} format={"0,0.00"} />
                                        </div>
                                      </div>
                                      <Form.Item name={['items', index, 'item_id']} style={{ display: 'none' }}>
                                        <Input type="hidden" />
                                      </Form.Item>
                                      <Form.Item name={['items', index, 'item_name']} style={{ display: 'none' }}>
                                        <Input type="hidden" />
                                      </Form.Item>
                                      <Form.Item name={['items', index, 'price']} style={{ display: 'none' }}>
                                        <Input type="hidden" />
                                      </Form.Item>
                                    </li>
                          })
                        }

                        {
                          form.getFieldValue('fulfillment_type') && form.getFieldValue('location_name') &&  
                          // formData.fulfillment_type && formData.location_name &&  
                          <>
                            <li className="item pb-2" key="delivery_pickup">
                              {
                                form.getFieldValue('fulfillment_type') === 'delivery' ?
                                <div className="fm-count"><em className="icon ni ni-truck fs-22px"></em></div>
                                :
                                <div className="fm-count"><em className="icon ni ni-location fs-22px"></em></div>
                              }
                              
                              <div className="info">
                                {
                                  form.getFieldValue('fulfillment_type') === 'delivery' ?
                                  <div className="title">Delivery</div>
                                  :
                                  <div className="title">Pick Up</div>
                                }

                                <div className="price text-dark">{formData.location_name}</div>
                                <div className="price">{formData.location}</div>
                                
                              </div>
                              <div className="total">
                                <div className="amount">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={form.getFieldValue('fulfillment_cost').toString()} format={"0,0.00"} />
                                </div>
                              </div>
                            </li>
                            {
                              formData.fulfillment_type === 'delivery' &&
                              <div className="alert alert-warning alert-icon">
                                <em className="icon ni ni-alert-circle" />
                                <p className="mb-0">Delivery fee may change when your order is confirmed.</p>
                                <p>{businessInfo.fulfillment_info.delivery_note}</p>
                              </div> 
                            } 

                            {
                              formData.fulfillment_type === 'pickup' &&
                              <div className="alert alert-warning alert-icon">
                                <em className="icon ni ni-alert-circle" />
                                <p>{businessInfo.fulfillment_info.pickup_note}</p>
                              </div> 
                            } 
                          </>
                        }
                      </ul>

                      {/* summary */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="nk-iv-scheme-details">
                            <ul className="nk-iv-wg3-list w-100">
                              <li className="px-0 py-2 fw-medium">
                                <div className="sub-text fs-16px">Sub Total</div>
                                <div className="nk-wg-action-content p-0">
                                  <h4 className="amount fs-16px">
                                    <small className="text-muted me-1">{businessInfo.currency}</small> 
                                    <Numeral value={orderSummary.sub_total.toString()} format={"0,0.00"} />
                                  </h4>
                                </div>
                              </li>
                              <li className="px-0 py-2 d-block">
                                {
                                  orderSummary.taxes.length > 0 &&
                                  <div className="mb-2">
                                    {
                                      orderSummary.taxes.map((tax, index) => {
                                        return(
                                          <div className="d-flex justify-content-between mb-1" key={index}>
                                            <div className="sub-text fs-14px">{tax.tax} <small>( {tax.percentage}% )</small></div>
                                            <div className="nk-wg-action-content p-0">
                                              <h4 className="amount fs-14px">
                                                <small className="text-muted me-1">{businessInfo.currency}</small> 
                                                <Numeral value={tax.amount.toString()} format={"0,0.00"} />
                                              </h4>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                }
                                

                                <div className="d-flex justify-content-between fw-medium">
                                  <div className="sub-text fs-14px">Total Tax</div>
                                  <div className="nk-wg-action-content p-0">
                                    <h4 className="amount fs-14px">
                                      <small className="text-muted me-1">{businessInfo.currency}</small> 
                                      <Numeral value={orderSummary.total_tax.toString()} format={"0,0.00"} />
                                    </h4>
                                  </div>
                                </div>
                              </li>
                              {/* <li className="px-0 py-2">
                                <div className="sub-text fs-14px">Discount</div>
                                <div className="nk-wg-action-content p-0">
                                  <h4 className="amount fs-14px">
                                    <small className="text-muted me-1">- {businessInfo.currency}</small>
                                    <Numeral value={orderSummary.discount_amount.toString()} format={"0,0.00"} />
                                    <small className="text-muted"> ({orderSummary.discount_percentage}%)</small>
                                  </h4>
                                </div>
                              </li> */}
                              <li className="px-0 py-2 fw-bold">
                                <div className="sub-text fs-18px">Total</div>
                                <div className="nk-wg-action-content p-0">
                                  <h4 className="amount fs-18px">
                                    <small className="text-muted me-1">{businessInfo.currency}</small> 
                                    <Numeral value={orderSummary.total_amount.toString()} format={"0,0.00"} />
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <Button disabled={requesting} className="m-1 btn-block" color="dark" size="lg" type="submit" >
                              {requesting ? <Spinner size="sm" color="light" /> : <span>Place Order</span>}
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-note">By placing this order, I agree to the <a href={businessInfo.terms} className="fw-bold text-dark" target="_blank">Terms & Conditions</a> of <span className="fw-bold">{businessInfo.business_name}</span>.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Block>
            }
          </>
        }
      </Content>
    </React.Fragment>
  );
};
export default Checkout;
