import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getToken as getMessagingToken, onMessage } from 'firebase/messaging';
import messaging from './firebase-config';
import { fcmVAPID } from './utils/Constants';
import { Button, notification, Space } from 'antd';
import { getNotificationIcons, getRouteFromUrl } from './utils/Utils';
import { Icon } from './components/Component';
import { addNotificationToken, getNotifications } from './layout/header/dropdown/notification/Notification';
import MeweNotificationSound from "./assets/sounds/mewe.mp3";

const notificationSound = new Audio(MeweNotificationSound);  

const checkExistingToken = () => {
  getMessagingToken(messaging, { vapidKey: fcmVAPID })
    .then((currentToken) => {
      if (currentToken) {
        let userToken = localStorage.getItem('user_note_token') || '';
        if(currentToken !== userToken){
          addNotificationToken(currentToken);
        }
      } else {
        console.log('No token available, requesting permission to generate one.');
        requestNotificationPermission();  // Function to request permissions and generate token
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token.', err);
      // Handle the error if token retrieval failed
    });
};

const requestNotificationPermission = () => {
  Notification.requestNotificationPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      fetchToken(); // Function to fetch token once permission is granted
    } else {
      console.log('Permission denied. Unable to get notification token.');
    }
  });
};

const fetchToken = () => {
  getMessagingToken(messaging, { vapidKey: fcmVAPID })
    .then((newToken) => {
      let userToken = localStorage.getItem('user_note_token') || '';
        if(newToken !== userToken){
          addNotificationToken(newToken);
        }
    })
    .catch((err) => {
      console.error('Failed to get token after permission granted.', err);
    });
};

const NotificationManager = () => {
  let history = useHistory();
  const [api, contextHolder] = notification.useNotification({ stack: { threshold: 1 } });

  useEffect(() => {
    checkExistingToken();
    
    // Set up the listener for foreground notifications
    registerForegroundNotificationListener();  
  }, []);

  const goToLink = (link) => {
    history.push(`${process.env.PUBLIC_URL}${getRouteFromUrl(link)}`);
    return;
  }

  const registerForegroundNotificationListener = () => {
    onMessage(messaging, (payload) => {
      notificationSound.play();

      // Display a notification dialog, update the UI, or handle the data from the payload
      const notificationTypeInfo = getNotificationIcons(payload.data.notification_type);
      const business_id = payload.data.business_id;
      const key = payload.messageId;
      const btn = (
        <Space>
          <Button type="link" size="small" onClick={(e) => {
              e.stopPropagation();
              api.destroy();
              history.push(`${process.env.PUBLIC_URL}/notifications/b/${business_id}`);
            }}>
            View All
          </Button>
          <Button type="link" size="small" onClick={(e) => {
              e.stopPropagation();
              api.destroy();              
            }}>
            Close All
          </Button>
        </Space>
      );

      api.open({
        message: payload.data.notification_title,
        description: payload.data.notification_description,
        icon: <Icon name={`${notificationTypeInfo.icon}`} className={`${notificationTypeInfo.color}`} />,
        btn,
        key,
        duration: null,
        onClick: () => { goToLink(payload.data.onclick_url); }
      });

      getNotifications(business_id);

    });
  };

  

  return <>{contextHolder}</>;
};

export default NotificationManager;
export {
  requestNotificationPermission
}
