import React, { useState, useEffect } from "react";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import Logo from "../../layout/logo/Logo";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";

const AccountActivation = ({ history, match }) => {
  const [preLoading, setPreLoading] = useState(true);
  const [invalidToken, setInvalidToken] = useState(true);
  const [activationError, setActivationError] = useState("");

  const validateActivationToken = (token) => {
    axios.post(baseURLs.API_URL + "/auth/account-verification-token", {token: token})
    .then((response) => {
      let data = response.data;
      setInvalidToken(false);
      setPreLoading(false);
    }).catch((error) => {
      try {
        let errorResponse = error.response.data;
        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        setActivationError(errorMessage);
      } catch (e) {
        setActivationError("Invalid account activation token");
      }
      
      setPreLoading(false);
    });
  }

  useEffect(() => {
    const token = match.params.token;
    if (token == undefined || null || "") {
      setActivationError("Invalid activation token");
      setInvalidToken(true);
      setPreLoading(false);
    } else {
      validateActivationToken(token);
    }
  }, [match.params.token]);

  return (
    <React.Fragment>
      <Head title="Account Activation" />
      <PageContainer>
        {
          preLoading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs">
            <Spinner  color="dark" />
          </Block>
          :
          <Block className="nk-block-middle nk-auth-body wide-xs">
            <div className="brand-logo pb-4 text-center">
              <Logo />
            </div>
            {
              invalidToken ?
              <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                <BlockHead>
                  <BlockContent className="text-center">
                    <em className="icon ni ni-na h1 text-danger" />
                    <BlockTitle className="mt-4" tag="h4">Account Verification Failed</BlockTitle>
                    <BlockDes>
                      <p className="fs-18px">{activationError}</p>
                    </BlockDes>
                  </BlockContent>
                </BlockHead>
                <div className="form-note-s2 text-center pt-2">
                  <Link to={`${process.env.PUBLIC_URL}/login`}>
                    <Button color="primary" size="lg">
                      Back to Login
                    </Button>
                  </Link>
                </div>
              </PreviewCard>
              :
              <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                <BlockHead>
                  <BlockContent className="text-center">
                    <em className="icon ni ni-check-fill-c h1 text-success" />
                    <BlockTitle tag="h4" className="mt-4">Account Activated</BlockTitle>
                    <BlockDes>
                      <p className="fs-18px">You have successfully activated your account. Login with the button below.</p>
                      <div className="form-note-s2 text-center pt-2">
                        <Link to={`${process.env.PUBLIC_URL}/login`}>
                          <Button color="primary" size="lg">Login</Button>
                        </Link>
                      </div>
                    </BlockDes>
                  </BlockContent>
                </BlockHead>
              </PreviewCard>
            }
            
          </Block>
        }
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default AccountActivation;
