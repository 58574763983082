import React from "react";
import Logo from "../../layout/logo/Logo";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button } from "../../components/Component";
import { Link } from "react-router-dom";

const PendingAccountActivation = () => {
  return (
    <React.Fragment>
      <Head title="Forgot Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Logo />
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Check your email</BlockTitle>
              <BlockDes>
                <p className="fs-18px">An email has been sent with instructions on how to activate your account.</p>
                <p className="text-primary mb-5">NB* Check your Spam or Promotion if the email doesn't show in your inbox.</p>
                <Link to={`${process.env.PUBLIC_URL}/login`}>
                  <Button color="primary" size="lg">Back to Login</Button>
                </Link>
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default PendingAccountActivation;
