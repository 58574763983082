import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";
import DatePicker from "react-datepicker";

export const SalesPaymentTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      amount_paid: 0,
      balance_due: 0,
    }, 
    payments: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    customer_search: '',
    phone_search: '',
    payment_type: '',
    sales_id_search: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    source: 'all'
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [filterLink, setFilterLink] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const currentUrl = (filters) => {
    let payment_type = filters.payment_type.length > 0 ? `&pt=${filters.payment_type}` : ``;
    let customer_search = filters.customer_search.length > 0 ? `&cs=${filters.customer_search}` : ``;
    let phone_search = filters.phone_search.length > 0 ? `&ps=${filters.phone_search}` : ``;
    let sales_id_search = filters.sales_id_search.length > 0 ? `&ss=${filters.sales_id_search}` : ``;
    let date_range = filters.date_range !== null ? `&r=${filters.date_range}` : ``;
    let source = filters.source !== null ? `&so=${filters.source}` : ``;

    if( ( payment_type !== '' ||
      customer_search !== '' ||
      phone_search !== '' ||
      sales_id_search !== '' ||
      date_range !== '' ||
      filters.source !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${payment_type}${customer_search}${phone_search}${sales_id_search}${date_range}${source}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    setFilterLink(params);
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getSalesPayment(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      payment_type: '',
      customer_search: '',
      phone_search: '',
      sales_id_search: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
      source: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getSalesPayment(params);
  }

  const filterSalesPayment = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getSalesPayment(filters);
  }

  const getSalesPayment = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/sales/payments", {
      params: {
        business_id: businessID,
        page: filters.page,
        payment_type: filters.payment_type,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        sale_number: filters.sales_id_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        sale_origin: filters.source
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            amount_paid: 0,
            balance_due: 0,
          }, 
          payments:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            amount_paid: 0,
            balance_due: 0,
          }, 
          payments:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const downloadSalesPayment = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/sales/payments/download", {
      params: {
        business_id: businessID,
        payment_type: filters.payment_type,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        sale_number: filters.sales_id_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        sale_origin: filters.source
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No payment found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let payment_type = queryParams.hasOwnProperty('pt') ? queryParams.pt : '';
    let customer_search = queryParams.hasOwnProperty('cs') ? queryParams.cs : '';
    let phone_search = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let sales_id_search = queryParams.hasOwnProperty('ss') ? queryParams.ss : '';
    let date_range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let source = queryParams.hasOwnProperty('so') ? queryParams.so : 'all';
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setFromDate(dateRangeStart);
      setToDate(dateRangeEnd);
    }

    let params = filters;
    params.page = page;
    params.payment_type = payment_type;
    params.customer_search = customer_search;
    params.phone_search = phone_search;
    params.sales_id_search = sales_id_search;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;
    params.source = source;

    setFilters(params);
    getSalesPayment(params);

  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.payments.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Amount Received</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_paid}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-danger">Balance Due</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.balance_due}</div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Payment Types` : `Payment Type`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Payments Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="payment_type">
                                    Payment Type
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="payment_type" className="form-control form-control-lg" id="payment_type" defaultValue={filters.payment_type} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="customer_search">
                                    Customer Name / Customer ID
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="customer_search" className="form-control form-control-lg" id="customer_search" defaultValue={filters.customer_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Phone Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="phone_search" className="form-control form-control-lg" id="phone_search" defaultValue={filters.phone_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="sales_id_search">
                                    Sale ID
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="number" onChange={onInputChange} name="sales_id_search" className="form-control form-control-lg" id="sales_id_search" defaultValue={filters.sales_id_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone-no">
                                    Sale Source
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select source"
                                      defaultValue={filters.source}
                                      style={{ width: "100%" }} 
                                      name="source"
                                      onChange={(value) => onSelectChange(value, "source")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "business", label: "Business"},
                                        {value: "storefront", label: "Storefront"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">From Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={fromDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'from');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">To Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={toDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'to');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterSalesPayment();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getSalesPayment(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadSalesPayment} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Payments"} descriptionText={"Please wait while your sales payment list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Sales Payment Ready"} descriptionText={"Sales Payment CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.payments.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No payment found</h5>
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow>
                          <span className="sub-text">Payment Type</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Payments</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total Amount</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <Icon name="more-h"></Icon>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.payments.map((data, index) => {
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/sales-payments-received/b/${businessID}/details/${data.payment_type}${filterLink ? `?p=1${filterLink}` : ``}`}>
                                  <div className="user-card">
                                    <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                    <div className="user-info">
                                      <span className={`tb-lead fw-bold`}>
                                        {data.payment_type}{" "}
                                      </span>
                                      <div className="price d-md-none">
                                        Payments: {data.payment_count} • <small>{currency} </small> {data.total_amount_paid}
                                      </div>
                                    </div>
                                  </div>                                  
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md" className="tb-tnx-desc">
                                <span>{data.payment_count}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.total_amount_paid}
                                </span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/sales-payments-received/b/${businessID}/details/${data.payment_type}${filterLink ? `?p=1${filterLink}` : ``}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
