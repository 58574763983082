import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert, Badge } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { Upload, InputNumber, Input, Select, Checkbox, Form, Divider, Space } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { createSubdomain, findUpper, getAxiosHeaders, getTextColorBaseOnTheme, phoneNumberFormatter, recommendedColors, validateNoZero, validateSubdomain, validateURL } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';
const { TextArea } = Input;

export const StorefrontFulfillment = ({ history, match }) => {

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [form] = Form.useForm();
  const [isVerified, setIsVerified] = useState("not-verified");
  const [noStorefrontFound, setNoStorefrontFound] = useState(false);
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    enable_delivery: "",
    delivery_type: "",
    delivery_config: {
      flat_amount: 0,
      has_free_above: "no",
      free_above_amount: 0
    },
    delivery_note: "",
    enable_pickup: "",
    pickup_note: ""
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setFormError("");

    axios.put(baseURLs.API_URL + `/businesses/storefront-fulfillment/${businessID}`, {
      enable_delivery: _formData.enable_delivery,
      delivery_type: _formData.delivery_type,
      delivery_config: JSON.stringify(formData.delivery_config),
      delivery_note: _formData.delivery_note,
      enable_pickup: _formData.enable_pickup,
      pickup_note: _formData.pickup_note,
    }, getAxiosHeaders())
    .then((response) => {
      // let responseInfo = response.data;
      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'enable_delivery',
              errors: errorResponse.errors.hasOwnProperty("enable_delivery") ? [errorResponse.errors.enable_delivery] : [],
            },
            {
              name: 'delivery_type',
              errors: errorResponse.errors.hasOwnProperty("delivery_type") ? [errorResponse.errors.delivery_type] : [],
            },
            {
              name: 'delivery_config',
              errors: errorResponse.errors.hasOwnProperty("delivery_config") ? [errorResponse.errors.delivery_config] : [],
            },
            {
              name: 'delivery_note',
              errors: errorResponse.errors.hasOwnProperty("delivery_note") ? [errorResponse.errors.delivery_note] : [],
            },
            {
              name: 'enable_pickup',
              errors: errorResponse.errors.hasOwnProperty("enable_pickup") ? [errorResponse.errors.enable_pickup] : [],
            },
            {
              name: 'pickup_note',
              errors: errorResponse.errors.hasOwnProperty("pickup_note") ? [errorResponse.errors.pickup_note] : [],
            }            
          ]);
          
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getStorefrontSettings = (businessID) => {
    axios.get(baseURLs.API_URL + `/businesses/storefront-settings`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then(async(response) => {
      if (response.status === 204) {
        setIsVerified(businessInfo.is_verified);
        setNoStorefrontFound(true);
      } else {
        let responseInfo = response.data;
        let isVerified = responseInfo.data.is_verified;
        setIsVerified(isVerified);
        let fulfillmentSettings = responseInfo.data.storefront_info;
  
        let _formData = formData;
        _formData.enable_delivery = fulfillmentSettings.delivery_enabled;
        _formData.enable_pickup = fulfillmentSettings.pickup_enabled;
        _formData.delivery_note = fulfillmentSettings.delivery_note;
        _formData.pickup_note = fulfillmentSettings.pickup_note;
        
        if(fulfillmentSettings.delivery_config){
          _formData.delivery_type = fulfillmentSettings.delivery_config.type;
          _formData.delivery_config = fulfillmentSettings.delivery_config.config;
        }
  
        setFormData({..._formData});
      }

      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const updateFulfillmentType = (fulfillmentType) => {
    if(fulfillmentType === 'delivery') {
      let oldState = form.getFieldValue('enable_delivery');
      let newState = oldState === 'yes' ? 'no' : 'yes'
      form.setFieldValue('enable_delivery', newState);
      setFormData({...formData, enable_delivery: newState})
      return
    }

    let oldState = form.getFieldValue('enable_pickup');
    let newState = oldState === 'yes' ? 'no' : 'yes'
    form.setFieldValue('enable_pickup', newState);
    setFormData({...formData, enable_pickup: newState})
  }

  const updateDeliveryType = (type) => {
    form.setFieldsValue({
      delivery_type: type,
      delivery_config: {
        flat_amount: 0,
        has_free_above: "no",
        free_above_amount: 0
      }
    });
    setFormData({
      ...formData, 
      delivery_type: type,
      delivery_config: {
        flat_amount: 0,
        has_free_above: "no",
        free_above_amount: 0
      }
    });
  }

  const updateDeliveryConfig = (field, value) => {   
    let deliveryConfig = formData.delivery_config;
    
    if(field === 'has_free_above') {
      let has_free_above = deliveryConfig.has_free_above === "yes" ? "no" : "yes";
      deliveryConfig.has_free_above = has_free_above

      form.setFieldsValue({
        delivery_config: {
          ...form.getFieldValue('delivery_config'), // Preserve other nested objects
          has_free_above
        }
      });

      setFormData({
        ...formData,
        delivery_config: deliveryConfig
      });

      return;
    }



  }

  const CustomCheckbox = ({ title, type, status }) => (
    <>
      <div className="title mb-0">
        <h6 className="mb-0">{title} 
          {
            status === 'Enabled' ?
            <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Enabled</span></Badge>
            :
            <Badge pill color="danger" className="badge-dim badge-sm"><Icon name="na"></Icon><span>Disabled</span></Badge>
          }
        </h6>
      </div>
      <span className="fs-12px fw-semibold text-dark">{type}</span>
    </>
  );

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getStorefrontSettings(businessID);
  }, [match.params.businessID]);


  return (
    <React.Fragment>
      <Head title="Storefront Fulfillment Settings"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Storefront Fulfillment Settings
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update the pick-up and delivery information for your storefront.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              formError &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {formError}{" "}
                  </Alert>
                </div>
                
              </Block>
            }

            <div className="card card-bordered">
              <div className="fm-content">
                {
                  isVerified !== 'verified' || noStorefrontFound ?
                  <Block>
                    {
                      noStorefrontFound ? 
                      <div className="text-center m-5">
                        <div className="price-plan-media">
                          <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                        </div>
                        <div className="price-plan-info">
                          <span className="title fw-normal">No Storefront found. Setup your Storefront then update the fulfillment settings.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/storefront-settings/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Setup Storefront</button>
                          </Link>                    
                        </div>
                      </div>
                      :
                      <div className="text-center m-5">
                        <div className="price-plan-media">
                          <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                        </div>
                        <div className="price-plan-info">
                          <span className="title fw-normal">Verify your business to start receiving orders online.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Verify Business</button>
                          </Link>                    
                        </div>
                      </div>
                    }
                    
                  </Block>
                  :
                  <Block>
                    <Form name="update-fulfillment-settings"
                      form={form} className="is-alter"
                      initialValues={formData} 
                      onFinish={handleFormSubmit}
                      onFinishFailed={onFinishFailed}>
          
                      <div className="row g-2">
                        <div className="col-md-12">
                          <h6 className="overline-title text-primary-alt mb-2">Delivery Settings</h6>
                        </div>
          
                        <div className="col-md-12">
                          <div className="form-group">
                            <Form.Item>
                              <Form.Item name={'enable_delivery'}
                                noStyle
                                rules={[
                                  {required: false}
                                ]}>
                                <div className={classNames("card-bordered card", {"bg-success-dim": formData.enable_delivery === 'yes'})}>
                                  <div className="nk-support-item pointer-cursor d-flex" onClick={() => updateFulfillmentType('delivery')}>
                                    <em className="icon ni ni-truck h3 me-2" />
                                    <div className="nk-support-content m-0 mt-1">
                                      <CustomCheckbox
                                        checked={formData.enable_delivery === 'yes'}
                                        id="enable_delivery"
                                        title="Delivery"
                                        status={formData.enable_delivery === 'yes' ? 'Enabled' : 'Disabled'} 
                                        type={formData.enable_delivery === 'yes' ? `Click to Disable` : `Click to Enable`}                                
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Form.Item> 
                            </Form.Item>
                          </div>
                        </div>
                        {
                          formData.enable_delivery === 'yes' &&
                          <div className="col-md-12">
                            <div className="form-group px-4 pb-3">
                              <Form.Item>
                                <div className="form-control-wrap">
                                  <Form.Item name={'delivery_type'}
                                    noStyle
                                    rules={[
                                      {required: true, message: 'Delivery type is required'}
                                    ]}>
                                      <div className="custom-control custom-radio w-100">
                                        <input
                                          type="radio"
                                          name="delivery_type"
                                          className="custom-control-input"
                                          onChange={() => {updateDeliveryType('free')}}
                                          checked={form.getFieldValue('delivery_type') === 'free'}
                                          id="free" />
                                        <label className="custom-control-label" htmlFor="free">
                                          <h6 className="mb-0 fs-15px">Free Delivery</h6>
                                          <p>Orders are delivered for free.</p>
                                        </label>
                                      </div>
          
                                      <div className="custom-control custom-radio w-100 mt-4">
                                        <input
                                          type="radio"
                                          name="delivery_type"
                                          className="custom-control-input"
                                          onChange={() => {updateDeliveryType('flat')}}
                                          checked={formData.delivery_type === 'flat'}
                                          id="flat" />
                                        <label className="custom-control-label" htmlFor="flat">
                                          <h6 className="mb-0 fs-15px">Flat Delivery Fee</h6>
                                          <p>Fixed delivery fee for orders.</p>
                                        </label>
                                      </div>
          
                                      {
                                        formData.delivery_type === "flat" &&
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="form-group mt-3">
                                              <Form.Item>
                                                <div className="form-label-group">
                                                  <label className="form-label">Delivery Fee <span className="text-danger">*</span></label>
                                                </div>
                                                <Form.Item name={['delivery_config','flat_amount']}
                                                  noStyle
                                                  rules={[
                                                    {required: true, message: 'A flat delivery fee is required'},
                                                    {validator: (_, value) => validateNoZero(value)}
                                                  ]}>
                                                  <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                                </Form.Item> 
                                              </Form.Item>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      
                                      <div className="custom-control custom-radio w-100 mt-4">
                                        <input
                                          type="radio"
                                          name="delivery_type"
                                          className="custom-control-input"
                                          onChange={() => {updateDeliveryType('location')}}
                                          checked={formData.delivery_type === 'location'}
                                          id="location" />
                                        <label className="custom-control-label" htmlFor="location">
                                          <h6 className="mb-0 fs-15px">Location Based Delivery Fee</h6>
                                          <p>Delivery fee is based on your delivery locations.
                                            <Link to={`${process.env.PUBLIC_URL}/delivery-pickup/b/${businessID}`}>
                                              <span className="fw-semibold ps-1">Update Locations</span>
                                            </Link>
                                          </p>
                                        </label>
                                      </div>
                                      
                                  </Form.Item>                          
                                </div>
                              </Form.Item>
                                                                          
                            </div>
                            
                            {
                              (formData.delivery_type === 'flat' || formData.delivery_type === 'location') &&
                              <div className="mt-4">
                                <div className="col-md-12">
                                  <hr className="m-4 mx-0" />
                                </div>
                                <Form.Item name={['delivery_config','has_free_above']}
                                  noStyle
                                  rules={[
                                    {required: false}
                                  ]}>
                                  <div className="custom-control custom-checkbox w-100">
                                    <input
                                      type="checkbox"
                                      name="has_free_above"
                                      className="custom-control-input"
                                      onChange={(value) => {updateDeliveryConfig('has_free_above', value)}}
                                      checked={formData.delivery_config?.has_free_above === 'yes'}
                                      id="has_free_above" />
                                    <label className="custom-control-label" htmlFor="has_free_above">
                                      <h6 className="mb-0 fs-15px">Free Delivery Over a Certain Amount</h6>
                                      <p>Delivery for an order is free once the order value is over a certain amount.</p>
                                    </label>
                                  </div>
                                </Form.Item>
                                {
                                  formData.delivery_config.has_free_above === "yes" &&
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group mt-3">
                                        <Form.Item>
                                          <div className="form-label-group">
                                            <label className="form-label">Order Amount <span className="text-danger">*</span></label>
                                          </div>
                                          <Form.Item name={['delivery_config','free_above_amount']}
                                            noStyle
                                            rules={[
                                              {required: true, message: 'amount is required'},
                                              {validator: (_, value) => validateNoZero(value)}
                                            ]}>
                                            <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                          </Form.Item> 
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div> 
                            } 
                            <div className="mt-4">
                              <div className="col-md-12">
                                <hr className="m-4 mx-0" />
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                      <label className="form-label">Delivery Note <span className="text-danger">*</span>
                                        <Button type="button" id="delivery_note_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                          <Icon name="info" />
                                        </Button>
                                        <UncontrolledPopover target="delivery_note_info_popover" placement="bottom" trigger="focus">
                                          <PopoverBody>
                                            <div className="mb-2">Delivery note is important information regarding delivery times, days, and any additional details relevant to the customer for a smooth delivery process.</div>
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </label>
                                    </div>
                                    <div className="form-control-wrap">
                                      <Form.Item name={'delivery_note'}
                                        noStyle
                                        rules={[
                                          {required: true, message: "Enter a delivery note"}
                                        ]}>
                                          <TextArea rows={4} placeholder="Note" />
                                      </Form.Item>                       
                                    </div>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
          
                        <div className="col-md-12">
                          <hr className="m-3 mx-0" />
                          <h6 className="overline-title text-primary-alt mb-4 mt-4">Pick Up Settings</h6>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Form.Item>
                            <Form.Item name={'enable_pickup'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                              <div className={classNames("card-bordered card", {"bg-success-dim": formData.enable_pickup === 'yes'})}>
                                <div className="nk-support-item pointer-cursor d-flex" onClick={() => updateFulfillmentType('pickup')}>
                                  <em className="icon ni ni-map-pin h3 me-2" />
                                  <div className="nk-support-content m-0 mt-1">
                                    <CustomCheckbox
                                      checked={formData.enable_pickup === 'yes'}
                                      id="enable_pickup"
                                      title="Pick Up"
                                      status={formData.enable_pickup === 'yes' ? 'Enabled' : 'Disabled'} 
                                      type={formData.enable_pickup === 'yes' ? `Click to Disable` : `Click to Enable`}                                
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      {
                        formData.enable_pickup === 'yes' &&
                        <>
                          <p className="text-dark">Pick-ups are based on pick-up locations.
                            <Link to={`${process.env.PUBLIC_URL}/delivery-pickup/b/${businessID}`}>
                              <span className="fw-semibold ps-1">Update Locations</span>
                            </Link>
                          </p>
          
                          <div className="col-md-12">
                            <div className="form-group mt-4">
                              <Form.Item>
                                <div className="form-label-group">
                                  <label className="form-label">Pick Up Note <span className="text-danger">*</span>
                                    <Button type="button" id="pickup_note_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                      <Icon name="info" />
                                    </Button>
                                    <UncontrolledPopover target="pickup_note_info_popover" placement="bottom" trigger="focus">
                                      <PopoverBody>
                                        <div className="mb-2">Pick-up note is important information regarding pick-up times, days, and any additional details relevant to the customer for a smooth pick-up process.</div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </label>
                                </div>
                                <div className="form-control-wrap">
                                  <Form.Item name={'pickup_note'}
                                    noStyle
                                    rules={[
                                      {required: true, message: "Enter a pick-up note"}
                                    ]}>
                                      <TextArea rows={4} placeholder="Note" />
                                  </Form.Item>                       
                                </div>
                              </Form.Item>
                            </div>
                          </div>
                        </>
                      }
          
                      <div className="pt-5 text-center">
                        <Button disabled={requesting} type="submit" color="primary" size="lg" >
                          {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                        </Button>
                      </div>
                    </Form>
                  </Block>
                } 
              </div>
            </div>
          </Block>
          
        </>
      }
      </Content>
      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Updated Successfully`} descriptionText={`Storefront fulfillment settings have been updated successfully.`} 
        />
      }
    </React.Fragment>
  )
}