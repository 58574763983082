import React from "react";
import Logo from "../../layout/logo/Logo";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";

const ResetPasswordSuccess = () => {
  return (
    <React.Fragment>
      <Head title="Forgot Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs text-center">
          <div className="brand-logo pb-5">
            <Logo />
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <em className="icon ni ni-check-fill-c h1 text-success" />
                <BlockTitle tag="h4" className="mt-4">Password Changed</BlockTitle>
                <BlockDes>
                  <p className="fs-18px mb-5">You have successfully changed your account password.</p>
                  <Link to={`${process.env.PUBLIC_URL}/login`}>
                    <Button color="primary" size="lg">Login</Button>
                  </Link>
                </BlockDes>
              </BlockContent>
            </BlockHead>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ResetPasswordSuccess;
