import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import logoPlaceholder from "../images/crop.png";
import Head from "../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BackTo,
  BlockTitle,
  Icon,
  Button
} from "../components/Component";
import { Input, Select, Checkbox, Form } from 'antd';
import { baseURLs } from '../utils/Constants';
import axios from 'axios';
import { Spinner, Alert } from "reactstrap";
import PhoneNumberInput from "./components/PhoneNumberInput";
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import { SuccessModal } from "./components/AlertModals";

const AccountSettings = ({ history }) => {

  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState(0);
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    full_name: "",
    email: ""
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setLoading(true);
    setFormError("");

    console.log({_formData});

    axios.put(baseURLs.API_URL + "/businesses/account-info", {
      full_name: _formData.full_name,
      email: _formData.email
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo.full_name = _formData.full_name;
      userInfo.email = _formData.email;

      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      toggleModal("successModal");
      setLoading(false);
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'full_name',
              errors: errorResponse.errors.hasOwnProperty("full_name") ? [errorResponse.errors.full_name] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            },
            
          ]);          

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setLoading(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getAccountInfo = () => {
    axios.get(baseURLs.API_URL + `/businesses/account-info`, {headers: getAxiosHeaders().headers})
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let accountInfo = responseInfo.data;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        userInfo.full_name = accountInfo.full_name;
        userInfo.email = accountInfo.email;
        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        let _formData = formData;
        _formData.full_name = accountInfo.full_name;
        _formData.email = accountInfo.email;

        setFormData(_formData);

      }

      setLoading(false);
    }).catch((error) => {
      console.log(error);
      // history.push(`${process.env.PUBLIC_URL}/server-offline`);
    });
  }

  const getInvitations = () => {
    axios.get(baseURLs.API_URL + `/businesses/invitations`, {headers: getAxiosHeaders().headers})
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        setInvitations(responseInfo.data.meta.total_records);
      }
    }).catch((error) => {
      console.log(error);
      // history.push(`${process.env.PUBLIC_URL}/server-offline`);
    });
  }

  useEffect(() => {
    getInvitations();
    getAccountInfo();
  }, []);

  return (
    <React.Fragment>
      <Head title="Account Settings" />
      <Content>        
        <BlockHead size="sm" className="wide-sm m-auto">
          <BlockHeadContent>
            <BackTo history={history} link="/my-businesses" icon="arrow-left">
              Go Back
            </BackTo>
            <BlockTitle tag="h2" className="fw-normal text-center mt-3">
              Account Settings
            </BlockTitle>
            <BlockDes>
              <p className="text-center">Update your account settings information.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>

        {
          invitations > 0 && 
          <Block className="wide-sm m-auto">
            <div className="card-bordered card mb-2">
              <div className="card-inner">
                <div className="nk-help">
                  <div className="nk-help-img m-0">
                    <lord-icon
                      src="https://cdn.lordicon.com/tmqaflqo.json"
                      trigger="loop"
                      delay="2000"
                      colors="primary:#4bb3fd,secondary:#ebe6ef,tertiary:#e4e4e4"
                      style={{width:"120px",height:"120px"}}>
                    </lord-icon>
                  </div>
                  <div className="nk-help-text">
                    <h5> You have <b>{invitations}</b> {`${invitations > 1 ? `Invitations` : `Invitation`}`}.</h5>
                    <p className="text-soft">Click to view invitations from businesses.</p>
                  </div>
                  <div className="nk-help-action">
                    <Link to={`${process.env.PUBLIC_URL}/invitations`}>
                      <button className="btn btn-outline-primary">
                        View Invitations
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        }

        <Block className="wide-sm m-auto">
          {formError && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {formError}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {
          loading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs">
            <div className="inner-pre-loader">
              <Spinner  color="dark" />          
            </div>
          </Block>
          :
          <Block className="wide-sm m-auto">
            {/* success modal */}
            { 
              activeModal === "successModal" &&
              <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                headerText={`Account Details Updated`} descriptionText={`Your account details have been updated successfully.`}
              />
            }

            <Form name="account-settings"
              form={form} className="is-alter"
              initialValues={formData} 
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}>
              <div className="card card-bordered">
                <div className="fm-head bg-lighter">
                  <div className="fm-count"><Icon name="user-alt fs-22px"></Icon></div>
                  <div className="fm-title">
                    <h5 className="title fw-normal">Personal Details</h5>
                    <p className="nk-block-des">Update your account information.</p></div>
                </div>
                <div className="fm-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Full Name <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'full_name'}
                            noStyle
                            rules={[
                              {required: true, message: 'Full name is required',}
                            ]}>
                            <Input size="large"
                              type="text" className="form-control form-control-lg" 
                              placeholder="Enter your full name"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Email <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'email'}
                            noStyle
                            rules={[
                              {required: true, message: 'Email is required'}, {type: 'email', message: 'Email is invalid'}
                            ]}>
                            <Input size="large" 
                              type="email" name="email" className="form-control form-control-lg" 
                              placeholder="mailme@email.com"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fm-footer">
                  <div className="pt-2 text-center">
                    <Button disabled={loading} type="submit" color="primary" size="lg" >
                      {loading ? <Spinner size="sm" color="light" /> : "Save Changes"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Block>
        }

      </Content>
    </React.Fragment>
  );
};

export default AccountSettings;
