import React, { useState, useRef, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  Badge,
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert,
  Collapse 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  UserAvatar,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getAxiosUploadHeaders, moneyToFloat, generateVariants, sumVariantTotalQuantity } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import imagePlaceholder from "../images/product_placeholder.png";
import DatePicker from "react-datepicker";
import { SuccessModal } from "./components/AlertModals";
import { PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AutoComplete, Empty, Input, Select, InputNumber, Divider, Spin, Space, Form, Tag, Upload, Tooltip } from 'antd';
import { AddProductVariant, EditProductVariant } from "./components/ProductModals";
const { Dragger } = Upload;

const EditProduct = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [showAdvanceOptions, setShowAdvanceOptions] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [productID, setProductID] = useState("");
  const [imageUploadLimit, setImageUploadLimit] = useState(1);
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [form] = Form.useForm();
  const [newCategory, setNewCategory] = useState("");
  const [stockLimitChecked, setStockLimitChecked] = useState(false);
  const [showOnStorefrontChecked, setShowOnStorefrontChecked] = useState(true);
  const [featuredChecked, setFeaturedChecked] = useState(false);
  const [categories, setCategories] = useState([]);

  const [variantTypes, setVariantTypes] = useState([]);
  const [variants, setVariants] = useState([]);
  const [editVariantTypeIndex, setEditVariantTypeIndex] = useState("");
  const [openVariantList, setOpenVariantList] = useState("0");
  const [totalVariantQuantity, setTotalVariantQuantity] = useState(0);
  
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const [fileList, setFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);
  const [primaryImageIndex, setPrimaryImageIndex] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const [expirationFields, setExpirationFields] = useState([]);
  const [formData, setFormData] = useState({
    product_name: "",
    description: "",
    cost_price: "",
    selling_price: "",
    previous_price: "",
    quantity: '1',
    restock_level: '1',
    stock_limit: 'limited',
    stock_status: 'in_stock',
    show_on_storefront: 'yes',
    featured_product: 'no',
    category: "",
    tags: [],
    variant_types: [],
    variants: [],
    SKU: "",
    barcode: "",
    product_location: "",
    expirations: [],
  });

  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleAddExpirationDate = () => {
    setExpirationFields([...expirationFields, { expiration_date: new Date(), alert_date: new Date() }]); // Each time you add, you append a new empty object
  };

  const handleExpirationDateChange = (date, index, type) => {
    const newFields = [...expirationFields];
    newFields[index][type] = date;
    setExpirationFields(newFields);
  };
  
  const handleRemoveExpirationDate = (index) => {
    const newFields = [...expirationFields];
    newFields.splice(index, 1);
    setExpirationFields(newFields);
  };

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    const formInfo = new FormData();

    // Append product images to formInfo
    fileList.forEach(file => {
      if(file?.is_new){
        formInfo.append('product_images', file);
      }
    });
    
    formInfo.set("removed_images", JSON.stringify(deleteFileList));
    
    formInfo.set("primary_product_image_index", primaryImageIndex);
    formInfo.set("business_id", businessID);
    formInfo.set("product_id", productID);
    formInfo.set("product_name", _formData.product_name);
    formInfo.set("description", _formData.description);
    formInfo.set("cost_price", _formData.cost_price);
    formInfo.set("selling_price", _formData.selling_price);
    formInfo.set("previous_price", _formData.previous_price);
    formInfo.set("quantity", _formData.quantity);
    formInfo.set("restock_level", _formData.restock_level);
    // formInfo.set("stock_limit", stockLimitChecked ? "unlimited" : "limited");
    
    // if(stockLimitChecked)
    // formInfo.set("stock_status", _formData.stock_status);
  
    formInfo.set("show_on_storefront", showOnStorefrontChecked ? "yes" : "no");
    formInfo.set("featured_product", featuredChecked ? "yes" : "no");
    formInfo.set("category", _formData.category);
    formInfo.set("tags", JSON.stringify(tags));
    formInfo.set("variants", JSON.stringify(variants ?? []));
    formInfo.set("variant_types", JSON.stringify(variantTypes ?? []));
    formInfo.set("SKU", _formData.SKU);
    formInfo.set("barcode", _formData.barcode);
    formInfo.set("product_location", _formData.product_location);
    formInfo.set("expirations", JSON.stringify(_formData?.expirations ?? []));
    

    axios.put(baseURLs.API_URL + `/products/edit/${productID}`, formInfo, getAxiosUploadHeaders())
    .then((response) => {      
      toggleModal("successModal");
      // getProductInfo(businessID, productID);
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'product_name',
              errors: errorResponse.errors.hasOwnProperty("product_name") ? [errorResponse.errors.product_name] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'cost_price',
              errors: errorResponse.errors.hasOwnProperty("cost_price") ? [errorResponse.errors.cost_price] : [],
            },
            {
              name: 'selling_price',
              errors: errorResponse.errors.hasOwnProperty("selling_price") ? [errorResponse.errors.selling_price] : [],
            },
            {
              name: 'previous_price',
              errors: errorResponse.errors.hasOwnProperty("previous_price") ? [errorResponse.errors.previous_price] : [],
            },
            {
              name: 'quantity',
              errors: errorResponse.errors.hasOwnProperty("quantity") ? [errorResponse.errors.quantity] : [],
            },
            {
              name: 'restock_level',
              errors: errorResponse.errors.hasOwnProperty("restock_level") ? [errorResponse.errors.restock_level] : [],
            },
            {
              name: 'show_on_storefront',
              errors: errorResponse.errors.hasOwnProperty("show_on_storefront") ? [errorResponse.errors.show_on_storefront] : [],
            },
            {
              name: 'featured_product',
              errors: errorResponse.errors.hasOwnProperty("featured_product") ? [errorResponse.errors.featured_product] : [],
            },
            {
              name: 'category',
              errors: errorResponse.errors.hasOwnProperty("category") ? [errorResponse.errors.category] : [],
            },
            {
              name: 'tags',
              errors: errorResponse.errors.hasOwnProperty("tags") ? [errorResponse.errors.tags] : [],
            },
            {
              name: 'variants',
              errors: errorResponse.errors.hasOwnProperty("variants") ? [errorResponse.errors.variants] : [],
            },
            {
              name: 'SKU',
              errors: errorResponse.errors.hasOwnProperty("SKU") ? [errorResponse.errors.SKU] : [],
            },
            {
              name: 'barcode',
              errors: errorResponse.errors.hasOwnProperty("barcode") ? [errorResponse.errors.barcode] : [],
            },
            {
              name: 'product_location',
              errors: errorResponse.errors.hasOwnProperty("product_location") ? [errorResponse.errors.product_location] : [],
            },
            {
              name: 'expirations',
              errors: errorResponse.errors.hasOwnProperty("expirations") ? [errorResponse.errors.expirations] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    setErrorMessage('Error: Some of the fields are invalid or missing some information.');
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['product_name', 'cost_price', 'selling_price', 'variants'];
    const watchedFieldsChanged = fieldsToWatch.filter(field => changedFields.some(f => f.name[0] === field));

    watchedFieldsChanged.forEach(field => {
      switch (field) {
        case 'product_name':
            handleProductNameChange(form.getFieldValue("product_name"))
            break;

        case 'cost_price':
            handlePriceChange('cost_price', form.getFieldValue("cost_price"))
            break;

        case 'selling_price':
            handlePriceChange('selling_price', form.getFieldValue("selling_price"))
            break;

        case 'variants':
            handleVariantInputChange();
            break;

        default:
            console.log(`Field ${field} changed but no specific action is defined.`);
            break;
      }
    });
  }

  const handleProductNameChange = (productName) => {
    if(variantTypes.length > 0){
      let cost_price = formData.cost_price;
      let selling_price = formData.selling_price;

      let productVariants = generateVariants(variantTypes, variants, productName, cost_price, selling_price);
        console.log({productVariants});
  
      setVariants([...productVariants]);
      form.setFieldsValue({
        variants: productVariants,
      });
    }

    setFormData({...formData, product_name: productName})

  };

  const handlePriceChange = (name, value) =>  {
    setFormData({ ...formData, [name]: value });

    if(variants.length > 0){
      let _variants = variants;
      let updatedVariants = _variants.map(variant => {
        if (variant[name] == null || variant[name] === '') {
          return {
              ...variant,
              [name]: value
          };
        }
        return variant;
      });

      setVariants([...updatedVariants]);
      form.setFieldsValue({
        variants: updatedVariants,
      });

    }

  };
  
  const handleVariantInputChange = () =>  {
    let _variants = form.getFieldValue('variants') || [];
    setVariants([..._variants]);
    
    let totalVariantQuantity = sumVariantTotalQuantity(_variants);
    setTotalVariantQuantity(totalVariantQuantity);

  };

  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const addNewCategory = (e) => {
    e.preventDefault();
    
    if(newCategory) {
      if(!categories.includes(newCategory)){
        setCategories([...categories, newCategory]);
      }
      setNewCategory("");
    }
  }

  const getProductsCategory = (businessID) => {

    axios.get(baseURLs.API_URL + "/products/category", {
      params: {
        business_id: businessID,
        limit: 'all'
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let categories = responseInfo.data.categories;
        let _categories = categories.map(category => category.category_name)
        setCategories([..._categories]);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getProductInfo = (businessID, productID) => {

    axios.get(baseURLs.API_URL + `/products/${productID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let productInfo = responseInfo.data.product_info;
        
        // update form data 
        setFormData({
          product_name: productInfo.product_name,
          description: productInfo.description ?? '',
          cost_price: moneyToFloat(productInfo.cost_price),
          selling_price: moneyToFloat(productInfo.selling_price),
          previous_price: moneyToFloat(productInfo.previous_price),
          quantity: parseInt(productInfo.quantity),
          restock_level: parseInt(productInfo.restock_level),
          show_on_storefront: productInfo.show_on_storefront,
          featured_product: productInfo.featured_product,
          category: productInfo.category,
          tags: productInfo.tags,
          variants: productInfo.variants,
          variant_types: productInfo.variant_types,
          SKU: productInfo.SKU ?? '',
          barcode: productInfo.barcode ?? '',
          product_location: productInfo.product_location ?? '',
          expirations: productInfo.expirations,
        });

        if(productInfo.images?.length > 0) {
          let productImages =productInfo.images;
          const primaryImageIndex = productImages.findIndex(productImages => productImages.is_primary === true);
          setPrimaryImageIndex(primaryImageIndex);
        }

        setTotalVariantQuantity(productInfo.quantity);
        setVariantTypes([...productInfo.variant_types]);
        setVariants([...productInfo.variants]);
        setShowOnStorefrontChecked(productInfo.show_on_storefront === "yes" ? true : false);
        setFeaturedChecked(productInfo.featured_product === "yes" ? true : false);
        setTags(productInfo.tags);
        setFileList(productInfo.images);
        if(productInfo.expirations) {
          let expirationsDates = productInfo.expirations;
          let _expirationsDates = [];
          for(let i = 0; i < expirationsDates.length; i++) {
            _expirationsDates.push({
              batch_number: expirationsDates[i].batch_number,
              expiration_date: moment(expirationsDates[i].expiration_date).toDate(),
              alert_date: moment(expirationsDates[i].alert_date).toDate(),
            })
          }
          setExpirationFields(_expirationsDates);
        }
        
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleDescriptionChange = (value) => {
    form.setFieldValue("description", value);
    setFormData({...formData, description: value});
  };

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const _productID = parseInt(match.params.productID, 10);
    if ( !Number.isInteger(businessID) && !Number.isInteger(_productID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    let _uploadLimit = 1;
    if(currentBusiness.subscription.package === 'growth'){
      _uploadLimit = 3;
    } else if(currentBusiness.subscription.package === 'expansion'){
      _uploadLimit = 5;
    }

    setImageUploadLimit(_uploadLimit);
    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setProductID(_productID);
    getProductInfo(businessID, _productID);
    getProductsCategory(businessID);
  }, [match.params.businessID]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEditVariantType = (variantIndex) => {
    setEditVariantTypeIndex(variantIndex);
    toggleModal("editVariantTypeModal");
  };

  const handleDeleteVariantType = (variantIndex) => {
    const _variantTypes = [...variantTypes];
    _variantTypes.splice(variantIndex, 1);

    let productName = formData.product_name;
    let cost_price = formData.cost_price;
    let selling_price = formData.selling_price;

    if(_variantTypes.length > 0){
      let productVariants = generateVariants(_variantTypes, variants, productName, cost_price, selling_price);
  
      setVariants([...productVariants]);
      form.setFieldsValue({
        variants: productVariants,
      });
      setVariantTypes(_variantTypes);
    
    } else {
      setVariants([]);
      form.setFieldsValue({
        variants: [],
      });
      setVariantTypes([]);
      setTotalVariantQuantity(0);
    }

  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const tagInputStyle = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const tagPlusStyle = {
    height: 22,
    borderStyle: 'dashed',
  };

  const removeProductImage = (file, index) => {
    const updatedFileList = fileList.filter(item => item.uid !== file.uid);
    if(index === primaryImageIndex) {
      setPrimaryImageIndex(0);
    }
    
    setFileList(updatedFileList);

    // add images to delete
    if(!file?.is_new){
      setDeleteFileList([...deleteFileList, file.url])
    }
  }

  const uploadProps = {
    fileList,
    listType: "picture",
    showUploadList: false,
    onRemove: (file) => {
      // setFileList([]);
      const updatedFileList = fileList.filter(item => item.uid !== file.uid);
      setFileList(updatedFileList);
    },
    beforeUpload: file => {
      const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type);
      if(!isImage) {
        file.status = 'error';
        file.error = 'You can only upload a png, jpeg and jpg file.';
        setErrorMessage('You can only upload a .png, .jpeg, .jpg file.');
      }
      
      const isSizeValid = file.size / 1024 / 1024 < 2;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 2MB!';
        setErrorMessage('File must be smaller than 2MB!');
      }

      if(isImage && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setErrorMessage("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
        return false;
      }

      if (fileList.length >= imageUploadLimit) {
        setErrorMessage(`You can only upload up to ${imageUploadLimit} ${imageUploadLimit > 1 ? `images` : `image`}.`);
        window.scrollTo({top: 0, behavior: 'smooth'});
        return false;
      }

      file.is_new = true;

      // Use FileReader to generate a preview URL
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        file.preview = reader.result;
        setFileList(prev => [...prev, file]);
      };

      return false;
    },
  };


  return (<React.Fragment>
    <Head title="Edit Product" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Edit Product
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/import`}>
                        <Button color="primary">
                          <Icon name="upload-cloud"></Icon>
                          <span>Import Products</span>
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/products/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Product Updated`} descriptionText={`The product has been updated successfully.`} 
          />
        }

        <Block className="wide-sm m-auto">
          <Form name="add-customer"
            form={form} className="is-alter"
            initialValues={formData} 
            onFieldsChange={(changedFields, allFields) => {
              debouncedHandleChangedFields(changedFields, allFields);       
            }}
            onFinish={handleFormSubmit}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="package fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Product Details</h5>
                  <p className="nk-block-des">Use the form below to edit this product.</p></div>
              </div>
              <div className="fm-content">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Product Name <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'product_name'}
                          noStyle
                          rules={[
                            {required: true, message: 'Product name is required',}
                          ]}>
                          <Input size="large"
                            type="text" className="form-control form-control-lg" 
                            placeholder="Awesome Product"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'description'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                            <ReactQuill
                              value={formData.description}
                              onChange={handleDescriptionChange}
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [1, 2, 3, false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    ['link'], ['clean']
                                  ],
                                }
                              }}
                              formats={[
                                'header',
                                'bold', 'italic', 'underline', 'strike',
                                'list', 'bullet', 'link'
                              ]}
                              placeholder="Enter product description..."
                            />
                          {/* <TextArea rows={4} placeholder="enter a description of the product" /> */}
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Product Images <span className="text-muted">(Max: {imageUploadLimit} {imageUploadLimit > 1 ? `Images` : `Image`})</span></label>
                      </div>
                      <Dragger {...uploadProps}>
                        <div className="p-3">
                          <p className="ant-upload-drag-icon fs-1 text-muted">
                            <Icon name="upload-cloud"></Icon>
                          </p>
                          <p className="ant-upload-text text-muted">Click or drag image to this area to upload</p>
                          <p className="ant-upload-hint text-muted">
                            Accepted file types for upload must be .jpg, .jpeg, .png format, and the file size should not exceed 2MB.
                          </p>

                        </div>
                      </Dragger>

                      {/* File list with option to mark as primary */}
                      <div className="d-flex flex-wrap">
                        {
                          fileList.map((file, index) => {
                            return (
                              <div className="me-2 m-lg-3 mt-2" key={file.uid}>
                                <div>
                                  <button className="btn btn-sm text-danger ps-0" type="button" onClick={() => removeProductImage(file, index)}><span>Remove</span><Icon name="trash"/></button>
                                </div>
                                <div className={`card-bordered card upload-product-image pointer-cursor ${primaryImageIndex === index && `border-primary border-width-3`}`}
                                  onClick={() => setPrimaryImageIndex(index)}
                                >
                                  {
                                    primaryImageIndex === index &&
                                    <ul className="product-badges">
                                      <li>
                                        <span className="badge bg-primary">Main</span>
                                      </li>
                                    </ul>
                                  }
                                  <img className="w-100 h-100 rounded-top" src={file.url || file.preview} alt="" />
                                </div>
                              </div>
                            )
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr className="m-3 mx-0" />
                    <h6 className="overline-title text-primary-alt mt-5">Pricing</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Cost Price <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'cost_price'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Selling Price <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'selling_price'}
                            noStyle
                            rules={[
                              {required: true, message: 'Selling price is required'}
                            ]}>
                            <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Previous Price 
                            <span className="text-muted"> (optional)</span>
                            <Button type="button" id="previous_price_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                              <Icon name="info" />
                            </Button>
                            <UncontrolledPopover target="previous_price_info_popover" placement="bottom" trigger="focus">
                              <PopoverBody>
                                The "Previous Price" shows the original price before a discount, helping customers see their savings. It's only displayed on the storefront for reference.
                              </PopoverBody>
                            </UncontrolledPopover>
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'previous_price'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr className="m-3 mx-0" />
                    <h6 className="overline-title text-primary-alt mt-5">Stock</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Quantity <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'quantity'}
                            noStyle
                            rules={[
                              {required: true, message: 'Quantity is required'}
                            ]}>
                            <InputNumber min={0} step={1} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Restock Level <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'restock_level'}
                            noStyle
                            rules={[
                              {required: true, message: 'Restock level is required'}
                            ]}>
                            <InputNumber min={1} step={1} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>

                  {/* advance options */}
                  <div className={`row g-4 d-flex ${showAdvanceOptions ? `d-block` : `d-none`}`}>
                  
                    {/* variants */}
                    <div className="col-md-12">
                      <hr className="m-3 mx-0" />
                      <h6 className="overline-title text-primary-alt mt-5">Variants</h6>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label justify-content-between d-flex">
                          <span>Variant Types</span>
                          {
                            variantTypes.length >= 3 ?
                            <Button size="sm" disabled={true} type="button" outline color="gray">
                              <Icon name="plus" />
                              <span>Add Variant Type</span>
                            </Button>
                            :
                            <Button size="sm" type="button" outline color="primary" onClick={() => {toggleModal('addVariantTypeModal')}}>
                              <Icon name="plus" />
                              <span>Add Variant Type</span>
                            </Button>
                          }
                        </label>
                      </div>
                      
                      {
                        variantTypes.length > 0 &&
                        <>
                          <div className="card-bordered card my-3">
                            <ul className="nk-support">
                              {
                                variantTypes.map((variantType, index) => (
                                  <li className="nk-support-item p-3" key={index}>
                                    <div className="nk-support-content">
                                      <div className="title m-0">
                                        <span>{variantType.type}</span>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            tag="a"
                                            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                          >
                                            <Icon name="more-h"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu end>
                                            <ul className="link-list-opt no-bdr">
                                              <li>
                                                <DropdownItem tag="a" className="pointer-cursor" onClick={() => handleEditVariantType(index)}>
                                                  <Icon name="edit-alt" />
                                                  <span>Edit</span>
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleDeleteVariantType(index)}>
                                                  <Icon name="trash" />
                                                  <span>Delete</span>
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                      <ul className="d-flex g-2">
                                        {
                                          variantType.options.map((option, oIndex) => (
                                            <li key={oIndex}>
                                              <Badge pill color="outline-secondary" >{option}</Badge>
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
                          <div className="accordion mt-4">
                            <div className="accordion-item">
                              <div className= {`accordion-head pointer-cursor ${openVariantList === '1' ? `` : `collapsed`}`} onClick={() => setOpenVariantList(openVariantList === "1" ? "0" : "1")}>
                                <p className="align-item-center fs-16px mb-0"><b>{variants.length}</b> Variants • Total Quantity: <b>{totalVariantQuantity}</b></p>
                                <p className="mb-0"><small>{`${openVariantList === '1' ? `Click to collapse` : `Click to expand`}`}</small></p>
                                <span className="accordion-icon"></span>
                              </div>
                              <Collapse className="accordion-body" isOpen={openVariantList === "1" ? true : false}>
                                <div className="accordion-inner p-0">
                                  <ul className="nk-support">
                                    {
                                      variants.map((variant, index) => (
                                        <li className="nk-support-item d-block" key={index}>
                                          <div className="nk-support-content">
                                            <div className="title m-0">
                                              <span>{variant.product_name}</span>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div className="form-group">
                                                  <Form.Item>
                                                    <div className="form-label-group">
                                                      <label className="form-label">Cost Price <span className="text-muted">(optional)</span></label>
                                                    </div>
                                                    <div className="form-control-wrap">
                                                      <Form.Item name={['variants',index,'cost_price']}
                                                        noStyle
                                                        rules={[
                                                          {required: false}
                                                        ]}>
                                                        <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" name="cost_price" /> 
                                                      </Form.Item> 
                                                    </div>
                                                  </Form.Item>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="form-group">
                                                  <Form.Item>
                                                    <div className="form-label-group">
                                                      <label className="form-label">Selling Price <span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="form-control-wrap">
                                                      <Form.Item name={['variants',index,'selling_price']}
                                                        noStyle
                                                        rules={[
                                                          {required: true, message: 'selling is required'}
                                                        ]}>
                                                        <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" name="selling_price" /> 
                                                      </Form.Item> 
                                                    </div>
                                                  </Form.Item>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="form-group">
                                                  <Form.Item>
                                                    <div className="form-label-group">
                                                      <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="form-control-wrap">
                                                      <Form.Item name={['variants',index,'quantity']}
                                                        noStyle
                                                        rules={[
                                                          {required: true, message: 'Quantity is required'}
                                                        ]}>
                                                        <InputNumber min={0} step={1} size="large" className="w-100" /> 
                                                      </Form.Item> 
                                                    </div>
                                                  </Form.Item>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>                                        
                                      ))
                                    }
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </>
                      }

                    </div>
                  
                    {/* storefront  */}
                    <div className="col-md-12">
                      <hr className="m-3 mx-0" />
                      <h6 className="overline-title text-primary-alt mt-5">Storefront</h6>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="show_on_storefront" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={showOnStorefrontChecked}
                              onChange={() => {
                                setShowOnStorefrontChecked(!showOnStorefrontChecked);
                                form.setFieldValue('show_on_storefront', showOnStorefrontChecked ? 'yes' : 'no');
                              }}
                              id="show_on_storefront" />
                            <label className="custom-control-label" htmlFor="show_on_storefront">
                              Show on Storefront
                              <span className="text-muted"> (optional)</span>
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="featured_product" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={featuredChecked}
                              onChange={() => {
                                setFeaturedChecked(!featuredChecked);
                                form.setFieldValue('featured_product', featuredChecked ? 'yes' : 'no');
                              }}
                              id="featured_product" />
                            <label className="custom-control-label" htmlFor="featured_product">
                              Featured Product
                              <span className="text-muted"> (optional)</span>
                              <Button type="button" id="featured_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                <Icon name="info" />
                              </Button>
                              <UncontrolledPopover target="featured_info_popover" placement="bottom" trigger="focus">
                                <PopoverBody>
                                  Marking a product as a 'Featured Product' ensures it takes a prominent spot on your storefront, making it easily visible to visitors.
                                </PopoverBody>
                              </UncontrolledPopover>
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Category <span className="text-muted">(optional)</span></label>
                          </div>

                          <Form.Item name={'category'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Select required size="large" 
                              placeholder="Select category"
                              style={{ width: "100%" }} 
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: '8px 0',
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: '0 8px 4px',
                                    }}
                                  >
                                    <Input
                                      placeholder="New category"
                                      value={newCategory}
                                      onChange={(e) => setNewCategory(e.target.value)}
                                    />
                                    <Button className="btn-dim btn-blank" onClick={addNewCategory}>
                                      <Icon name="plus" />
                                      <span>Add</span>
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={categories.map((category) => ({
                                key: category,
                                label: category,
                                value: category,
                              }))}
                              showSearch /> 
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Tags <span className="text-muted">(optional)</span></label>
                        </div>
                        <Space size={[0, 8]} wrap>
                          {tags.map((tag, index) => {
                            if (editInputIndex === index) {
                              return (
                                <Input
                                  ref={editInputRef}
                                  key={tag}
                                  size="small"
                                  style={tagInputStyle}
                                  value={editInputValue}
                                  onChange={handleEditInputChange}
                                  onBlur={handleEditInputConfirm}
                                  onPressEnter={handleEditInputConfirm}
                                />
                              );
                            }
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag
                                key={tag}
                                closable={true}
                                style={{
                                  userSelect: 'none',
                                }}
                                onClose={() => handleClose(tag)}
                              >
                                <span
                                  onDoubleClick={(e) => {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                  }}
                                >
                                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                </span>
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })}

                          {inputVisible ? (
                            <Input
                              ref={inputRef}
                              type="text"
                              size="small"
                              style={tagInputStyle}
                              value={inputValue}
                              onChange={handleInputChange}
                              onBlur={handleInputConfirm}
                              onPressEnter={handleInputConfirm}
                            />
                          ) : (
                            <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                              New Tag
                            </Tag>
                          )}
                        </Space>
                      </div>
                    </div>

                    {/* other product info */}
                    <div className="col-md-12">
                      <hr className="m-3 mx-0" />
                      <h6 className="overline-title text-primary-alt mt-5">Other Information</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">SKU <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'SKU'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Input size="large"
                              type="text" className="form-control form-control-lg" 
                              placeholder="eg. C123"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Barcode <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'barcode'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Input size="large"
                              type="text" className="form-control form-control-lg" 
                              placeholder="eg. 1234"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Product Location <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'product_location'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Input size="large"
                              type="text" className="form-control form-control-lg" 
                              placeholder="eg. B-12-5-3-4"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>

                    {/* expiration */}
                    <div className="col-md-12">
                      <hr className="m-3 mx-0" />
                      <h6 className="overline-title text-primary-alt mt-5">Expiration</h6>
                    </div>
                    <div className="col-md-12">
                      <div className="card-bordered card-full card">
                        {
                          expirationFields.length === 0 ?
                          <div className="nk-block">
                            <div className="text-center m-5">
                              <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                              <div className="price-plan-info">
                                <span className="title fw-normal">No expiration date found</span>
                              </div>
                              <div className="price-plan-action">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddExpirationDate()}>Add Expiry Date</button>
                              </div>
                            </div>
                          </div>
                          :
                          <ul className="nk-support">
                            {
                              expirationFields.map((expiration, index) => (
                                <li className="nk-support-item" key={index}>
                                  <div className="row g-4">
                                    <div className="col-md-12 text-end">
                                      <button className="btn btn-sm text-danger" type="button" onClick={() => handleRemoveExpirationDate(index)}><span>Remove</span><Icon name="trash"/></button>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label">#{index + 1}. Batch Number <span className="text-danger">*</span></label>
                                        </div>
                                        <Form.Item name={['expirations', index, 'batch_number']}
                                          rules={[{ required: true, message: 'Please enter a batch number' }]}>
                                          <Input size="large" type="text" className="form-control form-control-lg" placeholder="Enter batch number" />
                                        </Form.Item>
                                      </div>                                        
                                    </div>

                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label">Expiration Date <span className="text-danger">*</span></label>
                                        </div>
                                        <Form.Item name={['expirations', index, 'expiration_date']}
                                          rules={[{ required: true, message: 'Please select the expiration date' }]}
                                        >
                                          <DatePicker
                                            selected={expiration.expiration_date}
                                            dateFormat="dd-MMM-yyyy"
                                            onChange={date => handleExpirationDateChange(date, index, 'expiration_date')}
                                            shouldCloseOnSelect={false}
                                            showMonthDropdown
                                            showYearDropdown
                                            isClearable
                                            className="form-control form-control-lg date-picker"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label">Alert Date 
                                            <span className="text-danger">*</span>
                                            <Button type="button" id="exp_alert_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                              <Icon name="info" />
                                            </Button>
                                            <UncontrolledPopover target="exp_alert_info_popover" placement="bottom" trigger="focus">
                                              <PopoverBody>
                                                The expiration alert date is a reminder before a product expires. It helps you take action before the product's end date.
                                              </PopoverBody>
                                            </UncontrolledPopover>
                                          </label>
                                        </div>
                                        <Form.Item name={['expirations', index, 'alert_date']}
                                          dependencies={[['expirations', index, 'expiration_date']]}
                                          rules={[
                                            { required: true, message: 'Please select the alert date' },
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (!value || (expiration.expiration_date && moment(value).isBefore(moment(expiration.expiration_date)) )) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Alert date cannot be greater than the expiration date!'));
                                              }
                                            })
                                          ]}>
                                          <DatePicker
                                            selected={expiration.alert_date}
                                            dateFormat="dd-MMM-yyyy"
                                            onChange={date => handleExpirationDateChange(date, index, 'alert_date')}
                                            shouldCloseOnSelect={false}
                                            showMonthDropdown
                                            showYearDropdown
                                            isClearable
                                            className="form-control form-control-lg date-picker"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            }
                          </ul>                            
                        }
                      </div>
                      
                    </div>
                    {
                      expirationFields.length > 0 && 
                      <div className="col-md-12 text-center">
                        <Button className="m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddExpirationDate()} >
                          <Icon name="calendar-alt"></Icon>
                          <span>Add Expiry Date</span>
                        </Button>
                      </div>
                    }
                  </div>
                  

                </div>
              </div>
              <div className="fm-footer">
                <div className="text-center">
                  <Button disabled={requesting} className="m-1" type="button" outline={true} color="light" size="lg" onClick={() => setShowAdvanceOptions(!showAdvanceOptions)} >
                    <Icon name="list"></Icon>
                    <span>{showAdvanceOptions ? "Hide Advance Options" : "Show Advance Options"}</span>
                  </Button>
                  <Button disabled={requesting} className="m-1" type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Block>

        { 
          activeModal === "addVariantTypeModal" && 
          <AddProductVariant showModal={true} toggleModal={() => toggleModal('addVariantTypeModal')} mainForm={form} variantTypes={variantTypes} setVariantTypes={setVariantTypes} variants={variants} setVariants={setVariants} productName={formData.product_name} costPrice={formData.cost_price} sellingPrice={formData.selling_price} setTotalVariantQuantity={setTotalVariantQuantity} />
        }

        { 
          activeModal === "editVariantTypeModal" && 
          <EditProductVariant showModal={true} toggleModal={() => toggleModal('editVariantTypeModal')} mainForm={form} editVariantTypeIndex={editVariantTypeIndex} variantTypes={variantTypes} setVariantTypes={setVariantTypes} variants={variants} setVariants={setVariants} productName={formData.product_name} costPrice={formData.cost_price} sellingPrice={formData.selling_price} setTotalVariantQuantity={setTotalVariantQuantity} />
        }
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default EditProduct;