import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import logoPlaceholder from "../images/crop.png";
import Head from "../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BackTo,
  BlockTitle,
  Icon,
  Button,
  UserAvatar
} from "../components/Component";
import { Empty } from 'antd';
import { baseURLs } from '../utils/Constants';
import axios from 'axios';
import { Spinner, Alert } from "reactstrap";
import imagePlaceholder from "../images/placeholder.png";
import { getAxiosHeaders, findUpper, getTeamMemberRoleByName } from "../utils/Utils";
import classNames from "classnames";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  AlertModal,
  SuccessModal,
  LoadingModal,
  InfoModal
} from "./components/AlertModals";

const Invitations = ({ history }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0
    }, 
    invitations: []
  });
  const [loading, setLoading] = useState(true);
  const [memberID, setMemberID] = useState("");
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    description: ''
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getInvitations = () => {
    axios.get(baseURLs.API_URL + `/businesses/invitations`, {headers: getAxiosHeaders().headers})
    .then((response) => {
      if (response.status === 204) {
        setTableData({
          meta: {
            total_records: 0,
          }, 
          invitations:[]
        });
      } else {
        let responseInfo = response.data;
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTableData({
          meta: {
            total_records: 0,
          }, 
          invitations:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
        
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleInvitationSubmit = (action) => {
    setErrorMessage("");
    toggleModal('loadingModal');

    axios.post(baseURLs.API_URL + `/businesses/invitations/${memberID}/${action}`, null, getAxiosHeaders())
    .then((response) => {
      let _tableData = tableData;
      _tableData.invitations = _tableData.invitations.filter(invitation => invitation.member_id !== memberID);
      _tableData.meta.total_records -= 1;
      setTableData({..._tableData});
      setSuccessMessage({
        title: 'Invitation Accepted',
        description: action === 'accept' ? 'Invitation has been accepted successfully' : 'Invitation has been declined'
      })
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal(null);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal(null);
      }
    });
  }

  const handleInvitationResponse = (action, memberID) => {
    setMemberID(memberID);
    action === 'accept' ? toggleModal('acceptInvitationModal') : toggleModal('declineInvitationModal');
  }

  useEffect(() => {
    getInvitations();
  }, []);


  return (
    <React.Fragment>
      <Head title="Invitations" />
      <Content>
        <BlockHead size="sm" className="wide-sm m-auto">
          <BlockHeadContent>
            <BackTo history={history} link="/my-businesses" icon="arrow-left">
              Go Back
            </BackTo>
            <BlockTitle tag="h2" className="fw-normal text-center mt-3">
              Invitations
            </BlockTitle>
            <BlockDes>
              <p className="text-center">Accept or deadline invitation received from business that wants you to join their team.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>

        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>
        {
          loading ?
          <Block className="nk-block-middle nk-auth-body text-center wide-xs">
            <div className="inner-pre-loader">
              <Spinner  color="dark" />          
            </div>
          </Block>
          :
          <Block className="wide-sm m-auto">
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="mail fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">{`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Invitations` : `Invitation`}`}</h5>
                </div>
              </div>
              <div className="fm-content">
                {
                  tableData.invitations.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h6 className="title fw-normal">No invitation found</h6>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/my-businesses`}>
                        <button className="btn btn-outline-primary">Go to My Businesses</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <div className="row">
                    {
                      tableData.invitations.map((data, index) => {
                        return (
                          <div className="col-md-12" key={index}>
                            <div className="nk-help align-start">
                              <div className="nk-help-img m-md-0 w-80px">
                                <UserAvatar className="sq md" theme="lighter" text={findUpper(data.business_name)} image={data.logo} imagePlaceholder={imagePlaceholder} />
                              </div>
                              <div className="nk-help-text">
                                <h6>{data.business_name}</h6>
                                <p className="text-soft mb-0">{data.location}</p>
                                <p className="mb-3">Role: <b>{getTeamMemberRoleByName(data.role).role}</b></p>
                                <div className="d-flex g-3">
                                  <button className="btn btn-outline-primary m-1" onClick={() => handleInvitationResponse('accept', data.member_id)}>Accept</button>
                                  <button className="btn btn-outline-danger m-1" onClick={() => handleInvitationResponse('decline', data.member_id)}>Decline</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                }
              </div>
            </div>
          </Block>
        }

        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={`Updating...`} descriptionText={`Please wait while the invitation is being updated`} /> }

        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={successMessage.title} descriptionText={successMessage.description} 
          />
        }
        {
          activeModal === "declineInvitationModal" &&
          <AlertModal 
            index="decline"
            showModal={true} 
            toggleModal={() => toggleModal('declineInvitationModal')}
            headerText={`Decline Invitation?`}
            descriptionText={`Are you sure you want to decline this invitation? This action can not be reverted.`}
            leftButtonText={`Decline`}
            leftButtonOnClick={() => handleInvitationSubmit('decline')} />
        
        }

        {
          activeModal === "acceptInvitationModal" &&
          <InfoModal 
            index="accept"
            showModal={true} 
            toggleModal={() => toggleModal('acceptInvitationModal')}
            headerText={`Accept Invitation?`}
            descriptionText={`Are you sure you want to accept this invitation?`}
            leftButtonText={`Accept`}
            leftButtonOnClick={() => handleInvitationSubmit('accept')}
            rightButtonText={`Close`}
            rightButtonOnClick={() => toggleModal('acceptInvitationModal')} />
        }

      </Content>
    </React.Fragment>
  );
};

export default Invitations;
