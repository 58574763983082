import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Card, CardBody } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { Link } from "react-router-dom";
import classNames from "classnames";

const Unauthorized = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Services" />
    <Content>
      <Block>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Card className="card-bordered">
              <CardBody className="card-inner text-center">
                <div className="nk-modal">
                  <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-danger"></Icon>
                  <h4 className="nk-modal-title fw-normal">Unauthorized Access</h4>
                  <div className="nk-modal-text my-3">
                    <div className="caption-text">
                      You do not have permission to access this page or complete this action.<br/> Contact your admin to update your permissions.
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Block>
    </Content>
    
  </React.Fragment>)
}

export default Unauthorized;