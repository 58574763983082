import React from "react";
import classNames from "classnames";
import { Card, CardFooter, CardBody, DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  UserAvatar,
  Icon,
  Col,
} from "../../components/Component";
import imagePlaceholder from "../../images/placeholder.png";
import { findUpper } from "../../utils/Utils";
import { Link } from "react-router-dom";

const BusinessCard = ({ businessInfo, menuData, ...props }) => {
  const businessPermissions = businessInfo.permissions.map(p => p.permission);
  let link = `${process.env.PUBLIC_URL}/dashboard/b/${businessInfo.business_id}`;

  // Function to find the first link that matches the permissions
  const findLink = (menuItems, permissions) => {
    for (const item of menuItems) {
      if (item.link && item.permission && item.permission.some(p => permissions.includes(p))) {
        return `${process.env.PUBLIC_URL}${item.link}/${businessInfo.business_id}`;
      }
      if (item.subMenu) {
        const subLink = findLink(item.subMenu, permissions);
        if (subLink) {
          return subLink;
        }
      }
    }
    return null;
  };

  const matchedLink = findLink(menuData, businessPermissions);
  if (matchedLink) {
    link = matchedLink;
  }

  return (
    <Col md="6" lg="4">
      <Card className="card-bordered">
        <CardBody className="card-inner">
          <div className="project-head">
            <Link className="project-title" to={link}>
              <UserAvatar className="sq md" theme="lighter" text={findUpper(businessInfo.name)} image={businessInfo.logo} imagePlaceholder={imagePlaceholder} />
              <div className="business-info">
                <h6 className="title">{businessInfo.name}</h6>
                <span className="sub-text">{businessInfo.location}</span>
              </div>
            </Link>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li onClick={() => {}}>
                    <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessInfo.business_id}`}>
                      <Icon name="reports-alt"></Icon>
                      <span>Create Invoice / Receipt</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessInfo.business_id}`}>
                      <Icon name="coins"></Icon>
                      <span>View Sales</span>
                    </Link>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="project-details text-muted mb-0">
            <span>Balance Due</span>
            <div className={classNames({"text-danger": businessInfo.total_balance !== '0.00'})}><small>{businessInfo.currency}</small> <b>{businessInfo.total_balance}</b></div>
          </div>
        </CardBody>
        <CardFooter className="border-top">
          <Link className="text-muted d-block" to={link}>
            <Icon name="dashboard"></Icon>
            <span>Go to Dashboard</span>
          </Link>
        </CardFooter>
      </Card>
    </Col>
  );
};


export default BusinessCard;