import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import NanoPackageIcon from "../images/envelope.png";
import MicroPackageIcon from "../images/paper-plane.png";
import MegaPackageIcon from "../images/rocket.png";
import {
  AlertModal,
  SuccessConfettiModal,
  LoadingModal
} from "./components/AlertModals";
import { MessagingTransactions } from "./components/tables/MessagingTransactions";

const BuyMessages = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [usage, setUsage] = useState({used: 0, remaining: 0, package: ''});
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [newTransaction, setNewTransaction] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getUsage = (businessID) => {
    setLoadingUsage(true);
    axios.get(baseURLs.API_URL + `/businesses/subscription-usage`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let subscriptionPackage = responseInfo.data.plan_name;
      let messages = responseInfo.data.messages;
      setUsage({
        used: messages.used, 
        remaining: messages.remaining,
        package: subscriptionPackage
      });
      setLoadingUsage(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
        setLoadingUsage(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const payWithPaystack = (paymentConfig) => {
    var handler = window.PaystackPop.setup({
      key: paymentConfig.key,
      email: paymentConfig.email,
      amount: paymentConfig.amount,
      currency: paymentConfig.currency,
      ref: paymentConfig.reference,
      label: paymentConfig.label,
      metadata: paymentConfig.metadata,
      callback: function(response) {
        // verify payment
        var reference = response.reference;
        verifyPayment(reference);
      },
      onClose: function() {
        setErrorMessage(`Transaction was not completed, window closed.`);
        toggleModal('alertModal');
      },
    });
    handler.openIframe();
  }

  const initPayment = (packageName) => {
    toggleModal('loadingModal');
    axios.post(baseURLs.API_URL + `/payments/bulk-messaging/initiate/${businessID}`, {
      package_name: packageName
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let paystackConfig = responseInfo.data.payment_config;
      toggleModal(null);
      
      payWithPaystack(paystackConfig);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const verifyPayment = (reference) => {
    toggleModal('verifyModal');
    axios.get(baseURLs.API_URL + `/payments/bulk-messaging/verify/${reference}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      toggleModal('successModal');

      // reload transaction table
      setNewTransaction(reference);
      getUsage(businessID);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const _businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(_businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', _businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    // get message usage stats
    getUsage(_businessID);

    setBusinessInfo(currentBusiness);
    setBusinessID(_businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Buy Messages" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Buy Messages
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      {
                        loadingUsage ?
                        <button className={`btn btn-outline-light`}>
                          <span>SMS/Email Remaining: <b>...</b></span>
                          <Icon name="meter" />
                        </button>
                        :
                        <button className={`btn btn-outline-${usage.remaining < 10 ? `danger` : `light`}`}>
                          {
                            usage.package === 'expansion' ?
                            <span>SMS / Email Used: <b>{usage.used}</b></span>
                            :
                            <span>SMS / Email Remaining: <b>{usage.remaining}</b> </span>
                          }
                          <Icon name="meter" />
                        </button>
                      }
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/message-customers/b/${businessID}`}>
                        <Button color={`primary`}>
                          <Icon name="msg"></Icon>
                          <span>Message Customers</span>
                        </Button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block>
          <div className="row g-gs">
            <div className="col-md-4">
              <div className="price-plan card card-bordered text-center">
                <div className="card-inner">
                  <div className="price-plan-media">
                    <img src={NanoPackageIcon} width="100" alt=""/>
                  </div>
                  <div className="price-plan-info">
                    <h5 className="title">Nano</h5>
                    <p className="mb-0"><b>1000</b> Messages</p>
                  </div>
                  <h4 className="amount">
                    <small className="font-weight-bold">GHS </small><span>50</span>
                  </h4>
                  <div className="price-plan-action">
                    <button className="btn btn-primary" onClick={() => {initPayment('nano')}}>Buy Package</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="price-plan card card-bordered text-center">
                <div className="card-inner">
                  <div className="price-plan-media">
                    <img src={MicroPackageIcon} width="100" alt=""/>
                  </div>
                  <div className="price-plan-info">
                    <h5 className="title">Micro</h5>
                    <p className="mb-0"><b>3000</b> Messages</p>
                  </div>
                  <h4 className="amount">
                    <small className="font-weight-bold">GHS </small><span>100</span>
                  </h4>
                  <div className="price-plan-action">
                    <button className="btn btn-primary" onClick={() => {initPayment('micro')}}>Buy Package</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="price-plan card card-bordered text-center">
                <div className="card-inner">
                  <div className="price-plan-media">
                    <img src={MegaPackageIcon} width="100" alt=""/>
                  </div>
                  <div className="price-plan-info">
                    <h5 className="title">Mega</h5>
                    <p className="mb-0"><b>6000</b> Messages</p>
                  </div>
                  <h4 className="amount">
                    <small className="font-weight-bold">GHS </small><span>200</span>
                  </h4>
                  <div className="price-plan-action">
                    <button className="btn btn-primary" onClick={() => {initPayment('mega')}}>Buy Package</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Block>
        <MessagingTransactions history={history} businessID={businessID} newTransaction={newTransaction} />
      </>
    }
    </Content>

    { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={`Initiating Payment...`} descriptionText={`Please wait while payment is being initiated`} /> }

    { activeModal === "verifyModal" && <LoadingModal showModal={true} headerText={`Verifying Payment...`} descriptionText={`Please wait while payment is being verified`} /> }
    
    { 
      activeModal === "successModal" &&
      <SuccessConfettiModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Payment Successful`} descriptionText={`Your Messaging balance has been updated successfully.`} 
      />
    }

    {
      activeModal === "alertModal" &&
      <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
        headerText={"Error"} descriptionText={errorMessage}                       
      >
        <small>Please contact <a href="https://tawk.to/chat/5e76db0e8d24fc226589341c/default" target="_blank">support</a> if your account has been charged.</small>
        
      </AlertModal>
    }
  </React.Fragment>)
}

export default BuyMessages;