import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";
import { Numeral } from "react-numeral";

export const SubscriptionTransactions = ({ history, businessID, currency, newTransaction, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, transactions:[]});
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const currentUrl = (filters) => {
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getTransactions(params)
  }

  const getTransactions = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/payments/subscriptions", {
      params: {
        business_id: businessID,
        page: filters.page,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, transactions:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, transactions:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    if (newTransaction) {
      loadNextPage(1);
    }
}, [newTransaction]);

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;

    let params = filters;
    params.page = page;

    setFilters(params);
    getTransactions(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Transactions` : `Transaction`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getTransactions(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.transactions.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No transaction found</h5>
                    </div>
                  </div>
                  :
                  <>      
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          {/* <th className="tb-tnx-id">
                            <span className="">#</span>
                          </th> */}
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc">
                              <span>Package</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-md-none">Duration</span>
                              <span className="d-none d-md-block">
                                <span>Duration</span>
                                <span>Date</span>
                              </span>
                            </span>
                          </th>
                          <th className="tb-tnx-amount is-alt">
                            <span className="tb-tnx-total">Total</span>
                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData.transactions.map((data) => {
                            return (
                              <tr key={data.log_id} className="tb-tnx-item">
                                {/* <td className="tb-tnx-id">
                                  <span className="text-primary">{data.invoice_number}</span>
                                </td> */}
                                <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span className="title">{data.package_name} Package</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date">{data.duration} {`${data.duration > 1 ? `months` : `month`}`}</span>
                                    <span className="date">{moment(data.created_at).format("Do MMM YYYY h:mm a")}</span>
                                  </div>
                                </td>
                                <td className="tb-tnx-amount is-alt">
                                  <div className="tb-tnx-total">
                                    <span className="amount"><small>{data.currency} </small><Numeral value={data.total.toString()} format={"0,0"} /></span>
                                  </div>
                                  <div className="tb-tnx-status">
                                    <Badge
                                      className="badge-dot ccap"
                                      color={
                                        data.status === "paid" ? "success" : data.status === "pending" ? "warning" : data.status === "failed" ? "danger" : "dark"
                                      }
                                    >
                                      {data.status}
                                    </Badge>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table> 
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
