import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Card,
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, permissionsList, teamMemberRolePermissions } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, Divider, Empty, Form } from 'antd';

const EditTeamMemberPermissions = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [memberID, setMemberID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    role: '',
    permissions: []
  });
  const [formErrors, setFormErrors] = useState({
    role: '',
    permissions: ''
  });
  const [errorMessage, setErrorMessage] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleRoleChange = (role) => {
    let permissions = teamMemberRolePermissions(role);
    setFormData({...formData, role, permissions})
  };

  const updatePermissions = (permissionCode) => {
    // Check if the permission code exists in formData.permissions
    const index = formData.permissions.indexOf(permissionCode);

    // If permission code exists, remove it from formData.permissions
    if (index !== -1) {
      const updatedPermissions = [...formData.permissions];
      updatedPermissions.splice(index, 1);
      setFormData({ ...formData, permissions: updatedPermissions });
    } else {
      // If permission code doesn't exist, add it to formData.permissions
      setFormData({ ...formData, permissions: [...formData.permissions, permissionCode] });
    }
  };

  const getMemberInfo = (businessID, memberID) => {

    axios.get(baseURLs.API_URL + `/teams/${memberID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let memberInfo = responseInfo.data;
        
        // update form data 
        setFormData({
          full_name: memberInfo.full_name,
          email: memberInfo.email,
          role: memberInfo.role,
          permissions: memberInfo.permissions
        })
        
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleFormSubmit = () => {
    setRequesting(true);
    setErrorMessage("");
    setFormErrors({
      ...formErrors,
      role: '',
      permissions: ''
    })

    axios.put(baseURLs.API_URL + `/teams/permissions/${memberID}`, {
      business_id: businessID,
      role: formData.role,
      permissions: JSON.stringify(formData.permissions)
    }, getAxiosHeaders())
    .then((response) => {
      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      setRequesting(false);
      try{
        let errorResponse = error.response.data;
        if(errorResponse.hasOwnProperty("errors")){
          setFormErrors({
            ...formErrors,
            role: errorResponse.errors.hasOwnProperty("role") ? errorResponse.errors.role : '',
            permissions: errorResponse.errors.hasOwnProperty("permissions") ? errorResponse.errors.permissions : ''
          })
        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const memberID = parseInt(match.params.memberID, 10);
    if ( !Number.isInteger(businessID) && !Number.isInteger(memberID)) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setMemberID(memberID);
    getMemberInfo(businessID, memberID);
  }, [match.params.businessID]);

  const selectedPermissions = formData.permissions;

  // const groupedPermissions = permissionsList.reduce((acc, permission) => {
  //   if (!acc[permission.source]) {
  //     acc[permission.source] = [];
  //   }
  //   acc[permission.source].push(permission);
  //   return acc;
  // }, {});

  // const selectOptions = Object.entries(groupedPermissions).map(([source, permission]) => ({
  //   label: source,
  //   options: permission.map(perm => ({
  //     label: perm.permission,
  //     value: perm.permission_code,
  //     disabled: selectedPermissions.includes(perm.permission_code)
  //   })),
  // }));

  const groupedPermissions = permissionsList.reduce((acc, permission) => {
    const sourceIndex = acc.findIndex(item => item.source.toLowerCase() === permission.source.toLowerCase());
    const permissionData = { name: permission.permission, code: permission.permission_code };
    
    if (sourceIndex === -1) {
      acc.push({ source: permission.source.toLowerCase(), permissions: [permissionData] });
    } else {
      acc[sourceIndex].permissions.push(permissionData);
    }
    
    return acc;
  }, []);


  return (<React.Fragment>
    <Head title="Edit Permissions" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Edit Permissions
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/team/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Permissions Update`} descriptionText={`Team member's permissions has been updated successfully.`}
          />
        }

        <Block className="wide-sm m-auto">
          <form>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="user-list fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">{formData.full_name ?? 'Team Member'}</h5>
                  <p className="nk-block-des">{formData.email}</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Role <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">                        
                        <Select className="select-tags" size="large"
                          placeholder="eg. Sales Representative"
                          style={{ width: "100%" }} 
                          value={formData.role}
                          popupMatchSelectWidth={false}
                          onChange={(value) => handleRoleChange(value)}
                          options={[
                            {
                              key: 'manager',
                              label: 'Manager',
                              value: 'manager',
                            },
                            {
                              key: 'sales_representative',
                              label: 'Sales Representative',
                              value: 'sales_representative',
                            },
                            {
                              key: 'accountant',
                              label: 'Accountant/Bookkeeper',
                              value: 'accountant',
                            },
                            {
                              key: 'customer_service_representative',
                              label: 'Customer Service Representative',
                              value: 'customer_service_representative',
                            }                            
                          ]}
                          showSearch />   
                      </div>
                      {formErrors.role && <p className="text-danger fs-13px m-1">{formErrors.role}</p>}                      
                    </div>
                    {
                      formData.role === 'manager' && 
                      <p className="nk-block-des fs-12px d-flex">
                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                        <span>The Manager role grants access to oversee all aspects of the business, including sales, inventory, expenses, and customer management, ensuring smooth operations and effective decision-making.</span>
                      </p>
                    }

                    {
                      formData.role === 'sales_representative' && 
                      <p className="nk-block-des fs-12px d-flex">
                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                        <span>Sales Representatives focus on generating revenue by managing sales activities, accessing customer information, and processing orders to drive business growth and meet sales targets.</span>
                      </p>
                    }

                    {
                      formData.role === 'accountant' && 
                      <p className="nk-block-des fs-12px d-flex">
                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                        <span>Accountants/Bookkeepers handle financial transactions and reporting, managing expenses, sales, and inventory to ensure accurate financial records and compliance with accounting standards.</span>
                      </p>
                    }

                    {
                      formData.role === 'customer_service_representative' && 
                      <p className="nk-block-des fs-12px d-flex">
                        <span className="me-1 fs-14px"><Icon name="info" /></span>
                        <span>Customer Service Representatives provide assistance and support to customers, managing customer inquiries, messages, and orders to ensure high levels of satisfaction and retention.</span>
                      </p>
                    }
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-label-group mb-3">
                        <label className="form-label">Permissions <span className="text-danger">*</span></label>
                        {formErrors.permissions && <p className="text-danger fs-13px m-1">{formErrors.permissions}</p>}                      
                      </div>
                      <div className="form-control-wrap">
                        <div className="row">
                          {
                            groupedPermissions.map((data, index) => {
                              return (
                                <div className="col-md-12" key={index}> 
                                  <hr className="mt-4 mb-4"/>
                                  <h6 className="overline-title fs-10px mb-4">{data.source}</h6>
                                  <div className="row g-2">
                                    {
                                      data.permissions.map((permission) => {
                                        return (
                                          <div className="col-md-6" key={permission.code}>
                                            <div className="form-group">
                                              <div className="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  checked={formData.permissions.includes(permission.code)}
                                                  value={permission.code}
                                                  onChange={() => {updatePermissions(permission.code)}}
                                                  id={`perm_${permission.code}`} />
                                                <label className="custom-control-label" htmlFor={`perm_${permission.code}`}>
                                                  {permission.name}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-footer">
                <div className="pt-2 text-center">
                  <Button disabled={requesting} type="submit" onClick={(ev) => { ev.preventDefault(); handleFormSubmit();} } color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : "Save"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default EditTeamMemberPermissions;