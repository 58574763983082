import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Numeral from 'react-numeral';
import moment from "moment";
import { LinkItUrl } from 'react-linkify-it';
import { Link } from "react-router-dom";
import BalancePaymentForm from "./BalancePaymentForm";
import Footer from "./Footer";
import OrderInfo from "./OrderInfo";
import { SuccessModal } from "../AlertModals";

const Body = ({history, orderInfo, setOrderInfo, ...props }) => {

  return (
    <>
      {/* items section*/}
      <div className="fm-content" key="purchase_order_body">
        <ul className="nk-top-products">
          {
            orderInfo.items.map((data, index) => {
              return <li className="item" key={index}>                        
                      <div className="info">
                        <div className="title">{data.item_name}</div>
                        <div className="price mt-2">{orderInfo.currency} {data.unit_price} {`• Qty: ${data.quantity}`}</div>                          
                      </div>
                      <div className="total">
                        <div className="amount"><small className="text-muted">{orderInfo.currency}</small> {data.total_cost}</div>
                      </div>
                    </li>
            })
          }

          {
            orderInfo.delivery_amount !== '0.00' && 
            <>
              <li className="item" key="delivery_pickup">
                <div className="fm-count"><em className="icon ni ni-truck fs-22px"></em></div>
                <div className="info">
                  <div className="title">Delivery</div>
                </div>
                <div className="total">
                  <div className="amount"><small className="text-muted">{orderInfo.currency}</small> {orderInfo.delivery_amount}</div>
                </div>
              </li>
            </>
          }
        </ul>
      </div>

      {/* summary section */}
      {
        orderInfo.has_supplier === 'yes' ?
        <>
          {/* summary section */}
          <div className="fm-content bg-lighter">
            <div className="row">
              <div className="col-md-12">
                <div className="nk-iv-scheme-details">
                  <ul className="nk-iv-wg3-list w-100">                        
                    <li className="px-0 py-2 fw-medium">
                      <div className="sub-text fs-16px">Sub Total</div>
                      <div className="nk-wg-action-content p-0">
                        <h4 className="amount fs-16px">
                          <small className="text-muted me-1">{orderInfo.currency}</small> 
                          {orderInfo.sub_total_amount}
                        </h4>
                      </div>
                    </li>
                    <li className="px-0 py-2 d-block">
                      <div className="d-flex justify-content-between fw-medium">
                        <div className="sub-text fs-14px">Total Tax</div>
                        <div className="nk-wg-action-content p-0">
                          <h4 className="amount fs-14px">
                            <small className="text-muted me-1">{orderInfo.currency}</small> 
                            {orderInfo.tax_amount}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="px-0 py-2">
                      <div className="sub-text fs-14px">Discount</div>
                      <div className="nk-wg-action-content p-0">
                        <h4 className="amount fs-14px">
                          <small className="text-muted me-1">- {orderInfo.currency}</small>
                          {orderInfo.discount_amount}
                        </h4>
                      </div>
                    </li>
                    <li className="px-0 py-2 fw-bold">
                      <div className="sub-text fs-18px">Total</div>
                      <div className="nk-wg-action-content p-0">
                        <h4 className="amount fs-18px">
                          <small className="text-muted me-1">{orderInfo.currency}</small> 
                          {orderInfo.total_amount}
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="nk-iv-scheme-details">
                  <ul className="nk-iv-wg3-list w-100">
                    <li className="px-0 py-2">
                      <div className="sub-text fs-14px">Amount Paid</div>
                      <div className="nk-wg-action-content p-0">
                        <h4 className="amount fs-14px">
                          <small className="text-muted me-1">{orderInfo.currency}</small> 
                          {orderInfo.amount_paid}
                        </h4>
                      </div>
                    </li>
                    <li className="px-0 py-2 fw-bold">
                      <div className={`sub-text fs-18px ${orderInfo.status === 'owed' ? `text-danger`: ``}`}>Balance Due</div>
                      <div className="nk-wg-action-content p-0">
                        <h4 className={`amount fs-18px ${orderInfo.status === 'owed' ? `text-danger`: ``}`}>
                          <small className={`${orderInfo.status === 'owed' ? `text-danger`: `text-muted`} me-1`}>{orderInfo.currency}</small>
                          {orderInfo.balance_due}
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {
                orderInfo.status === 'paid' ?
                <div className="col-md-12">
                  <div className="alert alert-fill alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>Paid in full</strong></div>
                </div>
                :
                <>
                  <div className="col-md-12">
                    <div className="alert alert-fill alert-danger alert-icon mb-3">
                      <em className="icon ni ni-alert-circle"></em> 
                      <span>This purchase order is owed and yet to be paid in full.</span>
                    </div>
                  </div>

                  <BalancePaymentForm
                    orderInfo={orderInfo}  
                    setOrderInfo={setOrderInfo} />
                </>
              }

              {
                orderInfo.note &&              
                <div className="col-md-12 mt-5">
                  <hr className="mb-5" />
                  <p className="mb-1"><strong>Note</strong></p>
                  <LinkItUrl>
                  <p style={{whiteSpace: "pre-wrap"}}>
                    {orderInfo.note}
                  </p>
                  </LinkItUrl>
                </div>
              }
            </div>
          </div>
        </>
        :
        <div className="fm-content bg-lighter">
          <div className="row">
            <div className="col-md-12">
              <div className="nk-iv-scheme-details">
                <ul className="nk-iv-wg3-list w-100">
                  <li className="px-0 py-2 fw-bold">
                    <div className="sub-text fs-18px">Total Amount</div>
                    <div className="nk-wg-action-content p-0">
                      <h4 className="amount fs-18px">
                        <small className="text-muted me-1">{orderInfo.currency}</small> 
                        {orderInfo.total_amount}
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="alert alert-fill alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>Paid in full</strong></div>
            </div>

            {
              orderInfo.note &&              
              <div className="col-md-12 mt-5">
                <hr className="mb-5" />
                <p className="mb-1"><strong>Note</strong></p>
                <LinkItUrl>
                <p style={{whiteSpace: "pre-wrap"}}>
                  {orderInfo.note}
                </p>
                </LinkItUrl>
              </div>
            }
          </div>
        </div>
      }

      {/* order section */}
      {
        orderInfo.order_log.length > 0 &&
        <OrderInfo orderInfo={orderInfo} setOrderInfo={setOrderInfo} />
      }

      {/* footer section */}
      <Footer history={history} orderInfo={orderInfo} setOrderInfo={setOrderInfo} />
    </>
  );
};


export default Body;