import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber, formatTo2DP, moneyToFloat } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import Numeral from 'react-numeral';
import DatePicker from "react-datepicker";
import { SuccessModal } from "./components/AlertModals";
import { Divider, Input, Select, Space, InputNumber, Empty, Spin, Form } from 'antd';
const { Option } = Select;

export const AddDamagesLoss = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [fetchingMaterials, setFetchingMaterials] = useState(false);
  const [issueDate, setIssueDate] = useState(new Date());
  const [issueDateTime, setIssueDateTime] = useState(new Date());
  const [productsList, setProductsList] = useState([]);
  const [rawMaterialsList, setRawMaterialsList] = useState([]);
  const [form] = Form.useForm();
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [selectedMaterials, setSelectedMaterials] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const [itemsErrorMessage, setItemsErrorMessage] = useState("");
  const [itemFields, setItemFields] = useState([{
    items_name: '',
    cost_price: '',
    quantity: '1',
    type: 'damaged',
    unit: 'pcs'
  }]);
  const [formData, setFormData] = useState({
    issue_date: new Date(),
    issue_date_time: new Date(),
    source_type: 'inventory',
    items: [{
      items_name: '',
      cost_price: '0',
      quantity: '1',
      type: 'damaged',
      unit: 'pcs'
    }]
  });
  const [totalAmount, setTotalAmount] = useState(0);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");
    setItemsErrorMessage("");

    const formInfo = {};
    formInfo.business_id = businessID;
    
    let _issueDate = `${moment(_formData.issue_date).format("YYYY-MM-DD")} ${moment(_formData.issue_date_time).format("HH:mm")}`;
    formInfo.issue_date = _issueDate;

    formInfo.items = JSON.stringify(_formData.items);
    formInfo.source_type = _formData.source_type;

    axios.post(baseURLs.API_URL + `/damages/add`, formInfo, getAxiosHeaders())
    .then((response) => {  
      let responseInfo = response.data;
      form.resetFields();
      setItemFields([{
        items_name: '',
        cost_price: '',
        quantity: '1',
        type: 'damaged',
        unit: 'pcs'
      }])
      toggleModal('successModal');
      setRequesting(false);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          errorResponse.errors.hasOwnProperty("items") ? setItemsErrorMessage(errorResponse.errors.items) : setItemsErrorMessage("");
          form.setFields([
            {
              name: 'issue_date',
              errors: errorResponse.errors.hasOwnProperty("issue_date") ? [errorResponse.errors.issue_date] : [],
            },
            {
              name: 'items',
              errors: errorResponse.errors.hasOwnProperty("items") ? [errorResponse.errors.items] : [],
            },
            {
              name: 'source_type',
              errors: errorResponse.errors.hasOwnProperty("source_type") ? [errorResponse.errors.source_type] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);        
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const handleSourceTypeChange = () => {
    const newItemFields = [{
      item_name: '',
      quantity: '1',
      cost_price: '',
      type: 'damaged',
      unit: 'pcs'
    }];
    setItemFields(newItemFields);

    form.setFieldsValue({
      items: newItemFields,
    });

    updateSummary();
  }

  const getProducts = ({businessID, productName}) => {

    axios.get(baseURLs.API_URL + "/products/variants", {
      params: {
        business_id: businessID,
        product_name: productName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let products = responseInfo.data.products;

        setProductsList([...products]);
      }

      setLoading(false);

      if(fetchingProducts){
        setFetchingProducts(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        if(fetchingProducts){
          setFetchingProducts(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getRawMaterials = ({businessID, materialName}) => {

    axios.get(baseURLs.API_URL + "/raw-materials", {
      params: {
        business_id: businessID,
        material_name: materialName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let materials = responseInfo.data.materials;

        setRawMaterialsList([...materials]);
      }

      setLoading(false);

      if(fetchingMaterials){
        setFetchingMaterials(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        if(fetchingMaterials){
          setFetchingMaterials(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items'];
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      updateSummary();
    }    
  }

  const updateSummary = () => {
    const items = form.getFieldValue('items') || [];

    // Calculate total amount
    let totalAmount = items.reduce((total, item) => {
      const price = item.cost_price || 0;
      const quantity = item.quantity || 0;
      return total + (price * quantity);
    }, 0);

    setTotalAmount(totalAmount);
  }

  const debouncedFetchProducts = debounce(getProducts, 1000);
  const debouncedFetchMaterials = debounce(getRawMaterials, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const handleProductChange = (productName) => {
    // Fetch more products when the user enters a product name not found in the initial list
    if (!productsList.some(product => product.product_name === productName)) {
      debouncedFetchProducts({businessID, productName});
      setFetchingProducts(true);
    }
  };

  const handleProductSelect = (value, option, index) => {
    const selectedProduct = productsList.find(product => product.product_name === value);
    
    if (selectedProduct) {
      let { product_name, cost_price } = selectedProduct;
      cost_price = moneyToFloat(cost_price);

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_name: product_name,
        cost_price: cost_price,
        quantity: 1,
        type: 'damaged',
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleMaterialChange = (materialName) => {
    // Fetch more material when the user enters a material name not found in the initial list
    if (!rawMaterialsList.some(material => material.material_name === materialName)) {
      debouncedFetchMaterials({businessID, materialName});
      setFetchingMaterials(true);
    }
  };

  const handleMaterialSelect = (value, option, index) => {
    const selectedMaterial = rawMaterialsList.find(material => material.material_name === value);
    
    if (selectedMaterial) {
      let { material_name, unit, unit_cost } = selectedMaterial;
      unit_cost = moneyToFloat(unit_cost);

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_name: material_name,
        cost_price: unit_cost,
        quantity: 1,
        type: 'damaged',
        unit: unit ?? 'pcs'
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleAddItem = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, {
      item_name: '',
      quantity: '',
      cost_price: '',
      type: 'damaged'
    }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleRemoveItem = (index) => {
    const oldItemFields = form.getFieldValue("items");
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);
    setItemFields(newFields);

    form.setFieldsValue({
      items: newFields,
    });

    updateSummary();
  };

  useEffect(() => {
    const { productNames, materialNames } = itemFields.reduce(
      (acc, item) => {
        if (item.item_name) {
          const targetSet = form.getFieldValue('source_type') === 'inventory' ? acc.productNames : acc.materialNames;
          targetSet.add(item.item_name);
        }
        return acc;
      },
      {
        productNames: new Set(),
        materialNames: new Set(),
      }
    );
  
    setSelectedProducts(productNames);
    setSelectedMaterials(materialNames);
  }, [itemFields]);

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getProducts({businessID: businessID});
    getRawMaterials({businessID: businessID});
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Record Damages & Loss" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Record Damages &amp; Loss
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/damages-loss/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block className="wide-sm m-auto mt-5">
          {errorMessage && (
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorMessage}{" "}
            </Alert>
          )}
          <Form name="record-damages"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFieldsChange={(changedFields, allFields) => {
              debouncedHandleChangedFields(changedFields, allFields);       
            }}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              {/* source details section */}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="archived fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Source</h5>
                  <p className="nk-block-des">Enter the source of the damages or loss and the date.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Source Type <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'source_type'}
                            noStyle
                            rules={[
                              {required: true, message: `Select source type`}
                            ]}>
                            <Select required size="large" 
                              placeholder="Select Inventory or Raw Materials"
                              style={{ width: "100%" }} 
                              onChange={(value) => {handleSourceTypeChange()}}
                              options={[
                                {
                                  key: 'inventory',
                                  label: 'Inventory',
                                  value: 'inventory',
                                },
                                {
                                  key: 'raw_materials',
                                  label: 'Raw Materials',
                                  value: 'raw_materials',
                                },                              
                              ]}
                              showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Issue Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'issue_date'}
                              noStyle
                              rules={[
                                { required: true, message: 'Issue Date is required', }
                              ]}>
                              <DatePicker
                                selected={issueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setIssueDate(date);
                                  form.setFieldsValue({issue_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'issue_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Issue Date time is required',}
                              ]}>
                              <DatePicker
                                selected={issueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setIssueDateTime(time);
                                  form.setFieldsValue({issue_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              {/* products and services section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="list fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Items</h5>
                  <p className="nk-block-des">Add the items that are damaged or lost.</p>
                </div>
              </div>
              <div className="fm-content">
                {
                  itemsErrorMessage &&
                  <p className="text-danger">{itemsErrorMessage}</p>
                }
                <div className="row">
                <div className="col-md-12">
                  <div className="card-bordered card-full card">
                    {
                      itemFields.length === 0 ?
                      <div className="nk-block">
                        <div className="text-center m-5">
                          <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                          <div className="price-plan-info">
                            <span className="title fw-normal">No item added</span>
                          </div>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddItem()}>
                              <Icon name="plus-sm" />
                              <span>Add Item</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      :
                      <ul className="nk-support">
                        {
                          itemFields.map((item, index) => {
                            let itemField = form.getFieldValue('source_type') === 'inventory' ?
                            <>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label"><b>#{index + 1}.</b> Product Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'item_name']}
                                      rules={[{ required: true, message: 'Please enter a product name' }]}>
                                      <Select
                                        size="large"
                                        onChange={(value) => handleProductChange(value, index)}
                                        options={
                                          productsList.map(prod => {
                                            // Check if the product is selected in another field
                                            const isAlreadySelected = selectedProducts.has(prod.product_name) && prod.product_name !== itemFields[index].item_name;
                                            const outOfStock = prod.stock_status === 'out_of_stock' ? true : false;

                                            return {
                                              key: prod.product_id,
                                              label: isAlreadySelected ? `${prod.product_name} - (${outOfStock ? `Out-of-Stock - `: ``}Selected)` : `${prod.product_name} ${outOfStock ? `- (Out-of-Stock)`: ``}`,
                                              value: prod.product_name,
                                              disabled: isAlreadySelected || outOfStock, // Disable the option if it's already selected
                                            };
                                          })
                                        }
                                        onSelect={(value, option) => handleProductSelect(value, option, index)}
                                        onSearch={handleProductChange}
                                        showSearch={true}
                                        notFoundContent={
                                          fetchingProducts ? 
                                          <div className="text-center m-5">
                                            <Spin/>
                                          </div>
                                          :
                                          <div className="text-center m-5">
                                            <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                          </div>
                                        }
                                        placeholder="Enter product name"
                                        className="w-100"
                                      />
                                    </Form.Item>
                                  </div>                                        
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                      <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'quantity']}
                                      noStyle
                                      rules={[
                                        {required: true, message: 'Quantity is required'}
                                      ]}>
                                      <InputNumber min={1} step={1} size="large" className="w-100" /> 
                                    </Form.Item> 
                                  </Form.Item>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                        <label className="form-label">Loss Type <span className="text-danger">*</span></label>
                                      </div>
                                      <div className="form-control-wrap">
                                        <Form.Item name={['items', index, 'type']}
                                          noStyle
                                          rules={[
                                            {required: true, message: `Select loss type`}
                                          ]}>
                                          <Select required size="large" 
                                            placeholder="Select Inventory or Raw Materials"
                                            style={{ width: "100%" }} 
                                            options={[
                                              {
                                                key: 'damaged',
                                                label: 'Damaged',
                                                value: 'damaged',
                                              },
                                              {
                                                key: 'lost',
                                                label: 'Lost',
                                                value: 'lost',
                                              },                              
                                            ]}
                                            showSearch />   
                                        </Form.Item>                       
                                      </div>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label"><b>#{index + 1}.</b> Raw Material Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'item_name']}
                                      rules={[{ required: true, message: 'Please enter a raw material name' }]}>
                                      <Select
                                        size="large"
                                        onChange={(value) => handleProductChange(value, index)}
                                        options={
                                          rawMaterialsList.map(material => {
                                            // Check if the product is selected in another field
                                            const isAlreadySelected = selectedMaterials.has(material.material_name) && material.material_name !== itemFields[index].item_name;
                                            const outOfStock = material.stock_status === 'out_of_stock' ? true : false;
                                            
                                            return {
                                              key: material.material_id,
                                              label: isAlreadySelected ? `${material.material_name} - (${outOfStock ? `Out-of-Stock - `: ``}Selected)` : `${material.material_name} ${outOfStock ? `- (Out-of-Stock)`: ``}`,
                                              value: material.material_name,
                                              disabled: isAlreadySelected || outOfStock, // Disable the option if it's already selected
                                            };
                                          })
                                        }
                                        onSelect={(value, option) => handleMaterialSelect(value, option, index)}
                                        onSearch={handleMaterialChange}
                                        showSearch={true}
                                        notFoundContent={
                                          fetchingMaterials ? 
                                          <div className="text-center m-5">
                                            <Spin/>
                                          </div>
                                          :
                                          <div className="text-center m-5">
                                            <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                          </div>
                                        }
                                        placeholder="Enter raw material name"
                                        className="w-100"
                                      />
                                    </Form.Item>
                                  </div>                                        
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                      <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'quantity']}
                                      noStyle
                                      rules={[
                                        {required: true, message: 'Quantity is required'}
                                      ]}>
                                      <InputNumber suffix={item.unit} size="large" className="w-100" /> 
                                    </Form.Item>                                     
                                  </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                        <label className="form-label">Loss Type <span className="text-danger">*</span></label>
                                      </div>
                                      <div className="form-control-wrap">
                                        <Form.Item name={['items', index, 'type']}
                                          noStyle
                                          rules={[
                                            {required: true, message: `Select loss type`}
                                          ]}>
                                          <Select required size="large" 
                                            placeholder="Select Inventory or Raw Materials"
                                            style={{ width: "100%" }} 
                                            options={[
                                              {
                                                key: 'damaged',
                                                label: 'Damaged',
                                                value: 'damaged',
                                              },
                                              {
                                                key: 'lost',
                                                label: 'Lost',
                                                value: 'lost',
                                              },                              
                                            ]}
                                            showSearch />   
                                        </Form.Item>                       
                                      </div>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </>
                            return (<li className="nk-support-item d-block" key={index}>{itemField}</li>);
                          })
                        }
                      </ul>                            
                    }
                  </div>
                    
                  </div>
                  {
                    itemFields.length > 0 && 
                    <div className="col-md-12 text-center">
                      <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddItem()} >
                        <Icon name="plus-sm"></Icon>
                        <span>Add Item</span>
                      </Button>
                    </div>
                  }
                </div>
              </div>              
              

              <div className="fm-content bg-lighter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="nk-iv-scheme-details">
                      <ul className="nk-iv-wg3-list w-100">
                        <li className="px-0 py-2 fw-bold">
                          <div className="sub-text fs-18px">Total Amount</div>
                          <div className="nk-wg-action-content p-0">
                            <h4 className="amount fs-18px">
                              <small className="text-muted me-1">{businessInfo.currency}</small> 
                              <Numeral value={totalAmount.toString()} format={"0,0.00"} />
                            </h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        

              <div className="fm-footer">
                <div className="text-center">
                  <Button disabled={requesting} className="m-1" type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : <span>Submit</span>}
                  </Button>
                </div>
              </div>
            </div>

          </Form>
          
        </Block>
      </>
    }

    { 
      activeModal === "successModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Recorded Successfully`} descriptionText={`Damaged and lost items have been recorded successfully.`} 
      />
    }

    </Content>
    
  </React.Fragment>)
}