import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody,
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const PayoutsTable = ({ history, businessID, currency, businessVerified, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      total_payout: 0,
      amount_paid: 0,
      amount_pending: 0,     
    }, 
    payouts: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    batch_number_search: '',
    range: null,
    rangeStart: null,
    rangeEnd: null,
    status: 'all'
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    let params = filters;

    if(!moment(start).isValid() || !moment(end).isValid()){
      params.range = null;
      params.rangeStart = null;
      params.rangeEnd = null;
      setFilters({...params});
      return;
    }

    params.range = `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`;
    params.rangeStart = start;
    params.rangeEnd = end;
    setFilters({...params});
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const currentUrl = (filters) => {
    let batch_number_search = filters.batch_number_search.length > 0 ? `&b=${filters.batch_number_search}` : ``;
    let range = filters.range !== null ? `&r=${filters.range}` : ``;
    let status = filters.status !== null ? `&st=${filters.status}` : ``;
    
    if( (batch_number_search !== '' ||
    range !== '' ||
    filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${batch_number_search}${range}${status}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getPayouts(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      batch_number_search: '',
      range: null,
      rangeStart: null,
      rangeEnd: null,
      status: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getPayouts(params);
  }

  const filterPayouts = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getPayouts(filters);
  }

  const getPayouts = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/payments/payouts", {
      params: {
        business_id: businessID,
        page: filters.page,
        batch_number: filters.batch_number_search,
        date_from: !moment(filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD"),
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_payout: 0,
            amount_paid: 0,
            amount_pending: 0,
          }, payouts:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_payout: 0,
            amount_paid: 0,
            amount_pending: 0,
          }, payouts:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    onRangeChange(dateRange);
  }, [dateRange])

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let batch_number_search = queryParams.hasOwnProperty('b') ? queryParams.b : '';
    let range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let status = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';
    let rangeStart = null;
    let rangeEnd = null;

    if(range !== null){
      let rangeSplit = range.split(',');
      rangeStart = new Date(rangeSplit[0]);
      rangeEnd = new Date(rangeSplit[1]);
      setDateRange([rangeStart, rangeEnd]);
    }

    let params = filters;
    params.page = page;
    params.batch_number_search = batch_number_search;
    params.range = range;
    params.rangeStart = rangeStart;
    params.rangeEnd = rangeEnd;
    params.status = status;

    setFilters(params);
    getPayouts(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <div className="row g-gs mb-5">
            <div className="col-md-12">
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-warning">Pending</div>
                      <div className="amount">
                        <small className="text-muted fw-light me-1">{currency}</small>
                        {tableData.meta.amount_pending}
                      </div>
                    </div>
                    <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                    <div className="analytic-data text-md-center me-4">
                      <div className="title text-success">Paid</div>
                      <div className="amount">
                        <small className="text-muted fw-light me-1">{currency}</small>
                        {tableData.meta.amount_paid}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>   
            </div>
          </div>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Payouts` : `Payout`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Payouts Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="batch_number_search">
                                    Batch Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="batch_number_search" className="form-control form-control-lg" id="batch_number_search" defaultValue={filters.batch_number_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone-no">
                                    Status
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "paid", label: "Paid"},
                                        {value: "pending", label: "Pending"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label">Date Range</label>                          
                                  <div className="form-control-wrap">
                                    <DatePicker
                                      selectsRange={true}
                                      startDate={dateFrom}
                                      endDate={dateTo}
                                      onChange={(date) => {
                                        setDateRange(date);
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      // monthsShown={2}
                                      className="form-control form-control-lg date-picker"
                                    />
                                  </div>
                                  <div className="form-note fs-10px">
                                    Date Format: <code>dd/mm/yyyy</code>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterPayouts();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your payouts.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getPayouts(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.payouts.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    {
                      businessVerified === 'verified' ? 
                      <>
                        <div className="price-plan-info">
                          <h5 className="title fw-normal">No payout found</h5>
                          <span>Enable and update your online payment settings to receiving payments on your invoices and quotes from your customers.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/online-payment/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Online Payment Settings</button>
                          </Link>                    
                        </div>
                      </>
                      :
                      <>
                        <div className="price-plan-info">
                          <h5 className="title fw-normal">No payout found</h5>
                          <span>Verify your business to start receiving payments on your invoices and quotes from your customers.</span>
                        </div>
                        <div className="price-plan-action">
                          <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                            <button className="btn btn-outline-primary">Verify Business</button>
                          </Link>                    
                        </div>
                      </>
                    }
                  </div>
                  :
                  <>                   
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow size="md">
                          <span className="sub-text">Batch No.#</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Payout Account</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total Amount</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Fees</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Payout Amount</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Payout Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <Icon name="more-h"></Icon>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.payouts.map((data, index) => {
                          let status = data.status === 'paid' ? 
                          <Badge className="badge-dot me-2" color="success">Paid</Badge> 
                          : <Badge className="badge-dot me-2" color="warning">Pending</Badge>;                          

                          return (
                            <DataTableItem key={index}>
                              <DataTableRow size="md">
                                <Link to={`${process.env.PUBLIC_URL}/payouts/b/${businessID}/details/${data.batch_number}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary">#{data.batch_number}</div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/payouts/b/${businessID}/details/${data.batch_number}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary d-md-none">#{data.batch_number}</div>
                                      <span className="text-warning me-1 fw-bold">
                                        {
                                          data.payout_type === 'mobile_money' ?
                                          <Icon name="mobile"></Icon>
                                          :
                                          <Icon name="building"></Icon>
                                        }
                                      </span>
                                      <span className="text-dark fw-bold">{data.payout_account_number}</span> <br/>
                                      <span>{data.payout_account_name} - (<small>{data.payout_account_type}</small>)</span>
                                      
                                      <div className={`price d-md-none`}>
                                        <span>
                                          {status}
                                          <span className="tb-amount">
                                            Total Amount: <small className="text-muted">{currency} </small>{data.total_amount}
                                          </span>

                                          <span className="tb-amount">
                                            Total Fees: <small className="text-muted">{currency} </small>{data.total_fees}
                                          </span>

                                          <span className="tb-amount">
                                            Payout Amount: <small className="text-muted">{currency} </small>{data.payout_amount}
                                          </span>
                                        
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small className="text-muted">{currency} </small>{data.total_amount}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small className="text-muted">{currency} </small>{data.total_fees}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small className="text-muted">{currency} </small>{data.payout_amount}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md" className={"text-start"}>
                                <div className="tb-tnx-status">{status}</div>
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{moment(data.payout_date).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/payouts/b/${businessID}/details/${data.batch_number}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                    
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
