import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Numeral from 'react-numeral';

const Body = ({history, bodyInfo, ...props }) => {
  const [activeModal, setActiveModal] = useState(null);
  
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };
  
  return (
    <>
      {/* items section*/}
      <div className="fm-content" key="body">
        <ul className="nk-top-products">
          {
            bodyInfo.items.map((data, index) => {
              return <li className="item" key={index}>                        
                        <div className="info">
                          <div className="title">{data.item_name}</div>
                          <div className="price mt-2">{bodyInfo.currency} {data.unit_cost} {`• Qty: ${data.quantity}`}</div>                          
                        </div>
                        <div className="total">
                          <div className="amount"><small className="text-muted">{bodyInfo.currency}</small> {data.total}</div>
                        </div>
                      </li>
            })
          }
        </ul>
      </div>

      {/* summary section */}
      <div className="fm-content bg-lighter">
          <div className="row">
            <div className="col-md-12">
              <div className="nk-iv-scheme-details">
                <ul className="nk-iv-wg3-list w-100">
                  <li className="px-0 py-2 fw-bold">
                    <div className="sub-text fs-18px">Total Amount</div>
                    <div className="nk-wg-action-content p-0">
                      <h4 className="amount fs-18px">
                        <small className="text-muted me-1">{bodyInfo.currency}</small> 
                        <Numeral value={bodyInfo.total_amount.toString()} format={"0,0.00"} />
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};


export default Body;