import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Numeral from 'react-numeral';
import moment from "moment";
import { LinkItUrl } from 'react-linkify-it';
import { Link } from "react-router-dom";
import Footer from "./Footer";
import OrderInfo from "./OrderInfo";
import { SuccessModal } from "../AlertModals";

const Body = ({history, orderInfo, setOrderInfo, ...props }) => {

  return (
    <>
      {/* items section*/}
      <div className="fm-content" key="purchase_order_body">
        <ul className="nk-top-products">
          {
            orderInfo.items.map((data, index) => {
              return <li className="item" key={index}>                        
                      <div className="info">
                        <div className="title">{data.material_name}</div>
                        <div className="price mt-2">{orderInfo.currency} {data.unit_cost} {`• Qty: ${data.quantity}`}</div>                          
                      </div>
                      <div className="total">
                        <div className="amount"><small className="text-muted">{orderInfo.currency}</small> {data.total_cost}</div>
                      </div>
                    </li>
            })
          }
        </ul>
      </div>

      {/* summary section */}
      <div className="fm-content bg-lighter">
        <div className="row">
          <div className="col-md-12">
            <div className="nk-iv-scheme-details">
              <ul className="nk-iv-wg3-list w-100">
                <li className="px-0 py-2 fw-medium">
                  <div className="sub-text fs-16px">Unit Cost</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-16px">
                      <small className="text-muted me-1">{orderInfo.currency}</small> 
                      {orderInfo.unit_cost}
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 fw-bold">
                  <div className="sub-text fs-18px">Total Cost</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-18px">
                      <small className="text-muted me-1">{orderInfo.currency}</small> 
                      {orderInfo.total_cost}
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {
            orderInfo.notes &&              
            <div className="col-md-12 mt-5">
              <hr className="mb-5" />
              <p className="mb-1"><strong>Note</strong></p>
              <LinkItUrl>
              <p style={{whiteSpace: "pre-wrap"}}>
                {orderInfo.notes}
              </p>
              </LinkItUrl>
            </div>
          }
        </div>
      </div>

      {/* order section */}
      {
        orderInfo.order_log.length > 0 &&
        <OrderInfo orderInfo={orderInfo} setOrderInfo={setOrderInfo} />
      }

      {/* footer section */}
      <Footer history={history} orderInfo={orderInfo} setOrderInfo={setOrderInfo} />
    </>
  );
};


export default Body;