import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Numeral from 'react-numeral';
import moment from "moment";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { getAxiosHeaders, moneyToFloat, orderStatusOptions } from "../../../utils/Utils";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { LinkItUrl } from 'react-linkify-it';
import { Link, useHistory } from "react-router-dom";
import { AutoComplete, Empty, Input, Select, Space, InputNumber, Divider, Spin, Form } from 'antd';
import GooglePlacesInput from "../GooglePlacesInput";
import BalancePaymentForm from "./BalancePaymentForm";
import OrderInfo from "./OrderInfo";
import Footer from "./Footer";


const { Option } = Select;

const Body = ({saleInfo, setSaleInfo, view, updateSales, ...props }) => {
  let history = useHistory();
  const businessID = saleInfo.business_id;
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [fetchingLocations, setFetchingLocations] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [formData, setFormData] = useState(saleInfo.delivery_pickup ? 
    {
      fulfillment_type: saleInfo.delivery_pickup.location_type,
      location_name: saleInfo.delivery_pickup.location_address,
      location: saleInfo.delivery_pickup.location_landmark,
      fulfillment_cost: saleInfo.delivery_pickup.location_cost,  
      lat: saleInfo.delivery_pickup.location_lat,
      lng: saleInfo.delivery_pickup.location_lng     
    } :
    {}
  )
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/sales/fulfillment/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id,
      fulfillment_type: _formData.fulfillment_type,
      location_name: _formData.location_name,
      location: _formData.location,
      lat: formData.lat,
      lng: formData.lng,
      fulfillment_cost: _formData.fulfillment_cost
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      updateSales();
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'fulfillment_type',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_type") ? [errorResponse.errors.fulfillment_type] : [],
            },
            {
              name: 'location_name',
              errors: errorResponse.errors.hasOwnProperty("location_name") ? [errorResponse.errors.location_name] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'fulfillment_cost',
              errors: errorResponse.errors.hasOwnProperty("fulfillment_cost") ? [errorResponse.errors.fulfillment_cost] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }
  
  const getLocations = ({businessID, locationName, locationType}) => {

    axios.get(baseURLs.API_URL + `/businesses/delivery-pickup-locations`, {
      params: {
        business_id: businessID,
        location_name: locationName,
        location_type: locationType,
        access: 'record_sales',
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let locations = responseInfo.data.locations;

        setLocationsList([...locations]);
      }

      if(fetchingLocations){
        setFetchingLocations(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingLocations){
          setFetchingLocations(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleLocationChange = (locationName) => {
    // Fetch more location when the user enters a location name not found in the initial list
    if (!locationsList.some(location => location.location_name === locationName)) {
      debouncedFetchLocations({businessID, locationName, locationType: form.getFieldValue('fulfillment_type')});
      setFetchingLocations(true);
    }
  };

  const handleLocationSelect = (value, option) => {
    const selected = locationsList.find(location => location.location_name === value);
    
    if (selected) {
      let { cost, type, landmark, lat, lng } = selected;
      cost = moneyToFloat(cost);

      if(type === 'pick_up'){
        form.setFieldValue("fulfillment_cost", 0);
        form.setFieldValue("location", landmark);
        setFormData({
          ...formData,
          fulfillment_cost: 0,
          location: landmark,
          lat,
          lng
        });

      } else {
        form.setFieldValue("fulfillment_cost", cost);
        form.setFieldValue("location", '');
        setFormData({
          ...formData,
          fulfillment_cost: cost,
          location: '',
          lat: null,
          lng: null
        });
      }
            
    }
  };

  const debouncedFetchLocations = debounce(getLocations, 1000);

  useEffect(() => {
    if(saleInfo.sale_origin === 'storefront' && saleInfo.order_log[0]?.status !== 'delivered') {
      getLocations({
        businessID,
        locationType: saleInfo.delivery_pickup.location_type,
      });
      loadGoogleMapsApi();
    }
  },[])

  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }

  return (
    <>
      {/* items section*/}
      <div className="fm-content" key="sale_details_body">
        <ul className="nk-top-products">
          {
            saleInfo.items.map((data, index) => {
              let details = data.description && data.item_type === 'product' ?
              <> • <Button type="button" id={`item_description_popover`} className="btn-round btn-icon text-end p-0 text-muted" color="muted" size="sm">
                Details
              </Button>
              <UncontrolledPopover target={`item_description_popover`} placement="bottom" trigger="focus">
                <PopoverBody>
                  <p>{data.description}</p>
                </PopoverBody>
              </UncontrolledPopover> </> : ``;

              let quantity = data.item_type === 'product' ? `• Qty: ${data.quantity}` : data.quantity > 1 ? `• Qty: ${data.quantity}` : '';

              return <> 
                      <li className="item" key={index}>                        
                        <div className="info">
                          <div className="title">{data.item_name}</div>
                          { data.description && data.item_type === 'service' && <div className="price text-dark">{data.description}</div> }
                          <div className="price mt-2">{saleInfo.currency} {data.price} {quantity} {details}</div>                          
                        </div>
                        <div className="total">
                          <div className="amount"><small className="text-muted">{saleInfo.currency}</small> {data.total}</div>
                        </div>
                      </li>

                      
                    </>
            })
          }

          {
            saleInfo.delivery_pickup && 
            <>
              <li className="item" key="delivery_pickup">
                {
                  saleInfo.delivery_pickup.location_type === 'delivery' ?
                  <div className="fm-count"><em className="icon ni ni-truck fs-22px"></em></div>
                  :
                  <div className="fm-count"><em className="icon ni ni-location fs-22px"></em></div>
                }
                
                <div className="info">
                  {
                    saleInfo.delivery_pickup.location_type === 'delivery' ?
                    <div className="title">Delivery</div>
                    :
                    <div className="title">Pick Up</div>
                  }

                  <div className="price text-dark">{saleInfo.delivery_pickup.location_address}</div>
                  <div className="price">{saleInfo.delivery_pickup.location_landmark}</div>
                </div>
                <div className="total">
                  <div className="amount"><small className="text-muted">{saleInfo.currency}</small> {saleInfo.delivery_pickup.location_cost}</div>
                </div>
              </li>
              {
                saleInfo.sale_origin === 'storefront' && saleInfo.order_log[0]?.status !== 'delivered' &&
                <div className="alert alert-warning alert-icon">
                <em className="icon ni ni-alert-circle" />
                  Update Delivery / Pick Up information. <span onClick={() => {toggleModal('editDeliveryPickupModal')}} className="alert-link pointer-cursor">Click here to edit</span>
                </div> 
              } 
            </>
          }
        </ul>
      </div>

      {/* summary section */}
      <div className="fm-content bg-lighter">
        <div className="row">
          <div className="col-md-12">
            <div className="nk-iv-scheme-details">
              <ul className="nk-iv-wg3-list w-100">
                <li className="px-0 py-2 fw-medium">
                  <div className="sub-text fs-16px">Sub Total</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-16px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.sub_total.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 d-block">
                  {
                   saleInfo.taxes &&
                    <>
                      {
                        saleInfo.taxes.length > 0 &&
                        <div className="mb-2">
                          {
                            saleInfo.taxes.map((tax, index) => {
                              return(
                                <div className="d-flex justify-content-between mb-1" key={index}>
                                  <div className="sub-text fs-14px">{tax.tax} <small>( {tax.percentage}% )</small></div>
                                  <div className="nk-wg-action-content p-0">
                                    <h4 className="amount fs-14px">
                                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                                      <Numeral value={tax.amount.toString()} format={"0,0.00"} />
                                    </h4>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </>

                  }
                  

                  <div className="d-flex justify-content-between fw-medium">
                    <div className="sub-text fs-14px">Total Tax</div>
                    <div className="nk-wg-action-content p-0">
                      <h4 className="amount fs-14px">
                        <small className="text-muted me-1">{saleInfo.currency}</small> 
                        <Numeral value={saleInfo.tax_amount.toString()} format={"0,0.00"} />
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Discount</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-14px">
                      <small className="text-muted me-1">- {saleInfo.currency}</small>
                      <Numeral value={saleInfo.discount.discount_amount.toString()} format={"0,0.00"} />
                      <small className="text-muted"> ({saleInfo.discount.discount_percentage}%)</small>
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 fw-bold">
                  <div className="sub-text fs-18px">Total</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-18px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.total_amount.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="nk-iv-scheme-details">
              <ul className="nk-iv-wg3-list w-100">
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Amount Received</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-14px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.amount_received.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Amount Paid</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-14px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.amount_paid.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 fw-bold">
                  <div className={`sub-text fs-18px ${['invoice', 'quote'].includes(saleInfo.status) ? `text-danger`: ``}`}>Balance Due</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className={`amount fs-18px ${['invoice', 'quote'].includes(saleInfo.status) ? `text-danger`: ``}`}>
                      <small className={`${['invoice', 'quote'].includes(saleInfo.status) ? `text-danger`: `text-muted`} me-1`}>{saleInfo.currency}</small>
                      <Numeral value={saleInfo.balance_due.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Change</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className={`amount fs-14px ${parseFloat(saleInfo.change) > 0 ? `text-success`: ``}`}>
                      <small className={`${parseFloat(saleInfo.change) > 0 ? `text-success`: `text-muted`} me-1`}>{saleInfo.currency}</small>
                      <Numeral value={saleInfo.change.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {
            saleInfo.status === 'receipt' && 
            <div className="col-md-12">
              <div className="alert alert-fill alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>Receipt paid in full</strong></div>
            </div>
          }

          {
            ( saleInfo.status === 'invoice' || saleInfo.status === 'quote' )  && 
            <div className="col-md-12">
              <div className="alert alert-fill alert-danger alert-icon mb-3">
                <em className="icon ni ni-alert-circle"></em> 
                <b>Due Date: </b>
                <span>
                  {moment(saleInfo.due_date).format("Do MMM, YYYY h:mm a")}
                </span>
              </div>
            </div>
          }

          {/* update balance component */}
          {
            ( saleInfo.status === 'invoice' || saleInfo.status === 'quote' )  && view === 'business' &&
            <BalancePaymentForm
              saleInfo={saleInfo}  
              setSaleInfo={setSaleInfo} />
          }

          

          {
            saleInfo.note &&              
            <div className="col-md-12 mt-5">
              <hr className="mb-5" />
              <p className="mb-1"><strong>Note</strong></p>
              <LinkItUrl>
              <p style={{whiteSpace: "pre-wrap"}}>
                {saleInfo.note}
              </p>
              </LinkItUrl>
            </div>
          }

          {/* outstanding balance section */}
          {
            saleInfo.outstanding_balance.meta.total_records > 0 && 
              <>
                {
                  saleInfo.outstanding_balance.meta.total_records === 1 && 
                  saleInfo.sale_id === saleInfo.outstanding_balance.sales[0].sale_id ? <></> :
                  <div className="col-md-12">
                    <hr className="mb-4" />
                    <Link to={`${process.env.PUBLIC_URL}/sales/b/${saleInfo.business_id}?cs=${saleInfo.customer_uuid}&t=invoice`} className="card card-bordered text-soft">
                      <div className="card-inner">
                        <div className="align-center justify-between">
                          <div className="g">
                            <h6 className="title fw-normal">Total Outstanding Balance from {saleInfo.outstanding_balance.meta.total_records} {saleInfo.outstanding_balance.meta.total_records === 1 ? `invoice` : `invoices`}.</h6>
                            <h4 className="text-danger"><small className="fs-16px">{saleInfo.currency}</small> <b>{saleInfo.outstanding_balance.meta.total_outstanding_balance_due}</b></h4>
                          </div>
                          <div className="g"><span className="btn btn-icon btn-trigger mr-n1"><em className="icon ni ni-chevron-right"></em></span></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                }
              </>
          }
        </div>
      </div>


      {/* order section */}
      {
        saleInfo.order_log.length > 0 &&
        <OrderInfo businessID={saleInfo.business_id} teamMembers={saleInfo.team_members} saleInfo={saleInfo} orderInfo={saleInfo.order_log[0]} setSaleInfo={setSaleInfo} />
      }

      {/* footer section */}
      <Footer saleInfo={saleInfo} setSaleInfo={setSaleInfo} />

      {/* update delivery and pickup modal */}
      {
        activeModal === "editDeliveryPickupModal" &&
        <Modal backdrop="static" isOpen={true} id="delivery-pickup-modal">
          <ModalHeader
            toggle={() => toggleModal('editDeliveryPickupModal')}
            close={
              <button className="close" onClick={() => toggleModal('editDeliveryPickupModal')}>
                <Icon name="cross" />
              </button>
            }
          >
            Update Fulfillment
          </ModalHeader>
          <Form name="add-order"
              form={form} className="is-alter"
              onFinish={handleFormSubmit}
              initialValues={formData}
              onFinishFailed={onFinishFailed}>
          <ModalBody>
            {errorMessage && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorMessage}{" "}
                </Alert>
              </div>
            )}
            
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Fulfillment Type <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'fulfillment_type'}
                          noStyle
                          rules={[
                            {required: true, message: `Select delivery or pick up`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select delivery or pick up"
                            style={{ width: "100%" }} 
                            onChange={(value) => {getLocations({businessID, locationType: value})}}
                            options={[
                              {
                                key: 'delivery',
                                label: 'Delivery',
                                value: 'delivery',
                              },
                              {
                                key: 'pick_up',
                                label: 'Pick Up',
                                value: 'pick_up',
                              },                              
                            ]}
                            showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                          <label className="form-label">City / Town <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'location_name'}
                        noStyle
                        rules={[
                          {required: true, message: `City / Town is required`}
                        ]}>
                        <AutoComplete
                          
                          size="large"
                          options={locationsList.map(location => (
                            {
                              key: location.location_id,
                              label: location.location_name,
                              value: location.location_name,
                            }
                          ))}
                          filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }
                          notFoundContent={
                            fetchingLocations ? 
                            <div className="text-center m-5">
                              <Spin/>
                            </div> : null}
                          onSelect={handleLocationSelect}
                          onChange={handleLocationChange}
                          placeholder="Enter City / Town"
                          className="w-100"
                          allowClear
                        />
                      </Form.Item>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label">Location <span className="text-danger">*</span></label>
                    </div>
                    <GooglePlacesInput form={form} isRequired={true} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={saleInfo.business_country_iso} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Cost <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'fulfillment_cost'}
                        noStyle
                        rules={[
                          {required: true, message: `${formData.fulfillment_type} cost is required`}
                        ]}>
                        <InputNumber prefix={saleInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
              </div>
            
          </ModalBody>
          <ModalFooter className="bg-light justify-content-start">
            <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
              {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
            </Button>
            <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal('editDeliveryPickupModal')}>
              Close
            </Button>
          </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};


export default Body;