import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Empty } from 'antd';
import DatePicker from "react-datepicker";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Spinner, 
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import moment from "moment";
import { SalesLineChart } from "./components/AnalyticsCharts";
import { CardLoader } from "../utils/Loaders";
import { ErrorBlock } from "./error/ErrorBlock";

const Dashboard = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [analyticsStats, setAnalyticsStats] = useState({});
  const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [toDate, setToDate] = useState(new Date(new Date().getFullYear(), 11, 31));
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getAnalyticsStats = (businessID) => {
    setErrorMessage("");
    setLoading(true);

    if(!fromDate || !toDate) {
      setErrorMessage("Date range is required");
      return;
    }

    axios.get(baseURLs.API_URL + "/statistics/analytics", {
      params: {
        business_id: businessID,
        date_from: moment(fromDate).format("YYYY-MM-DD"), 
		    date_to: moment(toDate).format("YYYY-MM-DD"), 
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;

      setAnalyticsStats(responseInfo.data);
      setLoading(false);
      
            
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getAnalyticsStats(businessID);
  
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Analytics" />
    <Content>
      <BlockHead size="sm">
        <div className="nk-block-between">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              Analytics
            </BlockTitle>
            
          </BlockHeadContent>
          {
            !loading &&
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" onClick={() => toggleModal('filterModal')}>
                        <Icon name="calender-date" />
                        <span><b>{moment(analyticsStats?.date_range.date_from).format("Do MMM YYYY")}</b> - <b>{moment(analyticsStats?.date_range.date_to).format("Do MMM YYYY")}</b></span>
                      </Button>
                      {
                        activeModal === 'filterModal' &&
                        <Modal isOpen={true} toggle={() => toggleModal('filterModal')} >
                          <ModalHeader
                            toggle={() => toggleModal('filterModal')}
                            close={
                              <button className="close" onClick={() => toggleModal('filterModal')}>
                                <Icon name="cross" />
                              </button>
                            }
                          >
                            Filter Analytics
                          </ModalHeader>
                          <ModalBody>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">From Date</label>                          
                                  <div className="form-control-wrap">
                                    <DatePicker
                                      selected={fromDate}
                                      dateFormat="dd-MMM-yyyy"
                                      onChange={date => {
                                        setFromDate(date);
                                      }}
                                      
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      className="form-control form-control-lg date-picker"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">To Date</label>                          
                                  <div className="form-control-wrap">
                                    <DatePicker
                                      selected={toDate}
                                      dateFormat="dd-MMM-yyyy"
                                      onChange={date => {
                                        setToDate(date);
                                      }}
                                      
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      className="form-control form-control-lg date-picker"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter className="bg-lighter justify-content-center">
                            <Button color="primary" onClick={() => {toggleModal('filterModal'); getAnalyticsStats(businessID);}} size="lg">
                              {loading ? <Spinner size="sm" color="light" /> : "Apply Filter"}
                            </Button>
                          </ModalFooter>
                        </Modal>
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          }
        </div>
      </BlockHead>

      {
        errorMessage ? 
        <ErrorBlock errorMessage={errorMessage} />
        :
        <Block>
          {
            loading ?
            <Row className="g-gs">
              <Col lg="7"><CardLoader /></Col>
              <Col lg="5"><CardLoader /></Col>
            </Row>
            :
            <>
              {/* sales stats */}
              <Block>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <div className="fm-head border-0 p-0">
                      <div className="fm-count"><em className="icon ni ni-coins fs-22px"></em></div>
                      <div className="fm-title">
                        <h5 className="title">Sales Statistics</h5>
                        <p className="sub-title text-muted pointer-cursor" onClick={() => toggleModal('filterModal')}>Analytics from <span className="text-primary">{moment(analyticsStats?.date_range.date_from).format("Do MMM YYYY")}</span> to <span className="text-primary">{moment(analyticsStats?.date_range.date_to).format("Do MMM YYYY")}</span>.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <PreviewAltCard>
                      <div className="card-title-group pb-3 g-2">
                        <div className="card-title">
                          <h6 className="title">Sales </h6>
                          <p>Breakdown of your sales.</p>
                        </div>
                      </div>
                      <div className="sales-stats">
                        <div className="nk-sale-data-group flex-md-nowrap g-4">
                          <div className="nk-sale-data">
                            <div className="title fw-bold">Total Sales</div>
                            <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {analyticsStats?.sales_stats.total_sales}</div>
                            {/* <div className="change up">
                              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                            </div> */}
                          </div>

                          <div className="nk-sale-data">
                            <div className="title fw-bold text-danger">Balance to Receive</div>
                            <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {analyticsStats?.sales_stats.total_balance}</div>
                            {/* <div className="change up">
                              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                            </div> */}
                          </div>
                        </div>
                        <div className="mt-5">
                          <SalesLineChart duration={'period'} chatData={analyticsStats?.sales_stats.charts.chart_info} />
                        </div>
                      </div>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="5">
                    <PreviewAltCard>
                      <div className="nk-wg-action">
                        <div className="nk-wg-action-content">
                          <em className="icon ni ni-percent"></em>
                          <div className="title fw-bold">Total Discounts</div>
                          <h4 className="amount fw-normal">
                            {analyticsStats?.sales_stats.discounts.total_count}
                            <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.sales_stats.discounts.total_amount} </small>
                          </h4>
                        </div>
                      </div>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?t=receipt&r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-check-c"></em>
                            <div className="title fw-bold">Total Receipts</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.sales_stats.receipts.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.sales_stats.receipts.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?t=invoice&r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-minus-c"></em>
                            <div className="title fw-bold">Total Invoices</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.sales_stats.invoices.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.sales_stats.invoices.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?t=quote&r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-square-c"></em>
                            <div className="title fw-bold">Total Quotes</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.sales_stats.quotes.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.sales_stats.quotes.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <div className="nk-wg-action">
                        <div className="nk-wg-action-content">
                          <em className="icon ni ni-curve-up-left text-danger"></em>
                          <div className="title fw-bold">Total Refunds</div>
                          <h4 className="amount fw-normal">
                            {analyticsStats?.sales_stats.refunds.total_count}
                            <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.sales_stats.refunds.total_amount} </small>
                          </h4>
                        </div>
                      </div>
                      <hr className="mt-4 mb-4"/>
                      <div className="nk-wg-action">
                        <div className="nk-wg-action-content">
                          <em className="icon ni ni-cross-c text-danger"></em>
                          <div className="title fw-bold">Total Cancelled</div>
                          <h4 className="amount fw-normal">
                            {analyticsStats?.sales_stats.cancelled.total_count}
                            <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.sales_stats.cancelled.total_amount} </small>
                          </h4>
                        </div>
                      </div>
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>

              {/* inventory stats */}
              <Block>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <div className="fm-head border-0 p-0">
                      <div className="fm-count"><em className="icon ni ni-package fs-22px"></em></div>
                      <div className="fm-title">
                        <h5 className="title">Inventory Statistics</h5>
                        <p className="sub-title text-muted pointer-cursor" onClick={() => toggleModal('filterModal')}>Analytics from <span className="text-primary">{moment(analyticsStats?.date_range.date_from).format("Do MMM YYYY")}</span> to <span className="text-primary">{moment(analyticsStats?.date_range.date_to).format("Do MMM YYYY")}</span>.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <PreviewAltCard>
                      <div className="card-title-group pb-3 g-2">
                        <div className="card-title">
                          <h6 className="title">Top Performing Products 
                            <Button id="product_info_popover" className="btn-round text-end text-muted" size="sm">
                              <Icon name="info" />
                            </Button>
                            <UncontrolledPopover target="product_info_popover" placement="bottom" trigger="focus">
                              {/* <PopoverHeader>Product Ranking Explained</PopoverHeader> */}
                              <PopoverBody>
                                Top-performing products are ranked first by the number of times sold. If there's a tie, total revenue generated is considered, followed by total quantity sold for further tie-breaking. This approach highlights popularity, revenue, and sales volume.
                              </PopoverBody>
                            </UncontrolledPopover>
                          </h6>
                          <p>List of your most sold products.</p>
                        </div>
                      </div>
                      <div className="mt-1">
                        {
                          analyticsStats?.inventory_stats.top_products.length > 0 ?
                          <>
                          <div className="nk-tb-list is-loose">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col"><span>#</span></div>
                              <div className="nk-tb-col"><span>Product</span></div>
                              <div className="nk-tb-col text-right"><span>Purchases</span></div>
                              <div className="nk-tb-col"><span>Purchases %</span></div>
                            </div>
                            {
                              analyticsStats?.inventory_stats.top_products.map((data, index) => {
                                return (
                                  <div className="nk-tb-item" key={index}>
                                    <div className="nk-tb-col">{index + 1}.</div>
                                    <div className="nk-tb-col">
                                      <div className="user-info">
                                        <span className="tb-lead">{data.product}</span>
                                        <span><small>Times Sold: </small>{data.total_purchases} • <small>Qty: </small>{data.total_quantity}</span>
                                      </div>
                                    </div>
                                    <div className="nk-tb-col text-right"><span className="tb-sub tb-amount"><span><small>{businessInfo.currency} </small>{data.total_amount}</span></span>
                                    </div>
                                    <div className="nk-tb-col">
                                        <div className="progress-amount text-primary">{data.percentage}%</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            
                          </div>
                          {
                            analyticsStats?.inventory_stats.top_products.length > 4 &&
                            <div className="nk-block price-plan-action text-center">
                              <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/analytics?p=1&d=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`}>
                                <button className="btn btn-outline-light">Load More</button>
                              </Link>
                            </div>
                          }
                          </>
                          :
                          <div className="nk-tb-list is-loose">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <p className="title fw-normal text-center">No product found</p>
                            </div>
                          </div>
                        }
                      </div>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="5">
                    <PreviewAltCard>
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}?st=in_stock`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-unarchive text-success"></em>
                            <div className="title fw-bold">Products In-Stock</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.inventory_stats.products_in_stock}
                              {/* <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.inventory_stats.products_in_stock_amount} </small> */}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}?st=out_of_stock`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-archived text-danger"></em>
                            <div className="title fw-bold">Products Out of Stock</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.inventory_stats.products_out_stock}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}?st=low_stock`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-archive"></em>
                            <div className="title fw-bold">Products Low in Stock</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.inventory_stats.products_low_in_stock}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/damages-loss/b/${businessID}?r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-trash text-muted"></em>
                            <div className="title fw-bold">Damaged Products</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.inventory_stats.total_damaged_products}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.inventory_stats.total_damaged_products_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>

              {/* services stats */}
              <Block>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <div className="fm-head border-0 p-0">
                      <div className="fm-count"><em className="icon ni ni-briefcase fs-22px"></em></div>
                      <div className="fm-title">
                        <h5 className="title">Services Statistics</h5>
                        <p className="sub-title text-muted pointer-cursor" onClick={() => toggleModal('filterModal')}>Analytics from <span className="text-primary">{moment(analyticsStats?.date_range.date_from).format("Do MMM YYYY")}</span> to <span className="text-primary">{moment(analyticsStats?.date_range.date_to).format("Do MMM YYYY")}</span>.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <PreviewAltCard>
                      <div className="card-title-group pb-3 g-2">
                        <div className="card-title">
                          <h6 className="title">Top Performing Services 
                            <Button id="services_info_popover" className="btn-round btn-icon text-end" color="muted" size="sm">
                              <Icon name="info" />
                            </Button>
                            <UncontrolledPopover target="services_info_popover" placement="bottom" trigger="focus">
                              <PopoverBody>
                                Top-performing services are ranked first by the number of times sold. If there's a tie, total revenue generated is considered, followed by total quantity sold for further tie-breaking. This approach highlights popularity, revenue, and sales volume.
                              </PopoverBody>
                            </UncontrolledPopover>
                          </h6>
                          <p>List of your most sold services.</p>
                        </div>
                      </div>
                      <div className="mt-1">
                        {
                          analyticsStats?.services_stats.top_services.length > 0 ?
                          <>
                          <div className="nk-tb-list is-loose">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col"><span>#</span></div>
                              <div className="nk-tb-col"><span>Service</span></div>
                              <div className="nk-tb-col text-right"><span>Purchases</span></div>
                              <div className="nk-tb-col"><span>Purchases %</span></div>
                            </div>
                            {
                              analyticsStats?.services_stats.top_services.map((data, index) => {
                                return (
                                  <div className="nk-tb-item" key={index}>
                                    <div className="nk-tb-col">{index + 1}.</div>
                                    <div className="nk-tb-col">
                                      <div className="user-info">
                                        <span className="tb-lead">{data.service}</span>
                                        <span><small>Times Sold: </small>{data.total_purchases} • <small>Qty: </small>{data.total_quantity}</span>
                                      </div>
                                    </div>
                                    <div className="nk-tb-col text-right"><span className="tb-sub tb-amount"><span><small>{businessInfo.currency} </small>{data.total_amount}</span></span>
                                    </div>
                                    <div className="nk-tb-col">
                                        <div className="progress-amount text-primary">{data.percentage}%</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            
                          </div>
                          {
                            analyticsStats?.services_stats.top_services.length > 4 &&
                            <div className="nk-block price-plan-action text-center">
                              <Link to={`${process.env.PUBLIC_URL}/services/b/${businessID}/analytics?p=1&d=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`}>
                                <button className="btn btn-outline-light">Load More</button>
                              </Link>
                            </div>
                          }
                          </>
                          :
                          <div className="nk-tb-list is-loose">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <p className="title fw-normal text-center">No service found</p>
                            </div>
                          </div>
                        }
                      </div>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="5">
                    <PreviewAltCard>
                      <Link to={`${process.env.PUBLIC_URL}/services/b/${businessID}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-briefcase"></em>
                            <div className="title fw-bold">Total Services</div>
                            <h4 className="amount fw-normal">{analyticsStats?.services_stats.total_services}</h4>
                          </div>
                        </div>
                      </Link>
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>

              {/* customers stats */}
              <Block>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <div className="fm-head border-0 p-0">
                      <div className="fm-count"><em className="icon ni ni-happy fs-22px"></em></div>
                      <div className="fm-title">
                        <h5 className="title">Customers Statistics</h5>
                        <p className="sub-title text-muted pointer-cursor" onClick={() => toggleModal('filterModal')}>Analytics from <span className="text-primary">{moment(analyticsStats?.date_range.date_from).format("Do MMM YYYY")}</span> to <span className="text-primary">{moment(analyticsStats?.date_range.date_to).format("Do MMM YYYY")}</span>.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <PreviewAltCard>
                      <div className="card-title-group pb-3 g-2">
                        <div className="card-title">
                          <h6 className="title">Top Customers 
                            <Button id="customer_info_popover" className="btn-round text-end text-muted" size="sm">
                              <Icon name="info" />
                            </Button>
                            <UncontrolledPopover target="customer_info_popover" placement="bottom" trigger="focus">
                              <PopoverBody>
                                Top customers are ranked by the number of times they've purchased from your business. If there's a tie, the total amount of their purchases is used to break it. This approach emphasizes customer loyalty and the overall value of purchases.
                              </PopoverBody>
                            </UncontrolledPopover>
                          </h6>
                          <p>List of your top customers.</p>
                        </div>
                      </div>
                      <div className="mt-1">
                        {
                          analyticsStats?.customers_stats.top_customers.length > 0 ?
                          <>
                          <div className="nk-tb-list is-loose">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col"><span>#</span></div>
                              <div className="nk-tb-col"><span>Customer</span></div>
                              <div className="nk-tb-col text-right"><span>Purchases</span></div>
                              <div className="nk-tb-col"><span>Purchases %</span></div>
                            </div>
                            {
                              analyticsStats?.customers_stats.top_customers.map((data, index) => {
                                return (
                                  <div className="nk-tb-item" key={index}>
                                    <div className="nk-tb-col">{index + 1}.</div>
                                    <div className="nk-tb-col">
                                      <div className="user-info">
                                        <span className="tb-lead">{data.customer_name}</span>
                                        <span><small>No. of Purchases: </small>{data.total_purchases} </span>
                                      </div>
                                    </div>
                                    <div className="nk-tb-col text-right"><span className="tb-sub tb-amount"><span><small>{businessInfo.currency} </small>{data.total_amount}</span></span>
                                    </div>
                                    <div className="nk-tb-col">
                                        <div className="progress-amount text-primary">{data.percentage}%</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            
                          </div>
                          {
                            analyticsStats?.customers_stats.top_customers.length > 4 &&
                            <div className="nk-block price-plan-action text-center">
                              <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}?p=1&ct=top_customers&pd=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`}>
                                <button className="btn btn-outline-light">Load More</button>
                              </Link>
                            </div>
                          }
                          </>
                          :
                          <div className="nk-tb-list is-loose">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <p className="title fw-normal text-center">No customer found</p>
                            </div>
                          </div>
                        }
                      </div>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="5">
                    <PreviewAltCard>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-happy"></em>
                            <div className="title fw-bold">Total Customers</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.customers_stats.total_customers}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}?ct=returning_customers&pd=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-happy text-success"></em>
                            <div className="title fw-bold">Total Returning Customers</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.customers_stats.total_returning_customers}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}?ct=one_time_customers&pd=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-meh text-warning"></em>
                            <div className="title fw-bold">Total Onetime Customers</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.customers_stats.total_onetime_customers}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}?ct=customers_owing`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-meh text-danger"></em>
                            <div className="title fw-bold">Total Customers Owing</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.customers_stats.total_customers_owing}
                            </h4>
                          </div>
                        </div>
                      </Link>
                      {/* <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}?ct=potential_customers`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-meh text-muted"></em>
                            <div className="title fw-bold">Potential Customers</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.customers_stats.total_potential_customers}
                            </h4>
                          </div>
                        </div>
                      </Link> */}
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/customer-feedback/b/${businessID}&d=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-chat"></em>
                            <div className="title fw-bold">Total Customer Feedback</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.customers_stats.total_customer_feedback}
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>

              {/* expenses stats */}
              <Block>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <div className="fm-head border-0 p-0">
                      <div className="fm-count"><em className="icon ni ni-pie fs-22px"></em></div>
                      <div className="fm-title">
                        <h5 className="title">Expenses Statistics</h5>
                        <p className="sub-title text-muted pointer-cursor" onClick={() => toggleModal('filterModal')}>Analytics from <span className="text-primary">{moment(analyticsStats?.date_range.date_from).format("Do MMM YYYY")}</span> to <span className="text-primary">{moment(analyticsStats?.date_range.date_to).format("Do MMM YYYY")}</span>.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <PreviewAltCard>
                      <div className="card-title-group pb-3 g-2">
                        <div className="card-title">
                          <h6 className="title">Top Expenses Category 
                            <Button id="expenses_info_popover" className="btn-round text-end text-muted" size="sm">
                              <Icon name="info" />
                            </Button>
                            <UncontrolledPopover target="expenses_info_popover" placement="bottom" trigger="focus">
                              <PopoverBody>
                                Top expenses category are ranked first by the total amount of the expenses. If there's a tie, total number of the expenses is considered for further tie-breaking.
                              </PopoverBody>
                            </UncontrolledPopover>
                          </h6>
                          <p>List of your top expense categories.</p>
                        </div>
                      </div>
                      <div className="mt-1">
                        {
                          analyticsStats?.expenses_stats.top_categories.length > 0 ?
                          <>
                          <div className="nk-tb-list is-loose">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col"><span>#</span></div>
                              <div className="nk-tb-col"><span>Category</span></div>
                              <div className="nk-tb-col text-right"><span>Total Amount</span></div>
                              <div className="nk-tb-col"><span>Percentage %</span></div>
                            </div>
                            {
                              analyticsStats?.expenses_stats.top_categories.map((data, index) => {
                                return (
                                  <div className="nk-tb-item" key={index}>
                                    <div className="nk-tb-col">{index + 1}.</div>
                                    <div className="nk-tb-col">
                                      <div className="user-info">
                                        <span className="tb-lead">{data.category}</span>
                                        <span><small>No. Expenses: </small>{data.total_count}</span>
                                      </div>
                                    </div>
                                    <div className="nk-tb-col text-right"><span className="tb-sub tb-amount"><span><small>{businessInfo.currency} </small>{data.total_amount}</span></span>
                                    </div>
                                    <div className="nk-tb-col">
                                        <div className="progress-amount text-primary">{data.percentage}%</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                            
                          </div>
                          {
                            analyticsStats?.expenses_stats.top_categories.length > 4 &&
                            <div className="nk-block price-plan-action text-center">
                              <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}/analytics?p=1&d=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`}>
                                <button className="btn btn-outline-light">Load More</button>
                              </Link>
                            </div>
                          }
                          </>
                          :
                          <div className="nk-tb-list is-loose">
                            <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                            <div className="price-plan-info">
                              <p className="title fw-normal text-center">No category found</p>
                            </div>
                          </div>
                        }
                      </div>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="5">
                    <PreviewAltCard>
                      <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}?p=1&r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-pie"></em>
                            <div className="title fw-bold">Total Expenses</div>
                            <h4 className="amount fw-normal">
                              {/* {analyticsStats?.expenses_stats.total_expenses} */}
                              <small className="text-muted fs-16px me-1">{businessInfo.currency}</small>
                              <span>{analyticsStats?.expenses_stats.total_expenses_amount}</span>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}?p=1&s=paid&r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-wallet-saving text-success"></em>
                            <div className="title fw-bold">Total Paid Expenses</div>
                            <h4 className="amount fw-normal">
                              <small className="text-muted fs-16px me-1">{businessInfo.currency}</small>
                              <span>{analyticsStats?.expenses_stats.total_expenses_paid}</span>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}?p=1&s=owed&r=${moment(fromDate).format("YYYY-MM-DD")},${moment(toDate).format("YYYY-MM-DD")}`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-user-list text-danger"></em>
                            <div className="title fw-bold">Suppliers Owed</div>
                            <h4 className="amount fw-normal">
                              {analyticsStats?.expenses_stats.total_suppliers_expenses_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {analyticsStats?.expenses_stats.total_suppliers_expenses_balance_due} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>
            </>
          }
        </Block>
      }
    </Content>
    
  </React.Fragment>)
}

export default Dashboard;