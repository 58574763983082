import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, InputNumber, Divider, Space, Form } from 'antd';
import PhoneNumberInput from "./components/PhoneNumberInput";
const { TextArea } = Input;

const AddService = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    service_name: '',
    description: "",
    selling_price: '',
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/services/add`, {
      business_id: businessID,
      service_name: _formData.service_name,
      description: _formData.description,
      selling_price: _formData.selling_price
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      toggleModal("successModal");
      setRequesting(false);
    }).catch((error) => {
      setRequesting(false);
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'service_name',
              errors: errorResponse.errors.hasOwnProperty("service_name") ? [errorResponse.errors.service_name] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'selling_price',
              errors: errorResponse.errors.hasOwnProperty("selling_price") ? [errorResponse.errors.selling_price] : [],
            }
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setLoading(false);
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Add Service" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Add Service
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services/b/${businessID}/import`}>
                        <Button color="primary">
                          <Icon name="upload-cloud"></Icon>
                          <span>Import Services</span>
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/services/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Service Added`} descriptionText={`The service has been added successfully.`}
          />
        }

        <Block className="wide-sm m-auto">
          <Form name="create-service"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="briefcase fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Service Information</h5>
                  <p className="nk-block-des">Use the form below to add a service.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row g-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Service Name <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'service_name'}
                          noStyle
                          rules={[
                            {required: true,}
                          ]}>
                          <Input size="large" className="form-control form-control-lg" placeholder="Enter service name" />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'description'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <TextArea rows={4} placeholder="enter a description of the service" />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Selling Price <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'selling_price'}
                            noStyle
                            rules={[
                              {required: true, message: 'Selling price is required'}
                            ]}>
                            <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                          </Form.Item> 
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-footer">
                <div className="pt-2 text-center">
                  <Button disabled={requesting} type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : "Add Service"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default AddService;