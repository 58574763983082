import React, { useState } from "react";
import { countryPhoneCodes } from '../../utils/Countries';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { Input, Select, Space, Form } from 'antd';
import { getPhoneCodeAndNumber } from "../../utils/Utils";
const { Option } = Select;

const PhoneNumberInput = ({ isRequired, form, formData, setFormData, ...props }) => {
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if(inputValue.length > 10){
      let phoneNumberFormatted = getPhoneCodeAndNumber(inputValue);
    
      setPhoneCode(phoneNumberFormatted.phoneCode);
      setPhoneNumber(phoneNumberFormatted.phoneNumber);
      
      setFormData({
        ...formData,
        phone_code: phoneNumberFormatted.phoneCode,
        phone_number: phoneNumberFormatted.phoneNumber,
      });

      form.setFieldValue("phone_code", phoneNumberFormatted.phoneCode);
      form.setFieldValue("phone_number", phoneNumberFormatted.phoneNumber);

    } else{
      setPhoneNumber(inputValue);
      setFormData({
        ...formData,
        phone_number: inputValue,
      });
      form.setFieldValue("phone_number", inputValue);

    }
  };

  const countryCode = (
    <Select size="large" 
      className="fs-14px"
      style={{
        width: 'fit-content',
      }}
      showSearch 
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }      
      // popupClassName="phone-code"
      popupMatchSelectWidth={false} optionLabelProp="label">
      {
        countryPhoneCodes.map((data, index) => <Option key={index} title={data.label} value={data.value} label={<div><span className={`fi ${data.flag}`}></span> <span>{data.value}</span></div>}>
          <Space>
            <div>
              <span className={`fi ${data.flag}`}></span>
              <span className="ps-1">{data.label}</span>
              <span className="ps-1 text-muted">{data.value}</span>
            </div>
          </Space>
        </Option>)
      }
      
    </Select>
  );

  return (
  <Form.Item>
    <Space.Compact size="large" className="w-100">
      <Form.Item 
        name={'phone_code'}
        noStyle
        rules={[
          {required: isRequired, message: 'Country code is required',}
        ]}>
        {countryCode}
      </Form.Item>

      <Form.Item 
        name={'phone_number'}
        noStyle
        rules={[
          {required: isRequired, message: 'Phone number is required'},
          // {max: 10, message: "Phone number is invalid"}
        ]}>
        <Input 
          size="large" 
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
          // maxLength={10} 
          placeholder="020 123 4567" 
        />
      </Form.Item>
    </Space.Compact>
  </Form.Item>
  );
};

export default PhoneNumberInput;
