import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Card, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { Empty } from 'antd';
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from "./components/abandoned-cart/Header";
import Body from "./components/abandoned-cart/Body";

const AbandonedCartDetails = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [cartDetails, setCartDetails] = useState({
    business_id: '',
    currency: '',
    cart_id: '',
    customer_name: '',
    customer_phone_number: '',
    items: [],
    total_amount: '',
    created_at: ''
  });
  const [errorMessage, setErrorMessage] = useState("");

  const getCartDetails = (businessInfo, cartID) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/storefront/abandoned-cart/${cartID}`, {
      params: {
        business_id: businessInfo.business_id
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('Cart not found');
      } else {
        let responseInfo = response.data;
        let cartInfo = responseInfo.data.cart_info;
        let _cartDetails = cartDetails;
  
        _cartDetails.business_id = businessInfo.business_id;
        _cartDetails.currency = businessInfo.currency;
  
        _cartDetails.cart_id = cartID;
        _cartDetails.customer_name = cartInfo.customer_name;
        _cartDetails.customer_phone_number = cartInfo.customer_phone_number;
        _cartDetails.items = cartInfo.items;
        _cartDetails.total_amount = cartInfo.total_amount;
        _cartDetails.created_at = cartInfo.created_at;
  
        setCartDetails({..._cartDetails});
      }
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const _businessID = parseInt(match.params.businessID, 10);
    const cartID = match.params.cartID;
    if ( Number.isInteger(_businessID) && cartID ) {
      localStorage.setItem('current_business_id', _businessID);
      let businesses = JSON.parse(localStorage.getItem('my_businesses'));
      let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setBusinessInfo({...currentBusiness});
      setBusinessID(_businessID);
      getCartDetails(currentBusiness, cartID);
    } else {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }
  }, [match.params.business]);

  return (<React.Fragment>
    <Head title="Abandoned Cart Details" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                <span>Cart Details</span>
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/storefront/b/${businessID}/abandoned-carts`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {
            errorMessage ?
            <Card className="card-bordered">
              <div className="card-inner">
                <div className="text-center m-5">
                  <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                  <div className="price-plan-info">
                    <h5 className="title fw-normal">Cart not found</h5>
                    <span>The abandoned cart you are looking for could not be found.</span>
                    <p className="text-soft">If you need help, you can reach us on our <a href={baseURLs.LIVE_CHAT}>live chat</a>.</p>
                  </div>
                  <div className="price-plan-action">
                    <BackTo className="mb-3" history={history} link={`/storefront/b/${businessID}/abandoned-carts`} icon="arrow-left" type="button">Go Back</BackTo>
                  </div>
                </div>
              </div>
            </Card>
            :
            <Card className="card-bordered">
              <Header headerInfo={cartDetails}  />
              <Body history={history} bodyInfo={cartDetails} />
            </Card>
          }


        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default AbandonedCartDetails;