import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Link } from "react-router-dom";
import KUSPayIcon from "../../../images/kus_icon.png";
import PaystackIcon from "../../../images/paystack_logo.png";
import FlutterwaveIcon from "../../../images/flutterwave_logo.png";
import StripeIcon from "../../../images/stripe_logo.png";
import HubtelIcon from "../../../images/hubtel_logo.png";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../../components/Component";
import { 
  Card, 
  Alert, 
  Badge,
  Spinner,
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem,  
  PopoverHeader, 
  PopoverBody, 
  UncontrolledPopover,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Input, Select, InputNumber, Checkbox, Form, Upload } from 'antd';
import { baseURLs, paystackCurrencies, flutterwaveCurrencies, stripeCurrencies, payoutAccounts } from '../../../utils/Constants';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber } from "../../../utils/Utils";
import { AlertModal, LoadingModal, SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';
const { Dragger } = Upload;

export const OnlinePayments = ({ history, match }) => {

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [paymentOptions, setPaymentOptions] = useState({
    is_verified: 'not-verified',
    payment_options: []
  });
  const [selectedPayoutType, setSelectedPayoutType] = useState('mobile_money');
  const [paymentType, setPaymentType] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentOTP, setPaymentOTP] = useState("");
  const [OTPEmail, setOTPEmail] = useState("");
  const [OTPMessage, setOTPMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();
  const [keepupPayFormData, setKeepupPayFormData] = useState({
    payout_type: "mobile_money",
    account_type: "",
    account_number: "",
    account_name: "",
    fee_coverage: "customer"
  });
  const [thirdPartyPaymentFormData, setThirdPartyPaymentFormData] = useState({
    secret_api_key: "",
    public_api_key: "",
    fee_coverage: "business",
    fee_type: "percentage",
    fee: ''
  });

  const [hubtelPaymentFormData, setHubtelPaymentFormData] = useState({
    merchant_code: "",
    api_id: "",
    api_key: "",
    fee_coverage: "business",
    fee_type: "percentage",
    fee: ''
  });
  const [accountVerification, setAccountVerification] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [copyPaystackCallback, setCopyPaystackCallback] = useState(false);
  const [copyFlutterwaveCallback, setCopyFlutterwaveCallback] = useState(false);
  const [copyStripeCallback, setCopyStripeCallback] = useState(false);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onCopyClick = (payment_type) => {
    payment_type === 'paystack' ? 
    setCopyPaystackCallback(true) 
    : payment_type === 'flutterwave' ? setCopyFlutterwaveCallback(true) 
    : setCopyStripeCallback(true);

    setTimeout(() => payment_type === 'paystack' ? 
      setCopyPaystackCallback(false) 
      : payment_type === 'flutterwave' ? setCopyFlutterwaveCallback(false) 
      : setCopyStripeCallback(false) , 2000);
  };

  const getPaymentTypeName = (payment_type) => {
    switch (payment_type) {
      case 'keepup_pay':
        return 'Keepup Pay';
  
      case 'paystack':
        return 'Paystack';
  
      case 'flutterwave':
        return 'Flutterwave';
  
      case 'stripe':
        return 'Stripe';
      
      case 'hubtel':
        return 'Hubtel';
  
      default:
        return 'Keepup Pay';
    }
  }

  const handleOTPSubmit = () => {
    if(requesting) {return;}

    if(!paymentOTP) {
      setErrorMessage("OTP is required");
      return;
    }

    setErrorMessage("");
    setRequesting(true);

    setLoadingMessage(`Please wait while the OTP is verified.`);
    toggleModal('loadingModal');
    
    axios.post(baseURLs.API_URL + `/businesses/online-payments/otp/${businessID}`, {
      otp: paymentOTP,
      status: paymentStatus,
      payment_type: paymentType
    }, getAxiosHeaders())
    .then((response) => {

      let responseInfo = response.data;
      setSuccessTitle('Updated');
      setSuccessMessage(responseInfo.message);
      toggleModal('successModal');

      setRequesting(false);
      getOnlinePayments(businessID);

      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal(null)
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  // const handleKeepupPaySubmit = (_formData) => {
  //   if(requesting) {return;}

  //   setErrorMessage("");
  //   setRequesting(true);
    
  //   axios.post(baseURLs.API_URL + `/businesses/online-payments/keepup_pay`, {
  //     business_id: businessID,
  //     payout_type: _formData.payout_type,
  //     account_type: _formData.account_type,
  //     account_number: _formData.account_number,
  //     account_name: _formData.account_name,
  //     fee_coverage: _formData.fee_coverage,
  //   }, getAxiosHeaders())
  //   .then((response) => {
  //     let responseInfo = response.data;
  //     let _otpEmail = responseInfo.data.email;

  //     setPaymentType('keepup_pay');
  //     setPaymentStatus('enable');
  //     setOTPEmail(_otpEmail);
  //     setOTPMessage('Enter the OTP to enable Keepup Pay.');
  //     toggleModal("OTPModal")
  //     setRequesting(false);

  //     window.scrollTo({top: 0, behavior: 'smooth'});
  //   }).catch((error) => {
  //     try{
  //       let errorResponse = error.response.data;

  //       if(errorResponse.hasOwnProperty("errors")){
  //         form.setFields([
  //           {
  //             name: 'payout_type',
  //             errors: errorResponse.errors.hasOwnProperty("payout_type") ? [errorResponse.errors.payout_type] : [],
  //           },
  //           {
  //             name: 'account_type',
  //             errors: errorResponse.errors.hasOwnProperty("account_type") ? [errorResponse.errors.account_type] : [],
  //           },
  //           {
  //             name: 'account_number',
  //             errors: errorResponse.errors.hasOwnProperty("account_number") ? [errorResponse.errors.account_number] : [],
  //           },
  //           {
  //             name: 'account_name',
  //             errors: errorResponse.errors.hasOwnProperty("account_name") ? [errorResponse.errors.account_name] : [],
  //           },
  //           {
  //             name: 'fee_coverage',
  //             errors: errorResponse.errors.hasOwnProperty("fee_coverage") ? [errorResponse.errors.fee_coverage] : [],
  //           }
            
  //         ]);

  //       }

  //       let errorMessage = 'Error: Could not connect to server';
  //       if(errorResponse.hasOwnProperty("error")){
  //         errorMessage = errorResponse.error;
  //       }

  //       setErrorMessage(errorMessage);
  //       setRequesting(false);
  //       window.scrollTo({top: 0, behavior: 'smooth'});
  //     }catch(e){
  //       setErrorMessage("Error: Could not connect to server");
  //     }
  //   });

  // }

  const handleThirdPartyPaymentSubmit = (_formData) => {
    if(requesting) {return;}

    setErrorMessage("");
    setRequesting(true);
    
    axios.post(baseURLs.API_URL + `/businesses/online-payments/third-party`, {
      business_id: businessID,
      payment_type: paymentType,
      secret_api_key: _formData.secret_api_key,
      public_api_key: _formData.public_api_key,
      fees_coverage: _formData.fee_coverage,
      fees_type: _formData.fee_type,
      fees: _formData.fee,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let _otpEmail = responseInfo.data.email;

      setPaymentStatus('enable');
      setOTPEmail(_otpEmail);
      setOTPMessage(`Enter the OTP to enable ${getPaymentTypeName(paymentType)}.`);
      toggleModal("OTPModal")
      setRequesting(false);

      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'secret_api_key',
              errors: errorResponse.errors.hasOwnProperty("secret_api_key") ? [errorResponse.errors.secret_api_key] : [],
            },
            {
              name: 'public_api_key',
              errors: errorResponse.errors.hasOwnProperty("public_api_key") ? [errorResponse.errors.public_api_key] : [],
            },
            {
              name: 'fee_coverage',
              errors: errorResponse.errors.hasOwnProperty("fees_coverage") ? [errorResponse.errors.fees_coverage] : [],
            },
            {
              name: 'fee_type',
              errors: errorResponse.errors.hasOwnProperty("fees_type") ? [errorResponse.errors.fees_type] : [],
            },
            {
              name: 'fee',
              errors: errorResponse.errors.hasOwnProperty("fees") ? [errorResponse.errors.fees] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const handleHubtelPaymentSubmit = (_formData) => {
    if(requesting) {return;}

    setErrorMessage("");
    setRequesting(true);
    
    axios.post(baseURLs.API_URL + `/businesses/online-payments/hubtel`, {
      business_id: businessID,
      payment_type: paymentType,
      merchant_code: _formData.merchant_code,
      api_id: _formData.api_id,
      api_key: _formData.api_key,
      fees_coverage: _formData.fee_coverage,
      fees_type: _formData.fee_type,
      fees: _formData.fee,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let _otpEmail = responseInfo.data.email;

      setPaymentStatus('enable');
      setOTPEmail(_otpEmail);
      setOTPMessage(`Enter the OTP to enable ${getPaymentTypeName(paymentType)}.`);
      toggleModal("OTPModal")
      setRequesting(false);

      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'merchant_code',
              errors: errorResponse.errors.hasOwnProperty("merchant_code") ? [errorResponse.errors.merchant_code] : [],
            },
            {
              name: 'api_id',
              errors: errorResponse.errors.hasOwnProperty("api_id") ? [errorResponse.errors.api_id] : [],
            },
            {
              name: 'api_key',
              errors: errorResponse.errors.hasOwnProperty("api_key") ? [errorResponse.errors.api_key] : [],
            },
            {
              name: 'fee_coverage',
              errors: errorResponse.errors.hasOwnProperty("fees_coverage") ? [errorResponse.errors.fees_coverage] : [],
            },
            {
              name: 'fee_type',
              errors: errorResponse.errors.hasOwnProperty("fees_type") ? [errorResponse.errors.fees_type] : [],
            },
            {
              name: 'fee',
              errors: errorResponse.errors.hasOwnProperty("fees") ? [errorResponse.errors.fees] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getOnlinePayments = (businessID) => {
    
    axios.get(baseURLs.API_URL + `/businesses/online-payments`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID,
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let paymentOptions = responseInfo.data;
      setPaymentOptions(paymentOptions);

      setLoading(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  // const verifyPayoutAccount = () => {
  //   let account_type = form.getFieldValue('account_type');
  //   let account_number = form.getFieldValue('account_number');

  //   if(!account_type || !account_number){
  //     form.setFields([
  //       {
  //         name: 'account_type',
  //         errors: account_type ? [] : ["Account type is required"],
  //       },
  //       {
  //         name: 'account_number',
  //         errors: account_number ? [] : ["Account number is required"],
  //       },
  //     ])
      
  //     return;
  //   } else if(form.getFieldValue('payout_type') === 'mobile_money' && account_number.length !== 10) {
  //     form.setFields([
  //       {
  //         name: 'account_number',
  //         errors: ["Account number is invalid"],
  //       },
  //     ])

  //     return;
  //   }

    

  //   setErrorMessage("");
  //   setRequesting(true);

  //   axios.get(baseURLs.API_URL + `/businesses/online-payments/verify-payout-account`, {
  //     headers: getAxiosHeaders().headers,
  //     params: {
  //       business_id: businessID,
  //       account_type: account_type,
  //       account_number: account_number,
  //     }
  //   })
  //   .then((response) => {
  //     let responseInfo = response.data;
  //     let verificationInfo = responseInfo.data;

  //     form.setFieldValue('account_name', verificationInfo.account_name)
  //     setAccountVerification(true);
  //     setRequesting(false);
  //     window.scrollTo({top: 0, behavior: 'smooth'});
  //   }).catch((error) => {
  //     try{
  //       let errorResponse = error.response.data;

  //       let errorMessage = 'Error: Could not connect to server';
  //       if(errorResponse.hasOwnProperty("error")){
  //         errorMessage = errorResponse.error;
  //       }

  //       setErrorMessage(errorMessage);
  //       setRequesting(false);
  //       window.scrollTo({top: 0, behavior: 'smooth'});
  //     }catch(e){
  //       setErrorMessage("Error: Could not connect to server");
  //     }
  //   });

  // }

  const initPaymentUpdate = (payment_type, status) => {
    if(requesting) {return;}

    setRequesting(true);
    setErrorMessage("");
    
    setLoadingMessage(`Please wait while the payment option is being ${status === 'enable' ? `enabled` : `disabled`}`);
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + `/businesses/online-payments/init-verification/${businessID}`, {
      headers: getAxiosHeaders().headers,
      params: {
        payment_type,
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let _otpEmail = responseInfo.data.email;

      setPaymentType(payment_type);
      setPaymentStatus(status);
      setOTPEmail(_otpEmail);
      toggleModal("OTPModal")
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });

  }

  const updatePaymentForm = (value) => {
    setSelectedPayoutType(value);
    form.setFieldValue('account_type', '');
  }

  const showThirdPartyConfigModal = (payment_type) => {
    setPaymentType(payment_type);
    payment_type === 'hubtel' ? 
    toggleModal('configureHubtelModal') :
    toggleModal('configureThirdPartyModal');

  }

  const disablePayment = (payment_type, status) => {
    setPaymentType(payment_type);
    setPaymentStatus(status);
    toggleModal('disablePaymentModal');
  }

  const updateConfigForm = (payment_type) => {
    if(payment_type === 'keepup_pay') {
      let configInfo = paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'keepup_pay');
      
      setKeepupPayFormData({
        ...keepupPayFormData, 
        payout_type: configInfo.payout_info.payout_type,
        account_type: configInfo.payout_info.account_code,
        account_name: configInfo.payout_info.account_name,
        account_number: configInfo.payout_info.account_number,
        fee_coverage: configInfo.fees_coverage
      })

      setAccountVerification(true);  
      updatePaymentForm(configInfo.payout_info.payout_type);

      form.setFieldsValue({
        payout_type: configInfo.payout_info.payout_type,
        account_type: configInfo.payout_info.account_code,
        account_name: configInfo.payout_info.account_name,
        account_number: configInfo.payout_info.account_number,
        fee_coverage: configInfo.fees_coverage
      })

      toggleModal('configureKeepupPayModal');
    
    } else if(payment_type === 'hubtel') {
      let configInfo = paymentOptions.payment_options.find(payment_option => payment_option.payment_type === payment_type);
      setHubtelPaymentFormData({
        ...hubtelPaymentFormData,
        merchant_code: configInfo.merchant_code,
        api_id: configInfo.api_keys.public_key,
        api_key: configInfo.api_keys.secret_key,
        fee_coverage: configInfo.fees_coverage,
        fee_type: configInfo.fees_type ?? 'percentage',
        fee: configInfo.fees,
      })

      form.setFieldsValue({
        merchant_code: configInfo.merchant_code,
        api_id: configInfo.api_keys.public_key,
        api_key: configInfo.api_keys.secret_key,
        fee_coverage: configInfo.fees_coverage,
        fee_type: configInfo.fees_type ?? 'percentage',
        fee: configInfo.fees,
      })

      setPaymentType(payment_type);
      toggleModal('configureHubtelModal');
    } else {
      let configInfo = paymentOptions.payment_options.find(payment_option => payment_option.payment_type === payment_type);
      setThirdPartyPaymentFormData({
        ...thirdPartyPaymentFormData, 
        secret_api_key: configInfo.api_keys.secret_key,
        public_api_key: configInfo.api_keys.public_key,
        fee_coverage: configInfo.fees_coverage,
        fee_type: configInfo.fees_type ?? 'percentage',
        fee: configInfo.fees,
      })

      form.setFieldsValue({
        secret_api_key: configInfo.api_keys.secret_key,
        public_api_key: configInfo.api_keys.public_key,
        fee_coverage: configInfo.fees_coverage,
        fee_type: configInfo.fees_type ?? 'percentage',
        fee: configInfo.fees,
      })

      setPaymentType(payment_type);
      toggleModal('configureThirdPartyModal');
    }
  }

  useEffect(() => {  
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getOnlinePayments(businessID);
  }, [match.params.businessID]);

  const transactionFeeTypeOptions = (
    <Form.Item name={'fee_type'}
        noStyle
        rules={[
        {required: false}
      ]}>
      <Select className="select-after"
        popupMatchSelectWidth={false}
        options={[
          {
            key: 'fixed',
            label: businessInfo.currency,
            value: 'fixed',
          },
          {
            key: 'percentage',
            label: '%',
            value: 'percentage',
          },                              
        ]}
        style={{
          width: 'fit-content',
        }}/>
    </Form.Item>
  );

  return (
    <React.Fragment>
      <Head title="Online Payments"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Online Payments
              </BlockTitle>
              <BlockDes>
                <p>Manage your online payment options.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              errorMessage &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              </Block>
            }

            <div className="card">
              <div className="fm-content">
                {
                  paymentOptions.is_verified !== 'verified' ?
                  // paymentOptions.is_verified !== 'verified' ?
                  <Block>
                    <div className="text-center m-5">
                      <div className="price-plan-media">
                        <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                      </div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">Verify your business to start accepting payment online.</span>
                      </div>
                      <div className="price-plan-action">
                        <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                          <button className="btn btn-outline-primary">Verify Business</button>
                        </Link>                    
                      </div>
                    </div>
                  </Block>
                  :
                  <Block>
                    {/* {
                      businessInfo.currency === 'GHS' &&
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help align-baseline">
                            <div className="nk-help-img m-md-0 w-80px">
                              <img src={KUSPayIcon} />
                            </div>
                            <div className="nk-help-text">
                              <h6> Keepup Pay 
                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'keepup_pay' && payment_option.status === 'enabled') &&
                                  <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Enabled</span></Badge>
                                }

                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'keepup_pay' && payment_option.status === 'disabled') &&
                                  <Badge pill color="danger" className="badge-dim badge-sm"><Icon name="na"></Icon><span>Disabled</span></Badge>
                                }
                              </h6>
                              <p className="text-soft">
                                Accept payments from customers using different payment channels such as: <b>cards and mobile money accounts</b>
                              </p>
                              <ul className="preview-list">
                                <li className="preview-item py-1"><span className="badge bg-outline-light badge-sm">Transaction Fee: <b className="mx-1">2%</b></span></li>
                                <li className="preview-item py-1"><span className="badge bg-outline-light badge-sm">Bank Payout Fee: <small className="mx-1">GHS</small><b>10</b></span></li>
                                <li className="preview-item py-1"><span className="badge bg-outline-light badge-sm">Mobile Money Payout Fee: <small className="mx-1">GHS</small><b>2</b></span></li>
                              </ul>
                            </div>
                            <div className="nk-help-action">
                              {
                                !paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'keepup_pay') ?
                                <button className="btn btn-outline-primary" onClick={() => toggleModal('configureKeepupPayModal')}>
                                  Configure
                                </button>
                                :
                                <UncontrolledDropdown>
                                  <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                                    <span>Update</span>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                    {
                                      paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'keepup_pay' && payment_option.status === 'enabled') ?
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => disablePayment('keepup_pay', 'disable')}>
                                          <Icon name="na"></Icon>
                                          <span>Disable</span>
                                        </DropdownItem>
                                      </li>
                                      :
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => initPaymentUpdate('keepup_pay', 'enable')}>
                                          <Icon name="check-c"></Icon>
                                          <span>Enable</span>
                                        </DropdownItem>
                                      </li>
                                    }
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => updateConfigForm('keepup_pay')}>
                                          <Icon name="setting"></Icon>
                                          <span>Settings</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    } */}

                    {
                      paystackCurrencies.includes(businessInfo.currency) &&
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help align-baseline">
                            <div className="nk-help-img m-md-0 w-80px">
                              <img src={PaystackIcon} className="p-3" />
                            </div>
                            <div className="nk-help-text">
                              <h6>Paystack
                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'paystack' && payment_option.status === 'enabled') &&
                                  <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Enabled</span></Badge>
                                }

                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'paystack' && payment_option.status === 'disabled') &&
                                  <Badge pill color="danger" className="badge-dim badge-sm"><Icon name="na"></Icon><span>Disabled</span></Badge>
                                }
                              </h6>
                              <p className="text-soft">
                                Accept payments from customers using different payment channels such as: <b>cards, mobile money accounts, QR codes, Apple Pay, directly from their bank account or USSD.</b>
                              </p>
                              {
                                paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'paystack') &&
                                <div className="nk-refwg-url">
                                  <div className="form-label-group">
                                    <label className="form-label">Callback URL 
                                      <Button type="button" id="paystack_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                        <Icon name="info" />
                                      </Button>
                                      <UncontrolledPopover target="paystack_info_popover" placement="bottom" trigger="focus">
                                        <PopoverBody>
                                          <p className="mb-2">This link is crucial for receiving real-time updates on payment status from your <b>Paytstack Account</b>. </p>
                                          <p>Copy and paste this link into your <b>Paytstack Account</b> settings to enable seamless communication between your <b>Paytstack Account</b> and your business on <b>Keepup Store</b>  </p>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </label>
                                  </div>
                                  <div className={`form-control-wrap ${copyPaystackCallback ? "clipboard-success" : ""}`}>
                                    
                                    <CopyToClipboard
                                      text={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'paystack')?.callback_url}
                                      className="form-clip clipboard-init me-2"
                                      onCopy={() => onCopyClick('paystack')}>
                                      <div>
                                        <Icon name="copy" className="clipboard-icon"></Icon>
                                        <span className="clipboard-text">{copyPaystackCallback ? "Copied" : "Copy"}</span>
                                      </div>
                                    </CopyToClipboard>                                                      
                                    <div className="form-icon">
                                      <Icon name="link-alt"></Icon>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control copy-text"
                                      id="paystack_callback"
                                      defaultValue={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'paystack')?.callback_url}
                                      value={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'paystack')?.callback_url}/>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="nk-help-action">
                              {
                                !paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'paystack') ?
                                <button className="btn btn-outline-primary" onClick={() => showThirdPartyConfigModal('paystack')}>
                                  Configure
                                </button>
                                :
                                <UncontrolledDropdown>
                                  <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                                    <span>Update</span>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                    {
                                      paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'paystack' && payment_option.status === 'enabled') ?
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => disablePayment('paystack', 'disable')}>
                                          <Icon name="na"></Icon>
                                          <span>Disable</span>
                                        </DropdownItem>
                                      </li>
                                      :
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => initPaymentUpdate('paystack', 'enable')}>
                                          <Icon name="check-c"></Icon>
                                          <span>Enable</span>
                                        </DropdownItem>
                                      </li>
                                    }
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => updateConfigForm('paystack')}>
                                          <Icon name="setting"></Icon>
                                          <span>Settings</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      businessInfo.currency === 'GHS' &&
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help align-baseline">
                            <div className="nk-help-img m-md-0 w-80px">
                              <img src={HubtelIcon} className="p-1" />
                            </div>
                            <div className="nk-help-text">
                              <h6>Hubtel
                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'hubtel' && payment_option.status === 'enabled') &&
                                  <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Enabled</span></Badge>
                                }

                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'hubtel' && payment_option.status === 'disabled') &&
                                  <Badge pill color="danger" className="badge-dim badge-sm"><Icon name="na"></Icon><span>Disabled</span></Badge>
                                }
                              </h6>
                              <p className="text-soft">
                                Accept payments from customers using different payment channels such as: <b>cards, mobile money accounts, Wallets and GhQR</b>
                              </p>
                            </div>
                            <div className="nk-help-action">
                              {
                                !paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'hubtel') ?
                                <button className="btn btn-outline-primary" onClick={() => showThirdPartyConfigModal('hubtel')}>
                                  Configure
                                </button>
                                :
                                <UncontrolledDropdown>
                                  <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                                    <span>Update</span>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                    {
                                      paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'hubtel' && payment_option.status === 'enabled') ?
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => disablePayment('hubtel', 'disable')}>
                                          <Icon name="na"></Icon>
                                          <span>Disable</span>
                                        </DropdownItem>
                                      </li>
                                      :
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => initPaymentUpdate('hubtel', 'enable')}>
                                          <Icon name="check-c"></Icon>
                                          <span>Enable</span>
                                        </DropdownItem>
                                      </li>
                                    }
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => updateConfigForm('hubtel')}>
                                          <Icon name="setting"></Icon>
                                          <span>Settings</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      flutterwaveCurrencies.includes(businessInfo.currency) &&
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help align-baseline">
                            <div className="nk-help-img m-md-0 w-80px">
                              <img src={FlutterwaveIcon} className="p-1" />
                            </div>
                            <div className="nk-help-text">
                              <h6>Flutterwave
                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'flutterwave' && payment_option.status === 'enabled') &&
                                  <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Enabled</span></Badge>
                                }

                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'flutterwave' && payment_option.status === 'disabled') &&
                                  <Badge pill color="danger" className="badge-dim badge-sm"><Icon name="na"></Icon><span>Disabled</span></Badge>
                                }
                              </h6>
                              <p className="text-soft">
                                Accept payments from customers using different payment channels such as: <b>cards, mobile money accounts, QR codes, Apple Pay, directly from their bank account, Mpesa, and Google Pay.</b>
                              </p>
                              {
                                paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'flutterwave') &&
                                <div className="nk-refwg-url">
                                  <div className="form-label-group">
                                    <label className="form-label">Callback URL 
                                      <Button type="button" id="flutterwave_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                        <Icon name="info" />
                                      </Button>
                                      <UncontrolledPopover target="flutterwave_info_popover" placement="bottom" trigger="focus">
                                        <PopoverBody>
                                          <p className="mb-2">This link is crucial for receiving real-time updates on payment status from your <b>Flutterwave Account</b>. </p>
                                          <p>Copy and paste this link into your <b>Flutterwave Account</b> settings to enable seamless communication between your <b>Flutterwave Account</b> and your business on <b>Keepup Store</b>  </p>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </label>
                                  </div>
                                  <div className={`form-control-wrap ${copyFlutterwaveCallback ? "clipboard-success" : ""}`}>
                                    
                                    <CopyToClipboard
                                      text={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'flutterwave')?.callback_url}
                                      className="form-clip clipboard-init me-2"
                                      onCopy={() => onCopyClick('flutterwave')}>
                                      <div>
                                        <Icon name="copy" className="clipboard-icon"></Icon>
                                        <span className="clipboard-text">{copyFlutterwaveCallback ? "Copied" : "Copy"}</span>
                                      </div>
                                    </CopyToClipboard>                                                      
                                    <div className="form-icon">
                                      <Icon name="link-alt"></Icon>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control copy-text"
                                      id="flutterwave_callback"
                                      defaultValue={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'flutterwave')?.callback_url}
                                      value={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'flutterwave')?.callback_url}/>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="nk-help-action">
                              {
                                !paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'flutterwave') ?
                                <button className="btn btn-outline-primary" onClick={() => showThirdPartyConfigModal('flutterwave')}>
                                  Configure
                                </button>
                                :
                                <UncontrolledDropdown>
                                  <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                                    <span>Update</span>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                    {
                                      paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'flutterwave' && payment_option.status === 'enabled') ?
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => disablePayment('flutterwave', 'disable')}>
                                          <Icon name="na"></Icon>
                                          <span>Disable</span>
                                        </DropdownItem>
                                      </li>
                                      :
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => initPaymentUpdate('flutterwave', 'enable')}>
                                          <Icon name="check-c"></Icon>
                                          <span>Enable</span>
                                        </DropdownItem>
                                      </li>
                                    }
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => updateConfigForm('flutterwave')}>
                                          <Icon name="setting"></Icon>
                                          <span>Settings</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </div>
                          </div>
                        </div>
                      </div>      
                    }

                    {
                      stripeCurrencies.includes(businessInfo.currency) &&
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help align-baseline">
                            <div className="nk-help-img m-md-0 w-80px">
                              <img src={StripeIcon} className="p-1" />
                            </div>
                            <div className="nk-help-text">
                              <h6>Stripe
                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'stripe' && payment_option.status === 'enabled') &&
                                  <Badge pill color="success" className="badge-dim badge-sm"><Icon name="check-c"></Icon><span>Enabled</span></Badge>
                                }

                                {
                                  paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'stripe' && payment_option.status === 'disabled') &&
                                  <Badge pill color="danger" className="badge-dim badge-sm"><Icon name="na"></Icon><span>Disabled</span></Badge>
                                }
                              </h6>
                              <p className="text-soft">
                                Accept payments from customers using different payment channels such as: <b>cards, wallets, buy now - pay later, cash-based vouchers, and bank debits and transfers.</b>
                              </p>
                              {
                                paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'stripe') &&
                                <div className="nk-refwg-url">
                                  <div className="form-label-group">
                                    <label className="form-label">Callback URL 
                                      <Button type="button" id="stripe_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                        <Icon name="info" />
                                      </Button>
                                      <UncontrolledPopover target="stripe_info_popover" placement="bottom" trigger="focus">
                                        <PopoverBody>
                                          <p className="mb-2">This link is crucial for receiving real-time updates on payment status from your <b>Stripe Account</b>. </p>
                                          <p>Copy and paste this link into your <b>Stripe Account</b> settings to enable seamless communication between your <b>Stripe Account</b> and your business on <b>Keepup Store</b>  </p>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </label>
                                  </div>
                                  <div className={`form-control-wrap ${copyStripeCallback ? "clipboard-success" : ""}`}>
                                    
                                    <CopyToClipboard
                                      text={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'stripe')?.callback_url}
                                      className="form-clip clipboard-init me-2"
                                      onCopy={() => onCopyClick('stripe')}>
                                      <div>
                                        <Icon name="copy" className="clipboard-icon"></Icon>
                                        <span className="clipboard-text">{copyStripeCallback ? "Copied" : "Copy"}</span>
                                      </div>
                                    </CopyToClipboard>                                                      
                                    <div className="form-icon">
                                      <Icon name="link-alt"></Icon>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control copy-text"
                                      id="stripe_callback"
                                      defaultValue={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'stripe')?.callback_url}
                                      value={paymentOptions.payment_options.find(payment_option => payment_option.payment_type === 'stripe')?.callback_url}/>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="nk-help-action">
                              {
                                !paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'stripe') ?
                                <button className="btn btn-outline-primary" onClick={() => showThirdPartyConfigModal('stripe')}>
                                  Configure
                                </button>
                                :
                                <UncontrolledDropdown>
                                  <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                                    <span>Update</span>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                    {
                                      paymentOptions.payment_options.some(payment_option => payment_option.payment_type === 'stripe' && payment_option.status === 'enabled') ?
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => disablePayment('stripe', 'disable')}>
                                          <Icon name="na"></Icon>
                                          <span>Disable</span>
                                        </DropdownItem>
                                      </li>
                                      :
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => initPaymentUpdate('stripe', 'enable')}>
                                          <Icon name="check-c"></Icon>
                                          <span>Enable</span>
                                        </DropdownItem>
                                      </li>
                                    }
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => updateConfigForm('stripe')}>
                                          <Icon name="setting"></Icon>
                                          <span>Settings</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </Block>
                }
              </div>
            </div>
          </Block>
        </>
      }
      </Content>
      

      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={successTitle} descriptionText={successMessage} 
        />
      }

      {/* disable modal */}
      { 
        activeModal === "disablePaymentModal" &&
        <AlertModal 
          index="alert-key"
          showModal={true} 
          toggleModal={() => disablePayment('', 'disable')}
          headerText={`Disable This Payment?`}
          descriptionText={`Are you sure you want to disable this payment option? This will prevent your customers from making payment to you using this payment option.`}
          leftButtonText={`Disable`}
          leftButtonOnClick={() => {initPaymentUpdate(paymentType, 'disable')}} />
      }

      {/* loading modal */}
      { 
        activeModal === "loadingModal" &&
        <LoadingModal showModal={true} headerText={`Please wait...`} descriptionText={loadingMessage} />
      }

      {
        activeModal === "alertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }

      {/* OTP modal */}
      {
        activeModal === "OTPModal" &&
        <Modal backdrop="static" isOpen={true} id="otp-modal">
          <button className="close" onClick={() => toggleModal('OTPModal')}><em className="icon ni ni-cross"></em></button>
          <ModalBody className="modal-body-lg text-center">
            <div className="nk-modal">
              <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-more-h bg-light"></Icon>
              <h4 className="nk-modal-title fw-normal">OTP Verification</h4>
              <div className="nk-modal-text">
                <div className="caption-text mb-3">
                  {`An OTP email has been sent to: ${OTPEmail}.`}
                  <br/>
                  {OTPMessage}
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                      <label className="form-label">OTP <span className="text-danger">*</span></label>
                  </div>
                  <Input size="large" type="text" className="form-control form-control-lg" 
                    onChange={(e) => setPaymentOTP(e.target.value)} placeholder="eg. 000000" />
                  <p className="text-danger text-start mt-1">{errorMessage}</p>
                </div>
              </div>
              <div className="nk-modal-action mt-5">
                <Button disabled={requesting} color="dark" size="lg" className="btn-mw m-1" onClick={handleOTPSubmit}>
                  <span>Submit</span>
                </Button>

                <Button color="light" size="lg" className="btn-mw m-1" onClick={() => toggleModal('OTPModal')}>
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }

      {/* {
        activeModal === "configureKeepupPayModal" &&
        <Modal backdrop="static" isOpen={true} id="configure_keepuppay_modal">
          <ModalHeader
            toggle={() => toggleModal('configure')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModal()}>
                <Icon name="cross" />
              </button>
            }
          >
            Configure Keepup Pay
          </ModalHeader>
          <Form name="configure-keepupPay"
              form={form} className="is-alter"
              initialValues={keepupPayFormData} 
              onFinish={handleKeepupPaySubmit}
              onFieldsChange={(changedFields, allFields) => {
                
                const fieldsToWatch = ['payout_type', 'account_type', 'account_number'];        
                const hasChanges = changedFields.some(field =>
                  fieldsToWatch.some(watchedField => field.name[0] === watchedField)
                );

                if(hasChanges && accountVerification) {setAccountVerification(false);}

              }}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Payout Type <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'payout_type'}
                          noStyle
                          rules={[
                            {required: true, message: `Payout type is required`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select payout type"
                            style={{ width: "100%" }} 
                            onChange={(value) => {updatePaymentForm(value);}}
                            options={[
                              {
                                key: 'mobile_money',
                                label: 'Mobile Money',
                                value: 'mobile_money',
                              },
                              {
                                key: 'bank',
                                label: 'Bank',
                                value: 'bank',
                              },                              
                            ]}
                            showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                          <label className="form-label">Account Type <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'account_type'}
                        noStyle
                        rules={[
                          {required: true, message: `Account type is required`}
                        ]}>
                        <Select required size="large" 
                          placeholder="Select account type"
                          style={{ width: "100%" }} 
                          options={payoutAccounts
                            .filter(account => selectedPayoutType ? account.type === selectedPayoutType : true)
                            .map(account => (
                              {
                                key: account.id,
                                label: account.name,
                                value: account.code,
                              }
                            ))}
                          filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }  
                          showSearch />   
                      </Form.Item>    
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Account Number <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'account_number'}
                        noStyle
                        rules={[
                          {required: true, message: `Account number is required`}
                        ]}>
                          {
                            selectedPayoutType === 'mobile_money' ?
                            <Input maxLength={10} minLength={10} size="large" type="text" placeholder="eg. 0241234567" style={{ width: "100%" }}  />
                            :
                            <Input size="large" type="text" className="form-control form-control-lg" placeholder="eg. 30123445567889" style={{ width: "100%" }}  />
                          }                        
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
                {
                  accountVerification &&
                  <>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Account Number <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'account_name'}
                            noStyle
                            rules={[
                              {required: true, message: `Account name is required`}
                            ]}>
                              <Input disabled size="large" type="text" className="form-control form-control-lg" style={{ width: "100%" }}  />                      
                          </Form.Item> 
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Transaction Fee Coverage <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                            <Form.Item name={'fee_coverage'}
                              noStyle
                              rules={[
                                {required: true, message: `Transaction fee coverage is required`}
                              ]}>
                              <Select required size="large" 
                                placeholder="Select payout type"
                                style={{ width: "100%" }} 
                                onChange={(value) => {updatePaymentForm(value);}}
                                options={[
                                  {
                                    key: 'business',
                                    label: 'Business',
                                    value: 'business',
                                  },
                                  {
                                    key: 'customer',
                                    label: 'Customer',
                                    value: 'customer',
                                  },                              
                                ]}
                                showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </>
                }
              </div>          
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              {
                accountVerification ?
                <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                  {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
                :
                <Button disabled={requesting} type="button" color="success" size="md" className="btn-mw m-1" onClick={verifyPayoutAccount}>
                  {requesting ? <Spinner size="sm" color="light" /> : "Verify Payout Account"}
                </Button>
              }
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal()}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      } */}

      {
        activeModal === "configureThirdPartyModal" &&
        <Modal backdrop="static" isOpen={true} id="configure_thirdParty_modal">
          <ModalHeader
            toggle={() => toggleModal('configure')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModal()}>
                <Icon name="cross" />
              </button>
            }
          >
            Configure {getPaymentTypeName(paymentType)}
          </ModalHeader>
          <Form name="config-thirdParty"
              form={form} className="is-alter"
              initialValues={thirdPartyPaymentFormData} 
              onFinish={handleThirdPartyPaymentSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Secret Key <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'secret_api_key'}
                        noStyle
                        rules={[
                          {required: true, message: `Secret Key is required`}
                        ]}>
                          <Input size="large" type="text" className="form-control form-control-lg" style={{ width: "100%" }}  />                     
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        {
                          paymentType === 'stripe' ?
                          <label className="form-label">Publishable Key <span className="text-danger">*</span></label>
                          :
                          <label className="form-label">Public Key <span className="text-danger">*</span></label>
                        }
                      </div>
                      <Form.Item name={'public_api_key'}
                        noStyle
                        rules={[
                          {required: true, message: `Public Key is required`}
                        ]}>
                          <Input size="large" type="text" className="form-control form-control-lg" style={{ width: "100%" }}  />                     
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Transaction Fee Coverage <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'fee_coverage'}
                          noStyle
                          rules={[
                            {required: true, message: `Transaction fee coverage is required`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select who covers the transaction fee"
                            style={{ width: "100%" }} 
                            onChange={(value) => {updatePaymentForm(value);}}
                            options={[
                              {
                                key: 'business',
                                label: 'Business',
                                value: 'business',
                              },
                              {
                                key: 'customer',
                                label: 'Customer',
                                value: 'customer',
                              },                              
                            ]}
                            showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                {
                  form.getFieldValue("fee_coverage") === 'customer' &&
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Transaction Fee <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'fee'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <InputNumber addonAfter={transactionFeeTypeOptions} min={0} step={0.01}  size="large" className="w-100" placeholder="eg. 2" />
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div>
                }
              </div>          
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal(null)}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }

      {
        activeModal === "configureHubtelModal" &&
        <Modal backdrop="static" isOpen={true} id="configureHubtelModal">
          <ModalHeader
            toggle={() => toggleModal('configure')}
            close={
              <button className="close" disabled={requesting} onClick={() => toggleModal()}>
                <Icon name="cross" />
              </button>
            }
          >
            Configure {getPaymentTypeName(paymentType)}
          </ModalHeader>
          <Form name="config-thirdParty"
              form={form} className="is-alter"
              initialValues={hubtelPaymentFormData} 
              onFinish={handleHubtelPaymentSubmit}
              onFinishFailed={onFinishFailed}>
            <ModalBody>
              {errorMessage && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorMessage}{" "}
                  </Alert>
                </div>
              )}
              <div className="row">
              <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Merchant Code <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'merchant_code'}
                        noStyle
                        rules={[
                          {required: true, message: `Merchant code is required`}
                        ]}>
                          <Input size="large" type="text" className="form-control form-control-lg" style={{ width: "100%" }}  />                     
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">API ID <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'api_id'}
                        noStyle
                        rules={[
                          {required: true, message: `API ID is required`}
                        ]}>
                          <Input size="large" type="text" className="form-control form-control-lg" style={{ width: "100%" }}  />                     
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">API Key <span className="text-danger">*</span></label>
                      </div>
                      <Form.Item name={'api_key'}
                        noStyle
                        rules={[
                          {required: true, message: `API Key is required`}
                        ]}>
                          <Input size="large" type="text" className="form-control form-control-lg" style={{ width: "100%" }}  />                     
                      </Form.Item> 
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Form.Item>
                      <div className="form-label-group">
                        <label className="form-label">Transaction Fee Coverage <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <Form.Item name={'fee_coverage'}
                          noStyle
                          rules={[
                            {required: true, message: `Transaction fee coverage is required`}
                          ]}>
                          <Select required size="large" 
                            placeholder="Select who covers the transaction fee"
                            style={{ width: "100%" }} 
                            onChange={(value) => {updatePaymentForm(value);}}
                            options={[
                              {
                                key: 'business',
                                label: 'Business',
                                value: 'business',
                              },
                              {
                                key: 'customer',
                                label: 'Customer',
                                value: 'customer',
                              },                              
                            ]}
                            showSearch />   
                        </Form.Item>                       
                      </div>
                    </Form.Item>
                  </div>
                </div>
                {
                  form.getFieldValue("fee_coverage") === 'customer' &&
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Transaction Fee <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'fee'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <InputNumber addonAfter={transactionFeeTypeOptions} min={0} step={0.01}  size="large" className="w-100" placeholder="eg. 2" />
                        </Form.Item> 
                      </Form.Item>
                    </div>
                  </div>
                }
              </div>          
            </ModalBody>
            <ModalFooter className="bg-lighter justify-content-center">
              <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
                {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
              <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={() => toggleModal(null)}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </React.Fragment>
  )
}