import React, { useState, useEffect } from "react";
import Icon from "../../components/icon/Icon";
import axios from 'axios';
import { baseURLs, units } from '../../utils/Constants';
import { getAxiosHeaders } from "../../utils/Utils";
import {
  Alert,
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import { Empty, Input, Select, InputNumber, Divider, Form } from 'antd';
const { TextArea } = Input;

export const CreateNewMaterialModal = ({ businessID, currency, showModal, toggleModal, updateMaterialList, ...props }) => {
  const [form] = Form.useForm();
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/raw-materials/add`, {
      business_id: businessID,
      material_name: _formData.material_name,
      description: _formData.description,
      unit_price: _formData.unit_price,
      unit: _formData.unit,
      quantity: 0,
      restock_level: 1
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      setRequesting(false);
      updateMaterialList(_formData.material_name);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'material_name',
              errors: errorResponse.errors.hasOwnProperty("material_name") ? [errorResponse.errors.material_name] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'unit_price',
              errors: errorResponse.errors.hasOwnProperty("unit_price") ? [errorResponse.errors.unit_price] : [],
            },
            {
              name: 'unit',
              errors: errorResponse.errors.hasOwnProperty("unit") ? [errorResponse.errors.unit] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  

  const groupedOptions = units.reduce((acc, option) => {
    if (!acc[option.category]) {
      acc[option.category] = [];
    }
    acc[option.category].push(option);
    return acc;
  }, {});

  const selectOptions = Object.keys(groupedOptions).map(category => ({
    label: category,
    options: groupedOptions[category].map(unit => ({
      value: unit.abbreviation,
      label: `${unit.singular} (${unit.abbreviation})`,
    })),
  }));

  return (
    <Modal backdrop="static" isOpen={showModal} id="create_material_modal">
      <ModalHeader
        toggle={toggleModal}
        close={
          <button className="close" disabled={requesting} onClick={toggleModal}>
            <Icon name="cross" />
          </button>
        }
      >
        Add a New Raw Material
      </ModalHeader>
      <Form name="add-raw-material"
          form={form} className="is-alter"
          initialValues={{
            material_name: '',
            description: '',
            unit_price: 0,
            unit: '',
          }} 
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}>
        <ModalBody>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
          
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Raw Material Name <span className="text-danger">*</span></label>
                  </div>
                  <Form.Item name={'material_name'}
                    noStyle
                    rules={[
                      {required: true, message: 'Raw material name is required',}
                    ]}>
                    <Input size="large"
                      type="text" className="form-control form-control-lg" 
                      placeholder="Awesome Material"
                    />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                      <label className="form-label">Description <span className="text-muted">(optional)</span></label>
                  </div>
                  <Form.Item name={'description'}
                    noStyle
                    rules={[
                      {required: false}
                    ]}>
                    <TextArea rows={4} placeholder="enter a description of the product" />
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Unit Price <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'unit_price'}
                      noStyle
                      rules={[
                        {required: true, message: 'Unit price is required'}
                      ]}>
                      <InputNumber prefix={currency} min={0.00} step={0.0001} size="large" className="w-100" /> 
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label">Unit <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'unit'}
                      noStyle
                      rules={[
                        {required: true, message: 'unit is required'}
                      ]}>
                      <Select size="large" 
                        placeholder="Select a material unit"
                        style={{ width: "100%" }}
                        options={selectOptions}
                        filterOption={(inputValue, option) =>
                          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        showSearch
                        allowClear
                      />
                    </Form.Item> 
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-lighter justify-content-center">
          <Button disabled={requesting} type="submit" color="primary" size="md" className="btn-mw m-1">
            {requesting ? <Spinner size="sm" color="light" /> : "Submit"}
          </Button>
          <Button disabled={requesting} color="light" size="md" className="btn-mw m-1" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}