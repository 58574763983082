import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getAxiosUploadHeaders, getPhoneCodeAndNumber, formatTo2DP, moneyToFloat } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import Numeral from 'react-numeral';
import DatePicker from "react-datepicker";
import { SuccessModal } from "./components/AlertModals";
import { AutoComplete, Input, Select, InputNumber, Empty, Divider, Space, Spin, Form, Upload } from 'antd';
import PhoneNumberInput from "./components/PhoneNumberInput";
import { CreateNewProductModal } from "./components/ProductModals";
import { CreateNewMaterialModal } from "./components/RawMaterialModal";

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

export const EditPurchaseOrder = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [fetchingMaterials, setFetchingMaterials] = useState(false);
  const [fetchingSuppliers, setFetchingSuppliers] = useState(false);
  const [suppliersList, setSuppliersList] = useState([]);
  const [issueDate, setIssueDate] = useState(new Date());
  const [issueDateTime, setIssueDateTime] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState(new Date());
  const [productsList, setProductsList] = useState([]);
  const [rawMaterialsList, setRawMaterialsList] = useState([]);
  const [form] = Form.useForm();
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [selectedMaterials, setSelectedMaterials] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const [itemsErrorMessage, setItemsErrorMessage] = useState("");
  const [showSupplierSection, setShowSupplierSection] = useState(false);
  const [itemFields, setItemFields] = useState([]);
  const [formData, setFormData] = useState({
    issue_date: new Date(),
    issue_date_time: new Date(),
    expected_delivery_date: new Date(),
    expected_delivery_date_time: new Date(),
    supplier_name: '',
    phone_code: "+233",
    phone_number: '',
    email: '',
    supplier_other_info: '',
    order_type: 'inventory',
    order_status: 'reviewing',
    supplier_other_info: '',
    items: [],
    delivery_amount: 0,
    discount_amount: 0,
    tax_amount: 0,
    amount_paid: 0,
    payment_type: 'cash',
    note: ''
  });
  const [summaryInfo, setSummaryInfo] = useState({
    sub_total: 0,
    tax_amount: 0,
    discount_amount: 0,
    total_amount: 0,
    amount_paid: 0,
    balance_due: 0
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");
    setItemsErrorMessage("");

    const formInfo = {};
    formInfo.business_id = businessID;
    formInfo.order_id = orderID;
    
    let _issueDate = `${moment(_formData.issue_date).format("YYYY-MM-DD")} ${moment(_formData.issue_date_time).format("HH:mm")}`;
    formInfo.issue_date = _issueDate;

    let _expectedDeliveryDate = `${moment(_formData.expected_delivery_date).format("YYYY-MM-DD")} ${moment(_formData.expected_delivery_date_time).format("HH:mm")}`;
    formInfo.expected_delivery_date = _expectedDeliveryDate;

    if(showSupplierSection) {
      let phoneNumber = '';
      if(_formData.phone_code && _formData.phone_number){
        phoneNumber = `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`;
      }

      formInfo.supplier_name = _formData.supplier_name;
      formInfo.phone_number = phoneNumber;
      formInfo.email = _formData.email;
      formInfo.supplier_other_info = _formData.supplier_other_info;
      formInfo.discount_amount = _formData.discount_amount;
      formInfo.tax_amount = _formData.tax_amount;
      formInfo.amount_paid = _formData.amount_paid;
      formInfo.payment_type = _formData.payment_type;
    }

    formInfo.items = JSON.stringify(_formData.items);
    formInfo.note = _formData.note;
    formInfo.delivery_amount = _formData.delivery_amount;
    formInfo.order_type = _formData.order_type;
    formInfo.order_status = _formData.order_status;

    axios.put(baseURLs.API_URL + `/purchase-orders/edit/${orderID}`, formInfo, getAxiosHeaders())
    .then((response) => {  
      // redirect to order details page
      let responseInfo = response.data;
      let orderID = responseInfo.data.order_id;
      history.push(`${process.env.PUBLIC_URL}/purchase-orders/b/${businessID}/details/${orderID}`);
      return;

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          errorResponse.errors.hasOwnProperty("items") ? setItemsErrorMessage(errorResponse.errors.items) : setItemsErrorMessage("");
          form.setFields([
            {
              name: 'issue_date',
              errors: errorResponse.errors.hasOwnProperty("issue_date") ? [errorResponse.errors.issue_date] : [],
            },
            {
              name: 'expected_delivery_date',
              errors: errorResponse.errors.hasOwnProperty("expected_delivery_date") ? [errorResponse.errors.expected_delivery_date] : [],
            },
            {
              name: 'supplier_name',
              errors: errorResponse.errors.hasOwnProperty("supplier_name") ? [errorResponse.errors.supplier_name] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone_number] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            },
            {
              name: 'supplier_other_info',
              errors: errorResponse.errors.hasOwnProperty("supplier_other_info") ? [errorResponse.errors.supplier_other_info] : [],
            },
            {
              name: 'items',
              errors: errorResponse.errors.hasOwnProperty("items") ? [errorResponse.errors.items] : [],
            },
            {
              name: 'discount_amount',
              errors: errorResponse.errors.hasOwnProperty("discount_amount") ? [errorResponse.errors.discount_amount] : [],
            },
            {
              name: 'delivery_amount',
              errors: errorResponse.errors.hasOwnProperty("delivery_amount") ? [errorResponse.errors.delivery_amount] : [],
            },
            {
              name: 'tax_amount',
              errors: errorResponse.errors.hasOwnProperty("tax_amount") ? [errorResponse.errors.tax_amount] : [],
            },
            {
              name: 'note',
              errors: errorResponse.errors.hasOwnProperty("note") ? [errorResponse.errors.note] : [],
            },
            {
              name: 'payment_type',
              errors: errorResponse.errors.hasOwnProperty("payment_type") ? [errorResponse.errors.payment_type] : [],
            },
            {
              name: 'amount_paid',
              errors: errorResponse.errors.hasOwnProperty("amount_paid") ? [errorResponse.errors.amount_paid] : [],
            },
            {
              name: 'order_type',
              errors: errorResponse.errors.hasOwnProperty("order_type") ? [errorResponse.errors.order_type] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setErrorMessage(errorMessage);        
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      } finally {
        setRequesting(false);
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const updateProductList = (productName) => {
    toggleModal('successProductModal');
    getProducts({businessID, productName});
  }

  const updateMaterialList = (MaterialName) => {
    toggleModal('successMaterialModal');
    getRawMaterials({businessID, MaterialName});
  }

  const handleOrderTypeChange = () => {
    const newItemFields = [{
      item_name: '',
      quantity: '1',
      unit_price: '',
      update_cost_price: false,
      unit: 'pcs'
    }];
    setItemFields(newItemFields);

    form.setFieldsValue({
      items: newItemFields,
    });

    updateSummary();
  }

  const getProducts = ({businessID, productName}) => {

    return axios.get(baseURLs.API_URL + "/products/variants", {
      params: {
        business_id: businessID,
        product_name: productName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let products = responseInfo.data.products;

        setProductsList([...products]);
      }

      if(fetchingProducts){
        setFetchingProducts(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingProducts){
          setFetchingProducts(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getRawMaterials = ({businessID, materialName}) => {

    return axios.get(baseURLs.API_URL + "/raw-materials", {
      params: {
        business_id: businessID,
        material_name: materialName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let materials = responseInfo.data.materials;

        setRawMaterialsList([...materials]);
      }

      if(fetchingMaterials){
        setFetchingMaterials(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingMaterials){
          setFetchingMaterials(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getSuppliers = ({businessID, supplierName}) => {

    return axios.get(baseURLs.API_URL + "/suppliers", {
      params: {
        business_id: businessID,
        supplier: supplierName
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let suppliers = responseInfo.data.suppliers;

        setSuppliersList([...suppliers]);
      }

      if(fetchingSuppliers){
        setFetchingSuppliers(false);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        if(fetchingSuppliers){
          setFetchingSuppliers(false);
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleChangedFields = (changedFields, allFields) => {
    const fieldsToWatch = ['items', 'delivery_amount', 'discount_amount', 'tax_amount', 'amount_paid'];
          
    const shouldRecalculate = changedFields.some(field =>
      fieldsToWatch.some(watchedField => field.name[0] === watchedField)
    );

    if (shouldRecalculate) {
      updateSummary();
    }    
  }

  const updateSummary = () => {
    const items = form.getFieldValue('items') || [];
    let taxAmount = formatTo2DP(form.getFieldValue('tax_amount') || 0);
    let discountAmount = formatTo2DP(form.getFieldValue('discount_amount') || 0);
    let deliveryAmount = formatTo2DP(form.getFieldValue('delivery_amount') || 0);
    let amountPaid = formatTo2DP(form.getFieldValue('amount_paid') || 0);

    // Calculate Subtotal
    let subTotal = items.reduce((total, item) => {
      const price = item.unit_price || 0;
      const quantity = item.quantity || 0;
      return total + (price * quantity);
    }, 0) + deliveryAmount;

    // Calculate Total Amount including Taxes and Discounts
    let totalAmount = formatTo2DP((subTotal + taxAmount) - discountAmount);

    // Calculate balance due
    let balanceDue = formatTo2DP(totalAmount - amountPaid);

    setSummaryInfo({
      ...summaryInfo,
      sub_total: subTotal,
      tax_amount: taxAmount,
      discount_amount: discountAmount,
      total_amount: totalAmount,
      amount_paid: amountPaid,
      balance_due: balanceDue
    })
  }

  const debouncedFetchProducts = debounce(getProducts, 1000);
  const debouncedFetchMaterials = debounce(getRawMaterials, 1000);
  const debouncedFetchSuppliers = debounce(getSuppliers, 1000);
  const debouncedHandleChangedFields = debounce(handleChangedFields, 1000);

  const handleSupplierNameChange = (supplierName) => {
    // Fetch more suppliers when the user enters a supplier name not found in the initial list
    if (!suppliersList.some(supplier => supplier.supplier_name === supplierName)) {
      debouncedFetchSuppliers({businessID, supplierName});
      setFetchingSuppliers(true);
    }
  };

  const handleProductChange = (productName) => {
    // Fetch more products when the user enters a product name not found in the initial list
    if (!productsList.some(product => product.product_name === productName)) {
      debouncedFetchProducts({businessID, productName});
      setFetchingProducts(true);
    }
  };

  const handleProductSelect = (value, option, index) => {
    const selectedProduct = productsList.find(product => product.product_name === value);
    
    if (selectedProduct) {
      let { product_name } = selectedProduct;

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_name: product_name,
        unit_price: 0,
        quantity: 1
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleMaterialChange = (materialName) => {
    // Fetch more material when the user enters a material name not found in the initial list
    if (!rawMaterialsList.some(material => material.material_name === materialName)) {
      debouncedFetchMaterials({businessID, materialName});
      setFetchingMaterials(true);
    }
  };

  const handleMaterialSelect = (value, option, index) => {
    const selectedMaterial = rawMaterialsList.find(material => material.material_name === value);
    
    if (selectedMaterial) {
      let { material_name, unit } = selectedMaterial;

      // Assuming you have a function to set the form data or state
      const updatedItemFields = form.getFieldValue("items");
      updatedItemFields[index] = {
        ...updatedItemFields[index],
        item_name: material_name,
        unit_price: 0,
        quantity: 1,
        unit: unit ?? 'pcs'
      };
      setItemFields(updatedItemFields);
      
      // Update the formData state with the updated items array
      setFormData({
        ...formData,
        items: updatedItemFields,
      });
      
      form.setFieldsValue({
        items: updatedItemFields,
      });
    }
  };

  const handleSupplierSelect = (value, option) => {
    const selected = suppliersList.find(supplier => supplier.supplier_name === value);
    
    if (selected) {
      const { phone_number, email, other_info } = selected;
      let phoneCode = formData.phone_code;
      let phoneNumber = '';

      if(phone_number) {
        let phoneNumberFormatted = getPhoneCodeAndNumber(phone_number);
        phoneCode = phoneNumberFormatted.phoneCode;
        phoneNumber = phoneNumberFormatted.phoneNumber;
        form.setFieldValue("phone_code", phoneCode);
        form.setFieldValue("phone_number", phoneNumber);

      }
      form.setFieldValue("email", email || '');
      form.setFieldValue("supplier_other_info", other_info || '');
      
      setFormData({
        ...formData,
        phone_code: phoneCode,
        phone_number: phoneNumber,
        email: email || '', 
        supplier_other_info: other_info || '', 
      });
    }
  };

  const handleAddItem = () => {
    const oldItemFields = form.getFieldValue("items");
    const newItemFields = [...oldItemFields, {
      item_name: '',
      quantity: '',
      unit_price: '',
      update_cost_price: false
    }];

    // Update itemFields state
    setItemFields(newItemFields);

    // Update form fields
    form.setFieldsValue({
      items: newItemFields,
    });
  };

  const handleRemoveItem = (index) => {
    const oldItemFields = form.getFieldValue("items");
    const newFields = [...oldItemFields];
    newFields.splice(index, 1);
    setItemFields(newFields);

    form.setFieldsValue({
      items: newFields,
    });

    updateSummary();
  };

  const getPurchaseOrderDetails = (businessID, orderID) => {
    return axios.get(baseURLs.API_URL + `/purchase-orders/${orderID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      } else {
        let responseInfo = response.data;
        let orderInfo = responseInfo.data.order_info;
        let _formData = formData;
  
        _formData.order_type = orderInfo.order_type;
        _formData.expected_delivery_date = moment(orderInfo.expected_delivery_date);
        _formData.issue_date = moment(orderInfo.issue_date);
        
        if(orderInfo.has_supplier === 'yes') {
          let phoneCode = formData.phone_code;
          let phoneNumber = '';

          if(orderInfo.supplier_info.phone_number) {
            let phoneNumberFormatted = getPhoneCodeAndNumber(orderInfo.supplier_info.phone_number);
            phoneCode = phoneNumberFormatted.phoneCode;
            phoneNumber = phoneNumberFormatted.phoneNumber;
          }

          _formData.supplier_name = orderInfo.supplier_info.supplier_name;
          _formData.phone_code = phoneCode;
          _formData.phone_number = phoneNumber;
          _formData.email = orderInfo.supplier_info.email ?? '';
          _formData.supplier_other_info = orderInfo.supplier_info.other_info ?? '';
        }

        let items = [];
        for(let i = 0; i < orderInfo.items.length; i++) {
          items.push({
            item_name: orderInfo.items[i].item_name,
            quantity: orderInfo.items[i].quantity,
            unit_price: moneyToFloat(orderInfo.items[i].unit_price),
            update_cost_price: orderInfo.items[i].update_cost_price === "yes" ? true : false,
          })
        }
        _formData.items = items;

        _formData.order_status = orderInfo.order_status;
        _formData.discount_amount = moneyToFloat(orderInfo.discount_amount);
        _formData.delivery_amount = moneyToFloat(orderInfo.delivery_amount);
        _formData.tax_amount = moneyToFloat(orderInfo.tax_amount);
        _formData.amount_paid = moneyToFloat(orderInfo.amount_paid);
        _formData.note = orderInfo.note;
        
        setItemFields(items);
        setShowSupplierSection(orderInfo.has_supplier === 'yes' ? true : false)
        setIssueDate(moment(orderInfo.issue_date).toDate());
        setIssueDateTime(moment(orderInfo.issue_date).toDate());
        setDeliveryDate(moment(orderInfo.expected_delivery_date).toDate());
        setDeliveryDateTime(moment(orderInfo.expected_delivery_date).toDate());
        setFormData({..._formData});
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    const { productNames, materialNames } = itemFields.reduce(
      (acc, item) => {
        if (item.item_name) {
          const targetSet = form.getFieldValue('order_type') === 'inventory' ? acc.productNames : acc.materialNames;
          targetSet.add(item.item_name);
        }
        return acc;
      },
      {
        productNames: new Set(),
        materialNames: new Set(),
      }
    );
  
    setSelectedProducts(productNames);
    setSelectedMaterials(materialNames);
  }, [itemFields]);

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    const orderID = match.params.orderID;
    if ( !Number.isInteger(businessID) || !orderID) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setOrderID(orderID);

    Promise.all([
      getPurchaseOrderDetails(businessID, orderID),
      getProducts({businessID: businessID}),
      getRawMaterials({businessID: businessID}),
      getSuppliers({businessID: businessID}),
    ]).finally(() => {
      setLoading(false);
      updateSummary();
    });
    
  }, [match.params.businessID]);


  return (<React.Fragment>
    <Head title="Edit Purchase Order" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Edit Purchase Order
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/purchase-orders/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block className="wide-sm m-auto mt-5">
          {errorMessage && (
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorMessage}{" "}
            </Alert>
          )}
          <Form name="create-order"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFieldsChange={(changedFields, allFields) => {
              debouncedHandleChangedFields(changedFields, allFields);       
            }}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              {/* Order details section */}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="contact fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Order Information</h5>
                  <p className="nk-block-des">Enter the details of the purchase order.</p>
                </div>
              </div>
              <div className="fm-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Order Type <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <Form.Item name={'order_type'}
                            noStyle
                            rules={[
                              {required: true, message: `Select order type`}
                            ]}>
                            <Select required size="large" 
                              placeholder="Select Inventory or Raw Materials"
                              style={{ width: "100%" }} 
                              onChange={(value) => {handleOrderTypeChange()}}
                              options={[
                                {
                                  key: 'inventory',
                                  label: 'Inventory',
                                  value: 'inventory',
                                },
                                {
                                  key: 'raw_materials',
                                  label: 'Raw Materials',
                                  value: 'raw_materials',
                                },                              
                              ]}
                              showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Issue Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item name={'issue_date'}
                              noStyle
                              rules={[
                                { required: true, message: 'Issue Date is required', }
                              ]}>
                              <DatePicker
                                selected={issueDate}
                                dateFormat="dd-MMM-yyyy"
                                onChange={date => {
                                  setIssueDate(date);
                                  form.setFieldsValue({issue_date: date}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showMonthDropdown
                                showYearDropdown
                                isClearable
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>                      
                          </div>
                          <div className="col-5">
                            <Form.Item name={'issue_date_time'}
                              noStyle
                              rules={[
                                {required: true, message: 'Issue Date time is required',}
                              ]}>
                              <DatePicker
                                selected={issueDateTime}
                                dateFormat="h:mm aa"
                                onChange={time => {
                                  setIssueDateTime(time);
                                  form.setFieldsValue({issue_date_time: time}); // Update Ant Design Form
                                }}
                                shouldCloseOnSelect={false}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                className="form-control form-control-lg date-picker"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              {/* supplier details section */}
              <div className="fm-head bg-lighter justify-content-between">
                <div className="d-flex">
                  <div className="fm-count"><Icon name="user-list fs-22px"></Icon></div>
                  <div className="fm-title">
                    <h5 className="title fw-normal">Supplier Details</h5>
                    <p className="nk-block-des">Enter the details of the supplier your bought from.</p>
                  </div>
                </div>
                <button type="button" 
                  onClick={() => {setShowSupplierSection(!showSupplierSection)}}
                  className={`btn btn-sm btn-outline-${showSupplierSection ? `danger` : `primary`} m-1`}>
                    <span>{`${showSupplierSection ? `Remove` : `Add`}`}</span>
                  </button>
              </div>
              {
                showSupplierSection &&
                <div className="fm-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Supplier Name <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'supplier_name'}
                            noStyle
                            rules={[
                              {required: true,}
                            ]}>
                            <AutoComplete
                              size="large"
                              options={suppliersList.map(supplier => (
                                {
                                  key: supplier.supplier_id,
                                  label: `${supplier.supplier_name}`,
                                  value: `${supplier.supplier_name}`,
                                }
                              ))}
                              filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                              notFoundContent={
                                fetchingSuppliers ? 
                                <div className="text-center m-5">
                                  <Spin/>
                                </div> : null}
                              onSelect={handleSupplierSelect}
                              onChange={handleSupplierNameChange}
                              placeholder="Enter supplier name"
                              className="w-100"
                            />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Phone Number <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                          <PhoneNumberInput form={form} formData={formData} isRequired={false} setFormData={setFormData} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Email <span className="text-muted">(optional)</span></label>
                          </div>
                          <Form.Item name={'email'}
                            noStyle
                            rules={[
                              {required: false}, {type: 'email', message: 'Email is invalid'}
                            ]}>
                            <Input size="large" type="email" className="form-control form-control-lg" placeholder="supplier@email.com" />
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Other Information <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'supplier_other_info'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                              <TextArea rows={4} placeholder="Other supplier information" />
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/* products and services section*/}
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="list fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Order Items</h5>
                  <p className="nk-block-des">Add the items you are purchasing.</p>
                </div>
              </div>
              <div className="fm-content">
                {
                  itemsErrorMessage &&
                  <p className="text-danger">{itemsErrorMessage}</p>
                }
                <div className="row">
                <div className="col-md-12">
                  <div className="card-bordered card-full card">
                    {
                      itemFields.length === 0 ?
                      <div className="nk-block">
                        <div className="text-center m-5">
                          <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                          <div className="price-plan-info">
                            <span className="title fw-normal">No item added</span>
                          </div>
                          <div className="price-plan-action">
                            <button className="btn btn-outline-light btn-sm m-1" onClick={() => handleAddItem()}>
                              <Icon name="plus-sm" />
                              <span>Add Item</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      :
                      <ul className="nk-support">
                        {
                          itemFields.map((item, index) => {
                            let itemField = form.getFieldValue('order_type') === 'inventory' ?
                            <>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label"><b>#{index + 1}.</b> Product Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'item_name']}
                                      rules={[{ required: true, message: 'Please enter a product name' }]}>
                                      <Select
                                        size="large"
                                        onChange={(value) => handleProductChange(value, index)}
                                        dropdownRender={(menu) => (
                                          <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0', }} />
                                            <Space style={{ padding: '0 8px 4px', }}>
                                              <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewProductModal')}}>
                                                <Icon name="plus" />
                                                <span>Create New Product</span>
                                              </Button>
                                            </Space>
                                          </>
                                        )}
                                        options={
                                          productsList.map(prod => {
                                            // Check if the product is selected in another field
                                            const isAlreadySelected = selectedProducts.has(prod.product_name) && prod.product_name !== itemFields[index].item_name;
                                            
                                            return {
                                              key: prod.product_id,
                                              label: isAlreadySelected ? `${prod.product_name} - (Selected)` : `${prod.product_name}`,
                                              value: prod.product_name,
                                              disabled: isAlreadySelected, // Disable the option if it's already selected
                                            };
                                          })
                                        }
                                        onSelect={(value, option) => handleProductSelect(value, option, index)}
                                        onSearch={handleProductChange}
                                        showSearch={true}
                                        notFoundContent={
                                          fetchingProducts ? 
                                          <div className="text-center m-5">
                                            <Spin/>
                                          </div>
                                          :
                                          <div className="text-center m-5">
                                            <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                          </div>
                                        }
                                        placeholder="Enter product name"
                                        className="w-100"
                                      />
                                    </Form.Item>
                                  </div>                                        
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                        <label className="form-label">Unit Price <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['items', index, 'unit_price']}
                                        noStyle
                                        rules={[
                                          {required: true, message: 'Unit Price is required'}
                                        ]}>
                                        <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                      </Form.Item> 
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                        <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['items', index, 'quantity']}
                                        noStyle
                                        rules={[
                                          {required: true, message: 'Quantity is required'}
                                        ]}>
                                        <InputNumber min={1} step={1} size="large" className="w-100" /> 
                                      </Form.Item> 
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mt-lg-4">
                                    <Form.Item>
                                      <Form.Item name={['items', index, 'update_cost_price']}
                                        noStyle
                                        valuePropName="checked"
                                        rules={[
                                          {required: false}
                                        ]}>
                                        <div className="form-group">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              checked={form.getFieldValue(['items', index, 'update_cost_price'])}
                                              id={`update_cost_price_${index}`} />
                                            <label className="custom-control-label" htmlFor={`update_cost_price_${index}`}>
                                              Update Cost Price
                                              <Button type="button" id={`update_cost_price_${index}_info_popover`} className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                                <Icon name="info" />
                                              </Button>
                                              <UncontrolledPopover target={`update_cost_price_${index}_info_popover`} placement="bottom" trigger="focus">
                                                <PopoverBody>
                                                  <p>Check this box if you want to recalculate and update the cost price in the inventory based on the provided values for this item in the purchase order.</p>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </label>
                                          </div>
                                        </div>
                                      </Form.Item> 
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <>
                              <button className="btn btn-sm text-danger remove-button" type="button" onClick={() => handleRemoveItem(index)}><Icon name="trash"/><span>Remove</span></button>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label"><b>#{index + 1}.</b> Raw Material Name <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'item_name']}
                                      rules={[{ required: true, message: 'Please enter a raw material name' }]}>
                                      <Select
                                        size="large"
                                        onChange={(value) => handleMaterialChange(value, index)}
                                        dropdownRender={(menu) => (
                                          <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0', }} />
                                            <Space style={{ padding: '0 8px 4px', }}>
                                              <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createNewMaterialModal')}}>
                                                <Icon name="plus" />
                                                <span>Create New Raw Material</span>
                                              </Button>
                                            </Space>
                                          </>
                                        )}
                                        options={
                                          rawMaterialsList.map(material => {
                                            // Check if the product is selected in another field
                                            const isAlreadySelected = selectedMaterials.has(material.material_name) && material.material_name !== itemFields[index].item_name;
                                            
                                            return {
                                              key: material.material_id,
                                              label: isAlreadySelected ? `${material.material_name} - (Selected)` : `${material.material_name}`,
                                              value: material.material_name,
                                              disabled: isAlreadySelected, // Disable the option if it's already selected
                                            };
                                          })
                                        }
                                        onSelect={(value, option) => handleMaterialSelect(value, option, index)}
                                        onSearch={handleMaterialChange}
                                        showSearch={true}
                                        notFoundContent={
                                          fetchingMaterials ? 
                                          <div className="text-center m-5">
                                            <Spin/>
                                          </div>
                                          :
                                          <div className="text-center m-5">
                                            <div className="price-plan-media"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                                          </div>
                                        }
                                        placeholder="Enter raw material name"
                                        className="w-100"
                                      />
                                    </Form.Item>
                                  </div>                                        
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <Form.Item>
                                      <div className="form-label-group">
                                        <label className="form-label">Unit Price <span className="text-danger">*</span></label>
                                      </div>
                                      <Form.Item name={['items', index, 'unit_price']}
                                        noStyle
                                        rules={[
                                          {required: true, message: 'Unit Price is required'}
                                        ]}>
                                        <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                                      </Form.Item> 
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                  <Form.Item>
                                    <div className="form-label-group">
                                      <label className="form-label">Quantity <span className="text-danger">*</span></label>
                                    </div>
                                    <Form.Item name={['items', index, 'quantity']}
                                      noStyle
                                      rules={[
                                        {required: true, message: 'Quantity is required'}
                                      ]}>
                                      <InputNumber suffix={item.unit} size="large" className="w-100" /> 
                                    </Form.Item> 
                                  </Form.Item>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mt-lg-4">
                                    <Form.Item>
                                      <Form.Item name={['items', index, 'update_cost_price']}
                                        noStyle
                                        valuePropName="checked"
                                        rules={[
                                          {required: false}
                                        ]}>
                                        <div className="form-group">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              checked={form.getFieldValue(['items', index, 'update_cost_price'])}
                                              id={`update_cost_price_${index}`} />
                                            <label className="custom-control-label" htmlFor={`update_cost_price_${index}`}>
                                              Update Cost Price
                                              <Button type="button" id={`update_cost_price_${index}_info_popover`} className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                                <Icon name="info" />
                                              </Button>
                                              <UncontrolledPopover target={`update_cost_price_${index}_info_popover`} placement="bottom" trigger="focus">
                                                <PopoverBody>
                                                  <p>Check this box if you want to recalculate and update the cost price in the raw materials based on the provided values for this item in the purchase order.</p>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </label>
                                          </div>
                                        </div>
                                      </Form.Item> 
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </>
                            return (<li className="nk-support-item d-block" key={index}>{itemField}</li>);
                          })
                        }
                      </ul>                            
                    }
                  </div>
                    
                  </div>
                  {
                    itemFields.length > 0 && 
                    <div className="col-md-12 text-center">
                      <Button className="mt-4 m-1" type="button" outline={true} color="light" size="md" onClick={() => handleAddItem()} >
                        <Icon name="plus-sm"></Icon>
                        <span>Add Item</span>
                      </Button>
                    </div>
                  }
                </div>
              </div>

              {
                showSupplierSection ?
                <>
                  {/* discount and tax section*/}
                  <div className="fm-head bg-lighter">
                    <div className="fm-count"><Icon name="percent fs-22px"></Icon></div>
                    <div className="fm-title">
                      <h5 className="title fw-normal">Delivery, Discount, Order Status &amp; Tax</h5>
                      <p className="nk-block-des">Add delivery, discount, order status and tax to this purchase order.</p>
                    </div>
                  </div>
                  <div className="fm-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Order Status <span className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap">
                              <Form.Item name={'order_status'}
                                noStyle
                                rules={[
                                  {required: true, message: `Select order status`}
                                ]}>
                                <Select required size="large" 
                                  placeholder="Select order status"
                                  style={{ width: "100%" }} 
                                  options={[
                                    {
                                      key: 'reviewing',
                                      label: 'Reviewing',
                                      value: 'reviewing',
                                    },
                                    {
                                      key: 'pending_delivery',
                                      label: 'Pending Delivery',
                                      value: 'pending_delivery',
                                    },
                                    {
                                      key: 'delivered',
                                      label: 'Delivered',
                                      value: 'delivered',
                                    },                              
                                  ]}
                                  showSearch />   
                              </Form.Item>                       
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Delivery Amount <span className="text-muted">(optional)</span></label>
                            </div>
                            <Form.Item name={'delivery_amount'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Expected Delivery Date <span className="text-danger">*</span></label>
                            </div>
                            <div className="row g-2">
                              <div className="col-7">
                                <Form.Item name={'expected_delivery_date'}
                                  noStyle
                                  rules={[
                                    { required: true, message: 'Expected Date is required', }
                                  ]}>
                                  <DatePicker
                                    selected={deliveryDate}
                                    dateFormat="dd-MMM-yyyy"
                                    onChange={date => {
                                      setDeliveryDate(date);
                                      form.setFieldsValue({expected_delivery_date: date}); // Update Ant Design Form
                                    }}
                                    shouldCloseOnSelect={false}
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    className="form-control form-control-lg date-picker"
                                  />
                                </Form.Item>                      
                              </div>
                              <div className="col-5">
                                <Form.Item name={'expected_delivery_date_time'}
                                  noStyle
                                  rules={[
                                    {required: true, message: 'Expected Delivery Date time is required',}
                                  ]}>
                                  <DatePicker
                                    selected={deliveryDateTime}
                                    dateFormat="h:mm aa"
                                    onChange={time => {
                                      setIssueDateTime(time);
                                      form.setFieldsValue({expected_delivery_date_time: time}); // Update Ant Design Form
                                    }}
                                    shouldCloseOnSelect={false}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    className="form-control form-control-lg date-picker"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Discount Amount <span className="text-muted">(optional)</span></label>
                            </div>
                            <Form.Item name={'discount_amount'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Tax Amount <span className="text-muted">(optional)</span></label>
                            </div>
                            <div className="form-control-wrap">
                              <Form.Item name={'tax_amount'}
                                noStyle
                                rules={[
                                  {required: false}
                                ]}>
                                <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" />    
                              </Form.Item>                       
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Note <span className="text-muted">(optional)</span></label>
                            </div>
                            <div className="form-control-wrap">
                            <Form.Item name={'note'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                                <TextArea rows={4} placeholder="Note" />
                              </Form.Item>                       
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* payment section*/}
                  <div className="fm-head bg-lighter">
                    <div className="fm-count"><Icon name="cc-alt2 fs-22px"></Icon></div>
                    <div className="fm-title">
                      <h5 className="title fw-normal">Payment</h5>
                      <p className="nk-block-des">Add payment to this other.</p>
                    </div>
                  </div>
                  <div className="fm-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Payment Type <span className="text-danger">*</span></label>
                            </div>
                            <Form.Item name={'payment_type'}
                              noStyle
                              rules={[
                                {required: true, message: `Select payment type`}
                              ]}>
                              <Select required size="large" 
                                placeholder="Select payment type"
                                style={{ width: "100%" }} 
                                options={[
                                  {
                                    key: 'cash',
                                    label: 'Cash',
                                    value: 'cash',
                                  },
                                  {
                                    key: 'check',
                                    label: 'Check',
                                    value: 'check',
                                  }, 
                                  {
                                    key: 'card payment',
                                    label: 'Card Payment',
                                    value: 'card payment',
                                  },
                                  {
                                    key: 'bank',
                                    label: 'Bank Transfer / Deposit',
                                    value: 'bank',
                                  }, 
                                  {
                                    key: 'mobile money',
                                    label: 'Mobile Money',
                                    value: 'mobile money',
                                  },
                                  {
                                    key: 'digital wallet',
                                    label: 'Digital Wallet',
                                    value: 'digital wallet',
                                  },                              
                                ]}
                                showSearch />  
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Amount Paid <span className="text-danger">*</span></label>
                            </div>
                            <Form.Item name={'amount_paid'}
                              noStyle
                              rules={[
                                {required: true, message: 'amount paid is required'}
                              ]}>
                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  {/* note and attachment section*/}
                  <div className="fm-head bg-lighter">
                    <div className="fm-count"><Icon name="note-add fs-22px"></Icon></div>
                    <div className="fm-title">
                      <h5 className="title fw-normal">Delivery, Order Status and Note</h5>
                      <p className="nk-block-des">Add delivery amount, order status and note for this purchase.</p>
                    </div>
                  </div>
                  <div className="fm-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Order Status <span className="text-danger">*</span></label>
                            </div>
                            <div className="form-control-wrap">
                              <Form.Item name={'order_status'}
                                noStyle
                                rules={[
                                  {required: true, message: `Select order status`}
                                ]}>
                                <Select required size="large" 
                                  placeholder="Select order status"
                                  style={{ width: "100%" }} 
                                  options={[
                                    {
                                      key: 'reviewing',
                                      label: 'Reviewing',
                                      value: 'reviewing',
                                    },
                                    {
                                      key: 'pending_approval',
                                      label: 'Pending Approval',
                                      value: 'pending_approval',
                                    },
                                    {
                                      key: 'approved',
                                      label: 'Approved',
                                      value: 'approved',
                                    },
                                    {
                                      key: 'pending_delivery',
                                      label: 'Pending Delivery',
                                      value: 'pending_delivery',
                                    },
                                    {
                                      key: 'delivered',
                                      label: 'Delivered',
                                      value: 'delivered',
                                    },                              
                                  ]}
                                  showSearch />   
                              </Form.Item>                       
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Delivery Amount <span className="text-muted">(optional)</span></label>
                            </div>
                            <Form.Item name={'delivery_amount'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                              <InputNumber prefix={businessInfo.currency} min={0} step={0.01} size="large" className="w-100" /> 
                            </Form.Item> 
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Expected Delivery Date <span className="text-danger">*</span></label>
                            </div>
                            <div className="row g-2">
                              <div className="col-7">
                                <Form.Item name={'expected_delivery_date'}
                                  noStyle
                                  rules={[
                                    { required: true, message: 'Expected Date is required', }
                                  ]}>
                                  <DatePicker
                                    selected={deliveryDate}
                                    dateFormat="dd-MMM-yyyy"
                                    onChange={date => {
                                      setDeliveryDate(date);
                                      form.setFieldsValue({expected_delivery_date: date}); // Update Ant Design Form
                                    }}
                                    shouldCloseOnSelect={false}
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    className="form-control form-control-lg date-picker"
                                  />
                                </Form.Item>                      
                              </div>
                              <div className="col-5">
                                <Form.Item name={'expected_delivery_date_time'}
                                  noStyle
                                  rules={[
                                    {required: true, message: 'Expected Delivery Date time is required',}
                                  ]}>
                                  <DatePicker
                                    selected={deliveryDateTime}
                                    dateFormat="h:mm aa"
                                    onChange={time => {
                                      setDeliveryDateTime(time);
                                      form.setFieldsValue({expected_delivery_date_time: time}); // Update Ant Design Form
                                    }}
                                    shouldCloseOnSelect={false}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    className="form-control form-control-lg date-picker"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Form.Item>
                            <div className="form-label-group">
                              <label className="form-label">Note <span className="text-muted">(optional)</span></label>
                            </div>
                            <div className="form-control-wrap">
                            <Form.Item name={'note'}
                              noStyle
                              rules={[
                                {required: false}
                              ]}>
                                <TextArea rows={4} placeholder="Note" />
                              </Form.Item>                       
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }

              

              {
                showSupplierSection ?
                <>
                  {/* summary section */}
                  <div className="fm-content bg-lighter">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="nk-iv-scheme-details">
                          <ul className="nk-iv-wg3-list w-100">                        
                            <li className="px-0 py-2 fw-medium">
                              <div className="sub-text fs-16px">Sub Total</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-16px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={summaryInfo.sub_total.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                            <li className="px-0 py-2 d-block">
                              <div className="d-flex justify-content-between fw-medium">
                                <div className="sub-text fs-14px">Total Tax</div>
                                <div className="nk-wg-action-content p-0">
                                  <h4 className="amount fs-14px">
                                    <small className="text-muted me-1">{businessInfo.currency}</small> 
                                    <Numeral value={summaryInfo.tax_amount.toString()} format={"0,0.00"} />
                                  </h4>
                                </div>
                              </div>
                            </li>
                            <li className="px-0 py-2">
                              <div className="sub-text fs-14px">Discount</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-14px">
                                  <small className="text-muted me-1">- {businessInfo.currency}</small>
                                  <Numeral value={summaryInfo.discount_amount.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                            <li className="px-0 py-2 fw-bold">
                              <div className="sub-text fs-18px">Total</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-18px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={summaryInfo.total_amount.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="nk-iv-scheme-details">
                          <ul className="nk-iv-wg3-list w-100">
                            <li className="px-0 py-2">
                              <div className="sub-text fs-14px">Amount Paid</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className="amount fs-14px">
                                  <small className="text-muted me-1">{businessInfo.currency}</small> 
                                  <Numeral value={summaryInfo.amount_paid.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                            <li className="px-0 py-2 fw-bold">
                              <div className={`sub-text fs-18px ${summaryInfo.balance_due > 0 ? `text-danger`: ``}`}>Balance Due</div>
                              <div className="nk-wg-action-content p-0">
                                <h4 className={`amount fs-18px ${summaryInfo.balance_due > 0 ? `text-danger`: ``}`}>
                                  <small className={`${summaryInfo.balance_due > 0 ? `text-danger`: `text-muted`} me-1`}>{businessInfo.currency}</small>
                                  <Numeral value={summaryInfo.balance_due.toString()} format={"0,0.00"} />
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <div className="fm-content bg-lighter">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="nk-iv-scheme-details">
                        <ul className="nk-iv-wg3-list w-100">
                          <li className="px-0 py-2 fw-bold">
                            <div className="sub-text fs-18px">Total Amount</div>
                            <div className="nk-wg-action-content p-0">
                              <h4 className="amount fs-18px">
                                <small className="text-muted me-1">{businessInfo.currency}</small> 
                                <Numeral value={summaryInfo.total_amount.toString()} format={"0,0.00"} />
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
        

              <div className="fm-footer">
                <div className="text-center">
                  <Button disabled={requesting} className="m-1" type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : <span>Save</span>}
                  </Button>
                </div>
              </div>
            </div>

          </Form>
          
        </Block>
      </>
    }


    { 
      activeModal === "createNewProductModal" && 
      <CreateNewProductModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewProductModal')} updateProductList={updateProductList} showModal={true}  /> 
    }

    { 
      activeModal === "createNewMaterialModal" && 
      <CreateNewMaterialModal businessID={businessID} currency={businessInfo.currency} toggleModal={() => toggleModal('createNewMaterialModal')} updateMaterialList={updateMaterialList} showModal={true}  /> 
    }

    { 
      activeModal === "successProductModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The product has been created successfully.`} 
      />
    }

    { 
      activeModal === "successMaterialModal" &&
      <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
        headerText={`Created Successfully`} descriptionText={`The raw material has been created successfully.`} 
      />
    }

    </Content>
    
  </React.Fragment>)
}