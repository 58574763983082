import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert 
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, InputNumber, Divider, Space, Form } from 'antd';
import GooglePlacesInput from "./components/GooglePlacesInput";
import PhoneNumberInput from "./components/PhoneNumberInput";

const AddCustomer = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [form] = Form.useForm();
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [newDiscoverySource, setNewDiscoverySource] = useState("");
  const [categories, setCategories] = useState([]);
  const [discoverySources, setDiscoverySources] = useState(["Referral","Word of Mouth","Online Search","Facebook","Instagram","LinkedIn","TikTok","Twitter","WhatsApp"]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    customer_name: null,
    customer_code: null,
    phone_code: "+233",
    phone_number: null,
    email: null,
    location: "",
    lat: null,
    lng: null,
    discovery_source: null,
    category: [],
    credit: null,
    date_of_birth: null
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/customers/add`, {
      business_id: businessID,
      customer_name: _formData.customer_name,
      customer_code: _formData.customer_code,
      phone_number: `${_formData.phone_code}${_formData.phone_number?.replace(/^0+/, '')}`,
      email: _formData.email,
      location: _formData.location,
      lat: formData.lat,
      lng: formData.lng,
      date_of_birth: !_formData.date_of_birth ? null : `${moment(_formData.date_of_birth, "MMMM-DD").format("MMMM-DD")}`,
      category: JSON.stringify(_formData.category),
      discovery_source: _formData.discovery_source,
      credit: _formData.credit,
    }, getAxiosHeaders())
    .then((response) => {
      form.resetFields();
      toggleModal("successModal");
      setRequesting(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'customer_name',
              errors: errorResponse.errors.hasOwnProperty("customer_name") ? [errorResponse.errors.customer_name] : [],
            },
            {
              name: 'customer_code',
              errors: errorResponse.errors.hasOwnProperty("customer_code") ? [errorResponse.errors.customer_code] : [],
            },
            {
              name: 'phone_number',
              errors: errorResponse.errors.hasOwnProperty("phone_number") ? [errorResponse.errors.phone_number] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            },
            {
              name: 'location',
              errors: errorResponse.errors.hasOwnProperty("location") ? [errorResponse.errors.location] : [],
            },
            {
              name: 'discovery_source',
              errors: errorResponse.errors.hasOwnProperty("discovery_source") ? [errorResponse.errors.discovery_source] : [],
            },
            {
              name: 'category',
              errors: errorResponse.errors.hasOwnProperty("category") ? [errorResponse.errors.category] : [],
            },
            {
              name: 'credit',
              errors: errorResponse.errors.hasOwnProperty("credit") ? [errorResponse.errors.credit] : [],
            },
            {
              name: 'date_of_birth',
              errors: errorResponse.errors.hasOwnProperty("date_of_birth") ? [errorResponse.errors.date_of_birth] : [],
            }
            
          ]);

        }


        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
    
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  }

  const getCustomersCategory = (businessID) => {

    axios.get(baseURLs.API_URL + "/customers/category", {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let category = responseInfo.data.category;

        // Combine existing categories with new ones
        setCategories([...category]);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getCustomersDiscoverySources = (businessID) => {

    axios.get(baseURLs.API_URL + "/customers/discovery", {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let businessDiscoverySources = responseInfo.data.discovery_sources;
        const updatedDiscoverySources = businessDiscoverySources.filter(source => !discoverySources.includes(source));

        // Combine existing discovery sources with new ones
        setDiscoverySources(prevDiscoverySources => [...prevDiscoverySources, ...updatedDiscoverySources]);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const addNewCategory = (e) => {
    e.preventDefault();
    
    if(newCategory) {
      if(!categories.includes(newCategory)){
        setCategories([...categories, newCategory]);
      }
      setNewCategory("");
    }
  }

  const addNewDiscoverySource = (e) => {
    e.preventDefault();
    
    if(newDiscoverySource) {
      if(!discoverySources.includes(newDiscoverySource)){
        setDiscoverySources([...categories, newDiscoverySource]);
      }
      setNewDiscoverySource("");
    }
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getCustomersCategory(businessID);
    getCustomersDiscoverySources(businessID);
    loadGoogleMapsApi();
    setLoading(false);
  }, [match.params.businessID]);


  const loadGoogleMapsApi = () => {
    var element =  document.getElementById('googleapis');
    if (typeof(element) == 'undefined' || element == null) {
      const script = document.createElement('script');
      script.id = `googleapis`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCx2TgPL-NsY0vA94z7LwoanmGTiQ9N2t4&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsReady(true);
      // script.onerror = () => alert('Failed to load Google Maps. Please check your internet connection.');
      document.body.appendChild(script);
    }else{
      setGoogleMapsReady(true);
    }
    
  }

  return (<React.Fragment>
    <Head title="Add Customer" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Add Customer
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/import`}>
                        <Button color="primary">
                          <Icon name="upload-cloud"></Icon>
                          <span>Import Customers</span>
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/customers/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Customer Added`} descriptionText={`The customer has been added successfully.`} 
          />
        }

        <Block className="wide-sm m-auto">
          <Form name="add-customer"
            form={form} className="is-alter"
            initialValues={formData} 
            onFinish={handleFormSubmit}
            onFinishFailed={onFinishFailed}>
            <div className="card card-bordered">
              <div className="fm-head bg-lighter">
                <div className="fm-count"><Icon name="user-add fs-22px"></Icon></div>
                <div className="fm-title">
                  <h5 className="title fw-normal">Customer Details</h5>
                  <p className="nk-block-des">Use the form below to add a customer to your business.</p></div>
              </div>
              <div className="fm-content">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Customer Name <span className="text-danger">*</span></label>
                        </div>
                        <Form.Item name={'customer_name'}
                          noStyle
                          rules={[
                            {required: true, message: 'Customer name is required',}
                          ]}>
                          <Input size="large"
                            type="text" className="form-control form-control-lg" 
                            placeholder="Awesome Customer"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Customer Code <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'customer_code'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <Input size="large"
                            type="text" className="form-control form-control-lg" 
                            placeholder="eg. C123"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-control-wrap">
                        <PhoneNumberInput form={form} formData={formData} isRequired={true} setFormData={setFormData} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                            <label className="form-label">Email <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'email'}
                          noStyle
                          rules={[
                            {required: false}, {type: 'email', message: 'Email is invalid'}
                          ]}>
                          <Input size="large" 
                            type="email" name="email" className="form-control form-control-lg" 
                            placeholder="customer@email.com"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Birthday <span className="text-muted">(optional)</span></label>
                        </div>
                        <Form.Item name={'date_of_birth'}
                            noStyle
                            rules={[
                              {required: false},{type: 'date'}
                            ]}>
                          <DatePicker
                            selected={dateOfBirth}
                            onChange={(date) => {
                              setDateOfBirth(date);
                              form.setFieldsValue({date_of_birth: date});
                            }}
                            dateFormat="MMMM-dd"
                            autoComplete="off"
                            isClearable
                            placeholderText="Enter customer's date of birth"
                            // monthsShown={2}
                            className="form-control form-control-lg date-picker"
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Location <span className="text-muted">(optional)</span></label>
                      </div>
                      <GooglePlacesInput form={form} isRequired={false} googleMapsReady={googleMapsReady} formData={formData} setFormData={setFormData} countryRestrictionSearch={null} />
                    </div>
                  </div> 
                  <div className="col-md-12">
                    <hr className="m-3" />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Category <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'category'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <Select className="select-tags" size="large" 
                            mode="tags"
                            placeholder="Select category"
                            style={{ width: "100%" }} 
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0',
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px',
                                  }}
                                >
                                  <Input
                                    placeholder="New category"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                  />
                                  <Button className="btn-dim btn-blank" onClick={addNewCategory}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                  </Button>
                                </Space>
                              </>
                            )}
                            options={categories.map((category) => ({
                              key: category,
                              label: category,
                              value: category,
                            }))}
                            showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Source of Discovery <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'discovery_source'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                          <Select size="large" 
                            placeholder="Select source of discovery"
                            style={{ width: "100%" }} 
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0',
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px',
                                  }}
                                >
                                  <Input
                                    placeholder="New category"
                                    value={newDiscoverySource}
                                    onChange={(e) => setNewDiscoverySource(e.target.value)}
                                  />
                                  <Button className="btn-dim btn-blank" onClick={addNewDiscoverySource}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                  </Button>
                                </Space>
                              </>
                            )}
                            options={discoverySources.map((source) => ({
                              key: source,
                              label: source,
                              value: source,
                            }))}
                            showSearch />   
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <Form.Item>
                        <div className="form-label-group">
                          <label className="form-label">Credit 
                            <span className="text-muted"> (optional)</span> 
                            <Button type="button" id="credit_info_popover" className="btn-round btn-icon text-end" color="muted" size="sm">
                              <Icon name="info" />
                            </Button>
                            <UncontrolledPopover target="credit_info_popover" placement="bottom" trigger="focus">
                              <PopoverBody>
                                Credit is a balance from overpaid invoices, loyalty rewards, or giveaways that customers can use to reduce future purchase costs. Customers receive alerts when their credit changes.
                              </PopoverBody>
                            </UncontrolledPopover>
                          </label>
                        </div>
                        <div className="form-control-wrap">
                        <Form.Item name={'credit'}
                          noStyle
                          rules={[
                            {required: false}
                          ]}>
                            <InputNumber prefix={businessInfo.currency} min={0.00} step={0.01} size="large" className="w-100" /> 
                          </Form.Item>                       
                        </div>
                      </Form.Item>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="fm-footer">
                <div className="pt-2 text-center">
                  <Button disabled={requesting} type="submit" color="primary" size="lg" >
                    {requesting ? <Spinner size="sm" color="light" /> : "Add Customer"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default AddCustomer;