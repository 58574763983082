import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert } from "reactstrap";
import moment from "moment";
import { Upload, Input, Select, Checkbox, Form, Divider, Space } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { createSubdomain, findUpper, getAxiosHeaders, getAxiosUploadHeaders, getTextColorBaseOnTheme, phoneNumberFormatter, recommendedColors, validateSubdomain, validateURL } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';
import { CreateTaxProfileModal } from "../TaxModals";

const { Dragger } = Upload;
const { TextArea } = Input;

export const StorefrontSettings = ({ history, match }) => {

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [taxesList, setTaxesList] = useState([]);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isVerified, setIsVerified] = useState("not-verified");
  const [formError, setFormError] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const [formData, setFormData] = useState({
    url: "",
    description: "",
    tax_profile: "",
    socials: [
      {account: 'facebook', link: ''},
      {account: 'instagram', link: ''},
      {account: 'twitter', link: ''},
      {account: 'tiktok', link: ''},
      {account: 'youtube', link: ''},
      {account: 'snapchat', link: ''},
    ]
  });
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setFormError("");

    if(fileList.length === 0 && termsConditions.length === 0){
      setFormError('Terms and Conditions is required');
      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    const formInfo = new FormData();

    if(fileList.length > 0){
      fileList.forEach((file) => {
        formInfo.append('terms', file);
      });
    } else {
      formInfo.set("terms", termsConditions);
    }

    formInfo.set("url", _formData.url);
    formInfo.set("description", _formData.description);
    
    formInfo.set("tax_profile", _formData.tax_profile ?? '');
    
    let socials = _formData.socials;
    socials = socials.filter(obj => obj.link !== undefined && obj.link !== '');
    formInfo.set("socials", JSON.stringify(socials));

    axios.put(baseURLs.API_URL + `/businesses/storefront-settings/${businessID}`, formInfo, getAxiosUploadHeaders())
    .then((response) => {
      // let responseInfo = response.data;
      toggleModal("successModal");
      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'url',
              errors: errorResponse.errors.hasOwnProperty("url") ? [errorResponse.errors.url] : [],
            },
            {
              name: 'description',
              errors: errorResponse.errors.hasOwnProperty("description") ? [errorResponse.errors.description] : [],
            },
            {
              name: 'tax_profile',
              errors: errorResponse.errors.hasOwnProperty("tax_profile") ? [errorResponse.errors.tax_profile] : [],
            },
            {
              name: 'socials',
              errors: errorResponse.errors.hasOwnProperty("socials") ? [errorResponse.errors.socials] : [],
            }            
          ]);
          
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const updateTaxProfiles = () => {
    toggleModal('successTaxModal')
    getTaxProfiles(businessID);
  }

  const uploadProps = {
    fileList,
    maxCount: 1,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: file => {
      const isValidFile = 'application/pdf' === file.type;
      if (!isValidFile) {
        file.status = 'error';
        file.error = 'You can only upload a pdf file.';
        setFormError('You can only upload a .pdf file.');
      }
      
      const isSizeValid = file.size / 1024 / 1024 < 2;
      if (!isSizeValid) {
        file.status = 'error';
        file.error = 'File must be smaller than 2MB!';
        setFormError('File must be smaller than 2MB!');
      }

      if(isValidFile && isSizeValid){
        file.status = undefined; // Reset status
        file.error = undefined;
        setFormError("");
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }

      setFileList([file]);
      return false;
    },
  };

  useEffect(() => {
    if(fileList.length === 0){
      setFormError("");
    }
  }, [fileList])

  const getStoreSettings = (businessID) => {
    axios.get(baseURLs.API_URL + `/businesses/storefront-settings`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then(async(response) => {
      if (response.status === 204) {
        setIsVerified(businessInfo.is_verified);
      } else {      
        let responseInfo = response.data;
        let isVerified = responseInfo.data.is_verified;
        setIsVerified(isVerified);
        let storefrontSettings = responseInfo.data.storefront_info;

        let _formData = formData;
        _formData.url = storefrontSettings.url;
        _formData.description = storefrontSettings.description;
        _formData.tax_profile = storefrontSettings.tax_profile;

        let socials = [
          {account: 'facebook', link: ''},
          {account: 'instagram', link: ''},
          {account: 'twitter', link: ''},
          {account: 'tiktok', link: ''},
          {account: 'youtube', link: ''},
          {account: 'snapchat', link: ''}
        ];

        if(storefrontSettings.socials?.length > 0) {
          let socialAccounts = storefrontSettings.socials;

          socials.forEach((social, index) => {
            const matchingSocial = socialAccounts.find(socialAccounts => socialAccounts.account === social.account);
            if (matchingSocial) {
              socials[index].link = matchingSocial.link;
            }
          });
        
        } 

        _formData.socials = socials;

        if(storefrontSettings.terms) {
          setTermsConditions(storefrontSettings.terms);
        }

        setFormData(_formData);
      }

      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getTaxProfiles = (businessID) => {

    axios.get(baseURLs.API_URL + `/businesses/taxes`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let taxes = responseInfo.data.taxes;

        setTaxesList([...taxes]);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getStoreSettings(businessID);
    getTaxProfiles(businessID);
  }, []);


  return (
    <React.Fragment>
      <Head title="Storefront Settings"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Storefront Settings
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to update your storefront settings.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              formError &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {formError}{" "}
                  </Alert>
                </div>
              </Block>
            }
            
            <div className="card card-bordered">
              <div className="fm-content">
                {
                  isVerified !== 'verified' ?
                  <Block>
                    <div className="text-center m-5">
                      <div className="price-plan-media">
                        <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                      </div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">Verify your business to start receiving orders online.</span>
                      </div>
                      <div className="price-plan-action">
                        <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                          <button className="btn btn-outline-primary">Verify Business</button>
                        </Link>                    
                      </div>
                    </div>
                  </Block>
                  :
                  <Block>
                    <Form name="update-storefront-settings"
                      form={form} className="is-alter"
                      initialValues={formData} 
                      onFinish={handleFormSubmit}
                      onFinishFailed={onFinishFailed}>

                      <div className="row g-2">
                        <div className="col-md-12">
                          <div className="form-group">
                            <Form.Item>
                              <div className="form-label-group">
                                  <label className="form-label">URL<span className="text-danger">*</span>
                                    <Button type="button" id="storefront_url_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                      <Icon name="info" />
                                    </Button>
                                    <UncontrolledPopover target="storefront_url_info_popover" placement="bottom" trigger="focus">
                                      <PopoverBody>
                                        <div className="mb-2">Enter the subdomain you'd like to use for your Storefront. The subdomain should be unique and can contain letters, numbers, and hyphens (no spaces)</div>                              
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </label>
                              </div>
                              <Form.Item name={'url'}
                                noStyle
                                rules={[
                                  {required: true, message: 'URL is required'},
                                  { validator: (_, value) => validateSubdomain(value) }
                                ]}>
                                <Input size="large" addonBefore="https://" addonAfter=".keepup.store" placeholder={`${createSubdomain(businessInfo.name)}`} /> 
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <Form.Item>
                              <div className="form-label-group">
                                  <label className="form-label">Description 
                                    <span className="text-danger">*</span>
                                    <Button type="button" id="storefront_description_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                      <Icon name="info" />
                                    </Button>
                                    <UncontrolledPopover target="storefront_description_info_popover" placement="bottom" trigger="focus">
                                      <PopoverBody>
                                        <div className="mb-2">Describe your storefront here. This description will help search engines understand your business and attract more customers. Keep it concise and relevant to your products or services.</div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </label>
                              </div>
                              <Form.Item name={'description'}
                                noStyle
                                rules={[
                                  {required: true, message: 'Description is required'}
                                ]}>
                                <TextArea rows={4} placeholder="enter a description of the storefront" />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                        
                        <div className="col-md-12">
                          <div className="form-group ">
                            <div className="form-label-group">
                              <label className="form-label">Upload your Terms and Conditions <span className="text-danger">*</span></label>
                            </div>
                            <Alert className="alert-icon pointer-cursor my-3" color="warning" onClick={() => window.open(baseURLs.STOREFRONT_GENERIC_TERMS_AND_CONDITIONS, '_blank')}>
                              <Icon name="download-cloud" />
                              You can download and use this <b>generic Terms and Conditions PDF</b> for your storefront setup until you upload your own version. <a href="#" onClick={(ev) => { ev.preventDefault(); window.open(baseURLs.STOREFRONT_GENERIC_TERMS_AND_CONDITIONS, '_blank')}} className="alert-link">Click to Download</a>
                            </Alert>
                            <Dragger {...uploadProps}>
                              <div className="p-3">
                                <p className="ant-upload-drag-icon fs-1 text-muted">
                                  <Icon name="upload-cloud"></Icon>
                                </p>
                                <p className="ant-upload-text text-muted">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint text-muted">
                                  Accepted file type for upload must be .pdf format, and the file size should not exceed 2MB.
                                </p>

                              </div>
                            </Dragger>
                            {
                              termsConditions && 
                              <Alert className="alert-icon pointer-cursor my-3" color="light" onClick={() => {window.open(termsConditions, '_blank').focus()}}>
                                <Icon name="download-cloud" />
                                Terms and Conditions. <a href="#" onClick={(ev) => { ev.preventDefault(); window.open(termsConditions, '_blank').focus()}} className="alert-link">Click to Download</a>
                              </Alert>
                            }
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <Form.Item>
                              <div className="form-label-group">
                                <label className="form-label">Tax Profile 
                                  <span className="text-muted"> (optional)</span>
                                  <Button type="button" id="tax_profile_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                    <Icon name="info" />
                                  </Button>
                                  <UncontrolledPopover target="tax_profile_info_popover" placement="bottom" trigger="focus">
                                    <PopoverBody>
                                      <div className="mb-2">The tax profile determines the taxes applied to orders from your storefront.</div>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </label>
                              </div>
                              <div className="form-control-wrap">
                                <Form.Item name={'tax_profile'}
                                  noStyle
                                  rules={[
                                    {required: false}
                                  ]}>
                                  <Select size="large" 
                                    placeholder="Select a tax profile"
                                    style={{ width: "100%" }} 
                                    allowClear={true}
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: '8px 0',
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: '0 8px 4px',
                                          }}
                                        >
                                          <Button className="btn-dim btn-blank" onClick={() => {toggleModal('createTaxProfileModal')}}>
                                            <Icon name="plus" />
                                            <span>Create Tax Profile</span>
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                    options={taxesList.map(tax => (
                                      {
                                        key: tax.tax_id,
                                        label: tax.tax_profile_name,
                                        value: tax.tax_id,
                                      }
                                    ))}
                                    showSearch />   
                                </Form.Item>                       
                              </div>
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <hr className="m-3 mx-0" />
                          <h6 className="overline-title text-primary-alt mb-4 mt-4">Socials</h6>
                        </div>

                        <div className="col-md-12">
                          <div className="row">
                            {
                              formData.socials.map((social, index) => {
                                let accountName = '';
                                switch (social.account) {
                                  case 'instagram':
                                    accountName = "Instagram Link"
                                    break;

                                  case 'twitter':
                                    accountName = "Twitter Link"
                                    break;

                                  case 'tiktok':
                                    accountName = "Tiktok Link"
                                    break;

                                  case 'snapchat':
                                    accountName = "Snapchat Link"
                                    break;

                                  case 'youtube':
                                    accountName = "Youtube Link"
                                    break;
                                
                                  default:
                                    accountName = "Facebook Link"
                                    break;
                                }

                                return (
                                  <div className="col-md-6" key={index}>
                                    <div className="form-group">
                                      <Form.Item>
                                        <div className="form-label-group">
                                          <label className="form-label">{accountName} <span className="text-muted">(optional)</span></label>
                                        </div>
                                        <div className="form-control-wrap">
                                          <Form.Item name={['socials', index, 'link']}
                                            noStyle
                                            rules={[
                                              {required: false},
                                              { validator: (_, value) => validateURL(value) }
                                            ]}>
                                            <Input placeholder={accountName} size="large" />
                                          </Form.Item>  
                                          <Form.Item name={['socials', index, 'account']} hidden><Input type="hidden" /></Form.Item>                      
                                        </div>
                                      </Form.Item>
                                    </div>
                                  </div>
                                )

                              })
                            }

                          </div>
                        </div>
                      </div>
                      <div className="pt-5 text-center">
                        <Button disabled={requesting} type="submit" color="primary" size="lg" >
                          {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                        </Button>
                      </div>
                    </Form>
                  </Block>
                } 
              </div>
            </div>
          </Block>
        </>
      }
      </Content>
      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Updated Successfully`} descriptionText={`Storefront settings have been updated successfully.`} 
        />
      }

      {/* add new tax modal */}
      { 
        activeModal === "createTaxProfileModal" && 
        <CreateTaxProfileModal businessID={businessID} toggleModal={() => toggleModal('createTaxProfileModal')} updateTaxProfiles={updateTaxProfiles} showModal={true}  /> 
      }
    </React.Fragment>
  )
}