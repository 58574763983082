import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { findUpper, getTextColorBaseOnTheme, phoneNumberFormatter } from "../../../utils/Utils";
import {
  Icon,
  UserAvatar,
} from "../../../components/Component";
import imagePlaceholder from "../../../images/placeholder.png";
import verifiedBusinessBlackIcon from "../../../images/verified-business-black.png";
import verifiedBusinessWhiteIcon from "../../../images/verified-business-white.png";
import verifiedIndividualBlackIcon from "../../../images/verified-individual-black.png";
import verifiedIndividualWhiteIcon from "../../../images/verified-individual-white.png";

const Header = ({headerInfo, color, verificationStatus, verificationType, ...props }) => {
  const [textColor, setTextColor] = useState("#ffffff"); 

  useEffect(() => {    
    // Determine text color based on the theme color
    setTextColor(getTextColorBaseOnTheme(color));
  }, [color])


  return (
    <>
      <div className="fm-head position-relative justify-content-between" style={{backgroundColor: color}}>
        <div className="business-info d-flex" style={{zIndex: "1"}}>
          <div className="d-flex justify-content-center align-center me-2 w-25">
            <UserAvatar contained={false} theme="lighter" image={headerInfo.business_logo ?? imagePlaceholder} imagePlaceholder={imagePlaceholder} />
          </div>
          <div className="fm-title flex-column d-flex justify-content-center" style={{color: textColor}}>
            <h5 className="title fw-normal d-flex align-items-end" style={{color: textColor}}>{headerInfo.business_name} 
              {
                verificationStatus === 'verified' &&
                <>
                  {
                    verificationType === 'business' ?
                    <Popover content={`Verified as a Registered Business`} trigger="click" placement="bottomLeft">
                      <img src={textColor === '#000000' ? verifiedBusinessBlackIcon : verifiedBusinessWhiteIcon} width="25" height="25" className="ps-1" style={{objectFit: "contain"}} />                       
                    </Popover>
                    :
                    <Popover content={`Verified as an Individual`} trigger="click" placement="bottomLeft">
                      <img src={textColor === '#000000' ? verifiedIndividualBlackIcon : verifiedIndividualWhiteIcon} width="25" height="25" className="ps-1" style={{objectFit: "contain"}} />
                    </Popover>
                  }
                </>
              }
            </h5>
            <p className="sub-title mb-0">{headerInfo.business_location}</p>
            <small className="sub-title">{phoneNumberFormatter(headerInfo.business_phone_number)}</small>
          </div>
        </div>
        <div className="fm-title text-end d-none d-md-block" style={{zIndex: "1", color: textColor}}>
          <h5 className="title fw-normal text-uppercase" style={{color: textColor}}>{headerInfo.status}</h5>
          <p className="sub-title mb-0">#{headerInfo.sale_number}</p>
          <small className="sub-title">{moment(headerInfo.updated_at).format("Do MMM, YYYY h:mm a")}</small>
        </div>
      </div>

      <div className="fm-title ps-4 pe-4 pt-2 pb-2 d-flex d-md-none justify-content-between" style={{color: textColor, backgroundColor: color, position: "relative"}}>
        <div style={{zIndex: "1"}}>
          <h5 className="title text-center fw-normal text-uppercase" style={{color: textColor}}>{headerInfo.status}</h5>
          <p className="sub-title mb-0">#{headerInfo.sale_number}</p>
        </div>
        <small className="sub-title" style={{lineHeight: "4", zIndex: "1"}}>{moment(headerInfo.updated_at).format("Do MMM, YYYY h:mm a")}</small>
      </div>

      <div className="fm-head bg-lighter">
        <div className="me-1">
          {
            headerInfo.feedback.rate == null ?
            <em className={classNames("icon ni ", {"ni-happy text-muted" : headerInfo.feedback.rate == null}, {"ni-sad text-danger" : headerInfo.feedback.rate == 'bad'}, {"ni-meh text-dark" : headerInfo.feedback.rate == 'okay'}, {"ni-happy text-success" : headerInfo.feedback.rate == 'excellent'})} style={{fontSize: "45px"}}></em>
            :
            <Popover content={headerInfo.feedback.message} title="Feedback" trigger="click" placement="bottomLeft">
              <em className={classNames("icon ni ", {"ni-happy text-muted" : headerInfo.feedback.rate == null}, {"ni-sad text-danger" : headerInfo.feedback.rate == 'bad'}, {"ni-meh text-dark" : headerInfo.feedback.rate == 'okay'}, {"ni-happy text-success" : headerInfo.feedback.rate == 'excellent'})} style={{fontSize: "45px"}}></em>
            </Popover>
          }
        </div>
        {
          headerInfo.customer_phone_number === null ?
          <div className="fm-title">
            <span className="sub-title text-muted">Made to:</span>  
            <h5 className="title fw-normal">
              {headerInfo.customer_name} 
              {
                headerInfo.viewed > 0 && <small><em className="text-muted icon ni ni-eye ms-1" title="Viewed"></em></small>
              }
            </h5>
            {/* <p className="sub-title mb-0">{headerInfo.customer_phone_number}</p> */}
            <small className="text-muted">Issued By: {headerInfo.issued_by}</small>
          </div>
          :
          <Link to={`/customers/b/${headerInfo.business_id}?p=1&ps=${headerInfo.customer_phone_number.replace(/ /g,'')}`}>
            <div className="fm-title">
              <span className="sub-title text-muted">Made to:</span>  
              <h5 className="title fw-normal">
                {headerInfo.customer_name} 
                {
                  headerInfo.viewed > 0 && <small><em className="text-muted icon ni ni-eye ms-1" title="Viewed"></em></small>
                }
              </h5>
              <p className="sub-title mb-0">{phoneNumberFormatter(headerInfo.customer_phone_number)}</p>
              <small className="text-muted">Issued By: {headerInfo.issued_by}</small>
            </div>
          </Link>
        }
        
      </div>
    </>
  );
};


export default Header;