import React from "react";
import classNames from "classnames";

const Content = ({ className, ...props }) => {
  const contentClass = classNames({
    "nk-content-wrap": true,
    [`${className}`]: className,
  });

  return (
    <div className={contentClass}>
      {!props.page ? props.children : null}
      {props.page === "component" ? <div className="components-preview wide-md mx-auto">{props.children}</div> : null}
    </div>
  );
};
export default Content;
