import React from "react";
import { baseURLs } from '../../utils/Constants';
import packageJson from '../../../package.json';

const Footer = () => {
  return (
    <div className="nk-footer border-top pt-4">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            {" "}
            &copy; {new Date().getFullYear()} Keepup Store. All Rights Reserved.
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noreferrer" href={`${baseURLs.KUS_URL}/release-notes`}> Version: {packageJson.version}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noreferrer" href={`${baseURLs.KUS_URL}/terms-and-conditions`}> Terms and Conditions</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noreferrer" href={`${baseURLs.KUS_URL}/privacy-policy`}> Privacy Policy</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" rel="noreferrer" href="#" onClick={(e) => { e.preventDefault(); window.openChat(); }}> Live Support </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noreferrer" href={`https://docs.keepup.store`}> Tutorials</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
