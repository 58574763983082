import React from "react";
import classNames from "classnames";
import Icon from "../icon/Icon";

// Aviable sizes {xs,sm,md,lg,xl}
/* Aviable theme {blue-dim, blue, azure-dim, azure, indigo-dim, indigo, purple-dim, purple, pink-dim, pink, orange-dim, orange, teal-dim, teal, 
    primary-dim, primary, secondary-dim, secondary, success-dim, success, info-dim, info, warning-dim, warning, danger-dim, danger, dark-dim, dark, gray-dim, gray, lighter, light} */

const UserAvatar = ({ className, contained = true, size, theme, icon, text, image, imageAlt, imagePlaceholder, ...props }) => {
  const classes = classNames({
    "user-avatar": true,
    [`${className}`]: className,
    [`user-avatar-${size}`]: size,
    [`bg-${theme}`]: theme,
  });
  return (
    <>
      {
        contained ?
        <div className={classes}>
          {icon ? <Icon name={icon} /> : null}
          {image && <img src={image} alt={imageAlt} onError={e => { e.currentTarget.src = imagePlaceholder; }} />}
          {text && !image && <span>{text}</span>}
          {props.children}
        </div>
        :
        <>
          {image && <img className="w-100" src={image} alt={imageAlt} onError={e => { e.currentTarget.src = imagePlaceholder; }} />}
          {text && !image && <span>{text}</span>}
        </>
      }
    </>
  );
};

export default UserAvatar;
