import React, { useState, useEffect } from "react";
import { Empty } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAxiosHeaders } from "../../../utils/Utils";
import { Badge } from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import { TableLoader } from "../../../utils/Loaders";

export const CustomerSalesTable = ({ history, businessID, customerPhoneNumber, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, sales:[]});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
 
  const getSales = () => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/sales", {
      params: {
        business_id: businessID,
        phone_number: customerPhoneNumber
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTableData({meta: {total_records: 0}, sales:[]});
      } else {
        let responseInfo = response.data;
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTableData({meta: {total_records: 0}, sales:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    getSales();
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
        {
          tableData.sales.length === 0 ?
          <div className="nk-block">
            <div className="text-center m-5">
              <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
              <div className="price-plan-info">
                <h5 className="title fw-normal">No sales found</h5><span>Record a sale by sending an invoice, a quote, or a receipt to your customers.</span>
              </div>
              <div className="price-plan-action">
                <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}`}>
                  <button className="btn btn-outline-primary">Create Invoice / Receipt</button>
                </Link>                    
              </div>
            </div>
          </div>
          :
          <div className="nk-block">
            <h6 className="lead-text mb-3">
              {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Records` : `Record`}`}
            </h6>
            <div className="nk-tb-list nk-tb-ulist is-compact border round-sm">
              <div className="nk-tb-item nk-tb-head">
                <div className="nk-tb-col">
                  <span className="sub-text">#</span>
                </div>
                <div className="nk-tb-col">
                  <span className="sub-text">Total</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">Status</span>
                </div>
                <div className="nk-tb-col">
                  <span className="sub-text">Date</span>
                </div>
              </div>
              
                {
                  tableData.sales.map((data, index) => {
                    let salesStatus = <span className="text-end"><Badge className="badge-dot" color="success"><span className="d-none d-sm-block">Paid</span></Badge></span>;                            
                    switch (data.status) {
                      case 'invoice':
                        salesStatus = data.amount_paid === "0.00" ? <span className="text-end"><Badge className="badge-dot" color="warning"><span className="d-none d-sm-block">Unpaid</span></Badge></span> : <span className="text-end"><Badge className="badge-dot" color="warning"><span className="d-none d-sm-block">Part-Paid</span></Badge></span>;
                        break;
                      
                      case 'quote':
                        salesStatus = <span className="text-end"><Badge className="badge-dot" color="gray"><span className="d-none d-sm-block">Quote</span></Badge></span>;
                        break;
                    }

                    let amount = <span className="amount">
                                    <small className="text-muted">{currency} </small>
                                    {data.amount_paid}
                                    <small className="text-muted fw-bold"> / {data.total_amount}</small>
                                  </span>;
                    
                    if(data.amount_paid === data.total_amount){
                      amount = <span className="amount">
                                  <small className="text-muted">{currency} </small>
                                  {data.amount_paid}
                                </span>;
                    }

                    return (
                      <div className="nk-tb-item pointer-cursor" key={data.sale_id} onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`)}}>
                        <div className="nk-tb-col">
                          <span className="text-primary fw-bold">#{data.sale_number}</span>
                        </div>
                        <div className="nk-tb-col">
                          {amount}
                          <span className="d-sm-none ms-1">{salesStatus}</span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          {salesStatus}
                        </div>
                        <div className="nk-tb-col">
                          <span className="sub-text">{moment(data.updated_at).format("Do MMM, YYYY")}</span>
                        </div>
                      </div>
                    )
                  })
                }
                
            </div>
            {
              tableData.meta.total_records > 10 &&
              <div className="nk-block price-plan-action text-center">
                <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?p=1&ps=${customerPhoneNumber}`}>
                  <button className="btn btn-outline-light">View More</button>
                </Link>
              </div>
            }
          </div>
        }
        </>
      }
    </Block>
  );
};
