import React from "react";
import {  
  Pagination, 
  PaginationItem, 
  PaginationLink 
} from "reactstrap";


export const PaginationWithOnclick = ({ currentPage, pageCount, loadNextPage }) => {
  let items = [];

  let delta = 2,
    left = parseInt(currentPage) - delta,
    right = parseInt(currentPage) + delta + 1,
    result = [];

  result = Array.from({ length: parseInt(pageCount) }, (v, k) => k + 1)
  .filter((i) => i && i >= left && i < right);

  if (result[0] == currentPage) {
    items.push(
      <PaginationItem disabled key="prev">
        <PaginationLink href="#prev" onClick={(ev) => ev.preventDefault()} aria-label="Prev">
          Prev
        </PaginationLink>
      </PaginationItem>
    );
  } else {
    items.push(
      <PaginationItem key="prev">
        <PaginationLink href="#prev" onClick={(ev) => {ev.preventDefault(); loadNextPage('1');}}> Prev </PaginationLink>
      </PaginationItem>
    );
  }

  for (let x = 0; x < result.length; x++) {
    if (result[x] == currentPage) {
      items.push(
        <PaginationItem active key={result[x]}>
          <PaginationLink href={`#page-${result[x]}`} onClick={(ev) => {ev.preventDefault(); loadNextPage(result[x]);}}> {result[x]} </PaginationLink>
        </PaginationItem>
      );
    } else {
      items.push(
        <PaginationItem key={result[x]}>
          <PaginationLink href={`#page-${result[x]}`} onClick={(ev) => {ev.preventDefault(); loadNextPage(result[x]);}}> {result[x]} </PaginationLink>
        </PaginationItem>
      );
    }
  }

  if (pageCount == currentPage) {
    items.push(
      <PaginationItem disabled key="last">
        <PaginationLink href="#last" onClick={(ev) => ev.preventDefault()} aria-label="Prev">
          Last
        </PaginationLink>
      </PaginationItem>
    );
  } else {
    items.push(
      <PaginationItem key="last">
        <PaginationLink href="#last" onClick={(ev) => {ev.preventDefault(); loadNextPage(pageCount);}}> Last </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <Pagination aria-label="page-nav" listClassName="justify-content-center m-4">
      {items}
    </Pagination>
  );
};