import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import classnames from "classnames";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert,
  Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, phoneNumberFormatter } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import MessageNav from "./components/messages/MessageNav";
import MessageBody from "./components/messages/MessageBody";

const MessageCustomers = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [usage, setUsage] = useState({used: 0, remaining: 0, package: ''});
  const [aside, setAside] = useState(false);
  const [navData, setNavData] = useState([]);
  const [currentTab, setCurrentTab] = useState("customers");
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getUsage = (businessID) => {
    setLoadingUsage(true);
    axios.get(baseURLs.API_URL + `/businesses/subscription-usage`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let subscriptionPackage = responseInfo.data.plan_name;
      let messages = responseInfo.data.messages;
      setUsage({
        used: messages.used, 
        remaining: messages.remaining,
        package: subscriptionPackage
      });
      setLoadingUsage(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }
        
        setLoadingUsage(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    const _businessID = parseInt(match.params.businessID, 10);
    const _customerID = parseInt(match.params.customerID, 10);
    if ( !Number.isInteger(_businessID) && !Number.isInteger(_customerID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', _businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(_businessID);

    // get message usage stats
    getUsage(_businessID);
    setLoading(false);

    resizeFunc();
    window.addEventListener("resize", resizeFunc);
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, [match.params.businessID]);

  const resizeFunc = () => {
    setAside(false);
  };



  return (<React.Fragment>
    <Head title="Message Customers" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Message Customers
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      {
                        loadingUsage ?
                        <button className={`btn btn-outline-light`}>
                          <span>SMS/Email Remaining: <b>...</b></span>
                          <Icon name="meter" />
                        </button>
                        :
                        <button className={`btn btn-outline-${usage.remaining < 10 ? `danger` : `light`}`}>
                          {/* {
                            usage.package === 'expansion' ?
                            <span>SMS / Email Used: <b>{usage.used}</b></span>
                            :
                          } */}
                          <span>SMS / Email Remaining: <b>{usage.remaining}</b> </span>
                          <Icon name="meter" />
                        </button>
                        // <Link to={`${process.env.PUBLIC_URL}/buy-messages/b/${businessID}`}>
                        // </Link>
                      }
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/buy-messages/b/${businessID}`}>
                        <Button color={`primary`}>
                          <Icon name="chat"></Icon>
                          <span>Buy Messages</span>
                        </Button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        
        <div className="nk-ibx">
          <MessageNav
            aside={aside}
            setAside={setAside}
            navData={navData}
            setNavData={setNavData}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            toggleModal={toggleModal}
          />
          {aside && <div className="toggle-overlay" onClick={() => setAside(!aside)}></div>}
          <MessageBody
            aside={aside}
            setAside={setAside}
            currentTab={currentTab}
            toggleModal={toggleModal}
            getUsage={getUsage}
            history={history} businessInfo={businessInfo}
          />
        </div>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default MessageCustomers;