import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Link } from "react-router-dom";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../../components/Component";
import { UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, Spinner, Alert } from "reactstrap";
import { Input, Select, Checkbox, Form } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import { getAxiosHeaders } from "../../../utils/Utils";
import { SuccessModal } from "../AlertModals";
import classNames from "classnames";
import axios from 'axios';

export const SMSSenderName = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [businessVerification, setBusinessVerification] = useState("not-verified");
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");
  const [senderName, setSenderName] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleFormSubmit = (_formData) => {
    setRequesting(true);
    setFormError("");

    axios.post(baseURLs.API_URL + `/businesses/sms-sender-name/${businessID}`, {
      sender_name: _formData.sender_name
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      toggleModal("successModal");
      setRequesting(false);
      getBusinesses();
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'sender_name',
              errors: errorResponse.errors.hasOwnProperty("sender_name") ? [errorResponse.errors.sender_name] : [],
            },
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('Failed:', errorInfo);
  };

  const getBusinesses = (businessID) => {
    axios.get(baseURLs.API_URL + "/businesses", getAxiosHeaders())
    .then(async(response) => {
      if (response.status === 204) {
        history.push(`${process.env.PUBLIC_URL}/create-business`);
        return;

      }

      let responseInfo = response.data;
      let businesses = responseInfo.data.businesses;
      
      await localStorage.setItem('my_businesses', JSON.stringify(businesses));
      localStorage.setItem('current_business_id', businessID);
      let currentBusiness = businesses?.find((business) => business.business_id == businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setBusinessVerification(currentBusiness.is_verified);
      setBusinessInfo(currentBusiness);
      setLoading(false);
      
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        if(error.response.status === 307){
          history.push(`${process.env.PUBLIC_URL}/invitations`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setFormError(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getBusinesses(businessID);
  }, [match.params.businessID]);

  const validateSenderName = (rule, value) => {
    const senderNameRegex = /^(?=.*[a-zA-Z \-])([a-zA-Z0-9 \-]{3,11})$/;
    if (!value) {
        return Promise.resolve();
    }
    if (!senderNameRegex.test(value)) {
        return Promise.reject('Sender name must be 3 to 11 alphanumeric characters.');
    }
    if (value.toLowerCase().includes('keepup')) {
        return Promise.reject("Sender name cannot contain the word 'keepup'.");
    }
    return Promise.resolve();
  };



  return (
    <React.Fragment>
      <Head title="SMS Sender Name"></Head>
      <Content>
      {
        loading ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <>
          <BlockHead className="wide-sm m-auto mb-4" size="sm">
            <BlockHeadContent className="text-center">
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                SMS Sender Name
              </BlockTitle>
              <BlockDes>
                <p>Use the form below to set your custom SMS sender Name. Please note that you can change your sender name up to three times, with each change subject to verification.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block className="wide-sm m-auto">
            {
              formError &&
              <Block>
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {formError}{" "}
                  </Alert>
                </div>
              </Block>
            }

            <div className="card card-bordered">
              <div className="fm-content">
                {
                  businessVerification !== 'verified' ?
                  <Block>
                    <div className="text-center m-5">
                      <div className="price-plan-media">
                        <Icon name="shield-star" style={{fontSize: "45px"}}></Icon>
                      </div>
                      <div className="price-plan-info">
                        <span className="title fw-normal">Verify your business to set your SMS sender name.</span>
                      </div>
                      <div className="price-plan-action">
                        <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                          <button className="btn btn-outline-primary">Verify Business</button>
                        </Link>                    
                      </div>
                    </div>
                  </Block>
                  :
                  <Block>
                    { 
                      businessInfo.sms_sender_status === 'failed' &&
                      <Alert color="danger" className="alert-icon mb-5">
                        <Icon name="na" />
                        <h6>Sender Name Verification Failed</h6>
                        <p>The Sender name you entered could not be verified. Please try a different Sender name.</p>
                        <p className="pointer-cursor"><span className="alert-link" onClick={() => {window.Tawk_API.toggle();}}>Contact Support for assistance. </span></p>
                      </Alert>
                    }

                    {
                      businessInfo.sms_sender_status === 'verified' &&
                      <Alert color="success" className="alert-icon mb-5">
                        <Icon name="check-circle-cut" />
                        <h6>Verified</h6>
                        <p>Your SMS Sender name has been verified.</p>
                      </Alert>
                    }

                    {
                      businessInfo.sms_sender_status === 'pending' &&
                      <Alert color="warning" className="alert-icon mb-5">
                        <Icon name="info" />
                        <h6>Pending Verification</h6>
                        <p>Your SMS Sender name verification is pending.</p>
                      </Alert>
                    }
                    
                    <Form name="update-business"
                      form={form} className="is-alter"
                      initialValues={
                        {sender_name: businessInfo.sms_sender_name}
                      } 
                      onFinish={handleFormSubmit}
                      onFinishFailed={onFinishFailed}>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <Form.Item>
                              <div className="form-label-group">
                                  <label className="form-label">SMS Sender Name 
                                    <span className="text-danger">*</span>
                                    <Button type="button" id="sender_name_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                      <Icon name="info" />
                                    </Button>
                                    <UncontrolledPopover target="sender_name_info_popover" placement="bottom" trigger="focus">
                                      <PopoverBody>
                                        <div className="mb-2">SMS sender name is the name or number that will be displayed as the sender when you send SMS messages to your customers. A personalized sender name helps build trust and brand recognition, making it clear to your customers that the messages are coming from your business.</div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </label>
                              </div>
                              <Form.Item name={'sender_name'}
                                noStyle                      
                                rules={[
                                  {required: true, message: 'Sender name is required',},
                                  { validator: validateSenderName }
                                ]}>
                                <Input size="large"
                                  type="text" className="form-control form-control-lg" 
                                  disabled={businessInfo.sms_sender_status === 'pending' || businessInfo.sms_name_changes === 3}
                                  placeholder="AwesomeBiz"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      {
                        ['not-verified','failed'].includes(businessInfo.sms_sender_status || businessInfo.sms_name_changes < 3) &&
                        <div className="pt-5 text-center">
                          <Button disabled={requesting} type="submit" color="primary" size="lg" >
                            {requesting ? <Spinner size="sm" color="light" /> : "Save Changes"}
                          </Button>
                        </div>
                      }
                    </Form>
                  </Block>
                }
              </div>
            </div>
          </Block>
        </>
      }
      </Content>


      
      {/* success modal */}
      { 
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Updated Successfully`} descriptionText={`SMS Sender name has been updated successfully.`} 
        />
      }
    </React.Fragment>
  )
}