import React, { useEffect, useState, useLayoutEffect } from "react";
import Pages from "../route/Index";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import classNames from "classnames";
import NotificationManager from '../NotificationManager';
import SubscriptionAlert from "./header/SubscriptionAlert";

const Layout = () => {
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const [themeState, setThemeState] = useState({
    main: "default",
    header: "white",
    skin: localStorage.getItem("theme") === null ? "light" : localStorage.getItem("theme"),
  });

  // State to manage internet connection status
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    // Add event listeners for online/offline
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!visibility) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [visibility]);

  useEffect(() => {
    document.body.className = `nk-body bg-white npc-default has-aside no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : " "
    }`;

    let noSideNavLinks = ['/my-businesses', '/create-business', '/invitations','/pos/b/']
    let matched = noSideNavLinks.find((link) => window.location.pathname.includes(link));
    if ( matched ) {
      document.body.classList.add("apps-only");
      setShowSideNav(false);
    } else {
      document.body.classList.remove("apps-only");
      setShowSideNav(true);
    }

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);

    // Cleanup
    return () => {
      window.removeEventListener("load", viewChange);
      window.removeEventListener("resize", viewChange);
    };

  }, [window.location.pathname, themeState]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };

  const sidebarClass = classNames({
    "mobile-menu": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });

  return (
    <React.Fragment>
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-wrap">
            <Header
              sidebarToggle={toggleSidebar}
              setVisibility={setVisibility}
              fixed={true}
              showSideNav={showSideNav}
              setThemeState={setThemeState}
              themeState={themeState}
              theme={themeState.header}
              />
            { showSideNav && 
              <SubscriptionAlert />
            }
            <NotificationManager />
            <div className="nk-content">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  { showSideNav && <Sidebar
                    sidebarToggle={toggleSidebar}
                    visibility={visibility}
                    mobileView={mobileView}
                    fixed
                    theme="light"
                    className={sidebarClass}
                  /> }
                  {visibility && mobileView && <div className="toggle-overlay" onClick={(e) => toggleSidebar(e)} />}
                  <div className="nk-content-body">
                    <Pages />
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !isOnline &&
        <div className="pmo-lv pmo-dark active bg-danger">
          {/* <a className="pmo-close" href="#"><em className="ni ni-cross"></em></a> */}
          <a className="pmo-wrap justify-content-center px-5 px-lg-3" target="_blank" href="https://softnio.com/get-early-access/">
            <div className="pmo-text text-white text-center">
              <em className="ni ni-alert-c pe-2"></em> You are offline. Some features may not work.
            </div>
          </a>
        </div>
      }
    </React.Fragment>
  );
};
export default Layout;
