import React, { useState, useEffect } from "react";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { setCookie, getCookie } from "../../utils/Utils";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import Logo from "../../layout/logo/Logo";
import { Input, Form } from 'antd';
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLoginButton from "../components/GoogleLogin";

const Register = ({ history }) => {
  
  const [loading, setLoading] = useState(false);
  const [loadingGoogleLogin, setLoadingGoogleLogin] = useState(false);
  const [country, setCountry] = useState("Ghana");
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleFormSubmit = (_formData) => {

    if(!recaptchaValue) {
      setFormError("Please verify you are not a robot!");
      return;
    }

    setLoading(true);
    setFormError("");
    
    let promo_code = getCookie('promo_code');
		if(document.cookie.indexOf('promo_code') < 0){
			promo_code = null;
		}

    axios.post(baseURLs.API_URL + "/auth/register", {
      full_name: _formData.full_name,
      email: _formData.email,
      password: _formData.password,
      country: country,
      promo_code: promo_code,
      source: "web",
      bot_check: recaptchaValue
    })
		.then((response) => {
      window.location = `${process.env.PUBLIC_URL}/pending-account-verification`;
    }).catch((error) => {
      setLoading(false);
      setFormError("Error creating account.");

      try{
        let errorResponse = error.response.data;

				if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'full_name',
              errors: errorResponse.errors.hasOwnProperty("full_name") ? [errorResponse.errors.full_name] : [],
            },
            {
              name: 'email',
              errors: errorResponse.errors.hasOwnProperty("email") ? [errorResponse.errors.email] : [],
            },
            {
              name: 'password',
              errors: errorResponse.errors.hasOwnProperty("password") ? [errorResponse.errors.password] : [],
            }            
          ]);					
				}

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setFormError(errorMessage);

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      
		});
  };

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    if(localStorage.getItem("country") === null){
      axios.get('https://ipapi.co/json/').then((response) => {
        let responseInfo = response.data;
        setCountry(responseInfo.country_name);
      }).catch((error) => {
        console.log(error);
        setCountry("Ghana");
      });
    } else {
      setCountry(localStorage.getItem("country"));
    }
  }, []);

  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Logo />
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent className="text-center">
                <BlockTitle tag="h4">Let's get you started</BlockTitle>
                <BlockDes>
                  <p>Create a new Keepup Store account</p>
                </BlockDes>
                <GoogleOAuthProvider clientId={baseURLs.GOOGLE_CLIENT_ID}>
                  <GoogleLoginButton 
                    buttonText="Continue with Google"
                    loadingGoogleLogin={loadingGoogleLogin} 
                    setLoadingGoogleLogin={setLoadingGoogleLogin} 
                    setFormError={setFormError} 
                    country={country} 
                  />
                </GoogleOAuthProvider>
                <div className="text-center pt-4 pb-3">
                  <h6 className="overline-title overline-title-sap">
                    <span>or sign up with email</span>
                  </h6>
                </div>
              </BlockContent>
            </BlockHead>
            {formError && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {formError}{" "}
                </Alert>
              </div>
            )}
            
            <Form form={form} className="is-alter"
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}>
              <div className="form-group">
                <Form.Item>
                  <label className="form-label" htmlFor="name">
                    Full name
                  </label>
                  <div className="form-control-wrap">
                    <Form.Item name={'full_name'}
                      noStyle
                      rules={[
                        {required: true, message: 'Full name is required'}
                      ]}>
                      <Input size="large"
                        type="text" className="form-control form-control-lg" 
                        placeholder="Enter your full name" />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'email'}
                      noStyle
                      rules={[
                        {required: true, message: 'Email is required'},
                        {type: "email", message: 'Email is invalid'}
                      ]}>
                      <Input size="large"
                        type="email" className="form-control form-control-lg" 
                        placeholder="Enter your email" />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'password'}
                      noStyle
                      rules={[
                        {required: true, message: 'Password is required'},
                        { type: 'string', min: 6 }
                      ]}>
                      <Input.Password size="large"
                        type="password" placeholder="Enter your password"
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="form-group center-recaptcha">
                <ReCAPTCHA
                  sitekey="6LdjnpAoAAAAAOR8pa8bCC2FxhoLfjiqcsFbIrE2"
                  onChange={value => setRecaptchaValue(value)}
                />
              </div>
              <div className="form-group">
                <p>By registering I agree to Keepup Store's 
                  <a target="_blank" rel="noreferrer" href={`${baseURLs.KUS_URL}/privacy-policy`}> Privacy Policy</a> &amp; 
                  <a target="_blank" rel="noreferrer" href={`${baseURLs.KUS_URL}/terms-and-conditions`}> Terms and Conditions.</a>
                </p>
              </div>
              <div className="form-group">
                <Button type="submit" color="primary" size="lg" className="btn-block" disabled={loadingGoogleLogin}>
                  {loading ? <Spinner size="sm" color="light" /> : "Create Account"}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Already have an account?{" "}
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                Sign in instead
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
